import React, { useEffect, useState } from 'react';
import { createSearchParams, Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../components/commonUI/LoadInitError';
import NoData from '../../../components/commonUI/NoData';

import arrowRight from 'app/assets/svg/arrow_right.svg';
import searchUser from 'app/assets/svg/search_user.svg';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import InputField from '../../../components/commonUI/InputField';
import useDebounce from '../../../hooks/useDebounce';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import { formatToJapaneseDate, getNameFromFirstLast, getNameKanji } from '../../../utils';
import { searchAllCustomerPagination } from '../../../services/api/14';
import Pagination from '../../../components/common/Pagination';

function CustomerResultItem({ customer }) {
  const isConfirmed = !!customer?.salonCustomers?.[0]?.isConfirmAgreement;
  const location = useLocation();
  return (
    <Link
      onClick={() => {
        GA.tracking(EVENT_NAMES.CUSTOMERS.CLICK_CUSTOMER_ITEM, {
          customerName: `${customer.lastNameKanji ?? ''}${customer.firstNameKanji ?? ''}`,
          customerId: customer.id,
        });
      }}
      to={
        Path.customerManagementDetail(customer.id) +
        '?' +
        createSearchParams({ from: location.pathname + location.search }).toString()
      }
      className="w-full h-auto"
    >
      <div className="w-full h-full flex items-center justify-between gap-3 shadow-item rounded-xl p-4">
        <div className="w-full h-full flex flex-col gap-2">
          <p
            className={`${isConfirmed ? 'text-blue-btn-primary bg-[#DEF4FF]' : 'bg-gray-base'} w-fit px-3 py-1 rounded-full`}
          >
            {isConfirmed ? '同意書締結済み' : '同意書未締結'}
          </p>
          <div className="w-full">
            {getNameKanji(customer)}({getNameFromFirstLast(customer)})
          </div>
          <span className="text-subtitle text-xs">登録日：{formatToJapaneseDate(customer.createdAt)}</span>
        </div>
        <img src={arrowRight} alt="arrow-right" className="w-full h-6 max-w-[24px]" />
      </div>
    </Link>
  );
}

function CustomerManagementSearchResult(props) {
  const { searchState, setSearchFieldValue, searchValues } = props;

  return (
    <div className="w-full h-full relative">
      <div className="md:pt-6 md:px-10">
        <NavigationBackBtn
          url={Path.home}
          text="お客様情報一覧"
          actionButton={
            <Link
              className="button-size bg-blue-btn-primary w-20 h-9 rounded-lg"
              onClick={() => {
                GA.tracking(EVENT_NAMES.CUSTOMERS.CREATE);
              }}
              to={Path.customerManagementRegisterNew}
            >
              <span className="text-white">新規登録</span>
            </Link>
          }
        />
      </div>

      <div className="pt-4 px-4 md:pt-6 md:px-10">
        <InputField
          placeholder="お名前で検索"
          inputClasses="rounded-full pl-12"
          prefixIcon={<img src={searchUser} alt="search-user" />}
          name="name"
          value={searchValues.name}
          onChange={(newValue) => setSearchFieldValue('name', newValue)}
        />
      </div>

      <div className="py-3 md:pb-5">
        {searchState.isLoading ? <LoadingProgress /> : null}
        {!searchState.isLoading && searchState.error ? <LoadInitError /> : null}
        {searchState.isLoading || searchState.error ? null : Array.isArray(searchState.data?.data) &&
          searchState.data?.data?.length > 0 ? (
          <div>
            <div className="w-full overflow-auto grid grid-cols-1 md:grid-cols-2 gap-4 px-4 py-3 md:px-10 md:py-5">
              {searchState.data.data.map((customer) => (
                <CustomerResultItem key={customer.id} customer={customer} />
              ))}
            </div>
            <Pagination totalPage={searchState.data.totalPages} />
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
}

function CustomerManagementTopPage() {
  const navigate = useNavigate();
  const [searchState, setSearchState] = useState({
    isLoading: false,
    searchConditions: {},
    data: null,
    error: null,
  });
  const [param] = useSearchParams();
  const page = parseInt(param.get('page') || '1', 10);
  const [searchValues, setSearchValues] = useState({
    name: param.get('name') || '',
    page: page,
  });
  const debouncedSearchValues = useDebounce(searchValues, 500);

  useEffect(() => {
    if (searchValues.page != page) {
      setSearchValues((oldState) => ({
        ...oldState,
        page: page,
      }));
    }
  }, [page]);

  useEffect(() => {
    const query = {};
    if (debouncedSearchValues.name) {
      query.name = debouncedSearchValues.name;
    }
    if (debouncedSearchValues.page > 1) {
      query.page = debouncedSearchValues.page;
    }
    navigate({
      pathname: '',
      search: createSearchParams(query).toString(),
    });
    if (searchValues.name !== '') {
      fetchCustomers(debouncedSearchValues);
    } else {
      fetchCustomers({ page: debouncedSearchValues.page || page });
    }
  }, [debouncedSearchValues]);

  const fetchCustomers = (searchParams) => {
    setSearchState((oldState) => ({
      ...oldState,
      isLoading: true,
    }));

    searchAllCustomerPagination(searchParams)
      .then((res) => {
        setSearchState((oldState) => ({
          searchConditions: oldState.searchValues,
          data: res,
          error: null,
        }));
      })
      .catch((error) => {
        setSearchState((oldState) => ({
          searchConditions: oldState.searchValues,
          data: null,
          error: error,
        }));
      })
      .finally(() => {
        setSearchState((oldState) => ({
          ...oldState,
          isLoading: false,
        }));
      });
  };

  const setSearchFieldValue = (name, value) => {
    setSearchValues((oldState) => ({
      ...oldState,
      name: value,
      page: 1,
    }));
  };

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<span className="text-sm">お客様情報</span>} style={{ padding: '0px' }}>
        <CustomerManagementSearchResult
          searchValues={searchValues}
          setSearchFieldValue={setSearchFieldValue}
          searchState={searchState}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default CustomerManagementTopPage;
