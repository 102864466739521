const SurveyButton = ({ handleClick, active, text, disabled, display }) => {
  return (
    <button
      type="button"
      onClick={display || disabled ? null : handleClick}
      disabled={disabled}
      className={`
        px-4 py-3 text-sm font-medium rounded-full transition-all duration-200
        ${
          active
            ? 'text-white border border-[#00A0E9] bg-[#00A0E9]'
            : 'text-gray-700 border border-gray-200 hover:border-gray-300 '
        }
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
        ${display && (active ? 'cursor-default' : 'hidden')}
      `}
    >
      {text}
    </button>
  );
};

export default SurveyButton;
