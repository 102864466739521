import client from "./client";

import {
  checkScalpLabMember,
  getCustomerInfo,
  updateCustomerInfo,
  getCustomerBasicInfo,
  updateCustomerBasicInfo,
  getReservationInfo,
  getAllCategory,
  getListServiceCategory,
  getSalonMenu,
  getAllCounselingSheet,
  addCounselingSheet,
  editCounselingSheet,
  addNewCustomer,
  addNewReservation,
  getAllCustomerVisitHistory,
  getDetailCustomerVisitHistory,
  getAllReservationToDay,
  getIntroductionOfScalpLab,
} from "./fake_services";

import {
  checkCustomerQrCode,
  getCustomerInfoAndCounselingHistory,
  getAvailableTimeAndStaffToReservation,
  createNewReservationAndVisit,
  getDetailCustomer,
  createNewCustomer,
  getDetailReservation,
  startUseServices,
} from "./11";

import { getAllCoundelingSheetByVisit, addCounselingSheetToVisit } from "./12";

import {
  getAllVisitsToday,
  getVisitDetailForPayment,
  updateVisitDetail,
  getAllSaleProducts,
  paymentForVisit,
  paymentForNonVisit,
} from "./13";

import {
  searchAllCustomer,
  getAllCustomerCounseling,
  updateCounseling,
  deleteCounseling,
  getAllCustomerVisit,
  updateCustomerBaseInfo,
  deleteCustomer,
  createNewFirstImpression,
  getAllFirstImpression,
} from "./14";

import {
  getStoreManagementStatus,
  searchCustomerInfo,
  getAllSalonMenus,
  getReservationDetail,
  updateReservationDetail,
  deleteReservation,
  createNewReservation,
  sendCustomerContact,
  createNewReservationForNonCustomer,
} from "./15";

import {
  getAllStaffWithShiftPlanByDate,
  deleteStaffShiftPlan,
  getAllSalonStaffs,
  getDetailSalonStaff,
  deleteSalonStaff,
  updateSalonStaff,
  getDetailSalonStaffWithAttendance,
  updateStaffAttendance,
  getAllStaffShiftPlans,
  registerStaffShiftPlans,
  getAllStaffAttendances,
  registerSalonStaff,
  getStaffAttendance,
  updateStaffShiftPlan,
  getStaffShiftPlanToday,
  staffShiftPlanAndCheckIn,
  getAllStaffWithShiftPlanByMonth,
  checkLoginNameIsValid,
} from "./16";

import {
  getSalonSaleDetail,
  addManyNonCustomerPaymentWithStaff,
  getSalonStaffSaleDetail,
} from "./17";

import {
  sendConsultation,
  getStatisticsCustomerVisit,
  getStatisticsCustomerRepeatRate,
  getStatisticsTurnover,
  getStatisticsAverageSpendPerCustomer,
  getStatisticsCostRate,
  getStatisticsOperatingProfitRatio,
  getStatisticsProductivity,
} from "./1B";
import axios from "axios";

export const login = (loginName, password, remember) => {
  return client.post("/login", { loginName, password, remember });
};

export const postPrintData = (url = "", data) => {
  return axios({
    url: url,
    method: "post",
    headers: { "Content-Type": "text/xml; charset=UTF-8" },
    data: data,
  });
};

export const labProducts = {
  exclusive: {
    all: () => {
      return client
        .get("/scalp-lab-product/all?productScope=monopoly")
        .then((res) => {
          return {
            data: res?.data?.result?.map((item) => {
              return {
                ...item,
                id: item?._id,
                name: item?.name,
                category: item?.category,
                price: item?.price,
                imageUrl: item?.imageUrl,
                reason: item?.reason,
              };
            }),
          };
        });
    },
  },
  normal: {
    all: () => {
      return client
        .get("/scalp-lab-product/all?productScope=general")
        .then((res) => {
          return {
            data: res?.data?.result?.map((item) => {
              return {
                ...item,
                id: item?._id,
                name: item?.name,
                category: item?.category,
                price: item?.price,
                imageUrl: item?.imageUrl,
                reason: item?.reason,
              };
            }),
          };
        });
    },
  },
  find: (productId) => {
    return client
      .get(`scalp-lab-product/find-product-detail?id=${productId}`)
      .then((res) => {
        return {
          data: {
            ...res.data.result,
            id: res.data.result?._id,
            name: res.data.result?.name,
            category: res.data.result?.category,
            price: res.data.result?.price,
            imageUrl: res.data.result?.imageUrl,
            reason: res.data.result?.reason,
          },
        };
      });
  },
};

export const labProductSalonOrder = {
  all: () => {
    return client
      .get("/scalp-lab-product-salon-order/all-by-salon")
      .then((res) => {
        return {
          data: res?.data?.result?.map((item) => {
            return {
              ...item,
              id: item?._id,
              name: item?.name,
              category: item?.category,
              price: item?.price,
              imageUrl: item?.imageUrl,
              reason: item?.reason,
            };
          }),
        };
      });
  },

  create: (input) => {
    return client
      .post(
        `/scalp-lab-product-salon-order/create`,
        input
      )
      .then((res) => {
        if (res.data.success) {
          return {
            data: res.data.result,
          };
        }
        return { data: null };
      });
  },

  receiveNewOrderProduct: (id, data) => {
    return client
      .put(`/scalp-lab-product-salon-order/receive-new-order-product?id=${id}`, {
        orderAmount: +data.orderAmount,
        orderStatus: "completed"
      })
      .then((res) => {
        return {
          data: res?.data,
        };
      });
  }
}

export {
  checkScalpLabMember,
  getCustomerInfo,
  updateCustomerInfo,
  deleteCustomer,
  getCustomerBasicInfo,
  updateCustomerBasicInfo,
  getReservationInfo,
  getAllCategory,
  getListServiceCategory,
  getSalonMenu,
  getAllCounselingSheet,
  addCounselingSheet,
  editCounselingSheet,
  addNewCustomer,
  addNewReservation,
  getAllCustomerVisitHistory,
  getDetailCustomerVisitHistory,
  getAllReservationToDay,
  getIntroductionOfScalpLab,

  // 11**
  checkCustomerQrCode,
  getCustomerInfoAndCounselingHistory,
  getAvailableTimeAndStaffToReservation,
  createNewReservationAndVisit,
  getDetailCustomer,
  createNewCustomer,
  getDetailReservation,
  startUseServices,

  // 12**
  getAllCoundelingSheetByVisit,
  addCounselingSheetToVisit,

  // 13**
  getAllVisitsToday,
  getVisitDetailForPayment,
  updateVisitDetail,
  getAllSaleProducts,
  paymentForVisit,
  paymentForNonVisit,

  // 14**
  searchAllCustomer,
  getAllCustomerCounseling,
  updateCounseling,
  deleteCounseling,
  getAllCustomerVisit,
  updateCustomerBaseInfo,
  createNewFirstImpression,
  getAllFirstImpression,

  // 15**
  getStoreManagementStatus,
  searchCustomerInfo,
  getAllSalonMenus,
  getReservationDetail,
  updateReservationDetail,
  deleteReservation,
  createNewReservation,
  sendCustomerContact,
  createNewReservationForNonCustomer,

  // 16**
  getAllStaffWithShiftPlanByDate,
  deleteStaffShiftPlan,
  getAllSalonStaffs,
  getDetailSalonStaff,
  deleteSalonStaff,
  updateSalonStaff,
  getDetailSalonStaffWithAttendance,
  updateStaffAttendance,
  getAllStaffShiftPlans,
  registerStaffShiftPlans,
  getAllStaffAttendances,
  registerSalonStaff,
  getStaffAttendance,
  updateStaffShiftPlan,
  staffShiftPlanAndCheckIn,
  getStaffShiftPlanToday,
  getAllStaffWithShiftPlanByMonth,
  checkLoginNameIsValid,

  // 17**
  getSalonSaleDetail,
  addManyNonCustomerPaymentWithStaff,
  getSalonStaffSaleDetail,

  // 1B**
  sendConsultation,
  getStatisticsCustomerVisit,
  getStatisticsCustomerRepeatRate,
  getStatisticsTurnover,
  getStatisticsAverageSpendPerCustomer,
  getStatisticsCostRate,
  getStatisticsOperatingProfitRatio,
  getStatisticsProductivity,
};
