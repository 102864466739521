import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ThreeDotIcon } from 'app/components/common/Icons';
import { formatPrice } from 'app/utils';
import GA, { EVENT_NAMES } from 'app/services/googleAnalytics';
import Path from 'app/route/Path';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';

const MENU_CATEGORIES = {
  cut: 'カット',
  color: 'カラー',
  perm: 'パーマ',
  straight: 'ストレート',
  spa: 'スパ',
  treatment: 'トリートメント',
  other: 'その他',
};

const CategoryBadge = ({ type }) => (
  <span className="px-3 py-1 bg-[#E6F4FB] text-[#00A0E9] text-xs rounded-full mb-1 mr-1 inline-block">
    {MENU_CATEGORIES[type] || type}
  </span>
);

const DropdownMenu = ({ onEdit, onDelete }) => (
  <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 z-10">
    <button className="w-full px-4 py-2 text-left text-sm hover:bg-gray-50 rounded-t-lg" onClick={onEdit}>
      <FontAwesomeIcon icon={faEdit} className="!w-5 !h-5 mr-2" />
      編集
    </button>
    <button
      className="w-full px-4 py-2 text-left text-sm text-red-500 hover:bg-gray-50 rounded-b-lg"
      onClick={onDelete}
    >
      <FontAwesomeIcon icon={faTrashCan} className="!w-5 !h-5 mr-2" />
      削除
    </button>
  </div>
);

export default function ItemMenu({ id, title, category, durationNumber, priceTaxExc, onDelete }) {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleEdit = () => {
    GA.tracking(EVENT_NAMES.MENU_LIST.CLICK_UPDATE, { menuId: id });
    navigate(Path.systemManagementMenuEdit(id));
    setShowDropdown(false);
  };

  const handleDelete = () => {
    setShowDropdown(false);
    onDelete(id);
  };

  const renderCategories = () => {
    if (Array.isArray(category)) {
      return category.map((item, index) => <CategoryBadge key={index} type={item} />);
    }
    return <CategoryBadge type={category} />;
  };

  return (
    <div className="w-full bg-white rounded-xl shadow-item2 mt-4 border border-gray-200 sm:min-h-[120px]">
      <div className="px-4 py-3 h-full">
        <div className="flex justify-between w-full items-center">
          <div className="flex flex-wrap mb-2">{renderCategories()}</div>
          <div className="relative">
            <button className="text-gray-400 hover:text-gray-600" onClick={() => setShowDropdown(!showDropdown)}>
              <ThreeDotIcon />
            </button>
            {showDropdown && <DropdownMenu onEdit={handleEdit} onDelete={handleDelete} />}
          </div>
        </div>

        <div>
          <h3 className="text-base font-medium mb-1 truncate max-w-full">{title}</h3>
        </div>

        <div className="flex justify-between w-full items-center">
          <p className="text-sm text-gray-600">所要時間 {durationNumber}分</p>
          <span className="text-lg">{formatPrice(priceTaxExc)}</span>
        </div>

        <div className="flex flex-col justify-between items-end"></div>
      </div>
    </div>
  );
}
