import Path from "../../../route/Path";
import { EVENT_NAMES } from "../../../services/googleAnalytics";

export const MENUS = [
  // {
  //   title: "頭皮ラボ",
  //   children: [
  //     {
  //       title: "提案\nする",
  //       path: Path.selectCustomer,
  //       eventName:
  //         EVENT_NAMES.SCALP_LAB_MANAGEMENT.CLICK_SELECT_CUSTOMER,
  //     },
  //   ],
  // },
  {
    title: "頭皮データ",
    children: [
      {
        title: "登録\nする",
        path: Path.orderScalpLab,
        eventName: EVENT_NAMES.SCALP_LAB_MANAGEMENT.CLICK_ORDER_FORM,
      },
      {
        title: "発送\nする",
        path: Path.batchDeliverScalpLab,
        eventName:
          EVENT_NAMES.SCALP_LAB_MANAGEMENT.CLICK_BATCH_DELIVER,
      },
      {
        title: "発送履歴を確認する",
        path: Path.historyOfOrdering,
        eventName:
          EVENT_NAMES.SCALP_LAB_MANAGEMENT.CLICK_HISTORY_ORDER,
      },
    ],
  },
  {
    title: "検査キット",
    children: [
      {
        title: "在庫数を確認する",
        path: Path.orderKits,
        eventName: EVENT_NAMES.SCALP_LAB_MANAGEMENT.CLICK_ORDER_KIT,
      },
    ],
  },
  // {
  //   title: "頭皮ラボ運営",
  //   children: [
  //     {
  //       title: "連絡\nする",
  //       path: Path.contactOperator,
  //       eventName: EVENT_NAMES.SCALP_LAB_MANAGEMENT.CLICK_CONTACT_US,
  //     },
  //   ],
  // },
  // {
  //   title: "頭皮ラボ商品",
  //   children: [
  //     {
  //       title: "発注\nする",
  //       path: Path.labProductOrder("orderInfo"),
  //       eventName:
  //         EVENT_NAMES.SCALP_LAB_MANAGEMENT.CLICK_PRODUCT_ORDERS,
  //     },
  //   ],
  // },
];
