import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import * as Yup from 'yup';

import InputDateField from 'app/components/commonUI/InputDateField';
import InputField from 'app/components/commonUI/InputField';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { defaultDateFormat, GENDER } from '../../../constants';
import { deleteCustomer } from '../../../services/api';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import ConfirmDeleteDialog from '../../CommonDialog/ConfirmDeleteDialog';
import SelectField from '../../commonUI/SelectField';
import { PREFECTURES } from '../../salonForm/CreateSalonForm/const';

const validationErrMessages = {
  firstNameKanjiRequired: '名は必須です。',
  firstNameKanjiMaxLength: '名は最大 30 文字にする必要があります。',
  lastNameKanjiRequired: '姓は必須です。',
  lastNameKanjiMaxLength: '姓は最大 30 文字にする必要があります。',
  firstNameKataRequired: 'メイは必須です。',
  firstNameKataMaxLength: 'メイは最大 30 文字にする必要があります。',
  lastNameKataRequired: 'セイは必須です。',
  lastNameKataMaxLength: 'セイは最大 30 文字にする必要があります。',
  dobRequired: '生年月日は必須です。',
  phoneRequired: '電話番号は必須です。',
  phoneValid: '電話番号は 10 桁または 11 桁で構成する必要があります',
  emailRequired: 'メールアドレスが必要です。',
  emailValid: '有効なメールアドレスを入力してください。',
  passwordRequired: 'passwordが必要です。',
  passwordValid: '有効なpasswordを入力してください。',
  addressRequired: '住所は必須です。',
  postalCodeRequired: '郵便番号は必須です。',
  postalCodeValid: '郵便番号は7桁でなければなりません',
};

function CreateCustomerForm(props) {
  const { customerInfo = {}, isReadMode, handleCreate, confirmCustomerData, hanldeBackClick } = props;
  const [isShowConfirmDelete, setIsShowConfirmDelete] = useState(false);

  const validationSchema = Yup.object().shape({
    firstNameKanji: Yup.string()
      .required(validationErrMessages.firstNameKanjiRequired)
      .max(30, validationErrMessages.firstNameKanjiMaxLength),
    lastNameKanji: Yup.string()
      .required(validationErrMessages.lastNameKanjiRequired)
      .max(30, validationErrMessages.lastNameKanjiMaxLength),
    firstNameKata: Yup.string()
      .required(validationErrMessages.firstNameKataRequired)
      .max(30, validationErrMessages.firstNameKataMaxLength),
    lastNameKata: Yup.string()
      .required(validationErrMessages.lastNameKataRequired)
      .max(30, validationErrMessages.lastNameKataMaxLength),
    // dob: Yup.object().required(validationErrMessages.dobRequired),
    phone1: Yup.string().matches(/^(\d{10}|\d{11})$/, validationErrMessages.phoneValid),
    phone2: Yup.string().matches(/^(\d{10}|\d{11})$/, validationErrMessages.phoneValid),
    email: Yup.string().email(validationErrMessages.emailValid),
    //.required(validationErrMessages.emailRequired),
    postalCode: Yup.string()
      //.required(validationErrMessages.postalCodeRequired)
      .matches(/^(\d{7})$/, validationErrMessages.postalCodeValid),
    //address: Yup.string().required(validationErrMessages.addressRequired),
  });

  const onSubmit = async (values) => {
    const dobString = values?.dob?.format(defaultDateFormat);
    confirmCustomerData({ ...values, dob: dobString || null });
  };

  const { handleSubmit, errors, values, setFieldValue, touched, handleBlur } = useFormik({
    initialValues: {
      firstNameKanji: customerInfo.firstNameKanji || '',
      lastNameKanji: customerInfo.lastNameKanji || '',
      firstNameKata: customerInfo.firstNameKata || '',
      lastNameKata: customerInfo.lastNameKata || '',
      dob: customerInfo.dob ? moment.tz(customerInfo.dob, defaultDateFormat, true, 'Asia/Tokyo') : null,
      phone1: customerInfo.phone1 || '',
      phone2: customerInfo.phone2 || '',
      email: customerInfo.email || '',
      postalCode: customerInfo.postalCode || '',
      prefecture: customerInfo.prefecture || '',
      city: customerInfo.city || '',
      town: customerInfo.town || '',
      address: customerInfo.address || '',
      gender: customerInfo.gender || GENDER.DEFAULT,
    },
    onSubmit,
    validationSchema,
  });

  const handleSearchByPostalCode = async (postalCode) => {
    try {
      // Use Japan Post API to get address details
      const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`);
      const data = await response.json();

      if (data.status === 200 && data.results) {
        const addressData = data.results[0];

        // Set address fields
        setFieldValue('prefecture', addressData.address1);
        setFieldValue('city', addressData.address2);
        setFieldValue('town', addressData.address3);
      } else {
        toast.error('郵便番号情報が見つかりません');
      }
    } catch (error) {
      console.error(error);
      toast.error('郵便番号情報が見つかりません');
    }
  };

  const handlePostalCodeChange = (newValue) => {
    const cleanedValue = newValue.replace(/[^\d]/g, '');
    setFieldValue('postalCode', cleanedValue);

    if (cleanedValue.length === 7) {
      handleSearchByPostalCode(cleanedValue);
    }
  };

  const handleDeleteCustomer = async () => {
    if (customerInfo?.id) {
      GA.tracking(EVENT_NAMES.CUSTOMER_DETAIL.DELETE, {
        customerId: customerInfo?.id,
        customerNo: customerInfo.customerNo,
      });
      return await deleteCustomer(customerInfo?.salonCustomerId);
    }
  };

  return (
    <>
      <div className="w-full flex flex-col gap-4 pt-6 px-4 md:px-0">
        <div className="w-full flex gap-4">
          <InputField
            label="姓"
            placeholder="姓"
            name="lastNameKanji"
            value={values.lastNameKanji}
            type="text"
            inputWrapClasses="w-full"
            inputClasses="w-full md:w-[352px]"
            onChange={(newValue) => setFieldValue('lastNameKanji', newValue)}
            handleBlur={handleBlur}
            errors={errors.lastNameKanji}
            touched={touched.lastNameKanji}
            disabled={isReadMode}
          />
          <InputField
            label="名"
            placeholder="名"
            name="firstNameKanji"
            value={values.firstNameKanji}
            type="text"
            inputWrapClasses="w-full"
            inputClasses="w-full md:w-[352px]"
            onChange={(newValue) => setFieldValue('firstNameKanji', newValue)}
            handleBlur={handleBlur}
            errors={errors.firstNameKanji}
            touched={touched.firstNameKanji}
            disabled={isReadMode}
          />
        </div>

        <div className="w-full flex gap-4">
          <InputField
            label="セイ"
            placeholder="セイ"
            name="lastNameKata"
            value={values.lastNameKata}
            type="text"
            inputWrapClasses="w-full"
            inputClasses="w-full md:w-[352px]"
            onChange={(newValue) => setFieldValue('lastNameKata', newValue)}
            handleBlur={handleBlur}
            errors={errors.lastNameKata}
            touched={touched.lastNameKata}
            disabled={isReadMode}
          />
          <InputField
            label="メイ"
            placeholder="メイ"
            name="firstNameKata"
            value={values.firstNameKata}
            type="text"
            inputWrapClasses="w-full"
            inputClasses="w-full md:w-[352px]"
            onChange={(newValue) => setFieldValue('firstNameKata', newValue)}
            handleBlur={handleBlur}
            errors={errors.firstNameKata}
            touched={touched.firstNameKata}
            disabled={isReadMode}
          />
        </div>

        <InputDateField
          label="生年月日"
          name="dob"
          value={values.dob}
          className="bg-[#FFFFFF] w-full md:w-[352px]"
          inputWrapClasses="w-full"
          setValue={(newValue) => setFieldValue('dob', newValue)}
          handleBlur={handleBlur}
          errors={errors.dob}
          disabled={isReadMode}
          touched={touched.dob}
        />

        <SelectField
          label="性別"
          inputWrapClasses="w-full"
          inputClasses="w-full md:w-[352px]"
          value={values.gender}
          setValue={(newValue) => setFieldValue('gender', newValue)}
          options={[
            { value: 0, label: '未回答' },
            { value: 1, label: '男性' },
            { value: 2, label: '女性' },
          ]}
          disabled={!!isReadMode}
          errors={errors.gender}
          touched={touched.gender}
        />

        <div className="w-full flex gap-4">
          <InputField
            label="郵便番号"
            name="postalCode"
            inputProps={{ maxLength: 7 }}
            value={values.postalCode}
            type="tel"
            onChange={(newValue) => handlePostalCodeChange(newValue)}
            handleBlur={handleBlur}
            errors={errors.postalCode}
            touched={touched.postalCode}
            disabled={isReadMode}
          />
          <p className="text-sm text-subtitle h-12 flex mt-auto items-center">住所を自動入力</p>
        </div>

        <SelectField
          label="都道府県"
          name="prefecture"
          inputWrapClasses="w-full"
          inputClasses="w-full md:w-[352px]"
          value={values.prefecture}
          setValue={(newValue) => setFieldValue('prefecture', newValue)}
          options={PREFECTURES}
          disabled={isReadMode || false}
          errors={errors.prefecture}
          touched={touched.prefecture}
        />

        <InputField
          label="市区町村"
          name="city"
          value={values.city}
          type="text"
          inputWrapClasses="w-full"
          inputClasses="w-full md:w-[352px]"
          onChange={(newValue) => setFieldValue('city', newValue)}
          handleBlur={handleBlur}
          errors={errors.city}
          touched={touched.city}
          disabled={isReadMode}
        />

        <InputField
          label="町名"
          name="town"
          value={values.town}
          type="text"
          inputWrapClasses="w-full"
          inputClasses="w-full md:w-[352px]"
          onChange={(newValue) => setFieldValue('town', newValue)}
          handleBlur={handleBlur}
          errors={errors.town}
          touched={touched.town}
          disabled={isReadMode}
        />

        <InputField
          label="番地・ビル名"
          name="address"
          value={values.address}
          type="text"
          inputWrapClasses="w-full"
          inputClasses="w-full md:w-[352px]"
          onChange={(newValue) => setFieldValue('address', newValue)}
          handleBlur={handleBlur}
          errors={errors.address}
          touched={touched.address}
          disabled={isReadMode}
        />

        <InputField
          label="電話番号1"
          name="phone1"
          value={values.phone1}
          type="text"
          inputWrapClasses="w-full"
          inputClasses="w-full md:w-[352px]"
          onChange={(newValue) => setFieldValue('phone1', newValue)}
          handleBlur={handleBlur}
          errors={errors.phone1}
          touched={touched.phone1}
          disabled={isReadMode}
        />

        <InputField
          label="電話番号2"
          name="phone2"
          value={values.phone2}
          type="text"
          inputWrapClasses="w-full"
          inputClasses="w-full md:w-[352px]"
          onChange={(newValue) => setFieldValue('phone2', newValue)}
          handleBlur={handleBlur}
          errors={errors.phone2}
          touched={touched.phone2}
          disabled={isReadMode}
        />
        <InputField
          label="メールアドレス"
          name="email"
          value={values.email}
          type="text"
          inputWrapClasses="w-full"
          inputClasses="w-full md:w-[352px]"
          onChange={(newValue) => setFieldValue('email', newValue)}
          handleBlur={handleBlur}
          errors={errors.email}
          touched={touched.email}
          disabled={isReadMode}
        />
      </div>

      <div className="w-full py-12 px-4 md:py-10 md:px-0">
        <div className="w-full flex gap-4">
          <button type="button" className="button-size bg-white text-black" onClick={hanldeBackClick}>
            キャンセル
          </button>
          <button
            type="button"
            className="button-size bg-blue-btn-primary"
            onClick={() => {
              if (handleCreate) {
                if (customerInfo?.id) {
                  GA.tracking(EVENT_NAMES.CUSTOMER_DETAIL.UPDATE, {
                    customerId: customerInfo?.id,
                    customerNo: customerInfo.customerNo,
                  });
                } else {
                  GA.tracking(EVENT_NAMES.CUSTOMERS.CREATE);
                }
                handleCreate();
              } else {
                handleSubmit();
              }
            }}
          >
            {handleCreate ? (customerInfo?.id ? '変更する' : '登録する') : '確認画面へ'}
          </button>
        </div>
        {!isReadMode && customerInfo?.id && (
          <div
            className="mt-8 text-center text-[#DC1717] font-medium cursor-pointer"
            onClick={() => setIsShowConfirmDelete(true)}
          >
            お客様情報を削除する
          </div>
        )}
      </div>

      <ConfirmDeleteDialog
        open={isShowConfirmDelete}
        handleClose={() => setIsShowConfirmDelete(false)}
        confirmTitle="以下のお客様情報を全て削除しますか？"
        confirmMessage={customerInfo?.lastNameKata + customerInfo?.firstNameKata}
        confirmTitleAgain="本当に以下のお客様情報を全て削除しますか？"
        confirmMessageAgain={customerInfo?.lastNameKata + customerInfo?.firstNameKata}
        deleteCompleteTitle="お客様情報を削除しました。"
        deleteCompleteBtnLabel="終了"
        deleteCompleteAction={() => {
          setIsShowConfirmDelete(false);
          hanldeBackClick();
        }}
        deleteAction={handleDeleteCustomer}
        handleDeleteError={() => {
          setIsShowConfirmDelete(false);
        }}
      />
    </>
  );
}

export default CreateCustomerForm;
