import moment from 'moment';
import React from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import GA, { EVENT_NAMES } from '../../services/googleAnalytics';
import Path from 'app/route/Path';
import { ArrowRightIcon } from '../common/Icons';

export default function BatchDeliveryItem(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = props;

  return (
    <div
      className="w-full h-full relative shadow-center rounded-[12px] p-4 my-4 cursor-pointer flex items-center justify-between"
      onClick={() => {
        GA.tracking(EVENT_NAMES.BATCH_DELIVER.CLICK_DETAIL);
        navigate({
          pathname: Path.batchDeliverScalpLabDetail(data?._id),
          search: createSearchParams({ from: location.pathname + location.search }).toString(),
        });
      }}
    >
      <div>
        <div className="flex gap-2 items-center">
          <div className="bg-gray-base rounded-[34px] text-subtitle px-3 py-1 text-sm">管理番号</div>
          <div>{data.manageCode || ''}</div>
        </div>
        <div className="flex gap-2 mt-3 items-center text-sm">
          <div>検体数：{data.inspectionKitIds.length || ''}</div>
          <div>発送日時：{data.salonDeliverDate ? moment(data.salonDeliverDate).format('YYYY-MM-DD HH:mm') : '未発送'}</div>
        </div>
      </div>
      <div>
        <ArrowRightIcon />
      </div>
    </div>
  );
}
