import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Path from 'app/route/Path';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import CreateCompleteDialog from '../../../components/CommonDialog/CreateCompleteDialog';
import LoadingBackdrop from '../../../components/commonUI/LoadingBackdrop';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../components/commonUI/LoadInitError';
import { fake_menu_list } from '../../../services/api/fake_services/fake_data';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import InventoryForm from '../InventoryManagementItemDetail/InventoryForm';
import useInventoryForm from '../InventoryManagementItemDetail/useInventoryForm';

function RegisterInventoryFormPage({ menuId }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { inventoryState, handleBackClick, handleConfirm, handleCreateNewInventory } = useInventoryForm();

  const [fetchInventoryDetail, setFetchInventoryDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadInventoryDetail = () => {
    setFetchInventoryDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    const getMenuDetail = (id) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            data: fake_menu_list[Number(id) - 1],
          });
        }, 1000);
      });
    };
    getMenuDetail(menuId)
      .then((res) => {
        setFetchInventoryDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInventoryDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    if (menuId) {
      loadInventoryDetail();
    }
  }, []);

  const handleBack = () => {
    if (inventoryState.confirmedData) {
      handleBackClick();
      return;
    }
    navigate(searchParams.get('from') || Path.inventoryManagementCheckStock);
  };

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-full pb-6 md:pb-8  relative">
          <div className="w-full flex items-center justify-start inset-x-0 top-0 pr-4 md:pr-0">
            <NavigationBackBtn onClick={handleBack} text={'在庫登録'} />
          </div>
          <div className="mt-6 w-full p-4 md:p-0">
            {fetchInventoryDetail.isLoading ? <LoadingProgress /> : null}

            {!fetchInventoryDetail.isLoading && fetchInventoryDetail.error ? (
              <LoadInitError error={fetchInventoryDetail.error} />
            ) : null}

            {!fetchInventoryDetail.isLoading && !fetchInventoryDetail.error && (
              <InventoryForm
                handleBackClick={handleBackClick}
                isReadMode={inventoryState.confirmedData}
                handleSubmit={inventoryState.confirmedData ? handleCreateNewInventory : null}
                handleConfirm={handleConfirm}
                menuId={menuId}
              />
            )}
          </div>

          <CreateCompleteDialog
            open={!!inventoryState.res}
            createCompleteTitle="在庫を登録しました"
            createCompleteBtnLabel1="在庫管理へ戻る"
            createCompleteAction1={() => navigate(Path.inventoryManagementCheckStock)}
            createCompleteBtnLabel2="終了"
            createCompleteAction2={() => navigate(Path.inventoryManagement)}
          />

          <LoadingBackdrop isOpen={inventoryState.isSubmitting} />
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default RegisterInventoryFormPage;
