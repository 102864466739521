import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import LoadingBackdrop from 'app/components/commonUI/LoadingBackdrop';
import LoadingProgress from 'app/components/commonUI/LoadingProgress';
import LoadInitError from 'app/components/commonUI/LoadInitError';
import { updateMenuSalon } from 'app/services/api/1D';
import { toast } from 'react-toastify';
import GA, { EVENT_NAMES } from 'app/services/googleAnalytics';
import NavigationBackBtn from 'app/components/common/NavigationBackButton';
import EditMenuForm from 'app/components/salonForm/EditMenuForm';
import { getSalonMenu } from 'app/services/api/1D';
import CreateCompleteDialog from 'app/components/CommonDialog/CreateCompleteDialog';

function EditMenuPage() {
  const [isUpdating, setIsUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [menuDetail, setMenuDetail] = useState(null);
  const navigate = useNavigate();
  const { menuId } = useParams();
  const [showModalComplete, setShowModalComplete] = useState(false);

  const loadMenuDetail = useCallback(async () => {
    if (!menuId) return;

    setLoading(true);
    try {
      const response = await getSalonMenu(menuId);
      setMenuDetail(response.data);
      setError(null);
    } catch (error) {
      toast.error('メニュー情報の取得に失敗しました');
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [menuId]);

  useEffect(() => {
    loadMenuDetail();
  }, [loadMenuDetail]);

  const handleUpdateMenu = async (values) => {
    setIsUpdating(true);
    try {
      await updateMenuSalon(menuId, values);
      GA.tracking(EVENT_NAMES.MENU_LIST.UPDATE);
      toast.success('メニューの更新が成功しました');
      setShowModalComplete(true);
    } catch (error) {
      toast.error('メニュー更新エラー');
    } finally {
      setIsUpdating(false);
    }
  };

  if (loading) return <LoadingProgress />;
  if (error) return <LoadInitError error={error} />;

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="flex items-center mb-6">
          <NavigationBackBtn url={Path.systemManagementAllMenu} text="メニュー編集" />
        </div>

        <div className="max-w-[960px] mx-auto bg-white">
          {menuDetail && <EditMenuForm onSubmit={handleUpdateMenu} initialValues={menuDetail} />}
        </div>

        <LoadingBackdrop isOpen={isUpdating} />
      </MainContentLayout>
      <CreateCompleteDialog
        open={showModalComplete}
        createCompleteTitle="メニューを更新しました。"
        createCompleteBtnLabel1="システム設定へ戻る"
        createCompleteAction1={() => {
          navigate(Path.systemManagement);
        }}
        createCompleteBtnLabel2="終了"
        createCompleteAction2={() => {
          navigate(Path.systemManagementAllMenu);
        }}
      />
    </DefaultLayout>
  );
}

export default EditMenuPage;
