import Path from "../../../route/Path";
import { EVENT_NAMES } from "../../../services/googleAnalytics";

export const MENUS = [
  {
    title: "店舗情報",
    path: Path.systemManagementDetail(),
    eventName: EVENT_NAMES.SYSTEM_MANAGEMENT.CLICK_SYSTEM_INFO,
  },
  {
    title: "スタッフ情報",
    path: Path.staffManagementList,
    eventName: EVENT_NAMES.SYSTEM_MANAGEMENT.CLICK_STAFF_LIST,
  },
  // {
  //   title: "店舗メニュー",
  //   path: Path.systemManagementAllMenu,
  //   eventName: EVENT_NAMES.SYSTEM_MANAGEMENT.CLICK_MENU_LIST,
  // },
];
