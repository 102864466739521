import { DEFAULT_INFO } from "../constants";

const TypesReferenceInfoItem = ({
  typeName = "",
  title = "",
  description = "",
  backgroundClass = "",
  isTablet,
  imageUrl = "",
  thumbBackground = "",
  displayName = ""
}) => {
  return (
    <div className="w-full h-auto flex flex-col gap-4">
      <div
        className={`w-full px-4 pt-6 flex items-end rounded-lg`}
        style={{
          backgroundColor:
            thumbBackground ?? DEFAULT_INFO.THUMB_BACKGROUND,
        }}
      >
        <div>
          <img
            alt="no data"
            className="self-center w-[100px] h-2/3"
            src={imageUrl ?? DEFAULT_INFO.IMAGE_URL}
          />
        </div>
        <div className="pl-4 pb-3">
          <div className="text-xl font-medium">
            {displayName}
          </div>
          <div className="flex gap-4 items-center py-2">
            <div className="text-sm">
              頭皮ラボ判定結果
            </div>
            <div className="flex gap-1 items-center">
              <div className="h-full text-4xl text-[#0096D4] font-bold font-mono">
                {typeName}
              </div>
              <div className="h-full text-sm text-[#252525] whitespace-nowrap">
                タイプ
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`text-xs min-[400px]:text-sm w-full p-4 rounded-lg ${backgroundClass}`}
      >
        <div className="text-center font-bold mb-2">{title}</div>
        <div className="leading-6 whitespace-pre-line">{description}</div>
      </div>
    </div>
  );
};

export default TypesReferenceInfoItem;
