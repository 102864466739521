import Image from '../../../components/Image';
import React from 'react';
import Path from '../../../route/Path';
import { useNavigate } from 'react-router-dom';
import { formatPrice } from '../../../utils';

export default function InventoryItem(props) {
  const { item = {}, toDetail = false } = props;
  const { product } = item;
  const navigate = useNavigate();

  if (!product) {
    return <></>;
  }

  const onClick = () => {
    if (toDetail) {
      navigate(Path.inventoryManagementItemDetail(product._id));
    }
  };

  return (
    <div
      className="flex flex-col gap-3 rounded-xl p-3 bg-white text-xs shadow-[0_1px_10px_0_#006B8833] cursor-pointer"
      onClick={onClick}
    >
      <div className="text-right">残り {item.inventory}</div>
      <div className="text-center">
        <Image src={product.imageUrl} alt={product.name} className="inline-block m-auto max-w-full" />
      </div>
      <div className="w-full flex flex-col gap-1">
        <div className="line-clamp-1 text-[#666666]">{product.series}</div>
        <div className="line-clamp-2 break-all">{product.name + (product.size ? `(${product.size})` : '')}</div>
      </div>
      <div className="flex flex-row gap-2 mt-auto">
        <div className="max-w-[48px]">税込価格</div>
        <div className="flex-1 max-w-[calc(100%-56px)]">
          <div className="truncate inline-block align-bottom max-w-[calc(100%-16px)]">
            {formatPrice(product.priceTaxInc, false)}
          </div>
          円
        </div>
      </div>
    </div>
  );
}
