import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import { useNavigate, useLocation } from 'react-router-dom';
import Path from '../../../route/Path';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';

function SuggestScalpLabToCustomer() {
  const navigate = useNavigate();
  const location = useLocation();
  const values = location.state?.values;

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <div>頭皮ラボ</div>
          </>
        }
      >
        <div className="w-full h-full relative">
          <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              <NavigationBackBtn url={Path.selectCustomer} text="同意書確認" />
            </h2>
          </div>
          <div className="px-4 md:px-0">
            <div className="w-full h-full">
              <div className="w-full h-full flex justify-center">
                <div className="h-full overflow-auto w-[1000px]">
                  <div className="break-all text-sm py-6 border-b border-[#cacaca]">
                    <span class="font-bold">頭皮解析サービスに関する説明書</span>をご確認のうえ、<span class="font-bold">同意書確認</span>へお進みください。
                  </div>
                  <h1 className="text-center text-lg mt-6">頭皮解析サービスに関する説明書</h1>
                  <br />
                  <div className="text-sm lg:text-base">
                    <div className="text-right">
                      株式会社頭皮 Labo
                      <br />
                      代表取締役　西尾　義弘
                    </div>
                    <br />
                    <br />
                    <div>
                      　この度、お客様に頭皮解析サービス（以下「本頭皮解析サービス」といいます。）をご利用いただくにあたり、下記の要領で、お客様の頭皮の角層及び毛髪（以下、併せて「検体」ということがあります。）その他お客様に関する情報をご提供いただくことについて、下記のとおりご説明申し上げます。
                      <br />
                      <br />
                      　下記のご説明内容について、ご理解・ご同意いただける場合には、お客様に、当社（株式会社頭皮
                      Labo。以下同じ。）が提供する頭皮解析サービスをご利用いただくことができます。
                    </div>
                    <br />
                    <div className="text-center">記</div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">１．</div>
                      <div>
                        <h4 className="">本頭皮解析サービスについて</h4>
                        <div>
                          本頭皮解析サービスは、
                          お客様が訪問された各理美容室（以下「担当理美容室」といいます。住所などは後記「１１.
                          担当理美容室」参照。）において採取したお客様の頭皮の角層及び毛髪を、 当社の委託先である DRC
                          株式会社（住所などは後記「１０. 解析実施機関」参照。以下「DRC
                          株式会社」といいます。）の解析技術を用いて解析することにより、お客様の頭皮の客観的な状態を明らかにし、
                          お客様及び担当理美容室にその解析結果をご提供することを通じて、
                          お客様の頭皮の状態に適合した施術内容及び専売商品（理美容室における専売商品をいいます。以下同じ。）を担当理美容室からお客様に対してご提案する
                          ことを目的（以下「本目的」といいます。）とするサービスです。
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">２．</div>
                      <div>
                        <div className="">頭皮の角層と毛髪の採取の方法について</div>
                        <div>
                          担当理美容室の理美容師は、以下の方法により、お客様の頭皮の角層と毛髪の採取を行います。
                        </div>

                        <div className="grid grid-cols-[30px_1fr] gap-y-2">
                          <div>(1)</div>
                          <div>
                            <div>角層の採取方法</div>
                            <div className="grid grid-cols-[20px_1fr] ">
                              <div>・</div>
                              <div>アルコールを用いて、頭皮の余分な皮脂を拭き取ります。</div>
                              <div>・</div>
                              <div>専用テープを頭皮に貼り付けます。</div>
                              <div>・</div>
                              <div>専用テープの上から数回、綿棒を押し当てます。</div>
                              <div>・</div>
                              <div>専用テープを頭皮から剝がします。</div>
                            </div>
                            <div>
                              ※理美容師が、お客様の頭皮の状態等を踏まえ、角層の採取を行うことが困難又は不適当であると判断した場合には、採取を中止する場合があります。
                            </div>
                          </div>
                          <div>(2)</div>
                          <div>
                            <div>毛髪の採取方法</div>
                            <div>ハサミでお客様の毛髪を数本採取いたします。</div>
                          </div>
                          <div>(3)</div>
                          <div>
                            <div>採取した検体の保管方法</div>
                            <div>採取した角層および毛髪は、ID 番号を付した上で、冷凍庫に保管します。</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">３．</div>
                      <div>
                        <div className="">検体の解析について</div>
                        <div className="grid grid-cols-[30px_1fr] gap-y-2">
                          <div>(1)</div>
                          <div>
                            <div>
                              採取した検体は、担当理美容室から DRC 株式会社に郵送され、DRC
                              株式会社において、専用機器により解析を行います。
                            </div>
                            <div>
                              本頭皮解析サービスにおいて解析する項目は以下のものを予定していますが、お客様への予告なく項目を追加・変更する場合があります。なお、検体のみから個人を特定できるような、いわゆるゲノム解析などは行いません。
                            </div>
                            <div className="grid grid-cols-[20px_1fr] ">
                              <div>・</div>
                              <div>
                                角層：皮膚の新陳代謝の状態、皮膚のバリア機能に関わるタンパク質の状態、細胞面積の大きさ、多重剝離度、皮膚のタンパク質の酸化の程度など
                              </div>{' '}
                              <div>・</div>
                              <div>毛髪：毛髪のタンパク質の状態、酸化の程度、親水性など</div> <div>・</div>
                              <div>理美容師の感触：頭皮や頭髪に触れた感触など</div> <div>・</div>
                              <div>
                                お客様に申告いただいた情報：年齢、性別、頭皮と頭髪についての自己評価、生活習慣など
                              </div>
                            </div>
                          </div>
                          <div>(2)</div>
                          <div>
                            当社は、 DRC 株式会社から解析結果の提供を受け、そのデータを専用データベースに登録し、又は
                            DRC
                            株式会社に、当社が保有若しくは管理するサーバー上の専用データベースに解析結果のデータを記録さ
                            せます。
                          </div>
                          <div>(3)</div>
                          <div>
                            お客様は、専用アプリを通じて、お客様の検体の解析結果をご覧になることができます。また、担当理美容室の理美容師は、専用システムを通じてお客様の検体の解析結果にアクセスし、当該解析結果を踏まえて、お客様に施術内容及び専売商品のご提案を行います。
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">４．</div>
                      <div>
                        <div className="">ご提供いただく情報について</div>
                        <div>
                          本頭皮解析サービスをご利用いただくにあたり、お客様から、以下の情報をご提供いただきます。
                        </div>
                        <div className="grid grid-cols-[20px_1fr] ">
                          <div>・</div>
                          <div>氏名</div>
                          <div>・</div>
                          <div>性別</div>
                          <div>・</div>
                          <div>年齢</div>
                          <div>・</div>
                          <div>頭皮・頭髪についての自己評価</div>
                          <div>・</div>
                          <div>生活習慣</div>
                          <div>・</div>
                          <div>その他、本頭皮解析サービスの提供にあたり必要な情報</div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">５．</div>
                      <div>
                        <div className="">必ずお守りいただきたいこと</div>
                        <div className="grid grid-cols-[30px_1fr] gap-y-2">
                          <div>(1)</div>
                          <div>
                            採取当日は、採取を受けるまで、染毛、パーマ、頭皮マッサージ等その他の施術を受けないでください。
                          </div>
                          <div>(2)</div>
                          <div>
                            頭皮や毛髪に関して痛み、ただれ、抜け毛その他の異常がある場合には、検体の採取を行うことはできません。必ず、異常がある旨を担当理美容室の理美容師に知らせてください。
                          </div>
                          <div>(3)</div>
                          <div>
                            採取の前後に、頭皮に痛みその他の異常を感じた場合には、直ちに担当理美容室の理美容師に知らせてください。
                          </div>
                          <div>(4)</div>
                          <div>
                            本頭皮解析サービスは、頭皮の状態に関する客観的な分析を行うものであり、医学的な診断を行うものではありません。頭皮や毛髪に関して痛み、ただれその他の異常を感じた場合には、速やかに医療機関を受診してください。
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">６．</div>
                      <div>
                        <div className="">検体等の保管期間</div>{' '}
                        <div>
                          採取した検体及びお客様の情報は、採取後 10
                          年間保管します。保管期限を経過した時点で、採取した検体及びお客様の情報は、匿名化した上で、焼却などの適切な方法で破棄します。
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">７．</div>
                      <div>
                        <div className="">
                          個人情報の取り扱いおよび研究成績の公表等における個人情報・プライバシーの保護並びに個人情報の第三者提供
                        </div>
                        <div>
                          本頭皮解析サービスをご利用いただくにあたり、お客様からご提供いただいた情報は、当社のプライバシーポリシーに従い、個人情報の保護およびプライバシーの保全に十分に配慮して取り扱います。
                        </div>
                        <br />
                        <div>
                          当社は、本頭皮解析サービスを実施するにあたり、お客様の氏名、性別、年齢、頭皮・頭髪についての自己評価などの個人情報を、当社の委託先である
                          DRC 株式会社に提供します。
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">８．</div>
                      <div>
                        <div className="">匿名加工情報の作成及び第三者提供</div>
                        <div>
                          当社は、お客様からご提供いただいた一切の情報を、匿名化（個人情報保護法その他の法令が匿名加工情報の作成について定める基準を満たす措置を講じることにより、情報を特定の個人を識別することができないように加工し、当該個人情報を復元することができないようにすることをいいます。以下、匿名化した情報を「本件匿名加工情報」といいます。）して、個人情報保護法その他の法令に従い、自由に利用（加工、販売及び使用許諾を含みますが、これらに限られません。以下、本項において同じ。）することができます。本件匿名加工情報は、原始的に当社に帰属します。
                        </div>
                        <br />
                        <div>
                          当社は、本件匿名加工情報を第三者に提供することができ、当該第三者は、本件匿名加工情報を自由に利用することができます。
                        </div>
                        <br />
                        <div>
                          本件匿名加工情報の作成及び第三者提供その他の本件匿名加工情報の取扱いについては、当社プライバシーポリシーに定めるとおりとします。
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">９．</div>
                      <div>
                        <div className="">共同利用</div>
                        <div>
                          当社は、 当社プライバシーポリシーに定めるとおり、 本頭皮解析サービスを実施するにあたり、
                          当社、 DRC 株式会社及び担当理美容室との間で、
                          お客様の氏名、性別、年齢、頭皮・頭髪についての自己評価などの個人情報の共同利用を行います。
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">１０．</div>
                      <div>
                        <div className="">解析実施機関</div>
                        <div>DRC株式会社</div>
                        <div>本社：大阪府大阪市北区東天満 2-10-31 第 9 田渕ビル 3F</div>
                        <div>代表取締役： 髙野　憲一</div>
                      </div>
                    </div>
                    <br />
                    <div className="grid grid-cols-[50px_1fr] gap-y-2">
                      <div className="">１１．</div>
                      <div>
                        <div className="">本頭皮解析サービスに関するお問合せ先</div>
                        <div>担当者： 西尾義弘</div>
                        <div>連絡先（月曜日～金曜日 9:00～18:00）： 06-7777-1819</div>
                        <div>
                          メールアドレス：
                          <a href="mailto:inquiry@touhilab.co.jp">inquiry@touhilab.co.jp</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="text-end">以上</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex py-4 gap-4 lg:gap-6 items-center justify-center text-white text-base">
              <button
                onClick={() =>
                  navigate(Path.scalpLabManagementConfirmApplicationForm, {
                    state: { values },
                  })
                }
                type="button"
                className="button-size bg-blue-btn-primary lg:w-[200px]"
              >
                同意書確認へすすむ
              </button>
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SuggestScalpLabToCustomer;
