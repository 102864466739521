import { Dialog } from '@mui/material';
import Fade from '@mui/material/Fade';
import React, { useState } from 'react';
import { CloseIcon } from '../../../components/common/Icons/CloseIcon';
import InputField from '../../../components/commonUI/InputField';
import CheckboxField from '../../../components/commonUI/CheckboxField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createNewOrderKit } from '../../../services/api/1C';
import { useNavigate } from 'react-router-dom';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import Path from '../../../route/Path';

const CONFIRM_STEPS = {
  CONFIRM: 'CONFIRM',
  COMPLETE: 'COMPLETE',
};

export default function OrderDialog({ open, onClose }) {
  const [showStep, setShowStep] = useState(CONFIRM_STEPS.CONFIRM);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    orderAmount: Yup.string().required('注文個数を入力してください。'),
  });

  const onSubmit = async (values) => {
    createNewOrderKit({
      orderAmount: values.orderAmount,
    })
      .then((res) => {
        GA.tracking(EVENT_NAMES.ORDER_KIT.SUBMIT, {
          amount: values.orderAmount,
        });
        setShowStep(CONFIRM_STEPS.COMPLETE);
      })
      .catch((e) => {
        alert(e);
      })
      .finally(() => {});
  };

  const { handleSubmit, errors, values, setFieldValue, touched, handleBlur } = useFormik({
    initialValues: {
      orderAmount: null,
      isConfirm: false,
    },
    onSubmit,
    validationSchema,
  });

  return (
    <Dialog
      fullWidth
      open={open}
      aria-labelledby="responsive-dialog-title"
      classes={{ root: 'bg-transparent' }}
      PaperProps={{
        classes: {
          root: 'px-4 py-6',
        },
        style: {
          borderRadius: '16px',
          maxWidth: '500px',
          width: 'calc(100% - 32px)',
          margin: '0 auto',
        },
      }}
      TransitionComponent={Fade}
    >
      {showStep === CONFIRM_STEPS.CONFIRM && (
        <>
          <div className="w-full flex justify-end">
            <div onClick={onClose} className="cursor-pointer">
              <CloseIcon />
            </div>
          </div>
          <div className="pt-2 px-2">
            <div className="text-justify mb-6">
              発注する検査キットの数を入力してください。発注内容に間違いがなければ「発注する」を押してください。
            </div>

            <div className="flex items-center gap-2 justify-center mb-10">
              <InputField
                name="orderAmount"
                placeholder="個数を入力"
                value={values.orderAmount}
                type="text"
                onChange={(newValue) => {
                  setFieldValue('orderAmount', Number(newValue.replace(/[^0-9.]/g, '')));
                }}
                handleBlur={handleBlur}
                touched={touched.orderAmount}
                errors={errors.orderAmount}
              />
              <div className="font-semibold text-base">個</div>
            </div>
            <div className="mb-6">
              <CheckboxField
                label="発注内容に間違いないことを確認しました"
                name="remember"
                value={values.isConfirm}
                onChange={(e) => setFieldValue('isConfirm', e.target.value)}
                isToggle={true}
              />
            </div>

            <div className="flex items-center gap-4 justify-center">
              <button className="button-size bg-white text-[#252525] h-[47px] font-medium" onClick={onClose}>
                キャンセル
              </button>
              <button
                className={`button-size bg-blue-btn-primary h-[47px] font-medium ${(values.isConfirm && values.orderAmount) ? 'opacity-100' : 'opacity-50 cursor-not-allowed'}`}
                disabled={!values.isConfirm || !values.orderAmount}
                onClick={handleSubmit}
              >
                発注する
              </button>
            </div>
          </div>
        </>
      )}
      {showStep === CONFIRM_STEPS.COMPLETE && (
        <div className="mt-2">
          <div className="mb-6 text-center text-[#252525]">検査キットを発注しました</div>
          <button
            className="button-size bg-blue-btn-primary h-[47px] font-medium max-w-[170px] mx-auto"
            onClick={() => navigate(Path.scalpLabManagement)}
          >
            トップに戻る
          </button>
        </div>
      )}
    </Dialog>
  );
}
