import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function BackBtn({ url, classButton }) {
  const navigate = useNavigate();

  return (
    <div>
      <Button
        variant="contained"
        className={`button-size bg-amber-300 ${classButton}`}
        onClick={() => {
          navigate(url || -1);
        }}
      >
        戻る
      </Button>
    </div>
  );
}
