import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import InputField from 'app/components/commonUI/InputField';
import InputDateField from 'app/components/commonUI/InputDateField';
import InputImage from 'app/components/commonUI/input-image';
import { toast } from 'react-toastify';
import Path from 'app/route/Path';
import moment from 'moment';
import ConfirmDeleteDialog from 'app/components/CommonDialog/ConfirmDeleteDialog';
import { getNameKanji } from 'app/utils';
import GA, { EVENT_NAMES } from 'app/services/googleAnalytics';
import { deleteSalonStaff } from 'app/services/api';

const validationErrMessages = {
  firstNameKanjiRequired: '名は必須です。',
  lastNameKanjiRequired: '姓は必須です。',
  firstNameKataRequired: 'メイは必須です。',
  lastNameKataRequired: 'セイは必須です。',
  dobRequired: '生年月日は必須です。',
  phoneRequired: '電話番号は必須です。',
  phoneValid: '電話番号は 10 桁または 11 桁で構成する必要があります',
  emailRequired: 'メールアドレスが必要です。',
  emailValid: '有効なメールアドレスを入力してください。',
  addressRequired: '住所は必須です。',
  postalCodeRequired: '郵便番号は必須です。',
  postalCodeValid: '郵便番号は7桁でなければなりません',
};

function EditStaffForm(props) {
  const { onSubmitForm, staffInfo } = props;
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
  const [isConfirmMode, setIsConfirmMode] = useState(false);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    firstNameKanji: Yup.string().required(validationErrMessages.firstNameKanjiRequired),
    lastNameKanji: Yup.string().required(validationErrMessages.lastNameKanjiRequired),
    firstNameKata: Yup.string().required(validationErrMessages.firstNameKataRequired),
    lastNameKata: Yup.string().required(validationErrMessages.lastNameKataRequired),
    dob: Yup.object().nullable(),
    phone: Yup.string().matches(/^(\d{10}|\d{11})$/, validationErrMessages.phoneValid),
    email: Yup.string().email(validationErrMessages.emailValid),
    address: Yup.string(),
    postalCode: Yup.string().matches(/^(\d{7})$/, validationErrMessages.postalCodeValid),
  });

  const handleFormSubmit = async (values) => {
    if (isConfirmMode) {
      onSubmitForm(values);
    } else {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length === 0) {
        setIsConfirmMode(true);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      imageUrl: staffInfo?.imageUrl || '',
      firstNameKanji: staffInfo?.firstNameKanji || '',
      lastNameKanji: staffInfo?.lastNameKanji || '',
      firstNameKata: staffInfo?.firstNameKata || '',
      lastNameKata: staffInfo?.lastNameKata || '',
      dob: staffInfo?.dob ? new moment(staffInfo.dob) : null,
      phone: staffInfo?.phone || '',
      email: staffInfo?.email || '',
      address: staffInfo?.address || '',
      postalCode: staffInfo?.postalCode || '',
    },
    onSubmit: handleFormSubmit,
    validationSchema,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, handleBlur } = formik;

  const handleSearchByPostalCode = async (postalCode) => {
    try {
      // Use Japan Post API to get address details
      const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`);
      const data = await response.json();

      if (data.status === 200 && data.results) {
        const addressData = data.results[0];

        // Set address fields
        setFieldValue('address', `${addressData.address1}${addressData.address2}${addressData.address3}`);
      } else {
        toast.error('郵便番号情報が見つかりません');
      }
    } catch (error) {
      console.error(error);
      toast.error('郵便番号情報が見つかりません');
    }
  };

  const handlePostalCodeChange = (newValue) => {
    // Remove any non-digit characters
    const cleanedValue = newValue.replace(/[^\d]/g, '');
    setFieldValue('postalCode', cleanedValue);

    // Auto-search when 7 digits are entered
    if (cleanedValue.length === 7) {
      handleSearchByPostalCode(cleanedValue);
    }
  };

  const handleDeleteSalonStaff = async () => {
    GA.tracking(EVENT_NAMES.STAFF_DETAIL.DELETE, { staffId: staffInfo?.id });
    return await deleteSalonStaff(staffInfo?.id);
  };

  const handleBack = () => {
    setIsConfirmMode(false);
  };

  return (
    <>
      <div className="bg-white px-4 lg:px-0">
        <InputImage
          label="アイコン"
          imageUrl={values.imageUrl}
          setImageUrl={(newValue) => setFieldValue('imageUrl', newValue)}
          disabled={isConfirmMode}
        />

        <div className="space-y-6 mt-4">
          <div className="grid grid-cols-2 gap-6">
            <div className="lg:w-[352px]">
              <p className="text-sm text-gray-600 mb-2">姓</p>
              <InputField
                name="lastNameKanji"
                value={values.lastNameKanji}
                onChange={(newValue) => setFieldValue('lastNameKanji', newValue)}
                handleBlur={handleBlur}
                touched={touched.lastNameKanji}
                errors={errors.lastNameKanji}
                placeholder=""
                disabled={isConfirmMode}
              />
            </div>
            <div className="lg:w-[352px]">
              <p className="text-sm text-gray-600 mb-2">名</p>
              <InputField
                name="firstNameKanji"
                value={values.firstNameKanji}
                onChange={(newValue) => setFieldValue('firstNameKanji', newValue)}
                handleBlur={handleBlur}
                touched={touched.firstNameKanji}
                errors={errors.firstNameKanji}
                placeholder=""
                disabled={isConfirmMode}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-6">
            <div className="lg:w-[352px]">
              <p className="text-sm text-gray-600 mb-2">セイ</p>
              <InputField
                name="lastNameKata"
                value={values.lastNameKata}
                onChange={(newValue) => setFieldValue('lastNameKata', newValue)}
                handleBlur={handleBlur}
                touched={touched.lastNameKata}
                errors={errors.lastNameKata}
                placeholder=""
                disabled={isConfirmMode}
              />
            </div>
            <div className="lg:w-[352px]">
              <p className="text-sm text-gray-600 mb-2">メイ</p>
              <InputField
                name="firstNameKata"
                value={values.firstNameKata}
                onChange={(newValue) => setFieldValue('firstNameKata', newValue)}
                handleBlur={handleBlur}
                touched={touched.firstNameKata}
                errors={errors.firstNameKata}
                placeholder=""
                disabled={isConfirmMode}
              />
            </div>
          </div>

          <div className="lg:w-[352px]">
            <p className="text-sm text-gray-600 mb-2">生年月日</p>
            <InputDateField
              id="dob"
              value={values.dob}
              setValue={(newValue) => setFieldValue('dob', newValue)}
              onBlur={handleBlur}
              touched={touched.dob}
              errors={errors.dob}
              placeholder=""
              className="w-full"
              disabled={isConfirmMode}
            />
          </div>

          <div className="lg:w-[352px]">
            <p className="text-sm text-gray-600 mb-2">電話番号</p>
            <InputField
              name="phone"
              value={values.phone}
              onChange={(newValue) => setFieldValue('phone', newValue)}
              handleBlur={handleBlur}
              touched={touched.phone}
              errors={errors.phone}
              placeholder=""
              disabled={isConfirmMode}
            />
          </div>

          <div className="lg:w-[352px]">
            <p className="text-sm text-gray-600 mb-2">メールアドレス</p>
            <InputField
              name="email"
              value={values.email}
              onChange={(newValue) => setFieldValue('email', newValue)}
              handleBlur={handleBlur}
              touched={touched.email}
              errors={errors.email}
              placeholder=""
              disabled={isConfirmMode}
            />
          </div>

          <div className="lg:w-[352px]">
            <p className="text-sm text-gray-600 mb-2">郵便番号</p>
            <div className="flex items-center gap-4 relative">
              <InputField
                name="postalCode"
                value={values.postalCode}
                onChange={(newValue) => handlePostalCodeChange(newValue)}
                handleBlur={handleBlur}
                inputProps={{ maxLength: 7 }}
                touched={touched.postalCode}
                errors={errors.postalCode}
                placeholder=""
                inputWrapClasses="w-[120px]"
                disabled={isConfirmMode}
              />
              <div className="absolute left-[140px] text-sm text-[#666666]">住所を自動入力</div>
            </div>
          </div>

          <div className="lg:w-[352px]">
            <p className="text-sm text-gray-600 mb-2">住所</p>
            <InputField
              name="address"
              value={values.address}
              onChange={(newValue) => setFieldValue('address', newValue)}
              handleBlur={handleBlur}
              touched={touched.address}
              errors={errors.address}
              placeholder=""
              disabled={isConfirmMode}
            />
          </div>
        </div>

        <div className="flex justify-end gap-4 mt-[40px]">
          {isConfirmMode ? (
            <>
              <button
                onClick={handleBack}
                type="button"
                className="px-4 py-3 text-sm border border-gray-300 rounded-xl hover:bg-gray-50 min-w-[120px] w-1/2 text-center"
              >
                戻る
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="px-4 py-3 text-sm text-white bg-[#00A0E9] rounded-xl hover:bg-[#0090D9] min-w-[120px] w-1/2"
              >
                内容更新
              </button>
            </>
          ) : (
            <>
              <Link
                to={Path.staffManagementDetail(staffInfo.id)}
                className="px-4 py-3 text-sm border border-gray-300 rounded-xl hover:bg-gray-50 min-w-[120px] w-1/2 text-center"
              >
                キャンセル
              </Link>
              <button
                type="button"
                onClick={handleSubmit}
                className="px-4 py-3 text-sm text-white bg-[#00A0E9] rounded-xl hover:bg-[#0090D9] min-w-[120px] w-1/2"
              >
                変更する
              </button>
            </>
          )}
        </div>

        {!isConfirmMode && (
          <div
            className="mt-8 text-[#DC1717] text-center text-base font-medium cursor-pointer"
            onClick={() => setShowConfirmDelete(true)}
          >
            スタッフ情報を削除する
          </div>
        )}
      </div>
      <ConfirmDeleteDialog
        open={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        confirmTitle="以下のスタッフ情報を削除してもよろしいですか"
        confirmMessage={getNameKanji(staffInfo)}
        confirmTitleAgain="本当に削除しますか？"
        confirmMessageAgain=""
        deleteCompleteTitle="スタッフ情報を削除しました"
        deleteCompleteBtnLabel="終了"
        deleteCompleteAction={() => {
          navigate(Path.staffManagementList);
        }}
        deleteAction={handleDeleteSalonStaff}
        confirmCancleLabel="キャンセル"
        confirmCancleAgainLabel="キャンセル"
        confirmLabel="削除"
        confirmAgainLabel="削除"
      />
    </>
  );
}

export default EditStaffForm;
