import React from 'react';
import SwitchCustom from 'app/components/common/SwitchCustom';

function SettingItem({ setting, handleChangeSetting, disabled }) {
  const handleSwitch = async (event) => {
    handleChangeSetting({
      ...setting,
      canAccess: event.target.checked,
    });
  };

  return (
    <div className="flex items-center justify-between lg:p-4 py-4 bg-white rounded-lg">
      <span className="text-base">{setting.functionName}</span>
      <SwitchCustom checked={setting.canAccess} onChange={handleSwitch} disabled={disabled} />
    </div>
  );
}

export default SettingItem;
