import React from 'react';
import { useNavigate } from 'react-router-dom';
import Path from 'app/route/Path';
import { getNameKanji } from 'app/utils';
import { ArrowRightIcon, SmallProfileIcon } from 'app/components/common/Icons';
import GA, { EVENT_NAMES } from 'app/services/googleAnalytics';

function ItemStaffCard(props) {
  const { staff } = props;
  const navigate = useNavigate();

  const handleNavigateToDetail = (staffId) => {
    GA.tracking(EVENT_NAMES.STAFF_LIST.CLICK_STAFF_DETAIL, {
      staffId,
    });
    navigate(Path.staffManagementDetail(staffId));
  };

  return (
    <button
      type="button"
      className="w-full flex items-center justify-between bg-white rounded-lg border border-gray-200 hover:bg-gray-50 shadow-item2 h-[56px] px-[16px]"
      onClick={() => handleNavigateToDetail(staff.id)}
    >
      <div className="flex items-center gap-4 w-[calc(100%-32px)]">
        <SmallProfileIcon />
        <span className="text-base truncate flex-1">{getNameKanji(staff)}</span>
      </div>
      <ArrowRightIcon />
    </button>
  );
}

export default ItemStaffCard;
