import defaultThumpnail from 'app/assets/images/no-image.png';
import { useEffect, useState } from 'react';

export default function Image({
  src,
  alt,
  className = 'w-[150px] h-[150px] mb-1',
  imageClassName = 'w-full h-full object-contain',
}) {
  const [errorImage, setErrorImage] = useState();

  // if user try to send the new src, clear the error state and then view the new src
  useEffect(() => {
    if (src) setErrorImage();
  }, [src]);

  const handleSetErrorImage = () => setErrorImage(defaultThumpnail);

  return (
    <div className={className}>
      <img
        src={errorImage || src || defaultThumpnail}
        alt={alt}
        className={imageClassName}
        onError={handleSetErrorImage}
      />
    </div>
  );
}
