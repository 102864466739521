import DefaultLayout from '../../../../layouts/DefaultLayout';
import MainContentLayout from '../../../../layouts/MainContentLayout';
import NavigationBackBtn from '../../../../components/common/NavigationBackButton';
import Path from '../../../../route/Path';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getHistoryOfOrderingDetail } from '../../../../services/api/1C';
import LoadingProgress from '../../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../../components/commonUI/LoadInitError';
import { getCustomerGenderText } from '../../../14/DetailCustomerPage/helpers';
import { formatToJapaneseDate } from '../../../../utils';
import CollectLocation from '../../OrderScalpLab/CollectLocation';
import moment from 'moment/moment';

const InfoRow = ({ label, value }) => {
  return (
    <div className="w-full h-auto flex flex-col gap-2">
      <label className={`text-sm text-subtitle`}>{label}</label>
      <div>{value}</div>
    </div>
  );
};

function HistoryOfOrderingDetailByKitIdCollectLocation() {
  const { customerNo, inspectionKitId } = useParams();
  const [fetchHistoryOrderDetail, setFetchHistoryOrderDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadHistoryOrderDetail = async () => {
    setFetchHistoryOrderDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    try {
      const res = await getHistoryOfOrderingDetail(customerNo);
      const found = res.data.find(function (item) {
        return item.scalpData.inspectionKitId === inspectionKitId;
      });
      setFetchHistoryOrderDetail({
        isLoading: false,
        data: found,
        error: null,
      });
    } catch (error) {
      setFetchHistoryOrderDetail({
        isLoading: false,
        data: null,
        error: error,
      });
    }
  };

  useEffect(() => {
    loadHistoryOrderDetail();
  }, []);

  const data = useMemo(() => {
    if (!fetchHistoryOrderDetail.data) {
      return {};
    }

    const data = fetchHistoryOrderDetail.data;

    return {
      customerName: (data?.customer.lastNameKanji || '') + (data?.customer.firstNameKanji || ''),
      customerNameKata: (data?.customer.lastNameKata || '') + (data?.customer.firstNameKata || ''),
      customerNo: data?.customerNo || '',
      customerGender: data?.customer.gender,
      customerBirthday: formatToJapaneseDate(data?.customer.dob),
      collectLocation: data?.scalpData.customerCollectLocation,
      createdAt:
        data?.scalpData.createdAt !== undefined ? moment(data?.scalpData.createdAt).format('YYYY-MM-DD HH:mm') : '-',
    };
  }, [fetchHistoryOrderDetail.data]);

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-full pb-8 lg:pb-12 relative">
          <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              <NavigationBackBtn text={'検体No. ' + inspectionKitId} url={Path.historyOfOrderingDetail(customerNo)} />
            </h2>
          </div>

          <div className="w-full h-full p-6 md:min-h-0 min-h-[calc(100vh-200px)] ">
            <div className="w-full h-full flex flex-col">
              <div className="w-full md:min-h-[567px] flex flex-col gap-6 pb-6">
                {fetchHistoryOrderDetail.isLoading && <LoadingProgress />}

                {!fetchHistoryOrderDetail.isLoading &&
                  fetchHistoryOrderDetail.error &&
                  fetchHistoryOrderDetail.data?.inspectionKitId && (
                    <LoadInitError error={fetchHistoryOrderDetail.error} />
                  )}

                {!fetchHistoryOrderDetail.isLoading &&
                  !fetchHistoryOrderDetail.error &&
                  fetchHistoryOrderDetail.data && (
                    <>
                      <InfoRow label="検体ナンバー" value={inspectionKitId} />
                      <InfoRow label="お客様No." value={customerNo} />
                      <InfoRow label="お名前" value={data.customerName || ''} />
                      <InfoRow label="お名前フリガナ" value={data.customerNameKata || ''} />
                      <InfoRow label="生年月日" value={data.customerBirthday} />
                      <InfoRow label="性別" value={getCustomerGenderText(data.customerGender)} />
                      <InfoRow label="登録日時" value={data.createdAt} />

                      <div className="border-t pt-4">
                        <div className="text-center">採取箇所</div>
                        <div className="py-4 text-center">
                          <CollectLocation disable={true} point={data.collectLocation} />
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default HistoryOfOrderingDetailByKitIdCollectLocation;
