import React from 'react';

import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
function ConfirmLoginDialog(props) {
  const { open, handleClose, handleBack, handleConfim, fullScreen, children, actions, ...rest } = props;
  return (
    <Dialog
      maxWidth={'xl'}
      fullWidth
      open={open}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        classes: {
          root: 'p-6 md:p-12 relative !rounded-[8px] md:!rounded-[12px] !my-[58px] !mx-[16px] !max-w-[1024px]',
        },
        style: {
          border: '1px solid #D0CECE',
        },
      }}
      TransitionComponent={Fade}
      sx={{
        '& > .MuiBackdrop-root': {
          background: 'transparent',
        },
        '.MuiDialog-container': {
          backgroundColor: '#00000080',
          '@media (min-width: 1024px)': {
            backgroundImage: 'linear-gradient(to bottom, #ddfbff, #ffffff 60%)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
        },
      }}
      {...rest}
    >
      <div className="w-full flex flex-col">
        {/* title */}
        <div className="w-full h-auto text-[#252525] text-sm !leading-[180%]">
          <p className="font-base  text-justify">
            いつもご利用いただき、誠にありがとうございます。この頭皮ラボシステムは、お客様の重要な情報を扱うため、その取り扱いには最大限の注意を払っております。ご利用の際には以下の点にご留意ください。
          </p>
          <p className="mt-5 font-base text-justify">
            １．このシステムは、お客様の貴重な情報を安全に管理するためのものです。ご自身のログイン情報は他人と共有しないでください。
          </p>
          <p className="font-base text-justify">
            ２．システム内での全操作は監視されております。不正な行動はシステムにより即座に検知されます。
          </p>
          <p className="font-base text-justify">
            ３．個人情報の取り扱いには、特に注意してください。不適切な取り扱いが発見された場合、法的措置を含む厳しい対応を取る場合があります。
          </p>
          <p className="font-base text-justify">
            ４．安全な使用のため、ログアウトは使用後必ず行ってください。また、パスワードは定期的に変更することをお勧めします。
          </p>
          <p className="font-base text-justify">
            ご利用の皆さまの協力により、より安全で信頼性の高いサービスを提供することが可能となります。このシステムを通じて、美容室様と美容室をご利用されるお客様との信頼関係を深めることができるよう努めてまいります。
          </p>
          <p className="font-base text-justify">
            本システムは、理容師又は美容師がお客様への説明・施術等を行う際の補助資料とするために、理容師又は美容師が、
            法令に基づく資格の範囲内で、理容又は美容に関する事項としてお伝えする内容を、視覚的にわかりやすく構成したものです。
          </p>
          <p className="font-base text-justify">
            本システムは、疾病の診断、治療若しくは予防に使用されること又は身体の構造若しくは機能に影響を及ぼすことを目
            的とするものではなく、医療機器ではありません。
          </p>
          <p className="font-base text-justify">
            本システム中の一切の記述は、医学的判断に基づくものではなく、疾患の名称、原因、現在の病状、罹患可能性、今後
            の病状の予測、治療方針、予防方針等について、判断若しくは伝達し又はこれらに関する決定を支援するものではありません。
          </p>
          <p className="font-base text-justify">
            皮膚に発赤、化膿、炎症等の異常がある方、その他医療及び医療的ケアや疾患に伴う専門的な管理を必要とする方は、
            本システムをご利用いただけません。
          </p>
          <p className="font-base text-justify">
            疾病の診断、治療若しくは予防については、医師の判断及び指示に従ってください。
          </p>
          <p className="font-base text-justify">
            医療機関を受診する場合には、改めて、当該医療機関の医師の指示による検査を受けてください。
          </p>
          <p className="font-base text-justify">ご理解とご協力をお願い申し上げます。</p>
          <p className="font-base text-justify">上記をご一読した上で「承認」をタッチしてお入りください。</p>
        </div>

        <div className="w-full flex items-center justify-center text-white text-base font-semibold">
          <button
            onClick={() => {
              handleClose && handleClose();
            }}
            type="button"
            className="button-size rounded-[12px] bg-blue-btn-primary px-10 py-6 mt-[40px] max-w-[240px]"
          >
            承認
          </button>
        </div>
      </div>
    </Dialog>
  );
}

export default ConfirmLoginDialog;
