import CreateCompleteDialog from 'app/components/CommonDialog/CreateCompleteDialog';
import LoadingBackdrop from 'app/components/commonUI/LoadingBackdrop';
import CreateCustomerForm from 'app/components/customerForms/CreateCustomerForm';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import { getDetailCustomer, updateCustomerBaseInfo } from 'app/services/api';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../components/commonUI/LoadInitError';

function UpdateCustomerBaseInfoPageContent(props) {
  const { customerInfo, customerId } = props;
  const [updatingCustomerState, setupdatingCustomerState] = useState({
    confirmedData: null,
    isCreating: false,
    error: null,
    res: null,
  });

  const navigate = useNavigate();

  const handleCreateNewCustomer = () => {
    setupdatingCustomerState((oldState) => ({
      ...oldState,
      isCreating: true,
      error: null,
      res: null,
    }));

    updateCustomerBaseInfo(customerId, updatingCustomerState.confirmedData)
      .then((res) => {
        setupdatingCustomerState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: null,
          res: res,
        }));
      })
      .catch((error) => {
        console.log('error: ', error);

        setupdatingCustomerState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: null,
          res: error,
        }));
      });
  };

  const confirmCustomerData = (confirmedData) => {
    setupdatingCustomerState({
      confirmedData,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  const hanldeBackClick = () => {
    if (!updatingCustomerState.confirmedData) return navigate(Path.home);

    setupdatingCustomerState({
      confirmedData: null,
      isCreating: false,
      error: null,
      res: null,
    });
  };

  return (
    <>
      <CreateCustomerForm
        customerInfo={customerInfo}
        isReadMode={updatingCustomerState.confirmedData}
        handleCreate={
          updatingCustomerState.confirmedData
            ? handleCreateNewCustomer
            : null
        }
        confirmCustomerData={confirmCustomerData}
        hanldeBackClick={hanldeBackClick}
      />

      <LoadingBackdrop isOpen={updatingCustomerState.isCreating} />

      <CreateCompleteDialog
        open={!!updatingCustomerState.res}
        createCompleteTitle="お客様情報が変更になりました"
        createCompleteBtnLabel2="終了"
        createCompleteAction2={() => {
          navigate(Path.customerManagementDetail(customerId));
        }}
      />
    </>
  );
}

function UpdateCustomerBaseInfoPage(props) {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const { customerId } = useParams();

  useEffect(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getDetailCustomer(customerId)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [customerId]);

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p className="text-sm">お客様情報</p>}>
        <NavigationBackBtn
          url={Path.customerManagementDetail(customerId)}
          text="お客様情報を変更"
        />
        {loadInitState.isLoading ? <LoadingProgress /> : null}

        {!loadInitState.isLoading && loadInitState.error ? <LoadInitError error={loadInitState.error} /> : null}

        {!loadInitState.isLoading && !loadInitState.error && loadInitState.data ? (
          <UpdateCustomerBaseInfoPageContent customerInfo={loadInitState.data} customerId={customerId} />
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default UpdateCustomerBaseInfoPage;
