import Path from '../../../route/Path';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createNewInventory, updateInventoryInfo } from '../../../services/api/18';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import { toast } from 'react-toastify';

function useInventoryForm(props = {}) {
  const { onBackClick, productId } = props;
  const navigate = useNavigate();

  const [inventoryState, setInventoryState] = useState({
    confirmedData: null,
    isSubmitting: false,
    error: null,
    res: null,
  });

  const handleBackClick = () => {
    if (!inventoryState.confirmedData) {
      return onBackClick ? onBackClick() : navigate(Path.inventoryManagement);
    }

    setInventoryState({
      confirmedData: false,
      isSubmitting: false,
      error: null,
      res: null,
    });
  };

  const handleConfirm = (confirmedData) => {
    setInventoryState({
      confirmedData,
      isSubmitting: false,
      error: null,
      res: null,
    });
  };

  const handleCreateNewInventory = async () => {
    setInventoryState((oldState) => ({
      ...oldState,
      isSubmitting: true,
      error: null,
      res: null,
    }));
    try {
      const res = await createNewInventory({
        ...inventoryState.confirmedData,
        price: inventoryState.confirmedData.price || 0,
        priceTaxInc: inventoryState.confirmedData.priceTaxInc || 0,
        priceTaxExc: inventoryState.confirmedData.priceTaxExc || 0,
      });

      GA.tracking(EVENT_NAMES.INVENTORY_REGISTER.SUBMIT, {
        productNo: inventoryState.confirmedData.productNumber,
      });
      setInventoryState((oldState) => ({
        ...oldState,
        isSubmitting: false,
        error: null,
        res: res,
      }));
      toast.success('インベントリの作成が成功しました');
    } catch (error) {
      setInventoryState((oldState) => ({
        ...oldState,
        isSubmitting: false,
        error: error,
        res: null,
      }));
      toast.error('製品番号が重複しているため、在庫の作成に失敗しました');
    }
  };

  const handleUpdateInventory = async () => {
    setInventoryState((oldState) => ({
      ...oldState,
      isSubmitting: true,
      error: null,
      res: null,
    }));
    try {
      const confirmedData = inventoryState.confirmedData;
      const params = {
        productNumber: confirmedData?.productNumber,
        maker: confirmedData?.maker,
        series: confirmedData?.series,
        name: confirmedData?.name,
        size: confirmedData?.size,
        imageUrl: confirmedData?.imageUrl,
        category: confirmedData?.category,
        productScope: confirmedData?.productScope,
        price: confirmedData?.price || 0,
        priceTaxInc: confirmedData?.priceTaxInc || 0,
        priceTaxExc: confirmedData?.priceTaxExc || 0,
        description: confirmedData?.description,
        classes: confirmedData?.classes,
        inventory: confirmedData?.inventory,
      };

      const res = await updateInventoryInfo(productId, params);

      GA.tracking(EVENT_NAMES.INVENTORY_UPDATE.SUBMIT, {
        productNo: inventoryState.confirmedData.productNumber,
      });
      setInventoryState((oldState) => ({
        ...oldState,
        confirmedData: null,
        isSubmitting: false,
        error: null,
        res: res,
      }));
      toast.success('インベントリの変更が成功しました');
    } catch (error) {
      setInventoryState((oldState) => ({
        ...oldState,
        confirmedData: false,
        isSubmitting: false,
        error: error,
        res: null,
      }));
      toast.error('在庫の変更に失敗しました');
    }
  };

  return {
    inventoryState,
    setInventoryState,
    handleBackClick,
    handleConfirm,
    handleCreateNewInventory,
    handleUpdateInventory,
  };
}

export default useInventoryForm;
