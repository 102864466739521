import { useCallback, useEffect, useRef, useState, memo } from 'react';

const CollectLocation = memo((props = {}) => {
  const { onClick, point, disable } = props;

  const [init, setInit] = useState(false);
  const canvasRef = useRef(null);
  const headImage = useRef(null);

  const isPointInImage = useCallback((x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    try {
      if (x >= 85 && x <= 110 && y >= 180 && y <= 210) {
        // position of blue point
        return false;
      }

      if (x <= 16 && y >= 115 && y <= 170) {
        // left ear
        return false;
      }

      if (x >= 178 && y >= 115 && y <= 170) {
        // right ear
        return false;
      }

      if (x >= 80 && x <= 110 && y <= 35) {
        // nose
        return false;
      }

      const imageData = ctx.getImageData(x, y, 1, 1);
      const pixel = imageData.data;

      const alpha = pixel[3];
      return alpha > 0;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, []);

  const drawHead = useCallback(() => {
    if (!headImage.current) {
      return;
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(headImage.current, 0, 0, canvas.width, canvas.height);
  }, []);

  const findPos = useCallback((canvas, event) => {
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    return { x, y };
  }, []);

  const drawRedPoint = useCallback((x, y) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    if (canvas.getAttribute('data-x')) {
      // clear old red point
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawHead();
    }

    // draw red point
    ctx.beginPath();
    ctx.arc(x, y, 6, 0, Math.PI * 2);
    ctx.fillStyle = 'red';
    ctx.fill();
    canvas.setAttribute('data-x', x);
    canvas.setAttribute('data-y', y);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = useCallback(() => {
    headImage.current = new Image();
    headImage.current.crossOrigin = 'Anonymous';
    headImage.current.src = '/thl_head.png';

    headImage.current.onload = () => {
      drawHead();
      setInit(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (init && point) {
      const { x, y } = point;
      if (isPointInImage(x, y)) {
        drawRedPoint(x, y);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, point]);

  const handleClick = (e) => {
    if (!init || disable) {
      return;
    }

    const canvas = canvasRef.current;
    const { x, y } = findPos(canvas, e);

    if (isPointInImage(x, y)) {
      drawRedPoint(x, y);

      onClick && onClick(e, { x, y });
    }
  };

  const handleMouseMove = (e) => {
    //
    // const canvas = canvasRef.current;
    // const { x, y } = findPos(canvas, e);
    // console.log(x, y);
  };

  return (
    <canvas
      width="200"
      height="241"
      ref={canvasRef}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      className={`m-auto ${disable ? 'cursor-default' : 'cursor-pointer'}`}
    />
  );
});

export default CollectLocation;
