import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import Path from '../../../route/Path';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getInventory } from '../../../services/api/18';
import LoadInitError from '../../../components/commonUI/LoadInitError';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import InventoryForm from './InventoryForm';
import InventoryDetail from './InventoryDetail';
import useInventoryForm from './useInventoryForm';

function InventoryManagementItemDetail() {
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const [fetchInventory, setFetchInventory] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const { productId } = useParams();
  const [searchParams] = useSearchParams();

  const { handleConfirm, handleBackClick, inventoryState, handleUpdateInventory } = useInventoryForm({
    onBackClick: () => {
      setIsEdit(false);
    },
    productId: productId,
  });

  const loadInventory = async () => {
    setFetchInventory({
      isLoading: true,
      data: null,
      error: null,
    });

    try {
      const res = await getInventory(productId);
      setFetchInventory({
        isLoading: false,
        data: res.data,
        error: null,
      });
    } catch (e) {
      setFetchInventory({
        isLoading: false,
        data: null,
        error: e,
      });
    }
  };

  const toggleEditMode = () => {
    setIsEdit(!isEdit);
  };

  useEffect(() => {
    if (productId) {
      loadInventory();
    }
  }, [productId]);

  useEffect(() => {
    if (inventoryState.res?.data) {
      setFetchInventory({
        isLoading: false,
        data: inventoryState.res.data,
        error: null,
      });
      setIsEdit(!isEdit);
    }
  }, [inventoryState]);

  const handleBack = () => {
    if (isEdit) {
      setIsEdit(false);
      return;
    }
    navigate(Path.inventoryManagement);
  };

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-full pb-6 md:pb-8  relative">
          <div className="w-full flex items-center justify-start inset-x-0 top-0 pr-4 md:pr-0">
            <NavigationBackBtn onClick={handleBack} text={isEdit ? '商品変更' : '商品詳細'} />
            {!isEdit && (
              <div className="flex flex-row gap-2 ml-auto">
                <button onClick={toggleEditMode} className="button-size-small bg-blue-btn-primary">
                  変更
                </button>
              </div>
            )}
          </div>
          <div className="mt-6 w-full p-4 md:p-0">
            {fetchInventory.isLoading ? <LoadingProgress /> : null}

            {!fetchInventory.isLoading && fetchInventory.error ? <LoadInitError error={fetchInventory.error} /> : null}

            {!fetchInventory.isLoading &&
              !fetchInventory.error &&
              fetchInventory.data &&
              (isEdit ? (
                <InventoryForm
                  defaultValues={{ ...fetchInventory.data.product, inventory: fetchInventory.data.inventory }}
                  key={fetchInventory.data.product?._id}
                  handleBackClick={handleBackClick}
                  isReadMode={inventoryState.confirmedData}
                  handleSubmit={inventoryState.confirmedData ? handleUpdateInventory : null}
                  handleConfirm={handleConfirm}
                  id={fetchInventory.data.product?._id}
                />
              ) : (
                <InventoryDetail item={fetchInventory.data} />
              ))}

            {!fetchInventory.isLoading && !isEdit && (
              <div className="w-full text-center mt-10 md:mt-[106px] flex flex-col gap-1 items-center justify-between text-[#252525] text-base font-semibold">
                <button onClick={handleBack} className="button-size bg-white md:w-auto text-[#252525]">
                  商品在庫一覧に戻る
                </button>
              </div>
            )}
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default InventoryManagementItemDetail;
