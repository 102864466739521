import React from 'react';
import { handleClassesToText } from '../InventoryManagementTopPage/helpers';
import Image from '../../../components/Image';
import { formatPrice } from '../../../utils';

function InventoryDetail(props) {
  const { item = {} } = props;
  const { product } = item;

  if (!product) {
    return <></>;
  }

  return (
    <div className=" flex flex-col gap-6 text-[#121212]">
      <div className="text-center ">
        <Image
          src={product.imageUrl}
          alt={product.name}
          className="inline-block m-auto max-w-full w-[200px] h-[200px]"
        />
      </div>

      <div className="flex-1 flex flex-col md:flex-row gap-4 relative">
        <div className="md:max-w-[calc(100%-320px)]">
          <div className="text-[#666666] text-sm break-words">
            {product.maker}/{product.series}
          </div>
          <div className="text-lg mt-1 break-words"> {product.name + (product.size ? `(${product.size})` : '')}</div>
        </div>
        <div className="flex flex-row flex-wrap md:flex-nowrap gap-4 flex-1 md:ml-auto text-base items-end justify-between md:justify-end">
          <div className="">
            <div className="flex flex-row gap-2">
              <div className="max-w-[80px]">税込価格</div>
              <div className="flex-1">
                <div className="truncate inline-block align-bottom max-w-[100px] md:max-w-[70px]">
                  {formatPrice(product.priceTaxInc, false)}
                </div>
                円
              </div>
            </div>
          </div>
          <div className="flex  gap-2">
            <div>在庫数</div>
            <div className="truncate inline-block align-bottom max-w-[70px] md:max-w-[70px]">{item.inventory}</div>
          </div>
        </div>
      </div>

      <hr className="h-px  border-0 bg-[#EDEDED]" />

      <div className="text-sm break-all whitespace-pre-line">{product.description}</div>

      <hr className="h-px  border-0 bg-[#EDEDED]" />

      <div className="text-base">クラス：{handleClassesToText(product.classes, ' ')}</div>
    </div>
  );
}

export default InventoryDetail;
