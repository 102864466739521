import scalpDataClassABImage from 'app/assets/images/Class_A_B.png';
import scalpDataClassCDImage from 'app/assets/images/Class_C_D.png';
import scalpDataClassEFImage from 'app/assets/images/Class_E_F.png';
import touhiClassAImage from 'app/assets/images/touhiimage-a.png';
import touhiClassBImage from 'app/assets/images/touhiimage-b.png';
import touhiClassCImage from 'app/assets/images/touhiimage-c.png';
import touhiClassDImage from 'app/assets/images/touhiimage-d.png';
import touhiClassEImage from 'app/assets/images/touhiimage-e.png';

export const CLUSTER = {
  A_CLASS: '1',
  B_CLASS: '2',
  C_CLASS: '3',
  D_CLASS: '4',
  E_CLASS: '5',
  AB_CLASS: '6',
  BA_CLASS: '7',
  AC_CLASS: '8',
  CA_CLASS: '9',
  BD_CLASS: '10',
  DB_CLASS: '11',
  CD_CLASS: '12',
  DC_CLASS: '13',
  DE_CLASS: '14',
  ED_CLASS: '15',
  F_CLASS: '16',
};

export const BACKGROUND_COLOR_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: '#00B3ED4D',
  [CLUSTER.B_CLASS]: '#BCD3D1B3',
  [CLUSTER.C_CLASS]: '#E8E2B7B3',
  [CLUSTER.D_CLASS]: '#E8CEA5B3',
  [CLUSTER.E_CLASS]: '#E3C1B2B3',
  [CLUSTER.AB_CLASS]: '#00B3ED4D',
  [CLUSTER.BA_CLASS]: '#BCD3D1B3',
  [CLUSTER.AC_CLASS]: '#E8E2B7B3',
  [CLUSTER.CA_CLASS]: '#E8CEA5B3',
  [CLUSTER.BD_CLASS]: '#E3C1B2B3',
  [CLUSTER.DB_CLASS]: '#00B3ED4D',
  [CLUSTER.CD_CLASS]: '#BCD3D1B3',
  [CLUSTER.DC_CLASS]: '#E8E2B7B3',
  [CLUSTER.DE_CLASS]: '#E8CEA5B3',
  [CLUSTER.ED_CLASS]: '#E3C1B2B3',
  [CLUSTER.F_CLASS]: '#E28E88B3',
};

export const BORDER_COLOR_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: '#1B81BD',
  [CLUSTER.B_CLASS]: '#20957E',
  [CLUSTER.C_CLASS]: '#B09A2F',
  [CLUSTER.D_CLASS]: '#CD8B2B',
  [CLUSTER.E_CLASS]: '#A1593B',
  [CLUSTER.AB_CLASS]: '#1B81BD',
  [CLUSTER.BA_CLASS]: '#20957E',
  [CLUSTER.AC_CLASS]: '#B09A2F',
  [CLUSTER.CA_CLASS]: '#CD8B2B',
  [CLUSTER.BD_CLASS]: '#A1593B',
  [CLUSTER.DB_CLASS]: '#1B81BD',
  [CLUSTER.CD_CLASS]: '#20957E',
  [CLUSTER.DC_CLASS]: '#B09A2F',
  [CLUSTER.DE_CLASS]: '#CD8B2B',
  [CLUSTER.ED_CLASS]: '#A1593B',
  [CLUSTER.F_CLASS]: '#C53125',
};

export const AVERAGE_VALUE_BY_CLUSTER = {
  // update A ~ E
  [CLUSTER.A_CLASS]: [6.51, 7.43, 7.39],
  [CLUSTER.B_CLASS]: [5.13, 5.37, 5.65],
  [CLUSTER.C_CLASS]: [4.92, 3.02, 7.53],
  [CLUSTER.D_CLASS]: [4.74, 2.51, 4.08],
  [CLUSTER.E_CLASS]: [2.74, 4.97, 7.85],
  [CLUSTER.AB_CLASS]: [6.51, 7.43, 7.39],
  [CLUSTER.BA_CLASS]: [5.13, 5.37, 5.65],
  [CLUSTER.AC_CLASS]: [4.92, 3.02, 7.53],
  [CLUSTER.CA_CLASS]: [4.74, 2.51, 4.08],
  [CLUSTER.BD_CLASS]: [2.74, 4.97, 7.85],
  [CLUSTER.DB_CLASS]: [6.51, 7.43, 7.39],
  [CLUSTER.CD_CLASS]: [5.13, 5.37, 5.65],
  [CLUSTER.DC_CLASS]: [4.92, 3.02, 7.53],
  [CLUSTER.DE_CLASS]: [4.74, 2.51, 4.08],
  [CLUSTER.ED_CLASS]: [2.74, 4.97, 7.85],
  [CLUSTER.F_CLASS]: [2.48, 2.96, 5.11],
};

export const AVERAGE_SCORE_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: [8.896, 9.414, 7.657],
  [CLUSTER.B_CLASS]: [6.456, 8.386, 3.728],
  [CLUSTER.C_CLASS]: [4.317, 5.193, 7.301],
  [CLUSTER.D_CLASS]: [5.725, 3.104, 1.841],
  [CLUSTER.E_CLASS]: [1.906, 3.47, 2.135],
};

export const IMAGE_URL_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: scalpDataClassABImage,
  [CLUSTER.B_CLASS]: scalpDataClassABImage,
  [CLUSTER.C_CLASS]: scalpDataClassCDImage,
  [CLUSTER.D_CLASS]: scalpDataClassCDImage,
  [CLUSTER.E_CLASS]: scalpDataClassEFImage,
  [CLUSTER.AB_CLASS]: scalpDataClassABImage,
  [CLUSTER.BA_CLASS]: scalpDataClassABImage,
  [CLUSTER.AC_CLASS]: scalpDataClassCDImage,
  [CLUSTER.CA_CLASS]: scalpDataClassCDImage,
  [CLUSTER.BD_CLASS]: scalpDataClassEFImage,
  [CLUSTER.DB_CLASS]: scalpDataClassABImage,
  [CLUSTER.CD_CLASS]: scalpDataClassABImage,
  [CLUSTER.DC_CLASS]: scalpDataClassCDImage,
  [CLUSTER.DE_CLASS]: scalpDataClassCDImage,
  [CLUSTER.ED_CLASS]: scalpDataClassEFImage,
  [CLUSTER.F_CLASS]: scalpDataClassEFImage,
};

export const IMAGE_URL_TOUHI_CLASS = {
  [CLUSTER.A_CLASS]: touhiClassAImage,
  [CLUSTER.B_CLASS]: touhiClassBImage,
  [CLUSTER.C_CLASS]: touhiClassCImage,
  [CLUSTER.D_CLASS]: touhiClassDImage,
  [CLUSTER.E_CLASS]: touhiClassEImage,
  [CLUSTER.AB_CLASS]: touhiClassAImage,
  [CLUSTER.BA_CLASS]: touhiClassBImage,
  [CLUSTER.AC_CLASS]: touhiClassCImage,
  [CLUSTER.CA_CLASS]: touhiClassDImage,
  [CLUSTER.BD_CLASS]: touhiClassEImage,
  [CLUSTER.DB_CLASS]: touhiClassAImage,
  [CLUSTER.CD_CLASS]: touhiClassBImage,
  [CLUSTER.DC_CLASS]: touhiClassCImage,
  [CLUSTER.DE_CLASS]: touhiClassDImage,
  [CLUSTER.ED_CLASS]: touhiClassEImage,
};

export const THUMB_BACKGROUND_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: '#DBF6FF',
  [CLUSTER.B_CLASS]: '#D8FFEF',
  [CLUSTER.C_CLASS]: '#F0FFD8',
  [CLUSTER.D_CLASS]: '#FFF2D8',
  [CLUSTER.E_CLASS]: '#FFDAD8',
  [CLUSTER.AB_CLASS]: '#DBF6FF',
  [CLUSTER.BA_CLASS]: '#D8FFEF',
  [CLUSTER.AC_CLASS]: '#F0FFD8',
  [CLUSTER.CA_CLASS]: '#FFF2D8',
  [CLUSTER.BD_CLASS]: '#FFDAD8',
  [CLUSTER.DB_CLASS]: '#DBF6FF',
  [CLUSTER.CD_CLASS]: '#D8FFEF',
  [CLUSTER.DC_CLASS]: '#F0FFD8',
  [CLUSTER.DE_CLASS]: '#FFF2D8',
  [CLUSTER.ED_CLASS]: '#FFDAD8',
  [CLUSTER.F_CLASS]: '#E28E88',
};

export const TEXT_BY_CLUSTER = {
  [CLUSTER.A_CLASS]: 'A',
  [CLUSTER.B_CLASS]: 'B',
  [CLUSTER.C_CLASS]: 'C',
  [CLUSTER.D_CLASS]: 'D',
  [CLUSTER.E_CLASS]: 'E',
  [CLUSTER.AB_CLASS]: 'AB',
  [CLUSTER.BA_CLASS]: 'BA',
  [CLUSTER.AC_CLASS]: 'AC',
  [CLUSTER.CA_CLASS]: 'CA',
  [CLUSTER.BD_CLASS]: 'BD',
  [CLUSTER.DB_CLASS]: 'DB',
  [CLUSTER.CD_CLASS]: 'CD',
  [CLUSTER.DC_CLASS]: 'DC',
  [CLUSTER.DE_CLASS]: 'DE',
  [CLUSTER.ED_CLASS]: 'ED',
  [CLUSTER.F_CLASS]: 'F',
};

export const DEFAULT_INFO = {
  THUMB_BACKGROUND: '#000',
  IMAGE_URL: scalpDataClassEFImage,
  TEXT: '?',
};

export const DEFAULT_RADAR_DATA = {
  // labels: ["細胞面積", "多重剥離", "カルボニル"],
  labels: ['0.0', '0.0', '0.0'],
  datasets: [
    {
      label: '# of Votes',
      data: [0, 0, 0],
      backgroundColor: 'rgba(157, 193, 226, 0.7)',
    },
  ],
};

export const EXPECTED_HEIGHT = 500;
export const EXPECTED_RATIO = 4 / 3;
