import { useMemo } from "react";
import {
  IMAGE_URL_BY_CLUSTER,
  THUMB_BACKGROUND_BY_CLUSTER,
} from "../constants";
import TypesReferenceInfoItem from "./Item";
import { TYPE_DATAS } from "./constants";

const TypesReferenceInfo = ({ type, factorCluster, displayName, isTablet }) => {
  const typeData = TYPE_DATAS.filter(
    (item) => item.typeName === type
  );

  const { imageUrl, thumbBackground } = useMemo(() => {
    const imageUrl = IMAGE_URL_BY_CLUSTER[factorCluster];
    const thumbBackground =
      THUMB_BACKGROUND_BY_CLUSTER[factorCluster];
    return { imageUrl, thumbBackground };
  }, [factorCluster]);

  return (
    <div className="w-full h-auto flex flex-row">
      {typeData.map((item, index) => (
        <TypesReferenceInfoItem
          key={index}
          typeName={item.typeName}
          title={item.title}
          description={item.description}
          backgroundClass={item.backgroundClass}
          isTablet
          imageUrl={imageUrl}
          thumbBackground={thumbBackground}
          displayName={displayName}
        />
      ))}
    </div>
  );
};

export default TypesReferenceInfo;
