export const questions = [
  [
    {
      id: 'Q_HairThickness',
      text: 'Q1．毛髪の太さについて',
      content: '毛髪の太さについて',
      index: 'Q1',
      type: 'options',
      options: ['細い', '普通', '太い'],
    },
    {
      id: 'Q_HairQuality',
      text: 'Q2. 髪質',
      content: '髪質',
      index: 'Q2',
      type: 'options',
      options: ['普通', 'やわらかい', 'かたい'],
    },
    {
      id: 'Q_HairAging',
      text: 'Q3. エイジング毛',
      content: 'エイジング毛',
      index: 'Q3',
      type: 'options',
      options: ['多い', '少しある', 'ない'],
    },
    {
      id: 'Q_HairCurliness',
      text: 'Q4. くせ毛',
      content: 'くせ毛',
      index: 'Q4',
      type: 'options',
      options: ['多い', '少しある', 'ない'],
    },
    {
      id: 'Q_WaterAbsorption',
      text: 'Q5. 吸水性について',
      content: '吸水性について',
      index: 'Q5',
      type: 'options',
      options: ['吸水毛', '普通毛', '撥水毛'],
    },
    {
      id: 'Q_ScalpColor',
      text: 'Q6. 頭皮の色について',
      content: '頭皮の色について',
      index: 'Q6',
      type: 'options',
      options: ['青色', '青黄', '黄色', '橙色', '赤色'],
    },
    {
      id: 'Q_AppearanceScalp',
      text: 'Q7. 頭皮の見た目',
      content: '頭皮の見た目',
      index: 'Q7',
      type: 'options',
      options: ['乾燥', '普通', '脂性'],
    },
    {
      id: 'Q_Hardness',
      text: 'Q8. 頭皮のかたさ',
      content: '頭皮のかたさ',
      index: 'Q8',
      type: 'options',
      options: ['やわらかい', '普通', 'かたい'],
    },
    {
      id: 'Q_Damage',
      text: 'Q9. 髪のダメージ',
      content: '髪のダメージ',
      index: 'Q9',
      type: 'options',
      options: ['悪い', 'やや悪い', '平均的', 'やや良い', '良い'],
    },
    {
      id: 'Q_OtherHairConcerns',
      text: 'Q10. その他毛髪で気になる点',
      content: 'その他毛髪で気になる点',
      index: 'Q10',
      type: 'input',
      options: '',
    },
  ],
  // Section 1.
  [
    {
      id: 'Q_Occupation',
      text: 'Q1. お客様の職業は何ですか',
      content: 'お客様の職業は何ですか',
      index: 'Q1',
      type: 'options',
      options: ['会社員', 'パート', 'アルバイト', '学生', '主夫・主婦', '自営業', 'その他'],
    },
    {
      id: 'Q_JobType',
      text: 'Q2. お客様の職種は何ですか',
      content: 'お客様の職種は何ですか',
      index: 'Q2',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_CommuteMethod',
      text: 'Q3. お客様はどんな方法で通勤していますか',
      content: 'お客様はどんな方法で通勤していますか',
      index: 'Q3',
      type: 'options',
      options: ['徒歩', '自転車', '電車', '車', 'バイク', 'その他'],
    },
    {
      id: 'Q_CommuteTime',
      text: 'Q4. 通勤時間はどの程度ですか',
      content: '通勤時間はどの程度ですか',
      index: 'Q4',
      type: 'options',
      options: ['10分以内', '10-30分', '30-60分', '60分以上'],
    },
    {
      id: 'Q_WorkSchedule',
      text: 'Q5. 仕事体系はどうですか',
      content: '仕事体系はどうですか',
      index: 'Q5',
      type: 'options',
      options: ['固定', 'シフト', 'その他'],
    },
    {
      id: 'Q_OffDays',
      text: 'Q6. お仕事がお休みの曜日は確認しましたか',
      content: 'お仕事がお休みの曜日は確認しましたか',
      index: 'Q6',
      type: 'options',
      options: ['平日', '土日', 'シフト'],
    },
    {
      id: 'Q_WorkplaceLocation',
      text: 'Q7. 職場のロケーションはどうですか',
      content: '職場のロケーションはどうですか',
      index: 'Q7',
      type: 'options',
      options: ['オフィス街', '商業地域', '工業地域', '住宅街', 'その他'],
    },
    {
      id: 'Q_DressCode',
      text: 'Q8. 職場のドレスコードに制約はありますか',
      content: '職場のドレスコードに制約はありますか',
      index: 'Q8',
      type: 'options',
      options: ['制服', '作業着', 'オフィス\nカジュアル', '自由', 'その他'],
    },
    {
      id: 'Q_HairLengthRestriction',
      text: 'Q9. 職場にヘアスタイル制約（長さ）はありますか',
      content: '職場にヘアスタイル制約（長さ）はありますか',
      index: 'Q9',
      type: 'options',
      options: ['自由', 'ショート', 'ひとまとめ', 'その他'],
    },
    {
      id: 'Q_HairColorRestriction',
      text: 'Q10. 職場にヘアスタイル制約（色）はありますか',
      content: '職場にヘアスタイル制約（色）はありますか',
      index: 'Q10',
      type: 'options',
      options: ['自由', '黒のみ', 'ある程度OK', 'その他'],
    },
    {
      id: 'Q_HairstyleRestriction',
      text: 'Q11. 職場にヘアスタイル制約（髪型）はありますか',
      content: '職場にヘアスタイル制約（髪型）はありますか',
      index: 'Q11',
      type: 'options',
      options: ['自由', '指定あり'],
    },
    {
      id: 'Q_ForeheadExposureRestriction',
      text: 'Q12. 職場にヘアスタイル制約（額の露出）はありますか',
      content: '職場にヘアスタイル制約（額の露出）はありますか',
      index: 'Q12',
      type: 'options',
      options: ['自由', '指定あり'],
    },
    {
      id: 'Q_BeardRestriction',
      text: 'Q13. 職場にヘアスタイル制約（ひげ）はありますか',
      content: '職場にヘアスタイル制約（ひげ）はありますか',
      index: 'Q13',
      type: 'options',
      options: ['自由', '指定あり'],
    },
    {
      id: 'Q_HeadgearRestriction',
      text: 'Q14. 職場にヘアスタイル制約（作業帽の有無）はありますか',
      content: '職場にヘアスタイル制約（作業帽の有無）はありますか',
      index: 'Q14',
      type: 'options',
      options: ['なし', 'あり'],
    },
    {
      id: 'Q_MakeupNorms',
      text: 'Q15. 職場での化粧慣習はありますか',
      content: '職場での化粧慣習はありますか',
      index: 'Q15',
      type: 'options',
      options: ['なし', 'あり'],
    },
  ],
  // Section 2.
  [
    {
      id: 'Q_Hobbies',
      text: 'Q1. どんな趣味をもっていますか',
      content: 'どんな趣味をもっていますか',
      index: 'Q1',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_HobbyBudget',
      text: 'Q2. その趣味にかける予算はどの程度ですか',
      content: 'その趣味にかける予算はどの程度ですか',
      index: 'Q2',
      type: 'options',
      options: ['5000円/月\n以下', '5000円～\n1万円/月', '1万円～\n10万円/月', '10万円/月\n以上'],
    },
    {
      id: 'Q_HobbyFrequency',
      text: 'Q3. 趣味の頻度はどの程度ですか',
      content: '趣味の頻度はどの程度ですか',
      index: 'Q3',
      type: 'options',
      options: ['毎日', '週2，3回', '週1回', '月2，3回', '月1回', '年に数回'],
    },
    {
      id: 'Q_FavoriteMedia',
      text: 'Q4. 好きな映画やドラマ、Youtubeチャンネルはありますか',
      content: '好きな映画やドラマ、Youtubeチャンネルはありますか',
      index: 'Q4',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_FavoriteMusic',
      text: 'Q5. 好きな音楽ジャンルやアーティストはありますか',
      content: '好きな音楽ジャンルやアーティストはありますか',
      index: 'Q5',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_WeekendActivities',
      text: 'Q6. 休日はなにをして過ごしていますか',
      content: '休日はなにをして過ごしていますか',
      index: 'Q6',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_WeekdayEveningActivities',
      text: 'Q7. 平日の夜は何をして過ごしていますか',
      content: '平日の夜は何をして過ごしていますか',
      index: 'Q7',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_OutdoorIndoorPreference',
      text: 'Q8. アウトドア派ですか？インドア派ですか？',
      content: 'アウトドア派ですか？インドア派ですか？',
      index: 'Q8',
      type: 'options',
      options: ['アウトドア派', 'インドア派'],
    },
  ],
  // Section 3.
  [
    {
      id: 'Q_MorningSkincareMakeup',
      text: 'Q1. 朝のスキンケア・メイクの手順',
      content: '朝のスキンケア・メイクの手順',
      index: 'Q1',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_EveningSkincareMakeup',
      text: 'Q2. 夜のスキンケア・メイクの手順',
      content: '夜のスキンケア・メイクの手順',
      index: 'Q2',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_WeekdayDiet',
      text: 'Q3. 平日はどんな食生活をしていますか',
      content: '平日はどんな食生活をしていますか',
      index: 'Q3',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_WeekendDiet',
      text: 'Q4. 休日はどんな食生活をしていますか',
      content: '休日はどんな食生活をしていますか',
      index: 'Q4',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_CosmeticBrand',
      text: 'Q5. 使用している化粧品ブランドは何ですか',
      content: '使用している化粧品ブランドは何ですか',
      index: 'Q5',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_AverageEatingOut',
      text: 'Q6. 1週間の平均外食回数はどの程度ですか',
      content: '1週間の平均外食回数はどの程度ですか',
      index: 'Q6',
      type: 'options',
      options: ['毎日', '週2，3回', '週1回', '月2，3回', '月１回', 'いかない'],
    },
    {
      id: 'Q_FrequentPlaces',
      text: 'Q7. よく訪れる店や場所はありますか',
      content: 'よく訪れる店や場所はありますか',
      index: 'Q7',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_HavePets',
      text: 'Q8. ペットは飼っていますか',
      content: 'ペットは飼っていますか',
      index: 'Q8',
      type: 'options',
      options: ['飼っている', '飼っていない'],
    },
    {
      id: 'Q_PetType',
      text: 'Q9. 飼っているペットは何ですか',
      content: '飼っているペットは何ですか',
      index: 'Q9',
      type: 'input',
      options: '',
    },
    // Add more if needed
  ],
  // Section 4.
  [
    {
      id: 'Q_PreferredSNS',
      text: 'Q1. よく利用するSNSはなんですか（複数選択可能）',
      content: 'よく利用するSNSはなんですか（複数選択可能）',
      index: 'Q1',
      type: 'options',
      isMultiSelect: true,
      options: ['Instagram', 'TikTok', 'X(Twitter)', 'facebook', 'Treads', 'LinkedIn', 'その他'],
    },
    {
      id: 'Q_WebsitesVisited',
      text: 'Q2. 普段どんなサイトをみますか',
      content: '普段どんなサイトをみますか',
      index: 'Q2',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_CommunicationMethods',
      text: 'Q3. よく使うコミュニケーション手段は何ですか',
      content: 'よく使うコミュニケーション手段は何ですか',
      index: 'Q3',
      type: 'options',
      options: ['LINE', 'Messenger', 'E-mail', '電話', 'その他'],
    },
    // Add more if needed
  ],
  // Section 5.
  [
    {
      id: 'Q_FrequentShoppingAreas',
      text: 'Q1. よく訪れるショッピングモールやエリアはどこですか',
      content: 'よく訪れるショッピングモールやエリアはどこですか',
      index: 'Q1',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_MainShoppingPlaces',
      text: 'Q2. 買い物の際の主な購入先はどこですか',
      content: '買い物の際の主な購入先はどこですか',
      index: 'Q2',
      type: 'options',
      options: ['オンライン', 'オフライン', 'その他'],
    },
    {
      id: 'Q_FavoriteBrands',
      text: 'Q3. お気に入りのブランドや店舗はありますか',
      content: 'お気に入りのブランドや店舗はありますか',
      index: 'Q3',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_ClothingShoppingFrequency',
      text: 'Q4. 服のショッピング頻度はどれくらいですか',
      content: '服のショッピング頻度はどれくらいですか',
      index: 'Q4',
      type: 'options',
      options: ['週2，3回', '週1回', '月2，3回', '月1回'],
    },
    {
      id: 'Q_ClothingBudget',
      text: 'Q5. 服を買うときの予算はありますか',
      content: '服を買うときの予算はありますか',
      index: 'Q5',
      type: 'options',
      options: ['5000円/月\n以下', '5000円～\n1万円/月', '1万円～\n10万円/月', '10万円/月\n以上'],
    },
    {
      id: 'Q_ShoppingCriteria',
      text: 'Q6. 買物の際の判断基準は何ですか',
      content: '買物の際の判断基準は何ですか',
      index: 'Q6',
      type: 'options',
      options: ['価格', 'ブランド', '品質', '使用感', 'その他'],
    },
    {
      id: 'Q_ShoppingInformationSource',
      text: 'Q7. 買物の際の情報収集はどこからですか',
      content: '買物の際の情報収集はどこからですか',
      index: 'Q7',
      type: 'options',
      options: ['SNS', 'ネット広告', '外出先', '特になし', 'その他'],
    },
    {
      id: 'Q_SaleDiscountUse',
      text: 'Q8. セールや割引を利用しますか',
      content: 'セールや割引を利用しますか',
      index: 'Q8',
      type: 'options',
      options: ['よくする', 'たまにする', 'しない'],
    },
    {
      id: 'Q_PaymentMethodPreference',
      text: 'Q9. 買い物の際の支払い方法の好みはなんですか',
      content: '買い物の際の支払い方法の好みはなんですか',
      index: 'Q9',
      type: 'options',
      options: ['現金', 'クレジット\nカード', 'デジタル支払', 'その他'],
    },
    {
      id: 'Q_GiftPurchaseFrequency',
      text: 'Q10. 贈り物やプレゼントの購入頻度はどの程度ですか',
      content: '贈り物やプレゼントの購入頻度はどの程度ですか',
      index: 'Q10',
      type: 'options',
      options: ['月に2，3回', '月に１回', '年に数回', 'しない', 'その他'],
    },
    {
      id: 'Q_RewardsForYourself',
      text: 'Q11. 自分へのご褒美はしますか',
      content: '自分へのご褒美はしますか',
      index: 'Q11',
      type: 'options',
      options: ['はい', 'いいえ'],
    },
    {
      id: 'Q_RewardsFrequency',
      text: 'Q12. 自分へのご褒美の頻度はどの程度ですか',
      content: '自分へのご褒美の頻度はどの程度ですか',
      index: 'Q12',
      type: 'options',
      options: ['週に2，3回', '週に１回', '月に2，3回', '月に1回', '年に数回', 'しない', 'その他'],
    },
    {
      id: 'Q_TechInterest',
      text: 'Q13. 家電やテクノロジー製品に興味はありますか',
      content: '家電やテクノロジー製品に興味はありますか',
      index: 'Q13',
      type: 'options',
      options: ['ある', 'ない', 'その他'],
    },
    {
      id: 'Q_TechPurchaseFrequency',
      text: 'Q14. 家電やテクノロジー製品の購入頻度はどの程度ですか',
      content: '家電やテクノロジー製品の購入頻度はどの程度ですか',
      index: 'Q14',
      type: 'options',
      options: ['月に2，3回', '月に1回', '年に数回', 'しない', 'その他'],
    },
    {
      id: 'Q_LifestyleInterest',
      text: 'Q15. 生活雑貨やインテリアに興味がありますか',
      content: '生活雑貨やインテリアに興味がありますか',
      index: 'Q15',
      type: 'options',
      options: ['ある', 'ない', 'その他'],
    },
    {
      id: 'Q_LifestylePurchaseFrequency',
      text: 'Q16. 生活雑貨やインテリアの購入頻度はどの程度ですか',
      content: '生活雑貨やインテリアの購入頻度はどの程度ですか',
      index: 'Q16',
      type: 'options',
      options: ['月に2，3回', '月に1回', '年に数回', 'しない', 'その他'],
    },
  ],
  // Section 6.
  [
    {
      id: 'Q_TravelPreference',
      text: 'Q1. 旅行は好きですか',
      content: '旅行は好きですか',
      index: 'Q1',
      type: 'options',
      options: ['はい', 'いいえ'],
    },
    {
      id: 'Q_TravelFrequency',
      text: 'Q2. 旅行の頻度はどの程度ですか',
      content: '旅行の頻度はどの程度ですか',
      index: 'Q2',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_DomesticInternationalRatio',
      text: 'Q3. 国内と海外の比率はどの程度ですか',
      content: '国内と海外の比率はどの程度ですか',
      index: 'Q3',
      type: 'options',
      options: ['国内優勢', '海外優勢', 'その他'],
    },
    {
      id: 'Q_LastTravelLocation',
      text: 'Q4. 最後に訪れた場所はどこですか',
      content: '最後に訪れた場所はどこですか',
      index: 'Q4',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_NextTravelDestination',
      text: 'Q5. 次に行ってみたい場所はどこですか',
      content: '次に行ってみたい場所はどこですか',
      index: 'Q5',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_TravelStyle',
      text: 'Q6. 旅行時のスタイルはありますか',
      content: '旅行時のスタイルはありますか',
      index: 'Q6',
      type: 'options',
      options: ['ツアー', '個人', 'その他'],
    },
    {
      id: 'Q_TravelEssentials',
      text: 'Q7. 旅行時の必須アイテムはありますか',
      content: '旅行時の必須アイテムはありますか',
      index: 'Q7',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_OutdoorActivitiesPreference',
      text: 'Q8. アウトドア活動は好きですか',
      content: 'アウトドア活動は好きですか',
      index: 'Q8',
      type: 'options',
      options: ['はい', 'いいえ'],
    },
    {
      id: 'Q_CampingExperience',
      text: 'Q9. キャンプ経験はありますか',
      content: 'キャンプ経験はありますか',
      index: 'Q9',
      type: 'options',
      options: ['はい', 'いいえ'],
    },
    {
      id: 'Q_HaircareDuringTravelOutdoor',
      text: 'Q10. 旅行やアウトドアでヘアケアはどうしていますか',
      content: '旅行やアウトドアでヘアケアはどうしていますか',
      index: 'Q10',
      type: 'input',
      options: '',
    },
  ],
  // Section 7.
  [
    {
      id: 'Q_DietConcern',
      text: 'Q1. ダイエットは気になったりしますか',
      content: 'ダイエットは気になったりしますか',
      index: 'Q1',
      type: 'options',
      options: ['はい', 'いいえ'],
    },
    {
      id: 'Q_ExerciseHabits',
      text: 'Q2. 運動はしてますか',
      content: '運動はしてますか',
      index: 'Q2',
      type: 'options',
      options: ['はい', 'いいえ'],
    },
    {
      id: 'Q_ExerciseFrequency',
      text: 'Q3. 運動の頻度はどの程度ですか',
      content: '運動の頻度はどの程度ですか',
      index: 'Q3',
      type: 'options',
      options: ['毎日', '週2，3回', '週1回', '月2，3回', '月１回', 'その他'],
    },
    {
      id: 'Q_TypeOfExercise',
      text: 'Q4. どんな運動をしていますか',
      content: 'どんな運動をしていますか',
      index: 'Q4',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_ReasonForStartingExercise',
      text: 'Q5. その運動をはじめたきっかけは何ですか',
      content: 'その運動をはじめたきっかけは何ですか',
      index: 'Q5',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_HealthCheckConcern',
      text: 'Q6. 健康診断の結果で気になったりしますか',
      content: '健康診断の結果で気になったりしますか',
      index: 'Q6',
      type: 'options',
      options: ['はい', 'いいえ'],
    },
    {
      id: 'Q_HealthCheckConcernAreas',
      text: 'Q7. 健康診断の結果で気になった項目は何ですか',
      content: '健康診断の結果で気になった項目は何ですか',
      index: 'Q7',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_StressReliefMethods',
      text: 'Q8. ストレスの解消法はなんですか',
      content: 'ストレスの解消法はなんですか',
      index: 'Q8',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_RelaxationFacilities',
      text: 'Q9. どんなリラクゼーション施設を活用しますか',
      content: 'どんなリラクゼーション施設を活用しますか',
      index: 'Q9',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_SupplementUse',
      text: 'Q10. サプリメントは接種していますか',
      content: 'サプリメントは接種していますか',
      index: 'Q10',
      type: 'options',
      options: ['はい', 'いいえ'],
    },
    {
      id: 'Q_SupplementTypes',
      text: 'Q11. どんなサプリメントを接種していますか',
      content: 'どんなサプリメントを接種していますか',
      index: 'Q11',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_SleepDuration',
      text: 'Q12. 睡眠時間はどの程度ですか',
      content: '睡眠時間はどの程度ですか',
      index: 'Q12',
      type: 'options',
      options: ['3時間以下', '3-5時間程度', '5-8時間程度', '8時間以上'],
    },
    {
      id: 'Q_SleepQuality',
      text: 'Q13. 睡眠の質はどうですか',
      content: '睡眠の質はどうですか',
      index: 'Q13',
      type: 'options',
      options: ['良い', '悪い'],
    },
    {
      id: 'Q_ImprovingSleepQuality',
      text: 'Q14. 睡眠の質を改善するために何かしていますか',
      content: '睡眠の質を改善するために何かしていますか',
      index: 'Q14',
      type: 'input',
      options: '',
    },
    {
      id: 'Q_AllergiesExist',
      text: 'Q15. アレルギーはありますか',
      content: 'アレルギーはありますか',
      index: 'Q15',
      type: 'options',
      options: ['はい', 'いいえ'],
    },
    {
      id: 'Q_AllergyPreventionMethods',
      text: 'Q16. アレルギー対策はなにかしていますか',
      content: 'アレルギー対策はなにかしていますか',
      index: 'Q16',
      type: 'input',
      options: '',
    },
  ],
  // Section 8
];

export const sections = [
  { id: 1, title: '１．髪質について' },
  { id: 2, title: '２．職業・勤務環境' },
  { id: 3, title: '３．趣味・レジャー' },
  { id: 4, title: '４．日常生活の習慣' },
  { id: 5, title: '５．ソーシャルライフ' },
  { id: 6, title: '６．買い物＆消費行動' },
  { id: 7, title: '７．旅行・アウトドア' },
  { id: 8, title: '８．健康＆ウェルネス' },
];
