import qrCodeIcon from 'app/assets/svg/qr_code.svg';
import CommonDialog from 'app/components/CommonDialog';
import LoadingProgress from 'app/components/commonUI/LoadingProgress';
import LoadInitError from 'app/components/commonUI/LoadInitError';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import { getDetailCustomer } from 'app/services/api';
import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import { formatToJapaneseDate, getNameFromFirstLast, getNameKanji } from '../../../utils';
import { getCustomerGenderText } from './helpers';

function CustomerBaseInfoContent({ customerInfo }) {
  return (
    <div className="w-full h-auto flex flex-col gap-6 md:gap-0">
      <div className="flex flex-col gap-2 md:border-b md:border-[#EDEDED] md:p-3">
        <label className="text-subtitle">お客様No.</label>
        <p className="text-[#252525] break-all">{customerInfo.customerNo}</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-0 md:border-b md:border-[#EDEDED]">
        <div className="flex flex-col gap-2 w-full md:border-r md:border-[#EDEDED] md:p-3">
          <label className="text-subtitle">お名前</label>
          <p className="text-[#252525] break-all">{getNameKanji(customerInfo)}</p>
        </div>
        <div className="flex flex-col gap-2 w-full md:p-3">
          <label className="text-subtitle">お名前フリガナ</label>
          <p className="text-[#252525] break-all">{getNameFromFirstLast(customerInfo)}</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-0 md:border-b md:border-[#EDEDED]">
        <div className="flex flex-col gap-2 w-full md:border-r md:border-[#EDEDED] md:p-3">
          <label className="text-subtitle">生年月日</label>
          <p className="text-[#252525] break-all">{formatToJapaneseDate(customerInfo.dob)}</p>
        </div>
        <div className="flex flex-col gap-2 w-full md:p-3">
          <label className="text-subtitle">性別</label>
          <p className="text-[#252525] break-all">{getCustomerGenderText(customerInfo?.gender)}</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-0 md:border-b md:border-[#EDEDED]">
        <div className="flex flex-col gap-2 w-full md:border-r md:border-[#EDEDED] md:p-3">
          <label className="text-subtitle">郵便番号</label>
          <p className="text-[#252525] break-all">{customerInfo.postalCode}</p>
        </div>
        <div className="flex flex-col gap-2 w-full md:p-3">
          <label className="text-subtitle">住所</label>
          <p className="text-[#252525] break-all">
            {customerInfo.prefecture} {customerInfo.city} {customerInfo.town} {customerInfo.address}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-0 md:border-b md:border-[#EDEDED]">
        <div className="flex flex-col gap-2 w-full md:border-r md:border-[#EDEDED] md:p-3">
          <label className="text-subtitle">電話番号1</label>
          <p className="text-[#252525] break-all">{customerInfo.phone1}</p>
        </div>
        <div className="flex flex-col gap-2 w-full md:p-3">
          <label className="text-subtitle">電話番号2</label>
          <p className="text-[#252525] break-all">{customerInfo.phone2 || '登録がありません'}</p>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-full md:p-3">
        <label className="text-subtitle">メールアドレス</label>
        <p className="text-[#252525] break-all">{customerInfo.email}</p>
      </div>
    </div>
  );
}

function DetailCustomerContent({ customerId, redirectToContact, customerBaseInfo, customerNo }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showMemberQrCode, setShowMemberQrCode] = useState(false);
  const isConfirmed = !!customerBaseInfo?.isConfirmAgreement;

  return (
    <div className="w-full h-full">
      <NavigationBackBtn
        url={searchParams.get('from') || Path.customerManagement}
        text={customerBaseInfo.lastNameKanji + ' ' + customerBaseInfo.firstNameKanji}
        actionButton={
          <p
            className={`${isConfirmed ? 'text-blue-btn-primary bg-[#DEF4FF]' : 'bg-gray-base'} w-fit px-3 py-1 rounded-full`}
          >
            {isConfirmed ? '同意書締結済み' : '同意書未締結'}
          </p>
        }
      />
      <CommonDialog open={showMemberQrCode} handleClose={() => setShowMemberQrCode(false)} actions={[]}>
        <div className="w-full h-full">
          <div className="w-full h-auto py-6 flex items-center justify-center">
            <div className="w-60 h-60">
              {customerId ? <QRCodeCanvas value={customerBaseInfo.id} size={240} /> : null}
            </div>
          </div>
        </div>
      </CommonDialog>
      <div className="grid grid-cols-2 gap-3 pt-4 pb-6 px-4">
        <Link
          onClick={() => {
            GA.tracking(EVENT_NAMES.CUSTOMER_DETAIL.CLICK_FIRST_IMPRESSION, {
              customerId,
              customerNo,
            });
          }}
          to={Path.customerManagementFactCheck(customerId, 1)}
          disabled
          className="button-size bg-blue-btn-primary rounded-lg text-white text-sm"
        >
          1stインプレッション
        </Link>
        <Link
          onClick={() => {
            GA.tracking(EVENT_NAMES.CUSTOMER_DETAIL.CLICK_SCALP_LIST, {
              customerId,
              customerNo,
            });
          }}
          to={Path.historyOfOrderingDetail(customerNo)}
          className="button-size bg-blue-btn-primary rounded-lg"
        >
          頭皮データ
        </Link>
        <button
          type="button"
          className="button-size bg-white gap-1 rounded-lg"
          onClick={() => {
            GA.tracking(EVENT_NAMES.CUSTOMER_DETAIL.CLICK_QR, {
              customerId,
              customerNo: customerBaseInfo.customerNo,
            });
            setShowMemberQrCode(true);
          }}
        >
          <img src={qrCodeIcon} alt="QRコード" />
          <span className="text-[#252525] text-sm font-medium">QRコードを表示</span>
        </button>
        <button
          type="button"
          className="button-size bg-white rounded-lg hidden"
          onClick={() => {
            GA.tracking(EVENT_NAMES.CUSTOMER_DETAIL.CLICK_CONTACT, {
              customerId,
              customerNo: customerBaseInfo.customerNo,
            });
            redirectToContact();
          }}
        >
          <span className="text-[#252525] text-sm font-medium">連絡する</span>
        </button>
      </div>

      <div className="w-full px-4 pb-6">
        <button
          type="button"
          className="button-size bg-white rounded-lg"
          onClick={() => {
            GA.tracking(EVENT_NAMES.CUSTOMER_DETAIL.CLICK_UPDATE, {
              customerId,
              customerNo: customerBaseInfo.customerNo,
            });
            navigate(Path.customerManagementUpdateBasicInfo(customerId));
          }}
        >
          <span className="text-[#252525] text-sm font-medium">お客様情報を変更</span>
        </button>
      </div>

      <div className="w-full h-full py-6 px-4 border-t border-[#EDEDED] md:border md:p-0">
        <CustomerBaseInfoContent customerInfo={customerBaseInfo} />
      </div>
    </div>
  );
}

function DetailCustomerPage() {
  const [loadInitState, setLoadInitState] = useState({
    isLoading: true,
    data: null,
    error: null,
  });
  const { customerId } = useParams();
  const navigate = useNavigate();

  const customerNo = loadInitState?.data?.customerNo;

  useEffect(() => {
    setLoadInitState({
      isLoading: true,
      data: null,
      error: null,
    });

    getDetailCustomer(customerId)
      .then((res) => {
        setLoadInitState({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setLoadInitState({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  }, [customerId]);

  return (
    <DefaultLayout>
      <MainContentLayout pageTitle={<p className="text-sm">お客様情報</p>}>
        {loadInitState.isLoading ? <LoadingProgress /> : null}

        {!loadInitState.isLoading && loadInitState.error ? <LoadInitError error={loadInitState.error} /> : null}

        {!loadInitState.isLoading && !loadInitState.error && loadInitState.data ? (
          <div className="w-full h-full flex items-center justify-center pb-12">
            <DetailCustomerContent
              customerBaseInfo={loadInitState.data}
              customerId={customerId}
              redirectToContact={() => navigate(Path.contactToCustomer(customerId))}
              customerNo={customerNo}
            />
          </div>
        ) : null}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default DetailCustomerPage;
