import moment from 'moment';
import React from 'react';

export default function BatchDeliveryDetailItem(props) {
  const { data } = props;

  return (
    <div className="w-full h-full relative shadow-center rounded-[12px] p-4 my-4">
      <div className="flex gap-2 items-center">
        <div className="bg-gray-base rounded-[34px] text-subtitle px-3 py-1 text-sm">検体No</div>
        <div className='text-sm'>{data.inspectionKitId || ''}</div>
      </div>
      <div className="flex gap-2 mt-3 items-center text-sm">
        <div>{data.customerName || ''}</div>
      </div>
      <div className="flex gap-2 mt-3 pt-3 items-center justify-between text-xs text-subtitle border-t border-[#EDEDED]">
        <div>お客様No. {data.customerNo || ''}</div>
        <div>登録日時： {data.createdAt !== undefined ? moment(data.createdAt).format('YYYY-MM-DD HH:mm') : '-'}</div>
      </div>
    </div>
  );
}
