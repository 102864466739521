import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Path from '../../../route/Path';
import { createNewFirstImpression, getDetailCustomer, getAllFirstImpression } from 'app/services/api';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import SelectingBlock from '../../../components/Customer1StImpressionAndFactCheckPage/SelectingBlock';
import { questions } from './constants';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import { Dialog } from '@mui/material';
import Fade from '@mui/material/Fade';

function Customer1StImpressionAndFactCheckPage() {
  const { customerId, stepId } = useParams();
  const navigate = useNavigate();

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);
  const [inputData, setInputData] = useState({});
  const [selectedButtons, setSelectedButtons] = useState({});
  const [customerName, setCustomerName] = useState('');

  const getCustomerName = async (id) => {
    try {
      const response = await getDetailCustomer(id);
      if (response?.data) {
        const { lastNameKanji, firstNameKanji } = response.data;
        setCustomerName(`${lastNameKanji} ${firstNameKanji}`);
      }
    } catch (error) {
      console.error('Error fetching customer name:', error);
    }
  };

  const findQuestionById = (id) => questions[stepId - 1].find((question) => question.id === id);

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;
    const question = findQuestionById(fieldName);
    setInputData((prev) => ({
      ...prev,
      [fieldName]: { question: question?.text, answer: value },
    }));
  };

  const handleCreateNewFirstImpression = async (combinedData) => {
    try {
      await createNewFirstImpression({
        id: customerId,
        survey: combinedData,
      });
    } catch (error) {
      console.error('Error creating impression:', error);
      setIsOpenDialog(false);
    }
  };

  const handleMultiSelect = (buttonName, buttonIndex, question, currentAnswers) => {
    const indexInAnswers = currentAnswers.indexOf(buttonIndex);
    const updatedAnswers =
      indexInAnswers !== -1
        ? currentAnswers.filter((answer) => answer !== buttonIndex)
        : [...currentAnswers, buttonIndex];

    return {
      question: question?.text,
      answers: updatedAnswers,
    };
  };

  const handleSingleSelect = (buttonName, buttonIndex, question, currentAnswer) => {
    return {
      question: question?.text,
      answer: currentAnswer === buttonIndex ? '' : buttonIndex,
    };
  };

  const handleButtonClick = (buttonName, buttonIndex) => {
    const question = findQuestionById(buttonName);
    const currentAnswers = selectedButtons[buttonName]?.answers || [];
    const currentAnswer = selectedButtons[buttonName]?.answer;

    setSelectedButtons((prev) => ({
      ...prev,
      [buttonName]: question?.isMultiSelect
        ? handleMultiSelect(buttonName, buttonIndex, question, currentAnswers)
        : handleSingleSelect(buttonName, buttonIndex, question, currentAnswer),
    }));
  };

  const handleNextSection = () => {
    const nextStep = parseInt(stepId) + 1;
    setSelectedValue(nextStep);
    GA.tracking(EVENT_NAMES.FIRST_IMPRESSION.CLICK_NEXT, { step: nextStep });
    navigate(Path.customerManagementFactCheck(customerId, nextStep));
  };

  const handleChange = (e) => {
    const value = e.target.value;
    GA.tracking(EVENT_NAMES.FIRST_IMPRESSION.CLICK_NEXT, { step: value });
    setSelectedValue(value);
    navigate(Path.customerManagementFactCheck(customerId, value));
  };

  const handleCreate = async () => {
    await handleCreateNewFirstImpression({ selectedButtons, inputData });
    GA.tracking(EVENT_NAMES.FIRST_IMPRESSION.SUBMIT, { step: stepId });
    navigate(Path.customerManagementDetail(customerId));
  };

  const loadAllFirstImpression = () => {
    getAllFirstImpression(customerId)
      .then((res) => {
        console.log(res);
        setSelectedButtons(res.data[0].survey.selectedButtons ? res.data[0].survey.selectedButtons : {});
        setInputData(res.data[0].survey.inputData ? res.data[0].survey.inputData : {});
      })
      .catch((error) => {
        console.log('Error fetching first impression:', error);
      });
  };

  useEffect(() => {
    loadAllFirstImpression();
  }, []);

  useEffect(() => {
    if (customerId) {
      getCustomerName(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    setSelectedValue(Number(stepId));
  }, [stepId]);

  return (
    <>
      <DefaultLayout>
        <MainContentLayout key={stepId}>
          <NavigationBackBtn text={`1stインプレッション ${stepId}/8`} url={Path.customerManagementDetail(customerId)} />
          <SelectingBlock
            stepId={stepId}
            customerName={customerName}
            selectedValue={selectedValue}
            selectedButtons={selectedButtons}
            inputData={inputData}
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            handleButtonClick={handleButtonClick}
            handleNextSection={handleNextSection}
            handleSubmit={() => setIsOpenDialog(true)}
          />
        </MainContentLayout>
      </DefaultLayout>

      <CompleteDialog
        open={isOpenDialog}
        message="1stインプレッションを登録しました"
        label="お客様情報へ戻る"
        onSubmit={handleCreate}
      />
    </>
  );
}

export default Customer1StImpressionAndFactCheckPage;

const CompleteDialog = ({ open, onSubmit, message, label }) => (
  <Dialog
    maxWidth="sm"
    open={open}
    onClose={() => {}}
    aria-labelledby="responsive-dialog-title"
    classes={{ root: 'bg-transparent' }}
    PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: '100%',
      },
    }}
    TransitionComponent={Fade}
  >
    <div className="bg-white w-full sm:w-[500px] py-6 px-4 rounded-2xl">
      <div className="text-center">{message}</div>
      <div className="items-center justify-center mt-6 flex">
        <button onClick={onSubmit} type="button" className="button-size bg-[#00B3ED] w-[170px]">
          {label}
        </button>
      </div>
    </div>
  </Dialog>
);

export { CompleteDialog };
