import { CLUSTER } from './constants';

const convertClusterToClass = (cluster) => {
  switch (cluster) {
    case 1:
      return 'A';
    case 2:
      return 'B';
    case 3:
      return 'C';
    case 4:
      return 'D';
    case 5:
      return 'E';
    case 6:
      return 'AB';
    case 7:
      return 'BA';
    case 8:
      return 'AC';
    case 9:
      return 'CA';
    case 10:
      return 'BD';
    case 11:
      return 'DB';
    case 12:
      return 'CD';
    case 13:
      return 'DC';
    case 14:
      return 'DE';
    case 15:
      return 'ED';
    default:
      return '';
  }
};

const convertClusterToText = (cluster) => {
  switch (String(cluster || '')) {
    case CLUSTER.A_CLASS:
      return 'Aタイプ';
    case CLUSTER.B_CLASS:
      return 'Bタイプ';
    case CLUSTER.C_CLASS:
      return 'Cタイプ';
    case CLUSTER.D_CLASS:
      return 'Dタイプ';
    case CLUSTER.E_CLASS:
      return 'Eタイプ';
    case CLUSTER.AB_CLASS:
      return 'ABタイプ';
    case CLUSTER.BA_CLASS:
      return 'BACタイプ';
    case CLUSTER.AC_CLASS:
      return 'ACタイプ';
    case CLUSTER.CA_CLASS:
      return 'CAタイプ';
    case CLUSTER.BD_CLASS:
      return 'BDタイプ';
    case CLUSTER.DB_CLASS:
      return 'DBタイプ';
    case CLUSTER.CD_CLASS:
      return 'CDタイプ';
    case CLUSTER.DC_CLASS:
      return 'DCタイプ';
    case CLUSTER.DE_CLASS:
      return 'DEタイプ';
    case CLUSTER.ED_CLASS:
      return 'EDタイプ';
    case CLUSTER.F_CLASS:
      return 'Fタイプ';
    default:
      return '';
    // return '?タイプ';
  }
};

const getAnalyseStatus = (analyseStatus, factorCluster) => {
  if (analyseStatus === 'analyse_not_started') {
    return '未分析';
  }

  if (analyseStatus === 'analyse_in_progress') {
    return '分析中';
  }

  if (analyseStatus === 'analyse_completed') {
    const factorClusterText = convertClusterToText(factorCluster);
    if (!factorClusterText) {
      return '判定不能';
    }
    return '判定結果';
  }

  return '未分析';
};

export { convertClusterToClass, convertClusterToText, getAnalyseStatus };
