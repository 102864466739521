import { FormControl, MenuItem, Select } from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';

export default function SelectField(props) {
  const {
    label,
    value,
    setValue,
    options,
    inputWrapClasses = '',
    inputClasses = '',
    errors,
    touched,
    displayEmpty = false,
    ...rest
  } = props;

  return (
    <div className={`w-full flex flex-col gap-2 ${inputWrapClasses}`}>
      {label && <div className="text-sm text-subtitle">{label}</div>}
      <FormControl fullWidth size="small">
        <Select
          className={`${inputClasses}
            ${rest.disabled ? 'bg-[#f3f4f6]' : 'bg-white'}
          `}
          id="gender"
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          sx={{
            '& .MuiSelect-select': {
              padding: '12.5px 16px',
            },
          }}
          error={!!errors && touched}
          touched={touched}
          {...rest}
          displayEmpty={displayEmpty}
          renderValue={(value) => {
            const option = options?.find((option) => option.value === value) ?? null;
            return <span className={option?.value === '' ? 'text-[#9ca3af]' : ''}>{option?.label ?? ''}</span>;
          }}
        >
          {options.map((option, key) => (
            <MenuItem key={key} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {touched && errors ? (
          <p className="w-full !text-red-600 !text-[12px]">
            <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
              <ErrorIcon fontSize="inherit" className="text-xs text-red-600 font-thin" />
            </span>
            <span>{errors}</span>
          </p>
        ) : null}
      </FormControl>
    </div>
  );
}
