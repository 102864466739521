import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import DefaultLayout from '../../../layouts/DefaultLayout';
import { useNavigate } from 'react-router-dom';
import MainContentLayout from '../../../layouts/MainContentLayout';
import Path from '../../../route/Path';
import CommonDialog from '../../../components/CommonDialog';
import InputField from '../../../components/commonUI/InputField';
import { createNewScalpData, getAllSalonCustomer, getSalonCustomer } from '../../../services/api/1C';

import { searchAllCustomer } from 'app/services/api';
import { FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import BarcodeScannerModal from './BarcodeScannerModal';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import { getCustomerGenderText } from '../../14/DetailCustomerPage/helpers';
import { formatToJapaneseDate } from '../../../utils';
import CollectLocation from './CollectLocation';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import useDebounce from '../../../hooks/useDebounce';

const confirmSteps = {
  CONFIRM: 'CONFIRM',
  CONFIRM_AGAIN: 'CONFIRM_AGAIN',
};

export const CameraIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3327 5.3335H12.666L9.33268 9.3335H5.33268C4.62544 9.3335 3.94716 9.61445 3.44706 10.1145C2.94697 10.6146 2.66602 11.2929 2.66602 12.0002V24.0002C2.66602 24.7074 2.94697 25.3857 3.44706 25.8858C3.94716 26.3859 4.62544 26.6668 5.33268 26.6668H26.666C27.3733 26.6668 28.0515 26.3859 28.5516 25.8858C29.0517 25.3857 29.3327 24.7074 29.3327 24.0002V12.0002C29.3327 11.2929 29.0517 10.6146 28.5516 10.1145C28.0515 9.61445 27.3733 9.3335 26.666 9.3335H22.666L19.3327 5.3335Z"
        stroke="#00B3ED"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21.3335C18.2091 21.3335 20 19.5426 20 17.3335C20 15.1244 18.2091 13.3335 16 13.3335C13.7909 13.3335 12 15.1244 12 17.3335C12 19.5426 13.7909 21.3335 16 21.3335Z"
        stroke="#00B3ED"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C14.866 18 18 14.866 18 11C18 7.13401 14.866 4 11 4ZM2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11Z"
        fill="#666666"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9929 15.9929C16.3834 15.6024 17.0166 15.6024 17.4071 15.9929L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L15.9929 17.4071C15.6024 17.0166 15.6024 16.3834 15.9929 15.9929Z"
        fill="#666666"
      />
    </svg>
  );
};

const InfoRow = ({ label, value }) => {
  return (
    <div className="w-full h-auto flex flex-col gap-2">
      <label htmlFor={'customer_autocomplete'} className={`text-sm text-subtitle`}>
        {label}
      </label>
      <div>{value}</div>
    </div>
  );
};

const PickCollectLocation = ({ onClick }) => {
  return (
    <div className="border-t pt-4">
      <div className="text-center">採取箇所を指定してください</div>
      <div className="py-4 text-center">
        <CollectLocation onClick={onClick} />
      </div>
    </div>
  );
};

function OrderScalpLab() {
  const navigate = useNavigate();
  const [creatingOrderScalpState, setCreatingOrderScalpState] = useState({
    confirmedData: null,
    isCreating: false,
    error: null,
    res: null,
  });
  const [listCustomer, setListCustomer] = useState();
  const [customer, setCustomer] = useState();
  const [open, setOpen] = useState(false);
  const [showStep, setShowStep] = useState(confirmSteps.CONFIRM);
  const [customerNameValue, setCustomerName] = useState();
  const [searchState, setSearchState] = useState({
    isSearching: false,
    searchConditions: {},
    data: null,
    error: null,
  });
  const [loading, setLoading] = useState(false);
  const [barCode, setBarCode] = useState('');

  const loadAllSalonCustomer = () => {
    setLoading(true);
    getAllSalonCustomer()
      .then((res) => {
        setListCustomer(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadSalonCustomer = (customerno) => {
    getSalonCustomer(customerno).then((res) => {
      if (res.data !== null) {
        setCustomer(res.data);
        setListCustomer([{ customer: res.data, customerNo: customerno }]);
        setFieldValue('customerName', `${res.data?.lastNameKanji || ''} ${res.data?.firstNameKanji || ''}`);
        setCustomerName(`${res.data?.lastNameKanji || ''} ${res.data?.firstNameKanji || ''}`);
      }
    });
  };

  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchValues = useDebounce(searchInput, 500);

  const handleSearchInputChange = (event, value) => {
    setSearchInput(value);
  };

  useEffect(() => {
    searchSalonCustomerByName(debouncedSearchValues);
  }, [debouncedSearchValues]);

  const searchSalonCustomerByName = (customerName) => {
    setLoading(true);
    searchAllCustomer({
      fullName: customerName,
    })
      .then((res) => {
        const transformedArray = res.data.map((item) => {
          return {
            customer: { ...item },
            customerNo: item.salonCustomers ? item.salonCustomers[0].customerNo : undefined,
          };
        });

        setListCustomer(transformedArray);

        setSearchState((oldState) => ({
          isSearching: false,
          searchConditions: oldState.customerName,
          data: res.data,
          error: null,
        }));
      })
      .catch((error) => {
        setSearchState((oldState) => ({
          isSearching: false,
          searchConditions: oldState.customerName,
          data: null,
          error: error,
        }));
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      });
  };

  useEffect(() => {
    // loadAllSalonCustomer();
  }, []);

  const handleAutoCompleteChange = (event, name) => {
    setFieldValue('customerName', `${name?.customer?.lastNameKanji || ''} ${name?.customer?.firstNameKanji || ''}`);
    setFieldValue('customerNameKata', `${name?.customer?.lastNameKata || ''} ${name?.customer?.firstNameKata || ''}`);
    setFieldValue('customerNo', name?.customerNo || '');
    setFieldValue('customerGender', name?.customer.gender);
    setFieldValue('customerBirthday', formatToJapaneseDate(name?.customer.dob));
  };

  const handleConfirm = async () => {
    if (showStep === confirmSteps.CONFIRM) {
      handleSubmit();
    } else if (showStep === confirmSteps.CONFIRM_AGAIN) {
      // executeAction();
      setOpen(false);
      setShowStep(confirmSteps.CONFIRM);
      navigate(Path.scalpLabManagement);
    }
  };

  const handleCodeDetected = (code) => {
    // 在这里处理从 BarcodeScanner 传来的条形码信息
    //alert('Detected456 code:', code);
    setFieldValue('inspectionKitId', code || '');
    setBarCode(code);
  };
  const validationSchema = Yup.object().shape({
    inspectionKitId: Yup.string().required('検体IDは必須です。'),
    customerName: Yup.string().required('お客様名は必須です。'),
    customerNo: Yup.string().required('お客様No.は必須です。'),
  });

  const onSubmit = async (values) => {
    setCreatingOrderScalpState((oldState) => ({
      ...oldState,
      isCreating: true,
      error: null,
      res: null,
    }));

    const params = {
      inspectionKitId: values.inspectionKitId,
      customerNo: values.customerNo,
      customerName: values.customerName,
      customerCollectLocation: values.customerCollectLocation,
    };
    createNewScalpData(params)
      .then((res) => {
        GA.tracking(EVENT_NAMES.ORDER_FORM.SUBMIT, {
          customerNo: values.customerNo,
          inspectionKitId: values.inspectionKitId,
        });
        setCreatingOrderScalpState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: null,
          res: res,
        }));
        toast.success('頭皮データの注文が成功しました');
        setShowStep(confirmSteps.CONFIRM_AGAIN);
      })
      .catch((error) => {
        setCreatingOrderScalpState((oldState) => ({
          ...oldState,
          isCreating: false,
          error: error,
          res: null,
        }));

        if (error.response.data.message == 'duplicate.kitId') {
          toast.error('キットIDが重複しました');
        } else {
          toast.error('正しい情報を入力してください');
        }
      });
  };
  const {
    handleSubmit,
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      inspectionKitId: '',
      customerNo: '',
      customerName: '',
      customerNameKata: '',
      customerBirthday: '',
      customerGender: '',
      customerCollectLocation: null,
    },
    onSubmit,
    validationSchema,
  });

  const customerOptions = useMemo(() => {
    return listCustomer ? listCustomer : [];
  }, [listCustomer]);

  const customerOptionLabel = useCallback((option) => {
    return `${option.customer?.lastNameKanji || ''} ${option.customer?.firstNameKanji || ''}`;
  }, []);

  const isSubmitDisabled = useMemo(() => {
    return !!(
      values.inspectionKitId === '' ||
      values.customerName === '' ||
      values.customerNo === '' ||
      !values.customerCollectLocation
    );
  }, [values]);

  const handleCloseDialog = () => {
    if (showStep === confirmSteps.CONFIRM_AGAIN) {
      // executeAction();
      setOpen(false);
      setShowStep(confirmSteps.CONFIRM);
      navigate(Path.scalpLabManagement);
      return;
    }
    setShowStep(confirmSteps.CONFIRM);
    setOpen(false);
  };

  const handleCollectLocation = (e, point) => {
    setFieldValue('customerCollectLocation', point);
  };

  useEffect(() => {
    if (!values.customerNo) {
      setFieldValue('customerCollectLocation', null);
    }
  }, [values.customerNo]);

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-full pb-8 lg:pb-12 relative">
          <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
            <NavigationBackBtn text="頭皮データの登録" url={Path.scalpLabManagement} />
          </div>

          <div className="w-full h-full p-6 md:min-h-0 min-h-[calc(100vh-200px)] ">
            <form className="w-full h-full flex flex-col">
              <div className="w-full md:min-h-[567px] flex flex-col gap-6 pb-6">
                <div className="w-full h-auto flex">
                  <InputField
                    label="検体ナンバーを入力または読み取ってください"
                    placeholder="検体ナンバーを入力する"
                    name="inspectionKitId"
                    value={values.inspectionKitId ? values.inspectionKitId : barCode}
                    type="text"
                    onChange={(newValue) => {
                      setFieldValue('inspectionKitId', newValue || '');
                      setBarCode(newValue);
                    }}
                    inputWrapClasses="w-full md:max-w-[343px] flex-1"
                  />
                  <div className="justify-end flex items-end md:w-auto md:items-end">
                    <BarcodeScannerModal
                      openDialog={true}
                      onCodeDetected={handleCodeDetected}
                      label={<CameraIcon />}
                    ></BarcodeScannerModal>
                  </div>
                </div>
                <div className="w-full h-auto flex flex-col gap-2 w-full md:max-w-[343px]">
                  <label htmlFor={'customer_autocomplete'} className={`text-sm text-subtitle`}>
                    登録するお客様を選択してください
                  </label>
                  <FormControl sx={{ width: '100%' }} size="small">
                    <Autocomplete
                      name="customer_autocomplete"
                      disablePortal
                      loading={loading}
                      loadingText={'読み込み中'}
                      options={customerOptions}
                      getOptionLabel={customerOptionLabel}
                      renderOption={(props, option) => {
                        const customer = option?.customer;
                        return (
                          <li {...props} key={customer?.id || props.key}>
                            {customer ? customerOptionLabel(option) : props.key}
                          </li>
                        );
                      }}
                      renderInput={(params) => {
                        handleSearchInputChange(null, params.inputProps.value);
                        return (
                          <TextField
                            {...params}
                            className={`bg-white focus:outline-[#0096D4] border border-[#CACACA] hover:border-gray-800 rounded text-base ${!listCustomer ? '!bg-[#f3f4f6] !border-[#CACACA]' : ''}`}
                            // label={'選択する'}
                            placeholder={'選択する'}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <div className="MuiAutocomplete-endAdornment absolute transform -translate-y-1/2 top-1/2 !right-[14px]">
                                  <div className="inline-flex content-center items-center">
                                    {loading ? <LoadingProgress size={24} /> : <SearchIcon />}
                                  </div>
                                </div>
                              ),
                            }}
                          />
                        );
                      }}
                      onChange={handleAutoCompleteChange}
                      disabled={!listCustomer}
                    />
                  </FormControl>
                </div>

                {values.customerNo && (
                  <>
                    <InfoRow label="お客様No." value={values.customerNo || ''} />
                    <InfoRow label="お名前" value={values.customerName || ''} />
                    <InfoRow label="お名前フリガナ" value={values.customerNameKata || ''} />
                    <InfoRow label="生年月日" value={values.customerBirthday} />
                    <InfoRow label="性別" value={getCustomerGenderText(values.customerGender)} />
                    <PickCollectLocation onClick={handleCollectLocation} />
                  </>
                )}
              </div>

              <div className="h-8 flex items-center justify-center text-white text-center font-semibold absolute inset-x-0 bottom-0 p-6">
                <button
                  type="button"
                  className={`button-size w-full md:w-auto ${isSubmitDisabled ? 'bg-gray-btn-secondary' : 'bg-blue-btn-primary'}`}
                  disabled={isSubmitDisabled}
                  onClick={() => {
                    if (!isSubmitDisabled) {
                      setOpen(true);
                    }
                  }}
                >
                  登録にすすむ
                </button>
              </div>
            </form>
          </div>
        </div>

        <CommonDialog
          open={open}
          handleClose={handleCloseDialog}
          hideCloseIcon={showStep === confirmSteps.CONFIRM_AGAIN}
          actions={
            <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
              {showStep === confirmSteps.CONFIRM && (
                <>
                  <button
                    onClick={handleCloseDialog}
                    type="button"
                    className="button-size btn-secondary text-black ml-2"
                  >
                    キャンセル
                  </button>
                  <button onClick={handleConfirm} type="button" className="button-size bg-blue-btn-primary mr-2">
                    登録する
                  </button>
                </>
              )}
              {showStep !== confirmSteps.CONFIRM && (
                <div className="w-full text-center">
                  <button
                    onClick={handleConfirm}
                    type="button"
                    className="button-size bg-blue-btn-primary w-auto m-auto"
                  >
                    トップに戻る
                  </button>
                </div>
              )}
            </div>
          }
        >
          <div className="pb-6 px-2">
            {showStep === confirmSteps.CONFIRM && (
              <div>
                <p className="text-center">
                  以下のお客様の頭皮データを登録します
                  <br />
                  よろしいですか？
                </p>
                <div className="p-4 rounded flex gap-4 flex-col bg-gray-base mt-6">
                  <div>お客様名</div>
                  <div>{values.customerName}</div>
                </div>
              </div>
            )}
            {showStep !== confirmSteps.CONFIRM && <p className="text-center pt-2">頭皮データを登録しました</p>}
          </div>
        </CommonDialog>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default OrderScalpLab;
