import React, { useRef } from 'react';
import { useFormik } from 'formik';
import validationSchema from '../RegisterInventoryFormPage/validator';
import { uploadProductImage } from '../../../services/api/18';
import { toast } from 'react-toastify';
import InputField from '../../../components/commonUI/InputField';
import BarcodeScannerModal from '../../1C/OrderScalpLab/BarcodeScannerModal';
import { PRODUCT_CATEGORY_OPTIONS, PRODUCT_CLASSES, PRODUCT_SCOPE } from '../../../constants';
import SurveyButton from '../../../components/Customer1StImpressionAndFactCheckPage/SurveyButton';
import { CameraIcon } from '../../1C/OrderScalpLab';
import RadioComponent from '../../../components/commonUI/RadioComponent';
import Image from '../../../components/Image';

function InventoryForm(props) {
  const {
    isReadMode,
    handleSubmit,
    handleConfirm,
    handleBackClick,
    defaultValues = {
      productNumber: '',
      maker: '',
      series: '',
      name: '',
      size: '',
      imageUrl: '',
      category: '',
      productScope: '',
      price: '',
      priceTaxInc: '',
      priceTaxExc: '',
      description: '',
      classes: [],
      inventory: '',
    },
    id = null,
  } = props;

  const {
    errors,
    values,
    touched,
    setFieldValue,
    handleBlur,
    handleSubmit: handleSubmitFormik,
  } = useFormik({
    enableReinitialize: true,
    initialValues: defaultValues,
    onSubmit: handleConfirm,
    validationSchema,
  });

  const fileInputRef = useRef(null);

  const handleCodeDetected = (value) => {
    setFieldValue('productNumber', value);
  };

  const handleChangeFile = async (event) => {
    const selectFile = event.target.files[0];
    if (selectFile) {
      const res = await uploadProductImage(selectFile);
      if (res.error) {
        toast.error(
          res.error?.errorCode === 'E900.9.1'
            ? '無効なファイルタイプです。許可されているタイプは：jpg、jpeg、pngです。'
            : '失敗',
        );
      } else {
        setFieldValue('imageUrl', res.result.imageUrl);
      }
    }
  };

  const handleClickFile = () => {
    if (isReadMode) {
      return;
    }
    fileInputRef.current.click();
  };

  return (
    <form className="w-full h-full">
      <div className="w-full h-full flex flex-col gap-6 overflow-auto">
        <div className="w-full h-[200px] flex justify-center">
          <div
            className={`w-[200px] h-[200px] flex justify-center overflow-hidden ${isReadMode ? '' : 'border border-black rounded-md  hover:border-blue-300 hover:bg-blue-500 hover:bg-opacity-10'}`}
          >
            <button
              type="button"
              className={`w-[100%] h-[100%] ${isReadMode ? 'cursor-default' : ''}`}
              onClick={handleClickFile}
            >
              {values.imageUrl || isReadMode ? (
                <Image
                  className="w-[200px] h-[200px]"
                  imageClassName="w-full h-full object-cover"
                  src={values.imageUrl}
                  alt="product-image"
                />
              ) : (
                <span>写真を選択</span>
              )}
            </button>
            <input
              id="fileInput"
              type="file"
              accept="image/jpeg, image/png"
              className="absolute -top-full opacity-0"
              aria-hidden="true"
              tabIndex="-1"
              ref={fileInputRef}
              onChange={handleChangeFile}
            />
          </div>
        </div>

        <div className="w-full h-auto flex">
          <InputField
            label="品番"
            name="productNumber"
            value={values.productNumber}
            type={isReadMode ? 'display' : 'text'}
            onChange={(newValue) => setFieldValue('productNumber', newValue)}
            handleBlur={handleBlur}
            touched={touched.productNumber}
            errors={errors.productNumber}
            disabled={isReadMode}
          />
          {!isReadMode && (
            <div className="justify-end flex  md:w-auto pt-[30px]">
              <BarcodeScannerModal onCodeDetected={handleCodeDetected} label={<CameraIcon />} />
            </div>
          )}
        </div>

        <InputField
          label="メーカー"
          name="maker"
          value={values.maker}
          type={isReadMode ? 'display' : 'text'}
          onChange={(newValue) => setFieldValue('maker', newValue)}
          handleBlur={handleBlur}
          touched={touched.maker}
          errors={errors.maker}
          disabled={isReadMode}
        />

        <InputField
          label="シリーズ"
          name="series"
          value={values.series}
          type={isReadMode ? 'display' : 'text'}
          onChange={(newValue) => setFieldValue('series', newValue)}
          handleBlur={handleBlur}
          touched={touched.series}
          errors={errors.series}
          disabled={isReadMode}
        />

        <InputField
          label="品名"
          name="name"
          value={values.name}
          type={isReadMode ? 'display' : 'text'}
          onChange={(newValue) => setFieldValue('name', newValue)}
          handleBlur={handleBlur}
          touched={touched.name}
          errors={errors.name}
          disabled={isReadMode}
        />

        <InputField
          label="サイズ"
          name="size"
          value={values.size}
          type={isReadMode ? 'display' : 'text'}
          onChange={(newValue) => setFieldValue('size', newValue)}
          handleBlur={handleBlur}
          touched={touched.size}
          errors={errors.size}
          disabled={isReadMode}
        />

        <div className="w-full h-auto flex">
          <RadioComponent
            label="カテゴリ"
            value={values.category}
            handleSelect={(newValue) => setFieldValue('category', newValue)}
            errors={errors.category}
            touched={touched.category}
            options={PRODUCT_CATEGORY_OPTIONS}
            display={isReadMode}
          />
        </div>

        <div className="flex flex-col gap-4">
          <label className={`text-sm text-subtitle`}>クラス</label>
          <div className="flex flex-wrap gap-2 ">
            {PRODUCT_CLASSES.map((item) => (
              <SurveyButton
                display={isReadMode}
                key={item.text}
                text={item.text}
                name={item.text}
                active={values.classes.includes(item.value)}
                handleClick={() => {
                  const preClasses = values.classes;
                  if (preClasses.includes(item.value)) {
                    const nextClasses = preClasses.filter((cl) => cl !== item.value);
                    setFieldValue('classes', nextClasses);
                  } else {
                    setFieldValue('classes', [...preClasses, item.value]);
                  }
                }}
              />
            ))}
          </div>
        </div>

        <InputField
          label="説明"
          name="description"
          value={values.description}
          type={isReadMode ? 'display' : 'text'}
          onChange={(newValue) => setFieldValue('description', newValue)}
          handleBlur={handleBlur}
          touched={touched.description}
          errors={errors.description}
          disabled={isReadMode}
          multiline={true}
        />

        <InputField
          label="価格"
          name="price"
          value={values.price}
          type={isReadMode ? 'display' : 'number'}
          onChange={(newValue) => {
            setFieldValue('price', newValue === '' ? null : Number(newValue.replace(/[^0-9.]/g, '')));
          }}
          handleBlur={handleBlur}
          touched={touched.price}
          errors={errors.price}
          disabled={isReadMode}
        />

        <InputField
          label="未税価格"
          name="priceTaxExc"
          value={values.priceTaxExc}
          type={isReadMode ? 'display' : 'number'}
          onChange={(newValue) =>
            setFieldValue('priceTaxExc', newValue === '' ? null : Number(newValue.replace(/[^0-9.]/g, '')))
          }
          handleBlur={handleBlur}
          touched={touched.priceTaxExc}
          errors={errors.priceTaxExc}
          disabled={isReadMode}
        />

        <InputField
          label="税込価格"
          name="priceTaxInc"
          value={values.priceTaxInc}
          type={isReadMode ? 'display' : 'number'}
          min="0"
          onChange={(newValue) => {
            setFieldValue('priceTaxInc', newValue === '' ? null : Number(newValue.replace(/[^0-9.]/g, '')));
          }}
          handleBlur={handleBlur}
          touched={touched.priceTaxInc}
          errors={errors.priceTaxInc}
          disabled={isReadMode}
        />

        <InputField
          label="在庫数"
          name="inventory"
          value={values.inventory}
          type={isReadMode ? 'display' : 'text'}
          onChange={(newValue) => {
            setFieldValue('inventory', Number(newValue.replace(/[^0-9.]/g, '')));
          }}
          handleBlur={handleBlur}
          touched={touched.inventory}
          errors={errors.inventory}
          disabled={isReadMode}
        />

        <div className="flex">
          <RadioComponent
            label="専売品"
            value={values.productScope}
            handleSelect={(newValue) => setFieldValue('productScope', newValue)}
            errors={errors.productScope}
            touched={touched.productScope}
            options={[
              {
                value: PRODUCT_SCOPE.MONOPOLY,
                label: '登録する',
              },
              {
                value: PRODUCT_SCOPE.GENERAL,
                label: '登録しない',
              },
            ]}
            display={isReadMode}
          />
        </div>
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold mt-12">
        <button onClick={handleBackClick} type="button" className="button-size bg-gray-btn-secondary mr-4 lg:mr-6">
          終了
        </button>
        <button
          onClick={() => {
            if (handleSubmit) {
              handleSubmit();
            } else {
              handleSubmitFormik();
            }
          }}
          type="button"
          className="button-size bg-blue-btn-primary"
        >
          {id ? '変更する' : '登録'}
        </button>
      </div>
    </form>
  );
}

export default InventoryForm;
