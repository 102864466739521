import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import LoadingProgress from 'app/components/commonUI/LoadingProgress';
import LoadInitError from 'app/components/commonUI/LoadInitError';
import { getAccessRight, createAccessRightForStaff } from 'app/services/api/1D';
import GA, { EVENT_NAMES } from 'app/services/googleAnalytics';
import SettingItem from '../ItemSetting';
import LoadingBackdrop from '../../../../components/commonUI/LoadingBackdrop';

const HIDDEN_PERMISSIONS = ['予約管理機能', '売上管理', 'レジ機能', '勤怠管理機能', 'DM配信配'];

function TabRightStaffDetail({ staffId }) {
  const [updateSetting, setUpdateSetting] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const loadStaffAccessRight = useCallback(async () => {
    if (!staffId) return;

    setLoading(true);
    try {
      const response = await getAccessRight(staffId);
      const filteredSettings = response.data.filter((item) => !HIDDEN_PERMISSIONS.includes(item.functionName));
      setUpdateSetting(filteredSettings);
      setError(null);
    } catch (error) {
      toast.error('アクセス権限のリストの取得に失敗しました');
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [staffId]);

  useEffect(() => {
    loadStaffAccessRight();
  }, [loadStaffAccessRight]);

  const handleChangeSetting = async (index, newSettingItem) => {
    try {
      setLoading(true);

      const data = [];
      const newUpdateSetting = [...updateSetting];
      newUpdateSetting[index] = newSettingItem;
      setUpdateSetting(newUpdateSetting);

      Array.isArray(newUpdateSetting) &&
        newUpdateSetting.forEach((setting) => {
          if (Array.isArray(setting.childrens)) {
            setting.childrens.forEach((subSetting) => {
              data.push({
                functionName: subSetting.functionCode,
                canAccess: subSetting.canAccess,
              });
            });
          } else {
            data.push({
              functionName: setting.functionCode,
              canAccess: setting.canAccess,
            });
          }
        });

      await createAccessRightForStaff(staffId, data);
      GA.tracking(EVENT_NAMES.ACCESS.CHANGE, { staffId });
      toast.success('アクセス権変更成功');
    } catch (error) {
      toast.error('アクセス権限の変更に失敗しました');
      console.error(error);
      // Revert the change if the API call fails
      loadStaffAccessRight();
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingBackdrop />;
  if (error) return <LoadInitError error={error} />;

  return (
    <div className="w-full">
      {updateSetting.map((setting, index) => (
        <SettingItem
          key={index}
          setting={setting}
          handleChangeSetting={(newSettingItem) => handleChangeSetting(index, newSettingItem)}
          disabled={loading}
        />
      ))}
    </div>
  );
}

export default TabRightStaffDetail;
