import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import ConfirmCreateDialog from 'app/components/CommonDialog/ConfirmCreateDialog';
import NavigationBackBtn from 'app/components/common/NavigationBackButton';
import RegisterStaffForm from '../components/RegisterStaffForm';
import { registerSalonStaff } from 'app/services/api';
import GA, { EVENT_NAMES } from 'app/services/googleAnalytics';

function StaffManagementRegisterPage() {
  const [confirmedData, setConfirmedData] = useState(null);
  const [showConfirmCreate, setShowConfirmCreate] = useState(false);
  const navigate = useNavigate();

  const handleCreate = async () => {
    if (!confirmedData) return;
    const data = {
      lastNameKata: confirmedData.lastNameKata,
      firstNameKata: confirmedData.firstNameKata,
      lastNameKanji: confirmedData.lastNameKanji,
      firstNameKanji: confirmedData.firstNameKanji,
      dob: confirmedData.dob?.toISOString() || null,
      phone: confirmedData.phone,
      postalCode: confirmedData.postalCode,
      address: confirmedData.address,
      imageUrl: confirmedData.imageUrl,
      loginName: confirmedData.loginName,
      password: confirmedData.password,
    };

    if (confirmedData.email) {
      data.email = confirmedData.email;
    }

    return await registerSalonStaff(data);
  };

  const handleCreateComplete = (createdData) => {
    GA.tracking(EVENT_NAMES.CREATE_STAFF.CREATE);
    navigate(Path.staffManagementDetail(createdData.id));
  };

  const handleCreateError = (error) => {
    const errors = error?.response?.data?.message[0]?.constraints;
    if (errors?.isEmail) {
      toast.error('メールアドレスが無効です。');
    } else {
      toast.error('ユーザー名が無効か、すでに存在します。別のユーザー名を選択してください。');
    }
    setShowConfirmCreate(false);
    setConfirmedData(null);
  };

  const onSubmitForm = (data) => {
    setConfirmedData(data);
    setShowConfirmCreate(true);
  };

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-16 lg:h-12 flex items-center justify-start inset-x-0 top-0">
          <h2 className="text-base lg:text-xl font-semibold">
            <NavigationBackBtn url={Path.staffManagementList} text={'スタッフ新規登録'} />
          </h2>
        </div>

        <RegisterStaffForm onSubmitForm={onSubmitForm} />

        <ConfirmCreateDialog
          open={confirmedData && showConfirmCreate}
          handleClose={() => setShowConfirmCreate(false)}
          confirmTitle="新規従業員を登録しますか?"
          confirmMessage=""
          createCompleteTitle="スタッフ情報を新規登録しました"
          createCompleteBtnLabel="終了"
          createCompleteAction={handleCreateComplete}
          createAction={handleCreate}
          confirmCancleLabel="キャンセル"
          confirmLabel="作成する"
          handleDeleteError={handleCreateError}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementRegisterPage;
