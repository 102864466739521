import React, { useState } from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import { useNavigate, useLocation } from 'react-router-dom';
import Path from '../../../route/Path';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import AgreementContent from '../../../components/AgreementContent';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';

function ApplicationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const values = location.state?.values;
  const [checked, setChecked] = useState(false);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full relative">
          <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              <NavigationBackBtn
                onClick={() =>
                  navigate(Path.scalpLabManagementIntroduction, {
                    state: { values },
                  })
                }
                text="同意書確認"
              />
            </h2>
          </div>
          <div className="px-4 md:px-0">
            <div className="w-full h-full">
              <div className="w-full flex justify-center h-full">
                <div className="w-[1000px] h-full overflow-y-auto overflow-x-hidden">
                  <AgreementContent />
                </div>
              </div>
            </div>

            <div className="w-full py-4 flex gap-6 items-center justify-center text-white text-base">
              <button
                type="button"
                className="button-size lg:w-[200px] bg-blue-btn-primary"
                onClick={() => navigate(Path.scalpLabManagementConfirmApplicationForm, { state: { values } })}
              >
                内容確認へ
              </button>
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ApplicationForm;
