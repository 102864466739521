import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import Path from 'app/route/Path';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../components/commonUI/LoadInitError';
import { getAllScalpDataBatchingGroup } from '../../../services/api/1C';
import NoData from '../../../components/commonUI/NoData';
import BasicTabs from '../../../components/common/BasicTabs';
import TabPanel from '../../../components/common/BasicTabs/TabPanel';
import BatchDeliveryItem from '../../../components/ScalpDelivery/BatchDeliveryItem';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import { DELIVERY_STATUS_BY_LABEL } from '../../../constants';
import { useTheme } from '@mui/material/styles';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

function ScalpDataBatchingGroupPage() {
  const theme = useTheme();
  const [fetchScalpDataBatchingGroup, setFetchScalpDataBatchingGroup] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  const loadScalpDataBatchingGroup = (deliveryStatus) => {
    setFetchScalpDataBatchingGroup({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllScalpDataBatchingGroup(deliveryStatus)
      .then((res) => {
        setFetchScalpDataBatchingGroup({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchScalpDataBatchingGroup({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const handleFilterScalpData = (deliveryStatus) => {
    navigate(
      {
        pathname: Path.batchDeliverScalpLab,
        search: createSearchParams({ tab: deliveryStatus }).toString(),
      },
      { replace: true },
    );
    loadScalpDataBatchingGroup(deliveryStatus);
  };

  useEffect(() => {
    const tabIndex = Object.entries(DELIVERY_STATUS_BY_LABEL).findIndex((val) => val[1] === searchParams.get('tab'));
    loadScalpDataBatchingGroup(tabIndex >= 0 ? searchParams.get('tab') : DELIVERY_STATUS_BY_LABEL['未発送']);
  }, []);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full relative">
          <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              <NavigationBackBtn url={Path.scalpLabManagement} text="頭皮データの発送" />
            </h2>
          </div>
          <div className="w-full h-full">
            <div className="w-full h-full">
              <BasicTabs
                parentValue={Object.entries(DELIVERY_STATUS_BY_LABEL).findIndex(
                  (val) => val[1] === searchParams.get('tab'),
                )}
                onChange={handleFilterScalpData}
                appendContainerSx={{
                  [theme.breakpoints.down('sm')]: {
                    display: 'flex',
                    alignItems: 'stretch',
                    paddingLeft: '1.75rem',
                    paddingRight: '1.75rem',

                    '& .MuiButtonBase-root': {
                      flex: 1,
                    },
                  },
                }}
              >
                {Object.entries(DELIVERY_STATUS_BY_LABEL).map(([key, value], index) => (
                  <TabPanel key={key} label={key} value={value} index={index} className={'px-4 md:px-0'}>
                    <div className="w-full h-full">
                      {fetchScalpDataBatchingGroup.isLoading ? <LoadingProgress /> : null}

                      {!fetchScalpDataBatchingGroup.isLoading && fetchScalpDataBatchingGroup.error ? (
                        <LoadInitError error={fetchScalpDataBatchingGroup.error} />
                      ) : null}
                      {!fetchScalpDataBatchingGroup.isLoading && !fetchScalpDataBatchingGroup.error ? (
                        Array.isArray(fetchScalpDataBatchingGroup.data) &&
                        fetchScalpDataBatchingGroup.data.length > 0 ? (
                          <div>
                            {fetchScalpDataBatchingGroup.data.map((item, index) => (
                              <BatchDeliveryItem key={index} data={item} />
                            ))}
                          </div>
                        ) : (
                          <NoData />
                        )
                      ) : null}
                    </div>
                  </TabPanel>
                ))}
              </BasicTabs>
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ScalpDataBatchingGroupPage;
