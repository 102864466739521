import moment from 'moment';
import { defaultDateFormat, defaultTimeFormat } from 'app/constants';
import client from '../client';

const customerBaseInfoAdapter = (rawData) => {
  const dob = moment(rawData.dob).tz('Asia/Tokyo').format(defaultDateFormat);
  const formattedDob = moment(dob, defaultDateFormat, true).isValid() ? dob : null;
  return {
    id: rawData._id,
    lastNameKata: rawData.lastNameKata,
    firstNameKata: rawData.firstNameKata,
    lastNameKanji: rawData.lastNameKanji,
    firstNameKanji: rawData.firstNameKanji,
    dob: formattedDob,
    phone1: rawData.phone1,
    phone2: rawData.phone2,
    email: rawData.email,
    postalCode: rawData.postalCode,
    address: rawData.address,
    createdAt: rawData.createdAt,
    // chen
    salonCustomers: rawData.salonCustomers,
    gender: rawData.gender,
  };
};

const firstImpressionAdapter = (rawData) => ({
  id: rawData._id,
  test: rawData.test,
});

const reservationAdapter = (rawData) => ({
  id: rawData._id,
  visitDate: moment(rawData.visitDate).tz('Asia/Tokyo').format(defaultDateFormat),
  startTime: moment(rawData.startTime).tz('Asia/Tokyo').format(defaultTimeFormat),
  finishTime: moment(rawData.finishTime).tz('Asia/Tokyo').format(defaultTimeFormat),

  customer: rawData.customer
    ? {
        ...rawData.customer,
        id: rawData.customer._id,
      }
    : {},

  staff: rawData.staff
    ? {
        ...rawData.staff,
        id: rawData.staff._id,
      }
    : null,

  reservationMenus: Array.isArray(rawData.reservationMenu)
    ? rawData.reservationMenu.map((visitmenu) => ({
        id: visitmenu._id,
        menuType: visitmenu.menuType,
        name: visitmenu.name,
        durationString: visitmenu.durationString,
        durationNumber: visitmenu.durationNumber,
        priceTaxExc: visitmenu.priceTaxExc,
        priceTaxInc: visitmenu.priceTaxInc,
      }))
    : [],

  visitId: rawData.visitId,
});

const counsellingAdapter = (rawData) => ({
  id: rawData._id,
  counsellingDate: moment(rawData.counsellingDate).tz('Asia/Tokyo').format(defaultDateFormat),
  memo: rawData.memo,
});

const visitAdapter = (rawData) => ({
  id: rawData._id,

  visitDate: moment(rawData.visitDate).tz('Asia/Tokyo').format(defaultDateFormat),
  expectedStartTime: moment(rawData.expectedStartTime).tz('Asia/Tokyo').format(defaultTimeFormat),
  expectedFinishTime: moment(rawData.expectedFinishTime).tz('Asia/Tokyo').format(defaultTimeFormat),
  startTime:
    typeof rawData.startTime === 'string' && rawData.startTime
      ? moment(rawData.startTime).tz('Asia/Tokyo').format(defaultTimeFormat)
      : '',
  finishTime:
    typeof rawData.finishTime === 'string' && rawData.finishTime
      ? moment(rawData.finishTime).tz('Asia/Tokyo').format(defaultTimeFormat)
      : '',

  customer: rawData.customer
    ? {
        ...rawData.customer,
        id: rawData.customer._id,
      }
    : {},

  staff: rawData.staff
    ? {
        ...rawData.staff,
        id: rawData.staff._id,
      }
    : null,

  sales: Array.isArray(rawData.sales)
    ? rawData.sales.map((sale) => ({
        id: sale._id,
        name: sale.name,
        price: sale.price,
        amount: sale.amount,
        product: { id: sale.productId },
      }))
    : [],

  visitMenus: Array.isArray(rawData.visitmenus)
    ? rawData.visitmenus.map((visitmenu) => ({
        id: visitmenu._id,
        menuType: visitmenu.menuType,
        name: visitmenu.name,
        durationString: visitmenu.durationString,
        durationNumber: visitmenu.durationNumber,
        priceTaxExc: visitmenu.priceTaxExc,
        priceTaxInc: visitmenu.priceTaxInc,
        salonMenuId: visitmenu.salonMenuId,
      }))
    : [],

  payment: rawData.payment,
  discounts: rawData.discounts,
  counsellings: Array.isArray(rawData.counsellings)
    ? rawData.counsellings.map((counseling) => counsellingAdapter(counseling))
    : [],
});

const counselingSheetAdapter = (rawData) => ({
  id: rawData._id,
  counsellingDate: moment(rawData.counsellingDate).tz('Asia/Tokyo').format(defaultDateFormat),
  memo: rawData.memo,
});

export const searchAllCustomer = (searchConditions = {}) => {
  return client
    .get('/customer/customer/find-paging', {
      params: {
        name: searchConditions?.name || undefined,
        isConfirmAgreement: searchConditions?.isConfirmAgreement ?? undefined, // boolean
        lastNameKata: searchConditions?.lastNameKata || undefined,
        firstNameKata: searchConditions?.firstNameKata || undefined,
        lastNameKanji: searchConditions?.lastNameKanji || undefined,
        firstNameKanji: searchConditions?.firstNameKanji || undefined,
        dob: searchConditions?.dob
          ? moment.tz(searchConditions?.dob, defaultDateFormat, true, 'Asia/Tokyo').toISOString(defaultDateFormat)
          : undefined,
        phone1: searchConditions?.phone1 || undefined,
        email: searchConditions?.email || undefined,
        postalCode: searchConditions?.postalCode || undefined,
        fullName: searchConditions?.fullName || undefined,
        fullNameKata: searchConditions?.fullNameKata || undefined,
        gender: searchConditions?.gender || undefined,
      },
    })
    .then((res) => {
      if (res.data.success) {
        const rawList = res.data.result;
        return {
          data: rawList.map((rawData) => customerBaseInfoAdapter(rawData)),
        };
      }
      return {
        data: [],
      };
    });
};

export const searchAllCustomerPagination = (searchConditions = {}) => {
  return client
    .get('/customer/customer/find-paging', {
      params: {
        name: searchConditions?.name || undefined,
        isConfirmAgreement: searchConditions?.isConfirmAgreement ?? undefined, // boolean
        lastNameKata: searchConditions?.lastNameKata || undefined,
        firstNameKata: searchConditions?.firstNameKata || undefined,
        lastNameKanji: searchConditions?.lastNameKanji || undefined,
        firstNameKanji: searchConditions?.firstNameKanji || undefined,
        dob: searchConditions?.dob
          ? moment.tz(searchConditions?.dob, defaultDateFormat, true, 'Asia/Tokyo').toISOString(defaultDateFormat)
          : undefined,
        phone1: searchConditions?.phone1 || undefined,
        email: searchConditions?.email || undefined,
        postalCode: searchConditions?.postalCode || undefined,
        fullName: searchConditions?.fullName || undefined,
        fullNameKata: searchConditions?.fullNameKata || undefined,
        gender: searchConditions?.gender || undefined,
        // limit: searchConditions.limit || 20,
        page: searchConditions.page,
      },
    })
    .then((res) => {
      if (res.data.success) {
        const { rows, ...rest } = res.data.result;
        return {
          ...rest,
          data: rows.map((rawData) => customerBaseInfoAdapter(rawData)),
        };
      }
      return {
        page: 1,
        perPage: 20,
        total: 0,
        totalPage: 1,
        data: [],
      };
    });
};

export const getAllCustomerReservation = (customerId) => {
  return client
    .get('/salon/reservation/all', {
      params: { customerId: customerId },
    })
    .then((res) => {
      if (res.data.success) {
        const rawList = res.data.result;
        return {
          data: rawList.map((rawData) => reservationAdapter(rawData)),
        };
      }
      return {
        data: [],
      };
    });
};

export const getAllCustomerVisit = (customerId) => {
  return client
    .get('/salon/visit/all', {
      params: { customerId: customerId },
    })
    .then((res) => {
      if (res.data.success) {
        const rawList = res.data.result;
        return {
          data: rawList.map((rawData) => visitAdapter(rawData)),
        };
      }
      return {
        data: [],
      };
    });
};

export const getAllCustomerCounseling = (customerId) => {
  return client
    .get('/counselling/get-all-counseling', {
      params: { customerId: customerId },
    })
    .then((res) => {
      if (res.data.success) {
        const rawDataList = res.data.result;
        if (Array.isArray(rawDataList))
          rawDataList.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        return {
          data: rawDataList.map((rawData) => counselingSheetAdapter(rawData)),
        };
      }
      return { data: [] };
    });
};

export const updateCounseling = (counselingId, memo) => {
  return client
    .put(`/counselling/update?id=${counselingId}`, {
      memo: memo,
      counsellingDate: moment().toISOString(),
    })
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        return {
          data: counselingSheetAdapter(rawData),
        };
      }
      return {
        data: null,
      };
    });
};

export const deleteCounseling = (counselingId) => {
  return client.delete(`/counselling/delete?id=${counselingId}`).then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return {
      data: null,
    };
  });
};

export const updateCustomerBaseInfo = (customerId, updateCustomerData) => {
  return client.put(`/customer/customer/update?id=${customerId}`, updateCustomerData).then((res) => {
    if (res.data.success) {
      const rawData = res.data.result;
      return {
        data: customerBaseInfoAdapter(rawData),
      };
    }
    return {
      data: null,
    };
  });
};

export const deleteCustomer = (salonCustomerId) => {
  return client.delete(`/salon/salon-customer/delete?id=${salonCustomerId}`).then((res) => {
    if (res.data.success) {
      return {
        data: res.data.result,
      };
    }
    return {
      data: null,
    };
  });
};

export const createNewFirstImpression = (newFirstImpressionData) => {
  return client.post('/1st-impression/create', newFirstImpressionData).then((res) => {
    if (res.data.success) {
      const rawData = res.data.result;
      return {
        data: firstImpressionAdapter(rawData),
      };
    }
    return {
      data: null,
    };
  });
};

export const getAllFirstImpression = (customerId) => {
  return client
    .get('/1st-impression/all', {
      params: { customerId: customerId },
    })
    .then((res) => {
      if (res.data.success) {
        const rawDataList = res.data.result;
        if (Array.isArray(rawDataList))
          rawDataList.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        return {
          data: rawDataList,
        };
      }
      return { data: [] };
    });
};
