import InventoryItem from './InventoryItem';
import React from 'react';

export default function ListInventory(props) {
  const { items } = props;

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-5  gap-4 md:gap-y-6">
        {items.map((item, index) => (
          <InventoryItem item={item} key={item.product._id || index}  toDetail={true}/>
        ))}
      </div>
    </>
  );
}
