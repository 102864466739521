import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import LoadingBackdrop from '../../../components/commonUI/LoadingBackdrop';
import CreateMenuForm from '../../../components/salonForm/CreateMenuForm';
import { createNewMenuSalon } from '../../../services/api/1D';
import { toast } from 'react-toastify';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import CreateCompleteDialog from 'app/components/CommonDialog/CreateCompleteDialog';

function RegisterMenuFormPage() {
  const [isCreating, setIsCreating] = useState(false);
  const [showModalComplete, setShowModalComplete] = useState(false);
  const navigate = useNavigate();

  const handleCreateMenu = async (values) => {
    setIsCreating(true);
    try {
      await createNewMenuSalon(values);
      GA.tracking(EVENT_NAMES.CREATE_MENU.CREATE);
      toast.success('メニューの作成が成功しました');
      setShowModalComplete(true);
    } catch (error) {
      toast.error('メニュー作成エラー');
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="flex items-center mb-6">
          <NavigationBackBtn url={Path.systemManagementAllMenu} text="メニュー追加" />
        </div>

        <div className="max-w-[960px] mx-auto bg-white">
          <CreateMenuForm onSubmit={handleCreateMenu} />
        </div>

        <LoadingBackdrop isOpen={isCreating} />
      </MainContentLayout>

      <CreateCompleteDialog
        open={showModalComplete}
        createCompleteTitle="メニューを登録しました。"
        createCompleteBtnLabel1="システム設定へ戻る"
        createCompleteAction1={() => {
          navigate(Path.systemManagement);
        }}
        createCompleteBtnLabel2="終了"
        createCompleteAction2={() => {
          navigate(Path.systemManagementAllMenu);
        }}
      />
    </DefaultLayout>
  );
}

export default RegisterMenuFormPage;
