import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
};

export default function BatchDeliverModal({ openDialog, handleOnCloseClicked, title, content, handleOnSendClicked }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [code, setCode] = React.useState(false);

  React.useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const onCloseClicked = () => {
    handleClose();
    handleOnCloseClicked();
  }

  const onSendClicked = () => {
    handleOnSendClicked();
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={onCloseClicked}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='mx-4 md:mx-0'
      >
        <Box sx={style} className={'px-4 py-6 rounded-[16px] w-full md:w-2/6'}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className="text-center">
            頭皮ラボに発送しますか
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ my: 4 }} align="center" className="">
            {content}
          </Typography>
          <div className="w-[180px] mx-auto">
            <button
              onClick={onSendClicked}
              type="button"
              className="button-size bg-blue-btn-primary mr-4 lg:mr-6"
            >
              一括発送
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}