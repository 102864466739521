import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Path from '../../../../route/Path';
import DefaultLayout from '../../../../layouts/DefaultLayout';
import MainContentLayout from '../../../../layouts/MainContentLayout';
import LoadingProgress from '../../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../../components/commonUI/LoadInitError';
import { getHistoryOfOrderingDetail } from '../../../../services/api/1C';
import NoData from '../../../../components/commonUI/NoData';
import TableScalpDataList from './TableScalpDataList';
import NavigationBackBtn from '../../../../components/common/NavigationBackButton';

function HistoryOfOrderDetailPage() {
  const { customerNo } = useParams();

  const [fetchHistoryOrderDetail, setFetchHistoryOrderDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const [init, setInit] = useState(false);

  const loadHistoryOrderDetail = (customerNo) => {
    setFetchHistoryOrderDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getHistoryOfOrderingDetail(customerNo)
      .then((res) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
        setInit(true);
      })
      .catch((error) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: null,
          error: error,
        });
        setInit(true);
      });
  };

  useEffect(() => {
    if (customerNo) {
      loadHistoryOrderDetail(customerNo);
    }
  }, [customerNo]);

  const items = useMemo(() => {
    if (
      !fetchHistoryOrderDetail?.data ||
      !Array.isArray(fetchHistoryOrderDetail.data) ||
      !fetchHistoryOrderDetail.data.length
    ) {
      return [];
    }

    return fetchHistoryOrderDetail.data;
  }, [fetchHistoryOrderDetail.data]);

  const customer = useMemo(() => {
    return items?.[0]?.customer;
  }, [items]);

  const navigationTitle = useMemo(() => {
    const customer = items?.[0]?.customer;
    if (!customer) {
      return '頭皮データ';
    }

    return `${customer.lastNameKanji || ''} ${customer.firstNameKanji || ''}さんの頭皮データ`;
  }, [customer]);

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-full pb-8 lg:pb-12 relative">
          {init && (
            <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
              <h2 className="text-base lg:text-xl font-semibold">
                <NavigationBackBtn
                  url={customer?._id ? Path.customerManagementDetail(customer?._id) : undefined}
                  text={navigationTitle}
                />
              </h2>
            </div>
          )}
          <div className="w-full h-full md:mt-6 mt-4 md:px-0 px-4">
            {init && <div className="text-xs text-[#666666]">お客様No. {customerNo || ''}</div>}

            <div className="w-full h-full md:mt-6 mt-4">
              {!!fetchHistoryOrderDetail.isLoading && <LoadingProgress />}

              {!!(fetchHistoryOrderDetail.error && fetchHistoryOrderDetail.data?.inspectionKitId) && (
                <LoadInitError error={fetchHistoryOrderDetail.error} />
              )}

              {init && <>{items?.length ? <TableScalpDataList allHistoryOrdering={items} /> : <NoData />}</>}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default HistoryOfOrderDetailPage;
