import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import InputField from '../../../components/commonUI/InputField';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../components/commonUI/LoadInitError';
import NoData from '../../../components/commonUI/NoData';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import Path from '../../../route/Path';
import { getSalonDetail } from '../../../services/api/18';
import { receiveNewOrderKit, updateInspectionKitAmount } from '../../../services/api/1C';
import OrderDialog from './OrderDialog';

const ORDER_STATUS = {
  waiting: '承認待ち',
  confirmed: '頭皮ラボ承認済み',
  delivering: '在庫に登録',
  completed: '在庫登録済',
};

function OrderKits() {
  const [open, setOpen] = useState(false);
  const [isEditKitAmount, setIsEditKitAmount] = useState(false);
  const [fetchAllKitOfScalpLab, setFetchAllKitOfScalpLab] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [isUpdatingStock, setIsUpdatingStock] = useState(false);

  const inspectionKitAmount = fetchAllKitOfScalpLab.data?.inspectionKit?.inStockAmount;

  const loadAllKitOfScalpLab = () => {
    setFetchAllKitOfScalpLab({
      isLoading: true,
      data: null,
      error: null,
    });

    getSalonDetail()
      .then((res) => {
        setFetchAllKitOfScalpLab({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchAllKitOfScalpLab({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const registerNewOrderKit = async (values) => {
    const input = {
      orderAmount: values.orderAmount,
    };
    receiveNewOrderKit(values._id, input)
      .then((res) => {
        loadAllKitOfScalpLab();
      })
      .catch((e) => {
        alert(e);
      })
      .finally(() => {});
  };

  const changeAmountOnSubmit = async () => {
    setIsUpdatingStock(true);
    updateInspectionKitAmount(changeAmountValues.inspectionKitAmount)
      .then(({ data }) => {
        if (data) {
          toast.success('更新しました');
          setFetchAllKitOfScalpLab({
            ...fetchAllKitOfScalpLab,
            data: {
              ...fetchAllKitOfScalpLab.data,
              inspectionKit: {
                ...fetchAllKitOfScalpLab.data.inspectionKit,
                inStockAmount: changeAmountValues.inspectionKitAmount,
              },
            },
          });
        } else {
          toast.error('更新エラー');
        }
      })
      .catch(() => {
        toast.error('更新エラー');
      })
      .finally(() => {
        setIsUpdatingStock(false);
      });
  };

  const {
    handleSubmit: changeAmountHandleSubmit,
    errors: changeAmountErrors,
    values: changeAmountValues,
    setFieldValue: changeAmountSetFieldValue,
    touched: changeAmountTouched,
    handleBlur: changeAmountHandleBlur,
  } = useFormik({
    initialValues: {
      inspectionKitAmount: inspectionKitAmount || 0,
    },
    onSubmit: changeAmountOnSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    loadAllKitOfScalpLab();
  }, []);

  const handleEditKitAmount = () => {
    if (changeAmountValues.inspectionKitAmount !== inspectionKitAmount) {
      changeAmountHandleSubmit();
    }
    setIsEditKitAmount((prev) => !prev);
  };

  return (
    <DefaultLayout>
      <MainContentLayout>
        <NavigationBackBtn url={Path.scalpLabManagement} text="検査キット" />
        {fetchAllKitOfScalpLab.isLoading ? <LoadingProgress /> : null}

        {!fetchAllKitOfScalpLab.isLoading && fetchAllKitOfScalpLab.error ? (
          <LoadInitError error={fetchAllKitOfScalpLab.error} />
        ) : null}

        {!fetchAllKitOfScalpLab.isLoading && !fetchAllKitOfScalpLab.error && (
          <div className="flex flex-col gap-6 px-4 md:gap-12">
            <div className="flex flex-col gap-4 mt-4 md:mt-6 md:max-w-[480px] md:mx-auto w-full">
              {isEditKitAmount ? (
                <InputField
                  name="inspectionKitAmount"
                  value={changeAmountValues.inspectionKitAmount}
                  onChange={(value) => {
                    changeAmountSetFieldValue('inspectionKitAmount', Number(value.replace(/[^0-9.]/g, '')));
                  }}
                  handleBlur={changeAmountHandleBlur}
                  touched={changeAmountTouched}
                  errors={changeAmountErrors.inspectionKitAmount}
                  inputClasses="border border-[#EDEDED] rounded-lg h-[61px] text-[#252525] text-[28px]"
                />
              ) : (
                <div className="border border-[#EDEDED] rounded-lg h-[61px] flex items-center justify-center text-[#252525] gap-4">
                  現在の在庫数
                  <span className="text-[28px]">
                    {!isUpdatingStock && fetchAllKitOfScalpLab.data?.inspectionKit?.inStockAmount}
                  </span>
                </div>
              )}
              <div className="flex gap-4">
                <button
                  type="button"
                  className="button-size bg-white text-[#252525]"
                  onClick={handleEditKitAmount}
                >
                  変更する
                </button>
                <button
                  onClick={() => {
                    setOpen(true);
                  }}
                  type="button"
                  className="button-size bg-blue-btn-primary"
                >
                  発注する
                </button>
              </div>
            </div>
            {fetchAllKitOfScalpLab?.data?.inspectionKitSalonOrders?.length > 0 ? (
              <div className="flex flex-col gap-4">
                {fetchAllKitOfScalpLab?.data?.inspectionKitSalonOrders?.map((row, index) => (
                  <div key={index} className="shadow-item p-4 rounded-xl flex flex-col gap-2">
                    <div className="text-[#252525] text-sm flex gap-4 items-center">
                      検体数
                      <span className="text-base">{row.orderAmount}</span>
                    </div>
                    <div className="flex gap-2 justify-between items-end">
                      <div className="flex flex-col gap-2 text-subtitle text-xs">
                        <span>発注日時：{row.orderDate ? moment(row.orderDate).format('YYYY-MM-DD HH:mm') : ''}</span>
                        <span>
                          発送日時：{row.deliveryDate ? moment(row.deliveryDate).format('YYYY-MM-DD HH:mm') : ''}
                        </span>
                      </div>

                      <button
                        className="button-size bg-blue-btn-primary text-sm font-medium max-w-fit px-3 py-2 max-h-9 disabled:bg-[#CACACA] disabled:shadow-none min-w-[94px]"
                        disabled={row.orderStatus !== 'delivering'}
                        onClick={() => {
                          registerNewOrderKit(row);
                        }}
                      >
                        {ORDER_STATUS?.[row.orderStatus] ?? '未知'}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <NoData />
            )}
          </div>
        )}

        <OrderDialog open={open} onClose={() => setOpen(false)} />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default OrderKits;
