import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { searchAllCustomer } from 'app/services/api';
import { FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DefaultLayout from '../../../layouts/DefaultLayout';
import { useNavigate } from 'react-router-dom';
import MainContentLayout from '../../../layouts/MainContentLayout';
import Path from '../../../route/Path';
import InputField from '../../../components/commonUI/InputField';
import { getAllSalonCustomer, getSalonCustomer } from '../../../services/api/1C';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import { getCustomerGenderText } from '../../14/DetailCustomerPage/helpers';
import moment from 'moment';
import useDebounce from '../../../hooks/useDebounce';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import { SearchIcon } from '../OrderScalpLab';

function SuggestCustomerPage() {
  const navigate = useNavigate();
  const [listCustomer, setListCustomer] = useState();
  const [, setCustomer] = useState();
  const [, setCustomerName] = useState();
  const [, setSearchState] = useState({
    isSearching: false,
    searchConditions: {},
    data: null,
    error: null,
  });

  const loadAllSalonCustomer = async () => {
    getAllSalonCustomer({ isConfirmAgreement: false }).then((res) => {
      setListCustomer(res.data);
    });
  };

  const loadSalonCustomer = (customerNo) => {
    getSalonCustomer(customerNo).then((res) => {
      if (res.data !== null) {
        setCustomer(res.data);
        setListCustomer([{ customer: res.data, customerNo }]);
        setFieldValue('customerName', res.data?.lastNameKanji + ' ' + res.data?.firstNameKanji);
        setFieldValue('salonCustomerId', res.data?.salonCustomer._id);
        setCustomerName(res.data?.lastNameKanji + ' ' + res.data?.firstNameKanji);
      }
    });
  };

  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchValues = useDebounce(searchInput, 500);
  const [loading, setLoading] = useState(false);
  const handleSearchInputChange = (event, value) => {
    setSearchInput(value);
  };

  useEffect(() => {
    searchSalonCustomerByName(debouncedSearchValues);
  }, [debouncedSearchValues]);

  const searchSalonCustomerByName = (customerName) => {
    setLoading(true);
    searchAllCustomer({ fullName: customerName, isConfirmAgreement: false })
      .then((res) => {
        const transformedArray = res.data.map((item) => {
          return {
            customer: { ...item },
            customerNo: item.salonCustomers ? item.salonCustomers[0].customerNo : undefined,
          };
        });

        setListCustomer(transformedArray);

        setSearchState((oldState) => ({
          isSearching: false,
          searchConditions: oldState.customerName,
          data: res.data,
          error: null,
        }));
      })
      .catch((error) => {
        setSearchState((oldState) => ({
          isSearching: false,
          searchConditions: oldState.customerName,
          data: null,
          error: error,
        }));
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      });
  };

  useEffect(() => {
    // loadAllSalonCustomer();
  }, []);

  const handleAutoCompleteChange = (event, value, reason) => {
    if (value === null) {
      return loadAllSalonCustomer();
    }
    setFieldValue('customerName', value?.customer.lastNameKanji + ' ' + value?.customer.firstNameKanji);
    setFieldValue('customerFullNameKata', value?.customer.lastNameKata + ' ' + value?.customer.firstNameKata);
    setFieldValue(
      'customerDob',
      value?.customer?.dob
        ? moment(value.customer.dob).isValid()
          ? moment(value.customer.dob).format('YYYY年MM月DD日')
          : ''
        : '',
    );
    setFieldValue('customerGender', getCustomerGenderText(value?.customer.gender));
    setFieldValue('customerNo', value?.customerNo);
    const salonCustomerId = value?.customer?.salonCustomers?.[0]?._id || value?.customer?.salonCustomerIds[0];
    setFieldValue('salonCustomerId', salonCustomerId);
  };

  const validationSchema = Yup.object().shape({
    customerName: Yup.string().required('お客様名は必須です。'),
    customerNo: Yup.string().required('お客様No.は必須です。'),
  });

  const formik = useFormik({
    initialValues: {
      customerNo: '',
      customerName: '',
      customerFullNameKata: '',
      customerGender: '',
      customerDob: '',
      isConfirmAgreement: true,
      salonCustomerId: '',
    },
    validationSchema,
  });

  const {
    errors,
    values,
    setFieldValue,
    // resetForm,
    touched,
    handleBlur,
  } = formik;

  const handleRegister = () => {
    navigate(Path.scalpLabManagementIntroduction, {
      state: { values },
    });
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full relative">
          <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              <NavigationBackBtn url={Path.scalpLabManagement} text="同意書確認" />
            </h2>
          </div>
          <div className="px-4 md:px-0 w-full h-full min-h-[calc(100vh-200px)]">
            <div className="w-full py-3">
              <div className="w-full">
                <div className="text-sm mb-4">提案する顧客を選択してください</div>
                <form className="w-full h-full flex flex-col">
                  <div className="w-full md:w-1/3">
                    <div className="w-full flex h-auto mb-5">
                      <FormControl sx={{ width: '100%' }} size="small">
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          loading={loading}
                          loadingText={'読み込み中'}
                          options={listCustomer ? listCustomer : { label: 'default' }}
                          getOptionLabel={(option) => {
                            const label = option.customer?.lastNameKanji + ' ' + option.customer?.firstNameKanji;
                            return label;
                          }}
                          renderOption={(props, option) => {
                            const customer = option?.customer;
                            return (
                              <li {...props} key={customer?.id || props.key}>
                                {customer ? customer?.lastNameKanji + ' ' + customer?.firstNameKanji : props.key}
                              </li>
                            );
                          }}
                          renderInput={(params) => {
                            handleSearchInputChange(null, params.inputProps.value);
                            return (
                              <TextField
                                {...params}
                                sx={{
                                  '& .MuiInputBase-root': {
                                    height: 48,
                                  },
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <div className="MuiAutocomplete-endAdornment absolute transform -translate-y-1/2 top-1/2 !right-[14px]">
                                      <div className="inline-flex content-center items-center">
                                        {loading ? <LoadingProgress size={24} /> : <SearchIcon />}
                                      </div>
                                    </div>
                                  ),
                                }}
                              />
                            );
                          }}
                          onChange={handleAutoCompleteChange}
                          disabled={listCustomer ? false : true}
                          sx={{
                            '& .MuiAutocomplete-popupIndicator': {
                              transform: 'none !important',
                            },
                          }}
                        />
                      </FormControl>
                    </div>
                  </div>
                  {/* <div className="flex flex-col md:flex-row gap-0 md:gap-6">
                    <div className="w-full md:w-1/3">
                      <div className="w-full h-auto mb-5">
                        <InputField
                          label="お客様No."
                          name="customerNo"
                          value={values.customerNo}
                          type="text"
                          onChange={(newValue) => {
                            setFieldValue('customerNo', newValue);
                            if (newValue) {
                              loadSalonCustomer(newValue);
                            }
                          }}
                          handleBlur={handleBlur}
                          touched={touched.customerNo}
                          errors={errors.customerNo}
                          labelClasses="!w-24"
                        />
                      </div>
                    </div>
                  </div> */}
                  {values.customerNo && (
                    <div className="flex flex-col md:flex-row gap-0 md:gap-6">
                      <div className="w-full">
                        <div className="w-full h-auto mb-5">
                          <InputField label="お客様No" value={values.customerNo} type="display" />
                        </div>
                      </div>
                    </div>
                  )}
                  {values.customerFullNameKata && (
                    <>
                      <div className="flex flex-col md:flex-row gap-0 md:gap-6">
                        <div className="w-full">
                          <div className="w-full h-auto mb-5">
                            <InputField label="お名前" value={values.customerName} type="display" />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row gap-0 md:gap-6">
                        <div className="w-full">
                          <div className="w-full h-auto mb-5">
                            <InputField label="お名前フリガナ" value={values.customerFullNameKata} type="display" />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row gap-0 md:gap-6">
                        <div className="w-full">
                          <div className="w-full h-auto mb-5">
                            <InputField label="生年月日" value={values.customerDob} type="display" />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row gap-0 md:gap-6">
                        <div className="w-full">
                          <div className="w-full h-auto mb-5">
                            <InputField label="性別" value={values.customerGender} type="display" />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </form>
              </div>
            </div>
            <div className="w-full p-6 flex items-center text-white text-base font-semibold absolute inset-x-0 bottom-0">
              <button
                onClick={() => {
                  if (values.customerName !== '' && values.customerNo !== '') {
                    handleRegister();
                  }
                }}
                type="button"
                className={`button-size w-full lg:w-[200px] button-size bg-blue-btn-primary mx-auto rounded-[12px] py-6  ${
                  values.customerName === '' || values.customerNo === ''
                    ? 'bg-gray-btn-secondary'
                    : 'bg-blue-btn-primary'
                }`}
                disabled={values.customerName === '' || values.customerNo === '' ? true : false}
              >
                提案にすすむ
              </button>
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SuggestCustomerPage;
