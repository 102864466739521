import React, { useEffect, useRef } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "moment/locale/ja";
import moment from "moment";


import { jpDateFormat } from "app/constants";

export default function InputDateField(props) {
  const {
    id,
    value,
    setValue,
    disabled,
    inputFontSize,
    className,
    touched,
    errors,
    onBlur,
    label,
    inputWrapClasses,
    ...rest
  } = props;

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setAttribute("id", id);
      inputRef.current.addEventListener("blur", onBlur);
    }
  }, [id, onBlur]);

  const formatValue = value ? moment(value) : null;
  
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ja">
      <div className={`h-auto flex flex-col gap-2 ${inputWrapClasses || ""}`}>
        {label && <label className={`text-sm text-subtitle`}>{label}</label>}
        <div className={``}>
          <DatePicker
            value={formatValue}
            onChange={(newValue) => setValue(newValue)}
            format={jpDateFormat}
            disabled={!!disabled}
            className={className}
            slotProps={{
              textField: {
                size: "small",
                error: touched && errors,
                helperText:
                  touched && errors ? (
                    <p className="w-auto !text-red-600 !text-[12px]">
                      <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
                        <ErrorIcon
                          fontSize="inherit"
                          className="text-xs text-red-600 font-thin"
                        />
                      </span>
                      <span>{errors}</span>
                    </p>
                  ) : null,
              },
            }}
            sx={{
              "& .MuiInputBase-input": {
                fontSize: inputFontSize || "16px",
                padding: "12.5px 16px",
              },
              "& .Mui-disabled": {
                backgroundColor: "#f3f4f6 !important",
              },
              "& input.Mui-disabled ": {
                color: "#000 !important",
                "-webkit-text-fill-color": "#000 !important",
              },
              "& .MuiOutlinedInput-notchedOutline ":
                touched && errors
                  ? {
                      borderColor: "#dc2626",
                    }
                  : {},
              "& .MuiOutlinedInput-notchedOutline.Mui-forcused ":
                touched && errors
                  ? {
                      borderColor: "#125fcc !important",
                    }
                  : {},
            }}
            inputRef={inputRef}
            {...rest}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
}
