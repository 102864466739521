import React, { useState, useEffect } from 'react';
import { setAuthUser, setAuthToken } from 'app/redux/authReducer';
import { useDispatch } from 'react-redux';
import LOGIN_LOGO from 'app/assets/images/tohilabo-logo.svg';

import { login } from '../../services/api';
import { ToastContainer, toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { accountTypes } from '../../constants';
import GA, { EVENT_NAMES } from '../../services/googleAnalytics';
import InputField from '../../components/commonUI/InputField';
import CheckboxField from '../../components/commonUI/CheckboxField';

const LOGIN_NAME = 'LOGIN';
const PASSWORD_NAME = 'PASSWORD';

function Login() {
  const dispatch = useDispatch();
  const [loginName, setLoginName] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const encode = (value) => {
    try {
      return btoa(value);
    } catch (e) {
      console.log(e);
      return '';
    }
  };
  const decode = (value) => {
    try {
      return atob(value);
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  const savePassword = (loginName, password, rememberMe) => {
    if (rememberMe) {
      try {
        localStorage.setItem(encode(LOGIN_NAME), encode(loginName));
        localStorage.setItem(encode(PASSWORD_NAME), encode(password));
      } catch (e) {
        console.log(e);
      }
    } else {
      localStorage.removeItem(encode(LOGIN_NAME));
      localStorage.removeItem(encode(PASSWORD_NAME));
    }
  };

  const restoreLoginAndPassword = () => {
    let loginValue = localStorage.getItem(encode(LOGIN_NAME));
    if (loginValue) {
      loginValue = decode(loginValue);
      setLoginName(loginValue);
    }

    let passwordValue = localStorage.getItem(encode(PASSWORD_NAME));
    if (passwordValue) {
      passwordValue = decode(passwordValue);
      setPassword(passwordValue);
    }
    if (loginValue && passwordValue) {
      setRememberMe(true);
    }
  };

  const handleLogin = () => {
    setPasswordError(''); // Reset error message

    login(loginName, password, rememberMe)
      .then((res) => {
        const tokenData = jwtDecode(res.data.result.accessToken);
        const userId = res.data.result.id;
        GA.login(userId);
        GA.tracking(EVENT_NAMES.CLICK_LOGIN);
        savePassword(loginName, password, rememberMe);
        dispatch(
          setAuthUser({
            id: res.data.result.id,
            email: res.data.result.email,
            permissions: tokenData?.permissions || [],
            accountType: tokenData?.accountType || accountTypes.STAFF,
            loginName,
            salonName: res.data?.result?.salonName,
            salonImageUrl: res.data?.result?.salonImageUrl ?? tokenData?.salonImageUrl,
            salonId: res.data?.result?.salonId ?? tokenData?.salonId,
          }),
        );
        dispatch(
          setAuthToken({
            accessToken: res.data.result.accessToken,
          }),
        );
      })
      .catch((err) => {
        setPasswordError('アカウント名またはパスワードが正しくありません。');
      });
  };

  const handleLoginNameChange = (value) => {
    setLoginName(value);
    setPasswordError('');
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setPasswordError('');
  };

  useEffect(() => {
    setIsRead(loginName && password);
  }, [loginName, password]);

  useEffect(() => {
    restoreLoginAndPassword();
  }, []);

  const isButtonDisabled = !isRead || passwordError;

  return (
    <>
      <div className="bg-gradient-to-b from-[#DDFBFF] via-[#FFFFFF] to-[#FFFFFF] h-screen">
        <section className="flex justify-center h-screen">
          <div className="w-full py-6 px-4 bg-transparent rounded-lg max-w-[343px] md:flex md:max-w-full md:justify-center md:gap-">
            <div className="md:w-[500px] md:flex md:items-center">
              <img
                src={LOGIN_LOGO}
                alt="tohilabo_logo"
                className="w-[145px] mx-auto mb-[24px] md:ml-[5%] md:w-[205px]"
              />
            </div>
            <div className="md:flex md:items-center">
              <form
                className="flex flex-wrap flex-col justify-center space-y-9 mt-6 md:w-[500px] md:mt-0 md:bg-white md:rounded-lg md:shadow-md md:p-10"
                action="#"
              >
                <div className="hidden md:flex md:items-center mb-[12px] font-[20px] justify-center text-black w-full">
                  ログイン
                </div>
                <div>
                  <InputField
                    label="アカウント名"
                    name="loginName"
                    value={loginName}
                    onChange={(e) => handleLoginNameChange(e)}
                    placeholder="アカウント名を入力してください"
                    required
                  />
                </div>
                <div>
                  <div className="relative">
                    <InputField
                      type="password"
                      label="パスワード"
                      name="password"
                      value={password}
                      onChange={(e) => handlePasswordChange(e)}
                      placeholder="パスワードを入力してください"
                      required
                    />
                  </div>
                  <p className="text-red-600 text-sm mt-2">{passwordError}</p>
                </div>

                <CheckboxField
                  label="パスワードを保存する"
                  name="remember"
                  value={rememberMe}
                  onChange={(e) => setRememberMe(e.target.value)}
                  isToggle={true}
                />

                <button
                  type="button"
                  onClick={handleLogin}
                  disabled={isButtonDisabled}
                  className={`button-size rounded-[12px] h-[58px] mb-9 ${!isButtonDisabled ? 'bg-blue-btn-primary' : 'bg-[#b1b1b1]'}`}
                >
                  ログイン
                </button>
              </form>
            </div>
          </div>
        </section>
      </div>

      <ToastContainer
        position="bottom-left"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
        limit={3}
      />
    </>
  );
}

export default Login;
