import moment from 'moment';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import GA, { EVENT_NAMES } from '../../services/googleAnalytics';
import Path from 'app/route/Path';
import {
  convertClusterToText,
  getAnalyseStatus,
} from '../../pages/1C/HistoryOfOrderingPage/HistoryOfOrderDetailByKitIdPage/helpers';

export default function HistoryOrderItem(props) {
  const { data } = props;
  const navigate = useNavigate();

  const analyseStatus = useMemo(() => {
    return getAnalyseStatus(data?.analyseStatus, data?.factorCluster);
  }, [data?.analyseStatus, data?.factorCluster]);


  const factorCluster = useMemo(() => {
    if (data?.analyseStatus !== 'analyse_completed') {
      return '';
    }

    return convertClusterToText(String(data?.factorCluster));
  }, [data?.factorCluster, data?.analyseStatus]);

  return (
    <div className="w-full h-full relative shadow-center rounded-[12px] p-4 my-4">
      <div className="flex gap-2 items-center justify-between">
        <div>
          <div className="flex gap-2 items-center">
            <div className="bg-gray-base rounded-[34px] text-subtitle px-3 py-1 text-sm">検体No</div>
            <div className="text-sm">{data.inspectionKitId || ''}</div>
          </div>
          <div className="flex gap-2 mt-3 items-center text-sm">
            <div>{data.customerName || ''}</div>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-sm text-right">
            {analyseStatus}
            {factorCluster ? ` ${factorCluster}` : ''}
          </div>
          <div>
            <button
              type="button"
              className="w-[94px] button-size bg-blue-btn-primary h-9 rounded-[8px] text-sm"
              onClick={() => {
                GA.tracking(EVENT_NAMES.HISTORY_ORDER.CLICK_DETAIL, {
                  customerNo: data?.customerNo,
                  inspectionKitId: data?.inspectionKitId,
                });
                navigate(Path.historyOfOrderingDetailByKitId(data?.customerNo, data?.inspectionKitId));
              }}
            >
              詳細を確認
            </button>
          </div>
        </div>
      </div>
      <div className="flex gap-2 mt-3 pt-3 items-center justify-between text-xs text-subtitle border-t border-[#EDEDED]">
        <div>お客様No. {data.customerNo || ''}</div>
        <div>
          発送日時： {data.deliverDate !== undefined ? moment(data.deliverDate).format('YYYY-MM-DD HH:mm') : '-'}
        </div>
      </div>
    </div>
  );
}
