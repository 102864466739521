import * as Yup from 'yup';

const validationErrMessages = {
  nameRequired: '店舗名は必須です。',
  emailRequired: 'メールアドレスは必須です。',
  emailValid: 'メールアドレスが正しくありません。',
  phoneRequired: '電話番号は必須です。',
  phoneValid: '電話番号は 10 桁または 11 桁で構成する必要があります',
  postalCodeRequired: '郵便番号は必須です。',
  prefectureRequired: '都道府県は必須です。',
  cityRequired: '市区町村群は必須です。',
  streetRequired: '町名は必須です。',
  buildingRequired: '番地ビル名は必須です。',
  workingHoursRequired: '営業時間は必須です。',
  postalCodeValid: '郵便番号は7桁でなければなりません',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(validationErrMessages.nameRequired),
  email: Yup.string().email(validationErrMessages.emailValid).required(validationErrMessages.emailRequired),
  phone: Yup.string().matches(/^(\d{10}|\d{11})$/, validationErrMessages.phoneValid),
  postalCode: Yup.string()
    .required(validationErrMessages.postalCodeRequired)
    .matches(/^(\d{7})$/, validationErrMessages.postalCodeValid),
  prefecture: Yup.string().required(validationErrMessages.prefectureRequired),
  city: Yup.string().required(validationErrMessages.cityRequired),
  street: Yup.string().required(validationErrMessages.streetRequired),
});

export default validationSchema;
