import React, { useState } from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import { useNavigate, useLocation } from 'react-router-dom';
import Path from '../../../route/Path';
import { toast } from 'react-toastify';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { updateSalonCustomer } from '../../../services/api/1C';
import CommonDialog from '../../../components/CommonDialog';
import AgreementContent from '../../../components/AgreementContent';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';

function ConfirmApplicationForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const values = location.state?.values;
  const isRegistrationFlow = values && Object.keys(values).length !== 0;

  const confirmSteps = {
    CONFIRM: 'CONFIRM',
    CONFIRM_AGAIN: 'CONFIRM_AGAIN',
  };

  const [showStep, setShowStep] = useState(confirmSteps.CONFIRM);

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenRegisterDialog, setIsOpenRegisterDialog] = useState(false);

  const handleSubmit = async (values) => {
    updateSalonCustomer(values.salonCustomerId, {
      customerNo: values.customerNo,
      isConfirmAgreement: values.isConfirmAgreement,
    })
      .then((res) => {
        setShowStep(confirmSteps.CONFIRM_AGAIN);
      })
      .catch((error) => {
        if (error) {
          setIsOpenRegisterDialog(false);
        }
        if (error.response.data.message === 'duplicate.kitId') {
          toast.error('キットIDが重複しました');
        } else {
          toast.error('正しい情報を入力してください');
        }
      });
  };

  const handleConfirm = () => {
    if (showStep === confirmSteps.CONFIRM) {
      GA.tracking(EVENT_NAMES.APPLICATION_FORM.CONFIRM_AGREEMENT, {
        customerNo: values.customerNo,
      });
      handleSubmit(values);
    } else if (showStep === confirmSteps.CONFIRM_AGAIN) {
      setIsOpenRegisterDialog(false);
      setShowStep(confirmSteps.CONFIRM);
      navigate(Path.home);
    }
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full relative">
          <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              <NavigationBackBtn
                onClick={() =>
                  navigate(Path.scalpLabManagementIntroduction, {
                    state: { values },
                  })
                }
                text="同意書確認"
              />
            </h2>
          </div>
          <div className="px-4 md:px-0">
            <div className="w-full h-full">
              <div className="w-full flex justify-center h-full">
                <div className="w-[1000px] h-full overflow-y-auto overflow-x-hidden">
                  <AgreementContent />
                </div>
              </div>
            </div>
            <div className="w-full py-4 flex gap-6 items-center justify-center text-white text-base">
              <button
                type="button"
                className="button-size bg-blue-btn-primary lg:w-[200px]"
                onClick={() => {
                  if (isRegistrationFlow) {
                    setIsOpenRegisterDialog(true);
                  } else {
                    setIsOpenDialog(true);
                  }
                }}
              >
                同意にすすむ
              </button>
            </div>
          </div>
        </div>

        <CommonDialog
          open={isOpenDialog}
          handleClose={() => setIsOpenDialog(false)}
          title="頭皮ラボの申込が完了しました"
          actions={
            <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
              <button
                onClick={() => navigate(Path.scalpLabManagement)}
                type="button"
                className="button-size bg-gray-btn-secondary"
              >
                頭皮ラボへ戻る
              </button>
              <button
                onClick={() => navigate(Path.scalpLabManagement)}
                type="button"
                className="button-size bg-blue-btn-primary"
              >
                終了
              </button>
            </div>
          }
        />

        <CommonDialog
          open={isOpenRegisterDialog}
          handleClose={() => {
            if (showStep === confirmSteps.CONFIRM) {
              setIsOpenRegisterDialog(false);
            }
          }}
          hideCloseIcon={showStep === confirmSteps.CONFIRM_AGAIN}
          actions={
            <div className="w-full flex gap-6 items-center justify-center text-white text-base">
              {showStep === confirmSteps.CONFIRM && (
                <button onClick={() => setIsOpenRegisterDialog(false)} type="button" className="button-size text-black">
                  キャンセル
                </button>
              )}
              <button onClick={handleConfirm} type="button" className={`button-size bg-blue-btn-primary ${showStep !== confirmSteps.CONFIRM && 'w-auto'}`}>
                {showStep === confirmSteps.CONFIRM ? '同意する' : 'トップに戻る'}
              </button>
            </div>
          }
        >
          <div className="flex justify-center mt-2">
            {showStep === confirmSteps.CONFIRM ? '同意書の内容に同意しますか？' : '同意書の登録が完了しました'}
          </div>
        </CommonDialog>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ConfirmApplicationForm;
