import UserPointItem from './Item';

const UserPoint = ({ scalpData, isTablet }) => {
  return (
    <div className="w-full h-auto flex flex-col gap-10 pb-8 md:px-10 border-b">
      <UserPointItem
        point={scalpData?.factorCellArea}
        bar={scalpData?.barCA}
        ratio={scalpData?.ratioCA}
        textLeft="細胞面積(階層細胞の大きさ)"
        textRight="ターンオーバーの指標"
        isTablet={isTablet}
      />
      <UserPointItem
        point={scalpData?.factorCP}
        bar={scalpData?.barCP}
        ratio={scalpData?.ratioCP}
        textLeft="カルボニルタンパク"
        textRight="頭皮酸化ストレスの指標"
        isTablet={isTablet}
      />
      <UserPointItem
        point={scalpData?.factorPeeling}
        bar={scalpData?.barMP}
        ratio={scalpData?.ratioMP}
        textLeft="多重剥離度(角層の重なり)"
        textRight="乾燥や肌荒れの指標"
        isTablet={isTablet}
      />
    </div>
  );
};

export default UserPoint;
