import React, { useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

const autofillStyles = {
  WebkitTransition: 'background-color ease-in-out 100ms',
  '&:WebkitAutofill': {
    WebkitTransition: 'background-color ease-in-out 100ms',
  },
  '&:WebkitAutofill:hover': {
    WebkitTransition: 'background-color ease-in-out 100ms',
  },
  '&:WebkitAutofill:focus': {
    WebkitTransition: 'background-color ease-in-out 100ms',
  },
  '&:WebkitAutofill:active': {
    WebkitTransition: 'background-color ease-in-out 100ms',
  },
};

export default function InputField(props) {
  const {
    name,
    value,
    label,
    type,
    onChange,
    handleBlur,
    touched,
    errors,
    inputWrapClasses,
    labelClasses,
    inputClasses,
    inputProps = {},
    disabled,
    placeholder,
    prefixIcon,
    showIconPassword = true,
    multiline = false,
    maxRows = 6,
  } = props;

  const [isRevealPassword, setIsRevealPassword] = useState(false);

  const togglePassword = () => {
    setIsRevealPassword((prevState) => !prevState);
  };

  return (
    <div className={`flex flex-col gap-2 ${inputWrapClasses || ''}`}>
      {label && (
        <label htmlFor={name} className={`text-sm text-subtitle ${labelClasses || ''}`}>
          {label}
        </label>
      )}

      <div className="flex flex-col relative">
        {prefixIcon && <div className="absolute left-4 top-1/2 -translate-y-1/2">{prefixIcon}</div>}
        {type === 'display' ? (
          <div className={`break-all ${multiline && 'whitespace-pre-line'}`}>
            {typeof value !== 'undefined' ? value : ''}
          </div>
        ) : (
          <>
            {!multiline && (
              <input
                name={name}
                id={name}
                className={`focus:outline-[#0096D4] border border-[#CACACA] hover:border-gray-800 rounded text-base px-4 h-12 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none
              ${inputClasses || ''}
              ${touched && errors ? '!border-red-500' : ''}
              ${disabled ? 'bg-[#f3f4f6]' : 'bg-white'} `}
                value={value}
                type={type === 'password' && isRevealPassword ? 'text' : type}
                onChange={(e) => onChange(e.target.value)}
                onBlur={handleBlur}
                disabled={!!disabled}
                autoComplete="off"
                placeholder={placeholder || ''}
                {...inputProps}
                style={autofillStyles}
              />
            )}

            {multiline && (
              <FormControl>
                <OutlinedInput
                  name={name}
                  id={name}
                  className={`text-base !border-red-500
                    ${inputClasses || ''}
                    ${touched && errors ? '!border-red-500' : ''}
                    ${disabled ? 'bg-[#f3f4f6]' : 'bg-white'} `}
                  sx={{
                    '&:hover fieldSet': {
                      borderColor: touched && errors ? 'rgb(239, 68, 68)' : 'rgb(31, 41, 55)',
                    },
                    '&.Mui-focused fieldSet': {
                      borderColor: touched && errors ? 'rgb(239, 68, 68)' : '#0096D4',
                      outlineColor: '#0096D4',
                      outlineWidth: '1px',
                    },
                    fieldset: {
                      padding: '12.5px 14px',
                      borderWidth: '1px',
                      borderColor: touched && errors ? 'rgb(239, 68, 68)' : '#CACACA',
                    },
                  }}
                  value={value}
                  type={type === 'password' && isRevealPassword ? 'text' : type}
                  onChange={(e) => onChange(e.target.value)}
                  onBlur={handleBlur}
                  disabled={!!disabled}
                  autoComplete="off"
                  placeholder={placeholder || ''}
                  {...inputProps}
                  style={autofillStyles}
                  multiline={true}
                  maxRows={maxRows}
                />
              </FormControl>
            )}
          </>
        )}

        {showIconPassword && type === 'password' && (
          <span
            onClick={togglePassword}
            role="presentation"
            className={'absolute inset-y-0 right-0 pr-3 flex items-center text-lg cursor-pointer text-gray-500'}
          >
            {type === 'password' && isRevealPassword ? <i className="fa fa-eye" /> : <i className="fa fa-eye-slash" />}
          </span>
        )}

        {touched && errors ? (
          <p className="w-full !text-red-600 !text-[12px] sm:whitespace-nowrap break-words">
            <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
              <ErrorIcon fontSize="inherit" className="text-xs text-red-600 font-thin" />
            </span>
            <span>{errors}</span>
          </p>
        ) : null}
      </div>
    </div>
  );
}
