import React from 'react';

export function ResponsiveMenuItem(props) {
  const { onClick, itemTitle, isMobile, isItemImgMode, isColorDiff } = props;

  let imgUrl = 'https://placehold.jp/1D7874/ffffff/150x150.png';

  if (isItemImgMode) {
    return (
      <img
        onClick={onClick}
        alt="touhilab"
        src={imgUrl}
        className="w-full h-20 rounded-md relative border-[#726966] group group-hover:border-[#666FC8] hover:border-[#666FC8] cursor-pointer"
      />
    );
  }

  if (isMobile)
    return (
      <button
        onClick={onClick}
        className={`basis-full lg:basis-1/3 h-12 rounded-lg relative 
                      ${isColorDiff ? 'border-[#FFFFFF] bg-[#FFFFFF] bg-opacity-25' : 'border-[#646160] bg-[#FFFFFF]'} 
                      group group-hover:border-[#666FC8] hover:border-[#666FC8]`}
      >
        <div
          className={`w-full h-full rounded-lg absolute shadow-center inset-x-0 bottom-0 flex items-center justify-center ${
            isColorDiff
              ? 'border-[#FFFFFF] bg-[#FFFFFF] bg-opacity-25 text-black'
              : 'border-[#646160] bg-[#FFFFFF] text-black'
          } group group-hover:bg-[#DEF4FF] hover:bg-[#666FC8] text-md font-medium`}
        >
          <div>{itemTitle}</div>
        </div>
      </button>
    );
  return (
    <button
      onClick={onClick}
      className={`w-60 h-20 lg:w-full lg:h-[10rem] rounded-[20px] relative 
                    ${isColorDiff ? 'border-[#FFFFFF] bg-[#FFFFFF] bg-opacity-25' : 'border-[#FFFFFF] bg-[#FFFFFF]'} 
                    group group-hover:border-[#666FC8] hover:border-[#666FC8]`}
    >
      <div
        className={`w-full h-full rounded-[20px] absolute inset-x-0 bottom-0 flex items-center justify-center ${
          isColorDiff
            ? 'border-[#FFFFFF] bg-[#FFFFFF] shadow-center bg-opacity-25 text-black'
            : 'border-[#646160] bg-[#FFFFFF] shadow-center'
        } group group-hover:bg-[#DEF4FF] hover:bg-[#DEF4FF] text-xs min-[400px]:text-sm font-semibold`}
      >
        <div>{itemTitle}</div>
      </div>
    </button>
  );
}
