import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import InputField from 'app/components/commonUI/InputField';
import InputDateField from 'app/components/commonUI/InputDateField';
import InputImage from 'app/components/commonUI/input-image';
import { checkLoginNameIsValid } from 'app/services/api';
import { toast } from 'react-toastify';
import Path from 'app/route/Path';

const validationErrMessages = {
  firstNameKanjiRequired: '名は必須です。',
  lastNameKanjiRequired: '姓は必須です。',
  firstNameKataRequired: 'メイは必須です。',
  lastNameKataRequired: 'セイは必須です。',
  dobRequired: '生年月日は必須です。',
  phoneRequired: '電話番号は必須です。',
  phoneValid: '電話番号は 10 桁または 11 桁で構成する必要があります',
  emailRequired: 'メールアドレスが必要です。',
  emailValid: '有効なメールアドレスを入力してください。',
  addressRequired: '住所は必須です。',
  postalCodeRequired: '郵便番号は必須です。',
  postalCodeValid: '郵便番号は7桁でなければなりません',
  loginNameRequired: 'ログイン名は必須です',
  passwordRequired: 'パスワードが必要',
};

function RegisterStaffForm(props) {
  const { onSubmitForm } = props;
  const [isConfirmMode, setIsConfirmMode] = useState(false);

  const validationSchema = Yup.object().shape({
    firstNameKanji: Yup.string().required(validationErrMessages.firstNameKanjiRequired),
    lastNameKanji: Yup.string().required(validationErrMessages.lastNameKanjiRequired),
    firstNameKata: Yup.string().required(validationErrMessages.firstNameKataRequired),
    lastNameKata: Yup.string().required(validationErrMessages.lastNameKataRequired),
    dob: Yup.object().nullable(),
    phone: Yup.string().matches(/^(\d{10}|\d{11})$/, validationErrMessages.phoneValid),
    email: Yup.string().email(validationErrMessages.emailValid),
    address: Yup.string(),
    postalCode: Yup.string().matches(/^(\d{7})$/, validationErrMessages.postalCodeValid),
    loginName: Yup.string()
      .required(validationErrMessages.loginNameRequired)
      .min(8, 'ユーザー名は8文字以上である必要があります')
      .max(100),
    password: Yup.string()
      .required(validationErrMessages.passwordRequired)
      .min(8, 'パスワードは8文字以上である必要があります')
      .matches(
        /^(?=.*[a-zA-Z]).{8,}$/,
        'パスワードには少なくとも 1 つの小文字のアルファベットが含まれている必要があります',
      )
      .matches(/^(?=.*[0-9]).{8,}$/, 'パスワードには少なくとも 1 つの数字が含まれている必要があります'),
  });

  const onSubmit = async (data) => {
    if (isConfirmMode) {
      onSubmitForm(data);
    } else {
      setIsConfirmMode(true);
    }
  };

  const { handleSubmit, errors, values, setFieldValue, touched, handleBlur } = useFormik({
    initialValues: {
      imageUrl: '',
      firstNameKanji: '',
      lastNameKanji: '',
      firstNameKata: '',
      lastNameKata: '',
      dob: null,
      phone: '',
      email: '',
      address: '',
      postalCode: '',
      loginName: '',
      password: '',
    },
    onSubmit,
    validationSchema,
  });

  const handleCheckLoginNameIsValid = (value) => {
    if (value && value.trim().length >= 8) {
      checkLoginNameIsValid(value)
        .then((res) => {
          if (res.data) {
            toast.success('ユーザー名を使用できます');
          } else {
            toast.error(
              'ログイン名が無効か重複ユーザー名が無効か重複しています。別のユーザー名を選択してくださいしています, ',
            );
          }
        })
        .catch(() => {
          toast.error(
            'ログイン名が無効か重複ユーザー名が無効か重複しています。別のユーザー名を選択してくださいしています, ',
          );
        });
    }
  };

  const handleSearchByPostalCode = async (postalCode) => {
    try {
      // Use Japan Post API to get address details
      const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`);
      const data = await response.json();

      if (data.status === 200 && data.results) {
        const addressData = data.results[0];

        // Set address fields
        setFieldValue('address', `${addressData.address1}${addressData.address2}${addressData.address3}`);
      } else {
        toast.error('郵便番号情報が見つかりません');
      }
    } catch (error) {
      console.error(error);
      toast.error('郵便番号情報が見つかりません');
    }
  };

  const handlePostalCodeChange = (newValue) => {
    // Remove any non-digit characters
    const cleanedValue = newValue.replace(/[^\d]/g, '');
    setFieldValue('postalCode', cleanedValue);

    // Auto-search when 7 digits are entered
    if (cleanedValue.length === 7) {
      handleSearchByPostalCode(cleanedValue);
    }
  };

  const handleBack = () => {
    setIsConfirmMode(false);
  };

  return (
    <div className="max-w-[960px] mx-auto bg-white px-4 sm:px-0">
      <InputImage
        label="アイコン"
        imageUrl={values.imageUrl}
        setImageUrl={(newValue) => setFieldValue('imageUrl', newValue)}
        disabled={isConfirmMode}
      />

      <div className="space-y-6 mt-4">
        <div className="grid grid-cols-2 gap-6">
          <div className="sm:w-[352px]">
            <p className="text-sm text-gray-600 mb-2">姓</p>
            <InputField
              inputProps={{ maxLength: 500 }}
              name="lastNameKanji"
              value={values.lastNameKanji}
              onChange={(newValue) => setFieldValue('lastNameKanji', newValue)}
              handleBlur={handleBlur}
              touched={touched.lastNameKanji}
              errors={errors.lastNameKanji}
              placeholder=""
              disabled={isConfirmMode}
            />
          </div>
          <div className="sm:w-[352px]">
            <p className="text-sm text-gray-600 mb-2">名</p>
            <InputField
              inputProps={{ maxLength: 500 }}
              name="firstNameKanji"
              value={values.firstNameKanji}
              onChange={(newValue) => setFieldValue('firstNameKanji', newValue)}
              handleBlur={handleBlur}
              touched={touched.firstNameKanji}
              errors={errors.firstNameKanji}
              placeholder=""
              disabled={isConfirmMode}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div className="sm:w-[352px]">
            <p className="text-sm text-gray-600 mb-2">セイ</p>
            <InputField
              name="lastNameKata"
              inputProps={{ maxLength: 500 }}
              value={values.lastNameKata}
              onChange={(newValue) => setFieldValue('lastNameKata', newValue)}
              handleBlur={handleBlur}
              touched={touched.lastNameKata}
              errors={errors.lastNameKata}
              placeholder=""
              disabled={isConfirmMode}
            />
          </div>
          <div className="sm:w-[352px]">
            <p className="text-sm text-gray-600 mb-2">メイ</p>
            <InputField
              name="firstNameKata"
              value={values.firstNameKata}
              inputProps={{ maxLength: 500 }}
              onChange={(newValue) => setFieldValue('firstNameKata', newValue)}
              handleBlur={handleBlur}
              touched={touched.firstNameKata}
              errors={errors.firstNameKata}
              placeholder=""
              disabled={isConfirmMode}
            />
          </div>
        </div>

        <div className="sm:w-[352px]">
          <p className="text-sm text-gray-600 mb-2">生年月日</p>
          <InputDateField
            id="dob"
            value={values.dob}
            setValue={(newValue) => setFieldValue('dob', newValue)}
            onBlur={handleBlur}
            touched={touched.dob}
            errors={errors.dob}
            placeholder=""
            className="w-full"
            disabled={isConfirmMode}
          />
        </div>

        <div className="sm:w-[352px]">
          <p className="text-sm text-gray-600 mb-2">電話番号</p>
          <InputField
            name="phone"
            value={values.phone}
            onChange={(newValue) => setFieldValue('phone', newValue)}
            handleBlur={handleBlur}
            touched={touched.phone}
            errors={errors.phone}
            placeholder=""
            disabled={isConfirmMode}
          />
        </div>

        <div className="sm:w-[352px]">
          <p className="text-sm text-gray-600 mb-2">メールアドレス</p>
          <InputField
            name="email"
            value={values.email}
            inputProps={{ maxLength: 500 }}
            onChange={(newValue) => setFieldValue('email', newValue)}
            handleBlur={handleBlur}
            touched={touched.email}
            errors={errors.email}
            placeholder=""
            disabled={isConfirmMode}
          />
        </div>

        <div className="sm:w-[352px]">
          <p className="text-sm text-gray-600 mb-2">郵便番号</p>
          <div className="flex items-center gap-4">
            <InputField
              name="postalCode"
              value={values.postalCode}
              onChange={(newValue) => handlePostalCodeChange(newValue)}
              handleBlur={handleBlur}
              inputProps={{ maxLength: 7 }}
              touched={touched.postalCode}
              errors={errors.postalCode}
              placeholder=""
              inputWrapClasses="w-[120px]"
              disabled={isConfirmMode}
            />
            <div className="px-4 py-2 text-sm text-[#666666]">住所を自動入力</div>
          </div>
        </div>

        <div className="sm:w-[352px]">
          <p className="text-sm text-gray-600 mb-2">住所</p>
          <InputField
            name="address"
            value={values.address}
            inputProps={{ maxLength: 500 }}
            onChange={(newValue) => setFieldValue('address', newValue)}
            handleBlur={handleBlur}
            touched={touched.address}
            errors={errors.address}
            placeholder=""
            disabled={isConfirmMode}
          />
        </div>

        <div className="sm:w-[352px]">
          <p className="text-sm text-gray-600 mb-2">ログイン名</p>
          <InputField
            name="loginName"
            value={values.loginName}
            inputProps={{ maxLength: 500 }}
            onChange={(newValue) => setFieldValue('loginName', newValue)}
            handleBlur={(e) => {
              handleBlur(e);
              handleCheckLoginNameIsValid(e.target.value);
            }}
            touched={touched.loginName}
            errors={errors.loginName}
            disabled={isConfirmMode}
          />
        </div>

        <div className="sm:w-[352px]">
          <p className="text-sm text-gray-600 mb-2">パスワード</p>
          <InputField
            name="password"
            value={values.password}
            inputProps={{ maxLength: 500 }}
            onChange={(newValue) => setFieldValue('password', newValue)}
            handleBlur={handleBlur}
            touched={touched.password}
            errors={errors.password}
            disabled={isConfirmMode}
          />
        </div>
      </div>

      <div className="flex justify-end gap-4 mt-8">
        {isConfirmMode ? (
          <>
            <button
              type="button"
              onClick={handleBack}
              className="px-4 py-3 text-sm border border-gray-300 rounded-xl hover:bg-gray-50 min-w-[120px] w-1/2 text-center"
            >
              戻る
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="px-4 py-3 text-sm text-white bg-[#00A0E9] rounded-xl hover:bg-[#0090D9] min-w-[120px] w-1/2"
            >
              登録
            </button>
          </>
        ) : (
          <>
            <Link
              to={Path.staffManagementList}
              className="px-4 py-3 text-sm border border-gray-300 rounded-xl hover:bg-gray-50 min-w-[120px] w-1/2 text-center"
            >
              キャンセル
            </Link>
            <button
              type="button"
              onClick={handleSubmit}
              className="px-4 py-3 text-sm text-white bg-[#00A0E9] rounded-xl hover:bg-[#0090D9] min-w-[120px] w-1/2"
            >
              確認画面へ
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default RegisterStaffForm;
