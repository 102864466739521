import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Path from '../../../../route/Path';
import DefaultLayout from '../../../../layouts/DefaultLayout';
import MainContentLayout from '../../../../layouts/MainContentLayout';
import LoadingProgress from '../../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../../components/commonUI/LoadInitError';
import { getScalpDataByBatchingGroup, batchDeliverToLab } from '../../../../services/api/1C';
import moment from 'moment';
import NoData from '../../../../components/commonUI/NoData';
import BatchDeliverModal from './BatchDeliverModal';
import NavigationBackBtn from '../../../../components/common/NavigationBackButton';
import { DELIVERY_STATUS_LABELS } from '../../../../constants';
import BatchDeliveryDetailItem from '../../../../components/ScalpDelivery/BatchDeliveryDetailItem';

function BatchDeliverScalpLabDetailPage() {
  const navigate = useNavigate();
  const customerNo = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [fetchHistoryOrderDetail, setFetchHistoryOrderDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const loadHistoryOrderDetail = () => {
    setFetchHistoryOrderDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getScalpDataByBatchingGroup(customerNo.group_id)
      .then((res) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadHistoryOrderDetail();
  }, []);

  const handleOnCloseClicked = () => {
    setOpenModal(false);
  };

  const handleOnSendClicked = () => {
    //loadHistoryOrderDetail()
    batchDeliverToLab(customerNo.group_id)
      .then((res) => {
        async function getAllExaminationDatas() {
          // const response = await Api.examinationDatas.all();
          // const items = response.data;
          // setExaminationDatas(items);
          // setExaminationDatasLoaded(true);
        }

        getAllExaminationDatas();
        //gotoNextStep();
        //setIsSubmitting(false);
        setOpenModal(false);
        alert('一括発送しました');
        navigate(Path.batchDeliverScalpLab);
      })
      .catch((e) => {
        //setIsSubmitting(false);
        alert(e);
      })
      .finally(() => {
        //setIsSubmitting(false);
      });
  };

  console.log('Ricky --fetchHistoryOrderDetail.data--', fetchHistoryOrderDetail.data);
  console.log('Ricky --openModal--', openModal);
  const isDeliveryDisabled = fetchHistoryOrderDetail.data?.salonDeliverStatus === 'pending' ? false : true;
  console.log('Ricky --isDeliveryDisabled--', isDeliveryDisabled);

  const RenderStatus = () => {
    const text = DELIVERY_STATUS_LABELS[fetchHistoryOrderDetail.data?.salonDeliverStatus || 'pending'];
    if (fetchHistoryOrderDetail.data?.salonDeliverStatus === 'pending') {
      return <p className="text-subtitle bg-gray-base w-fit px-3 py-1 rounded-full">{text}</p>;
    }
    return <p className="text-blue-btn-primary bg-[#DEF4FF] w-fit px-3 py-1 rounded-full">{text}</p>;
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>ラボ</p>
          </>
        }
      >
        <div className="w-full h-full">
          <NavigationBackBtn
            text={`管理番号：${fetchHistoryOrderDetail.data?.manageCode || ''}`}
            actionButton={<RenderStatus />}
          />
          <div className="px-4 md:px-0">
            <div className="flex justify-between items-center my-4">
              <div className="flex items-center gap-4 text-sm">
                <div>検体数： {fetchHistoryOrderDetail.data?.inspectionKitIds?.length || '0'}</div>
                <div>
                  発送日時：
                  {fetchHistoryOrderDetail.data?.salonDeliverStatus == 'pending'
                    ? '未発送'
                    : fetchHistoryOrderDetail.data?.salonDeliverDate
                      ? moment(fetchHistoryOrderDetail.data.salonDeliverDate).format('YYYY-MM-DD HH:mm')
                      : '-'}
                </div>
              </div>
              <div>
                <button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  type="button"
                  className={`button-size ${isDeliveryDisabled ? 'hidden' : 'bg-blue-btn-primary'} h-9 w-20 text-sm`}
                  disabled={isDeliveryDisabled}
                >
                  一括発送
                </button>
              </div>
            </div>
            <div className="w-full h-full">
              <div className="w-full h-full">
                {fetchHistoryOrderDetail.isLoading ? <LoadingProgress /> : null}

                {!fetchHistoryOrderDetail.isLoading &&
                fetchHistoryOrderDetail.error &&
                fetchHistoryOrderDetail.data?.inspectionKitId ? (
                  <LoadInitError error={fetchHistoryOrderDetail.error} />
                ) : null}
                {!fetchHistoryOrderDetail.isLoading && !fetchHistoryOrderDetail.error ? (
                  Array.isArray(fetchHistoryOrderDetail.data?.inspectionKitIds) &&
                  fetchHistoryOrderDetail.data?.inspectionKitIds.length > 0 ? (
                    <div>
                      {fetchHistoryOrderDetail.data?.inspectionKitIds.map((item, index) => (
                        <BatchDeliveryDetailItem key={index} data={item} />
                      ))}
                    </div>
                  ) : (
                    <NoData />
                  )
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>

          <BatchDeliverModal
            openDialog={openModal}
            handleOnCloseClicked={handleOnCloseClicked}
            content={'検体  ' + fetchHistoryOrderDetail.data?.inspectionKitIds.length + '  個'}
            handleOnSendClicked={handleOnSendClicked}
          ></BatchDeliverModal>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default BatchDeliverScalpLabDetailPage;
