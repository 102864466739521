import React from 'react';

export const StackIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.6599 10.3602C31.1388 10.1225 30.5727 9.99951 29.9999 9.99951C29.4272 9.99951 28.8611 10.1225 28.3399 10.3602L11.1999 18.1602C10.845 18.3167 10.5433 18.573 10.3315 18.8979C10.1196 19.2229 10.0068 19.6024 10.0068 19.9902C10.0068 20.3781 10.1196 20.7576 10.3315 21.0825C10.5433 21.4074 10.845 21.6637 11.1999 21.8202L28.3599 29.6402C28.8811 29.8779 29.4472 30.0009 30.0199 30.0009C30.5927 30.0009 31.1588 29.8779 31.6799 29.6402L48.8399 21.8402C49.1948 21.6837 49.4966 21.4274 49.7084 21.1025C49.9202 20.7776 50.033 20.3981 50.033 20.0102C50.033 19.6224 49.9202 19.2429 49.7084 18.9179C49.4966 18.593 49.1948 18.3367 48.8399 18.1802L31.6599 10.3602Z"
        stroke="#0096D4"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 30C9.99906 30.3825 10.1078 30.7573 10.3134 31.0799C10.519 31.4025 10.8128 31.6593 11.16 31.82L28.36 39.64C28.8784 39.8747 29.4409 39.9962 30.01 39.9962C30.5791 39.9962 31.1416 39.8747 31.66 39.64L48.82 31.84C49.174 31.6809 49.4741 31.4222 49.6836 31.0955C49.8931 30.7687 50.0031 30.3881 50 30"
        stroke="#0096D4"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 40C9.99906 40.3825 10.1078 40.7573 10.3134 41.0799C10.519 41.4025 10.8128 41.6593 11.16 41.82L28.36 49.64C28.8784 49.8747 29.4409 49.9962 30.01 49.9962C30.5791 49.9962 31.1416 49.8747 31.66 49.64L48.82 41.84C49.174 41.6809 49.4741 41.4222 49.6836 41.0955C49.8931 40.7687 50.0031 40.3881 50 40"
        stroke="#0096D4"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
