import Image from '../../../components/Image';
import React, { useState } from 'react';
import Path from '../../../route/Path';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { handleClassesToText } from '../InventoryManagementTopPage/helpers';
import InputField from '../../../components/commonUI/InputField';
import { MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import CommonPopoverMenu from '../../../components/commonUI/CommonPopoverMenu';
import { formatPrice } from '../../../utils';

const ThreeDotIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
        fill="black"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
        fill="black"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
        fill="black"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default function InventoryItemCheckStock(props) {
  const { item = {}, updateData, setUpdateData, handleDelete, index } = props;
  const { product } = item;
  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeInventory = (index, value) => {
    const newUpdateData = [...updateData];
    newUpdateData[index] = {
      ...item,
      inventory: Number(value),
      id: product._id,
    };

    setUpdateData(newUpdateData);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!product) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-3 rounded-xl p-3 bg-white text-xs shadow-[0_1px_10px_0_#006B8833] max-w-full">
      <div className="flex flex-row gap-[10px]">
        <div className="text-center w-[64px]">
          <Image src={product.imageUrl} alt={product.name} className="m-auto max-w-full" />
        </div>
        <div className="flex-1 flex flex-col gap-1 relative">
          <div>
            <div
              className="float-right"
              aria-controls={open ? 'inventory-item-menu' + product._id : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <ThreeDotIcon />
            </div>
            <div className="text-[#666666] text-xs">{`品番 ${product.productNumber}`}</div>
            <div className="text-[#666666] text-xs">
              {product.maker}/{product.series}
            </div>
          </div>
          <div className="line-clamp-2 text-[#121212] text-sm break-all">
            {product.name + (product.size ? `(${product.size})` : '')}
          </div>
        </div>
      </div>

      <hr className="h-px  border-0 bg-[#EDEDED]" />

      <div className="flex row flex-wrap text-xs">
        <div className="flex-1">
          <div>クラス：{handleClassesToText(product.classes, ' ')}</div>
          <div className="flex flex-row gap-2 mt-1">
            <div className="max-w-[48px]">税込価格</div>
            <div className="flex-1">
              <div className="truncate inline-block align-bottom max-w-[100px] sm:max-w-[300px] md:max-w-[450px]">
                {formatPrice(product.priceTaxInc, false)}
              </div>
              円
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <div>在庫数</div>
          <div>
            <InputField
              label=""
              type="text"
              value={
                typeof updateData[index]?.inventory !== 'undefined' ? updateData[index]?.inventory : item.inventory
              }
              onChange={(value) => handleChangeInventory(index, value.replace(/[^0-9.]/g, ''))}
              inputClasses=" !h-[37px] max-w-full !w-[60px]"
            />
          </div>
        </div>
      </div>

      <CommonPopoverMenu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            navigate({
              pathname: Path.inventoryManagementItemDetail(product._id),
              search: createSearchParams({ from: location.pathname + location.search }).toString(),
            });
          }}
        >
          <span className="mr-3">
            <FontAwesomeIcon icon={faCircleInfo} />
          </span>
          <span>詳細</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDelete(item);
            setAnchorEl(null);
          }}
          disableRipple
        >
          <span className="text-red-500 mr-3">
            <FontAwesomeIcon icon={faTrashCan} />
          </span>
          <span>消去</span>
        </MenuItem>
      </CommonPopoverMenu>
    </div>
  );
}
