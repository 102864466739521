const BottomButtons = ({ handleNextSection, handleSubmit, step }) => {
  const buttonClass = 'sm:w-[200px] w-full px-6 py-2.5 text-sm font-medium rounded-lg transition-colors duration-200';
  const primaryButtonClass = `${buttonClass} bg-[#00A0E9] text-white hover:bg-[#0090D9]`;
  const secondaryButtonClass = `${buttonClass} bg-white text-gray-700 border border-gray-300 hover:border-gray-400 mr-[16px]`;

  if (Number(step) === 8) {
    return (
      <div className="flex gap-4 justify-center items-center">
        <button onClick={handleSubmit} className={primaryButtonClass}>
          登録
        </button>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center">
      <button onClick={handleSubmit} className={secondaryButtonClass}>
        ここまでを登録
      </button>
      <button onClick={handleNextSection} className={primaryButtonClass}>
        次へすすむ
      </button>
    </div>
  );
};

export default BottomButtons;
