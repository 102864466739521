import * as Yup from "yup";

const validationErrMessages = {
  productNumberRequired: "品番は必須です。",
  makerRequired: "メーカーは必須です。",
  seriesRequired: "シリーズは必須です。",
  nameRequired: "品名は必須です。",
  priceRequired: "価格は必須です。",
  priceTaxIncRequired: "税込価格は必須です。",
  priceTaxExcRequired: "未税価格は必須です。",
  inventoryRequired: "在庫数は必須です。",
  categoryRequired: "カテゴリは必須です。",
  productScopeRequired: "専売品は必須です。",
};

const validationSchema = Yup.object().shape({
  productNumber: Yup.string().required(
    validationErrMessages.productNumberRequired
  ),
  maker: Yup.string().required(validationErrMessages.makerRequired),
  series: Yup.string().required(validationErrMessages.seriesRequired),
  name: Yup.string().required(validationErrMessages.nameRequired),
  price: Yup.string().notRequired(),
  priceTaxInc: Yup.string().notRequired(),
  priceTaxExc: Yup.string().notRequired(),
  inventory: Yup.string().required(validationErrMessages.inventoryRequired),
  productScope: Yup.string().required(
    validationErrMessages.productScopeRequired
  ),
  category: Yup.string().required(validationErrMessages.categoryRequired),
});

export default validationSchema;
