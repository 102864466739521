import React from 'react';

function MainContentLayout(props) {
  const { children, containerClass = '', pageTitle, contentClass, style = {} } = props;

  return (
    <div className={`${containerClass} py-4 lg:py-6 w-full h-full overflow-auto`}>
      <div
        className={`${contentClass} bg-white md:py-6 md:px-10 md:max-w-5xl m-auto md:shadow-[0px_1px_10px_0px_#006B8833]`}
        style={style}
      >
        {/* Chen: Comment out unused styling */}
        {/* <div className="absolute z-10 top-2 sm:top-0 sm:left-0 lg:top-6 lg:left-6 w-16 h-12 sm:w-16 sm:h-16 lg:w-20 lg:h-20">
          <button className="rounded-md sm:rounded-full overflow-hidden relative shrink-0 !border-[#666FC8] w-full h-full">
            <div className="w-full h-full rounded-md sm:rounded-full !border-[#666FC8] cursor-default border-[2px] lg:border-[5px]"></div>
            <div className="absolute inset-0 rounded-md sm:rounded-full overflow-hidden text-white  ">
              <div className="w-full h-[20%] sm:h-[36%] bg-transparent"></div>
              <div className="w-full h-[80%] sm:h-[64%] flex flex-col items-center justify-center !bg-[#666FC8] text-xs lg:text-base font-semibold sm:pb-2">
                {pageTitle}
              </div>
            </div>
          </button>
        </div> */}

        {children}
      </div>
    </div>
  );
}

export default MainContentLayout;
