import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Path from '../../../../route/Path';
import moment from 'moment';
import GA, { EVENT_NAMES } from '../../../../services/googleAnalytics';
import { convertClusterToText, getAnalyseStatus } from '../HistoryOfOrderDetailByKitIdPage/helpers';

export default function TableScalpDataList(props) {
  const { allHistoryOrdering } = props;

  if (!allHistoryOrdering) {
    return <></>;
  }
  return (
    <div>
      {allHistoryOrdering.map((item, index) => (
        <ScalpDataItem item={item} key={index} />
      ))}
    </div>
  );
}

const ScalpDataItem = (props) => {
  const navigate = useNavigate();
  const { item } = props;

  const scalpData = useMemo(() => {
    return item?.scalpData ?? {};
  }, [item?.scalpData]);

  const analyseStatus = useMemo(() => {
    return getAnalyseStatus(scalpData?.analyseStatus, scalpData?.factorCluster);
  }, [scalpData?.analyseStatus, scalpData?.factorCluster]);

  const factorCluster = useMemo(() => {
    if (scalpData?.analyseStatus !== 'analyse_completed') {
      return '';
    }

    return convertClusterToText(scalpData?.factorCluster);
  }, [scalpData?.factorCluster, scalpData?.analyseStatus]);

  const hasDetailPage = useMemo(() => {
    return scalpData?.analyseStatus === 'analyse_completed';
  }, [scalpData?.analyseStatus]);

  const goToCollect = () => {
    const { customerNo, inspectionKitId } = scalpData;
    GA.tracking(EVENT_NAMES.CUSTOMER_SCALP_LIST.CLICK_ITEM, {
      customerNo,
      inspectionKitId,
    });
    navigate(Path.historyOfOrderingDetailByKitIdCollectLocation(customerNo, inspectionKitId));
  };

  const goToDetail = () => {
    if (!hasDetailPage) {
      return;
    }
    const { customerNo, inspectionKitId } = scalpData;
    GA.tracking(EVENT_NAMES.CUSTOMER_SCALP_LIST.CLICK_ITEM, {
      customerNo,
      inspectionKitId,
    });
    navigate(Path.historyOfOrderingDetailByKitId(customerNo, inspectionKitId));
  };

  if (!item) {
    return <></>;
  }

  return (
    <div className="w-full h-full relative shadow-center rounded-[12px] p-4 mt-4  flex flex-col gap-2">
      <div className="flex gap-2 items-center">
        <div className="bg-gray-base rounded-[34px] text-subtitle px-3 py-1 text-sm w-full max-w-[81px]">検体No</div>
        <div className="text-sm text-[#252525] w-full max-w-[40px] sm:max-w-full break-all line-clamp-2">
          {scalpData.inspectionKitId || '-'}
        </div>
        <div className="ml-auto text-xs text-subtitle w-full text-right">
          登録日時：
          {scalpData.createdAt !== undefined ? moment(scalpData.createdAt).format('YYYY-MM-DD HH:mm') : '-'}
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <div className="mr-auto text-md text-[#252525]">
          {analyseStatus} {factorCluster}
        </div>
        <div className="ml-auto flex gap-2">
          <button type="button" onClick={goToCollect} className="button-size-small bg-blue-btn-primary">
            採取箇所
          </button>
          <button
            type="button"
            onClick={goToDetail}
            className={`button-size-small ${hasDetailPage ? 'bg-blue-btn-primary' : 'bg-gray-btn-secondary'}`}
            disabled={!hasDetailPage}
          >
            詳細
          </button>
        </div>
      </div>
    </div>
  );
};
