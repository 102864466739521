import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Pagination as MPagination, PaginationItem, useMediaQuery } from '@mui/material';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

function Pagination(props) {
  const { totalPage, onPageChange, clasName = '', ...paginationProps } = props;
  const [param] = useSearchParams();
  const page = parseInt(param.get('page') || '1', 10);
  const navigate = useNavigate();
  const sp = useMediaQuery('(max-width:500px)');

  const handleChange = (e, page) => {
    if (onPageChange) {
      onPageChange(page);
    } else {
      navigate({
        pathname: '',
        search: createSearchParams({
          ...Object.fromEntries(param.entries()),
          page: page,
        }).toString(),
      });
    }
  };

  return (
    <MPagination
      className={`mt-4 px-4 md:px-10 flex flex-wrap justify-center gap-y-2 ${clasName}`}
      page={page}
      count={totalPage}
      onChange={handleChange}
      shape="rounded"
      variant="outlined"
      sx={{
        '.MuiPagination-ul': {
          rowGap: '8px',
        },
      }}
      size={sp ? 'small' : 'medium'}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: PrevButton,
            next: NextButton,
          }}
          sx={{
            '&.Mui-selected,&.Mui-selected:hover,&:hover': {
              backgroundColor: '#00B3ED',
              borderColor: '#00B3ED',
              color: '#FFF',
            },
          }}
          {...item}
        />
      )}
      {...paginationProps}
    />
  );
}

const PrevButton = () => {
  return (
    <div>
      <NavigateBefore />
      <span>前ヘ</span>
    </div>
  );
};

const NextButton = () => {
  return (
    <div>
      <span>次ヘ</span>
      <NavigateNext />
    </div>
  );
};

export default Pagination;
