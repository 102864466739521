import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import NavigationBackBtn from 'app/components/common/NavigationBackButton';
import { getAllSalonStaffs } from 'app/services/api';
import LoadingProgress from 'app/components/commonUI/LoadingProgress';
import { toast } from 'react-toastify';
import ItemStaffCard from './components/ItemStaffCard';
import GA, { EVENT_NAMES } from 'app/services/googleAnalytics';

function StaffManagementListPage() {
  const navigate = useNavigate();
  const [fetchStaffState, setFetchStaffState] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadStaffList = async () => {
    setLoading(true);
    try {
      const { data } = await getAllSalonStaffs();
      setFetchStaffState(data);
    } catch (error) {
      toast.error('スタッフ情報の取得に失敗しました');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadStaffList();
  }, []);

  const handleNavigateToRegister = () => {
    GA.tracking(EVENT_NAMES.STAFF_LIST.CLICK_CREATE_NEW_STAFF);
    navigate(Path.staffManagementRegister);
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="absolute inset-0 flex items-center justify-center">
          <LoadingProgress />
        </div>
      );
    }

    return (
      <div className="space-y-4">
        {fetchStaffState?.map((staff) => (
          <ItemStaffCard key={staff.id} staff={staff} />
        ))}
      </div>
    );
  };

  return (
    <DefaultLayout>
      <MainContentLayout contentClass="lg:min-h-full">
        <div className="w-full h-16 lg:h-12 flex items-center justify-between inset-x-0 top-0">
          <h2 className="text-base lg:text-xl font-semibold">
            <NavigationBackBtn text="スタッフ情報" url={Path.systemManagement} />
          </h2>
          <button
            onClick={handleNavigateToRegister}
            className="px-[12px] py-[8px] text-white bg-blue-btn-primary rounded-lg hover:bg-blue-60 text-sm lg:text-base mr-3 lg:mr-0"
          >
            スタッフを登録
          </button>
        </div>

        <div className="w-full h-full pt-6 sm:py-6 px-3 lg:px-0 relative">
          <div className="w-full max-w-[960px] mx-auto">{renderContent()}</div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default StaffManagementListPage;
