import DefaultLayout from '../../../layouts/DefaultLayout';
import BackBtn from '../../../components/common/BackButton';

const ActOfCommercialTransactions = () => {
  return (
    <DefaultLayout containerMargin="mb-12">
      <div className="flex justify-center text-justify">
        <div className="w-full mx-3 sm:mx-16 sm:px-6 px-4 h-[calc(100vh-100px)] sm:h-[calc(100vh-120px)] overflow-auto">
          <div className="flex flex-col px-2 items-center">
            <h1 className="my-4 text-center font-semibold">特定商取引法</h1>

            <div className="grid grid-cols-[50px_1fr] gap-y-2">
              <div className="mb-1 font-semibold">１．</div>
              <div>
                <div className="mb-1 font-semibold">販売業者名</div>
                <div>株式会社頭皮labo</div>
              </div>
              <div className="mb-1 font-semibold">２．</div>
              <div>
                <div className="mb-1 font-semibold">代表責任者</div>
                <div>代表者取締役　西尾義弘</div>
              </div>
              <div className="mb-1 font-semibold">３．</div>
              <div>
                <div className="mb-1 font-semibold">所在地</div>
                <div>〒530-0044 大阪府大阪市北区東天満2-10-31第九田渕ビル305</div>
              </div>
              <div className="mb-1 font-semibold">４．</div>
              <div>
                <div className="mb-1 font-semibold">電話番号</div>
                <div>06-7777-1819</div>
              </div>
              <div className="mb-1 font-semibold">５．</div>
              <div>
                <div className="mb-1 font-semibold">受付時間</div>
                <div>10:00-17:00　土日祝日を除く</div>
              </div>
              <div className="mb-1 font-semibold">６．</div>
              <div>
                <div className="mb-1 font-semibold">メールアドレス</div>
                <a href="mailto:inquiry@touhilab.co.jp">inquiry@touhilab.co.jp</a>
              </div>
              <div className="mb-1 font-semibold">７．</div>
              <div>
                <div className="mb-1 font-semibold">サイトＵＲＬ</div>
                <a href="https://salon.touhilab-hub.com/">https://salon.touhilab-hub.com/</a>
              </div>
              <div className="mb-1 font-semibold">８．</div>
              <div>
                <div className="mb-1 font-semibold">商品代金以外に必要な料金</div>
                <div>配送料（宅急便：660円）</div>
              </div>
              <div className="mb-1 font-semibold">９．</div>
              <div>
                <div className="mb-1 font-semibold">支払方法</div>
                <div>クレジットカード決済、銀行振込</div>
              </div>
              <div className="mb-1 font-semibold">１０．</div>
              <div>
                <div className="mb-1 font-semibold">支払時期</div>
                <div>銀行振込：注文後20日以内にお支払ください</div>
              </div>
              <div className="mb-1 font-semibold">１１．</div>
              <div>
                <div className="mb-1 font-semibold">お申込みの有効期限</div>
                <div>商品申込から20日以内にご入金を確認できなかった場合、キャンセルされたものとみなします</div>
              </div>
              <div className="mb-1 font-semibold">１２．</div>
              <div>
                <div className="mb-1 font-semibold">商品の引き渡し時期</div>
                <div>入金確認後、5営業日以内に発送致します</div>
              </div>
              <div className="mb-1 font-semibold">１３．</div>
              <div>
                <div className="mb-1 font-semibold">返品、交換、キャンセル等</div>
                <div>
                  <div className="grid grid-cols-[20px_1fr] gap-y-2">
                    <div>・</div>
                    <div>
                      <div>返品・交換・キャンセル可否条件</div>
                      <div className="grid grid-cols-[20px_1fr] gap-y-2">
                        <div>-</div>
                        <div>商品に欠陥がない場合、商品発送後の返品、返却等をお請けしかねます</div>
                      </div>
                      <div className="grid grid-cols-[20px_1fr] gap-y-2">
                        <div>-</div>
                        <div>商品が不良の場合は、良品と交換いたします</div>
                      </div>
                    </div>
                    <div>・</div>
                    <div>
                      <div>返品期限</div>
                      <div className="grid grid-cols-[20px_1fr] gap-y-2">
                        <div>-</div>
                        <div>ご購入後、7日以内にご連絡があった場合は返金可能となります</div>
                      </div>
                    </div>
                    <div>・</div>
                    <div>
                      <div>返品送料</div>
                      <div className="grid grid-cols-[20px_1fr] gap-y-2">
                        <div>-</div>
                        <div>不良品や欠品については当社負担、それ以外はお客様負担となります</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-1 font-semibold">１４．</div>

              <div>
                <div className="mb-1 font-semibold">販売価格</div>
                <div>検査キット１箱：30,000円（10検体分、税抜）</div>
                <div>スターターキット：54,000円（検査キット１箱、オイル3本）</div>
                <div>送料：商品代に含む</div>
              </div>

              <div className="h-12"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-4 right-4">
        <BackBtn classButton="h-7 sm:h-8" />
      </div>
    </DefaultLayout>
  );
};

export default ActOfCommercialTransactions;
