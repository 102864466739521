import React, { useEffect, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Path from '../../../route/Path';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import CommonDialog from '../../../components/CommonDialog';
import NoData from '../../../components/commonUI/NoData';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../components/commonUI/LoadInitError';
import { deleteInventory, getInventoryAll, updateInventory, updateProduct } from '../../../services/api/18';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import InventoryItemCheckStock from './InventoryItemCheckStock';
import { toast } from 'react-toastify';
import ConfirmDeleteDialog from '../../../components/CommonDialog/ConfirmDeleteDialog';

function InventoryManagementCheckStockPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [updateData, setUpdateData] = useState([]);
  const [open, setOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [name] = useState('');
  const [fetchInventory, setFetchInventory] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isShowUpdateButton, setIsShowUpdateButton] = useState(false);
  const [productItem, setProductItem] = useState(null);
  const [searchParams] = useSearchParams();

  const category = searchParams.get('category');
  const maker = searchParams.get('maker');

  useEffect(() => {
    setIsShowUpdateButton(updateData.length > 0);
  }, [updateData]);

  const loadInventory = () => {
    setFetchInventory({
      isLoading: true,
      data: null,
      error: null,
    });

    getInventoryAll(name, maker, '', '', '', '', category)
      .then((res) => {
        setFetchInventory({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInventory({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const handleChangeInventory = (index, value) => {
    const newUpdateData = [...updateData];
    newUpdateData[index] = {
      ...newUpdateData[index],
      inventory: Number(value),
    };
    setUpdateData(newUpdateData);
  };

  const handleUpdateInventory = () => {
    setOpen(true);
  };

  const handleUpdate = () => {
    let action = [];
    updateData.forEach((data) => {
      if (data?.inventory) {
        action.push(updateInventory(data.id, data.inventory));
      }
      if (data?.description) {
        action.push(
          updateProduct(data.id, {
            description: data.description,
          }),
        );
      }
    });
    Promise.all(action)
      .catch((e) => console.log(e))
      .finally(() => {
        setOpen(false);
        setIsShowUpdateButton(false);
        setLoad(!load);
        setUpdateData([]);
      });
  };

  const handleShowConfirmDelete = (productItem) => {
    setShowConfirmDelete(true);
    setProductItem(productItem);
  };

  const handleCloseConfirmDelete = (productItem) => {
    setShowConfirmDelete(false);
    setProductItem(null);
  };

  const handleDelete = () => {
    const productId = productItem?.product?._id;
    if (!productId) {
      return;
    }
    deleteInventory(productId)
      .then((res) => {
        // remove product from updateData if it is deleted
        const index = updateData.findIndex((data) => data.product._id === productId);
        if (index >= 0) {
          const newUpdateData = [...updateData];
          newUpdateData.splice(index, 1);
          setUpdateData(newUpdateData);
        }

        toast.success('削除成功');
        setLoad(!load);
        setShowConfirmDelete(false);
        setProductItem(null);
      })
      .catch((error) => {
        toast.error('削除が成功しない');
      });
  };

  useEffect(() => loadInventory(), [load]);

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-full pb-6 md:pb-8  relative">
          <div className="w-full flex items-center justify-start inset-x-0 top-0 pr-4 md:pr-0">
            <NavigationBackBtn url={Path.inventoryManagement} text={'商品在庫管理'} />
            <div className="flex flex-row gap-2 ml-auto">
              <button
                onClick={() =>
                  navigate({
                    pathname: Path.inventoryManagementRegister,
                    search: createSearchParams({ from: location.pathname + location.search }).toString(),
                  })
                }
                className="button-size-small bg-blue-btn-primary"
              >
                商品登録
              </button>
            </div>
          </div>

          <div className="mt-6 w-full p-4 md:p-0">
            {fetchInventory.isLoading && <LoadingProgress />}

            {!fetchInventory.isLoading && fetchInventory.error && <LoadInitError error={fetchInventory.error} />}

            {!fetchInventory.isLoading && !fetchInventory.error && (
              <>
                {Array.isArray(fetchInventory.data) && fetchInventory.data.length > 0 ? (
                  <div className="grid  gap-4 ">
                    {fetchInventory.data.map((item, index) => (
                      <InventoryItemCheckStock
                        item={item}
                        key={item.product._id || index}
                        index={index}
                        updateData={updateData}
                        setUpdateData={setUpdateData}
                        handleDelete={handleShowConfirmDelete}
                      />
                    ))}
                  </div>
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div>

          {isShowUpdateButton && (
            <div className="w-full text-center mt-4 flex flex-row gap-1 items-center justify-center text-white text-base font-semibold">
              <button
                onClick={() => navigate(Path.inventoryManagement)}
                className="button-size bg-gray-btn-secondary w-auto mr-4"
              >
                戻る
              </button>
              <button
                onClick={handleUpdateInventory}
                className="button-size bg-blue-btn-primary w-auto"
                // disabled={open}
              >
                登録
              </button>
            </div>
          )}
        </div>

        <CommonDialog
          open={open}
          handleClose={() => setOpen(false)}
          actions={
            <div className="w-full flex gap-6 items-center justify-end text-white text-base font-semibold">
              <button onClick={() => setOpen(false)} type="button" className="button-size bg-gray-btn-secondary">
                在庫管理へ戻る
              </button>
              <button onClick={handleUpdate} type="button" className="button-size bg-blue-btn-primary">
                終了
              </button>
            </div>
          }
        >
          <div className="w-full h-full flex justify-center items-center text-xl font-bold">在庫数を登録しました</div>
        </CommonDialog>

        <ConfirmDeleteDialog
          key={productItem?._id}
          open={showConfirmDelete}
          handleClose={handleCloseConfirmDelete}
          confirmTitle="在庫情報を削除しますか？"
          confirmMessage=""
          confirmTitleAgain="本当に在庫情報を削除しますか?"
          confirmMessageAgain=""
          deleteCompleteTitle="在庫情報を削除しました"
          deleteCompleteBtnLabel="完了"
          deleteCompleteAction={handleCloseConfirmDelete}
          deleteAction={handleDelete}
          confirmCancleLabel="キャンセル"
          confirmCancleAgainLabel="キャンセル"
          confirmLabel="削除する"
          confirmAgainLabel="削除する"
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default InventoryManagementCheckStockPage;
