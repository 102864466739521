import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ children, onChange, appendContainerSx = null, parentValue }) {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (parentValue >= 0) {
      setValue(parentValue);
    }
  }, [parentValue]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#00B3ED',
            },
            '& .MuiTabs-flexContainer': appendContainerSx || {
              [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'center',
              },
            },
          }}
        >
          {React.Children.map(children, (child, index) => (
            <Tab
              sx={{
                color: '#0096D4',
                '&.Mui-selected': {
                  color: '#252525',
                },
              }}
              label={child.props.label}
              onClick={() => onChange?.(child.props.value || child.props.label)}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {React.Children.map(children, (child, index) => React.cloneElement(child, { value, index }))}
    </Box>
  );
}
