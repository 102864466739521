import moment from "moment";
import "moment-timezone";
import client from "../client";
import {
  defaultDateFormat,
  defaultDateTimeFormat,
  defaultTimeFormat,
} from "../../../constants";

const customerBaseInfoAdapter = (rawData) => {
  const dob = moment(rawData.dob)
    .tz("Asia/Tokyo")
    .format(defaultDateFormat);
  const formattedDob = moment(dob, defaultDateFormat, true).isValid()
    ? dob
    : null;
  return {
    id: rawData._id,
    lastNameKata: rawData.lastNameKata,
    firstNameKata: rawData.firstNameKata,
    lastNameKanji: rawData.lastNameKanji,
    firstNameKanji: rawData.firstNameKanji,
    dob: formattedDob,
    gender: rawData.gender,
    phone1: rawData.phone1,
    phone2: rawData.phone2,
    email: rawData.email,
    postalCode: rawData.postalCode,
    address: rawData.address,
    customerNo: rawData.customerNo,
    prefecture: rawData.prefecture,
    city: rawData.city,
    town: rawData.town,
    salonCustomerId: rawData?.salonCustomerIds?.[0] || "",
    isConfirmAgreement:
      rawData?.salonCustomers?.[0].isConfirmAgreement,
  };
};

const counselingSheetAdapter = (rawData) => ({
  id: rawData._id,
  counsellingDate: rawData.counsellingDate,
  memo: rawData.memo,
});

const reservationAdapter = (rawData) => ({
  id: rawData._id,
  visitDate: moment(rawData.visitDate)
    .tz("Asia/Tokyo")
    .format(defaultDateFormat),
  startTime: moment(rawData.startTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  finishTime: moment(rawData.finishTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),

  customer: rawData.customer
    ? {
        ...rawData.customer,
        id: rawData.customer._id,
      }
    : {},

  staff: rawData.staff
    ? {
        ...rawData.staff,
        id: rawData.staff._id,
      }
    : null,

  reservationMenus: Array.isArray(rawData.reservationMenus)
    ? rawData.reservationMenus.map((visitmenu) => ({
        id: visitmenu._id,
        menuType: visitmenu.menuType,
        name: visitmenu.name,
        durationString: visitmenu.durationString,
        durationNumber: visitmenu.durationNumber,
        priceTaxExc: visitmenu.priceTaxExc,
        priceTaxInc: visitmenu.priceTaxInc,
      }))
    : [],

  visit: rawData.visit ? { id: rawData.visit._id } : null,
  customerNo: rawData.customerNo,
});

const avaiableStaffAndStartTimeAdapter = (rawData) => ({
  visitDate: moment(rawData.startTime)
    .tz("Asia/Tokyo")
    .format(defaultDateFormat),
  startTime: moment(rawData.startTime)
    .tz("Asia/Tokyo")
    .format(defaultTimeFormat),
  staff: rawData.staff
    ? {
        ...rawData.staff,
        id: rawData.staff._id,
      }
    : null,
});

const convertReservationToTokyoTimeZone = (reservation) => ({
  ...reservation,
  visitDate: moment
    .tz(reservation.visitDate, defaultDateFormat, true, "Asia/Tokyo")
    .toISOString(),
  startTime: moment
    .tz(
      `${reservation.visitDate} ${reservation.startTime}`,
      defaultDateTimeFormat,
      true,
      "Asia/Tokyo"
    )
    .toISOString(),
  finishTime: moment
    .tz(
      `${reservation.visitDate} ${reservation.finishTime}`,
      defaultDateTimeFormat,
      true,
      "Asia/Tokyo"
    )
    .toISOString(),
});

// const fakeResponseDataApi = (responseData) => {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve({
//         data: responseData,
//       });
//     }, 1000);
//   });
// };

// const fake_data_customer_info = {
//   id: "6942d1a5-1ea7-4b86-8105-0a45f1a252122",
//   lastNameKata: "セイ",
//   firstNameKata: "メイ",
//   lastNameKanji: "姓",
//   firstNameKanji: "名",
//   dob: "1990-09-18",
//   phone1: "0123456666",
//   phone2: "0123456789",
//   email: "test@gmail.com",
//   postalCode: "111-0000",
//   address: "住所",
//   counseling: [],
// };

export const checkCustomerQrCode = (qrCodeValue) => {
  const today = moment().tz("Asia/Tokyo").format(defaultDateFormat);

  return client
    .get("/salon/reservation/all", {
      params: { visitDate: today, customerId: qrCodeValue },
    })
    .then((res) => {
      if (res.data.success) {
        const reservationList = res.data.result;

        return {
          data: {
            customerId: qrCodeValue,
            reservationId: reservationList[0]?._id,
          },
        };
      }
      return {
        data: {
          customerId: "",
          reservationId: "",
        },
      };
    });
};

export const createNewCustomer = (newCustomerData) => {
  return client
    .post("/customer/customer/create", newCustomerData)
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        return {
          data: customerBaseInfoAdapter(rawData),
        };
      }
      return {
        data: null,
      };
    });
};

export const getDetailCustomer = (customerId) => {
  return client
    .get("/customer/customer/get", {
      params: { id: customerId },
    })
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        return {
          data: customerBaseInfoAdapter(rawData),
        };
      }
      return { data: null };
    });
};

export const getDetailReservation = (reservationd) => {
  return client
    .get("/salon/reservation/get", {
      params: { id: reservationd },
    })
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        return {
          data: reservationAdapter(rawData),
        };
      }
      return { data: null };
    });
};

export const getCounselingHistory = (customerId) => {
  return client
    .get("/counselling/get-all-counseling", {
      params: { customerId: customerId },
    })
    .then((res) => {
      if (res.data.success) {
        const rawDataList = res.data.result;
        if (Array.isArray(rawDataList))
          rawDataList.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() -
              new Date(a.createdAt).getTime()
          );
        return {
          data: rawDataList.map((rawData) =>
            counselingSheetAdapter(rawData)
          ),
        };
      }
      return { data: [] };
    });
};

export const getCustomerInfoAndCounselingHistory = async (
  customerId
) => {
  const getDetailCustomerPromise = getDetailCustomer(customerId);
  const getCounselingHistoryPromise =
    getCounselingHistory(customerId);

  const res = await Promise.all([
    getDetailCustomerPromise,
    getCounselingHistoryPromise,
  ]);

  const customerInfo = res[0].data;
  const counselingHistory = res[1].data;

  return {
    customerInfo: customerInfo,
    counselingHistory: counselingHistory,
  };
};

export const getAvailableTimeAndStaffToReservation = (duration) => {
  return client
    .get(
      `/salon/salon-staff/find-avaiable-staff-and-time-to-reveration?duration=${duration}`
    )
    .then((res) => {
      if (res.data.success && res.data.result) {
        const rawDataData = res.data.result;
        if (rawDataData && rawDataData.startTime && rawDataData.staff)
          return {
            data: avaiableStaffAndStartTimeAdapter(rawDataData),
          };
      }
      return { data: null };
    });
};

export const createNewReservationAndVisit = ({
  reservation,
  counsellings,
}) => {
  console.log("createNewReservationAndVisit: ");
  console.log("reservation: ", reservation);
  console.log("counsellings: ", counsellings);

  return client
    .post("/customer/customer/create-visit-for-customer", {
      reservation: convertReservationToTokyoTimeZone(reservation),
      counsellings,
    })
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        return {
          data: {
            id:
              rawData._id || "6942d1a5-1ea7-4b86-8105-0a45f1a252122", // TODO-T:
          },
        };
      }
      return {
        data: null,
      };
    });
};

export const startUseServices = (reservationId) => {
  return client
    .get("/salon/visit/confirm-visit-by-reservation-id", {
      params: { id: reservationId },
    })
    .then((res) => {
      if (res.data.success) {
        const rawData = res.data.result;
        return {
          data: { id: rawData._id },
        };
      }
      return { data: null };
    });
};
