import React, { useState } from 'react';
import LoadingBackdrop from '../commonUI/LoadingBackdrop';
import Dialog from '@mui/material/Dialog';
import Fade from '@mui/material/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const deleteSteps = {
  CONFIRM: 'CONFIRM',
  CONFIRM_AGAIN: 'CONFIRM_AGAIN',
  DELETING: 'DELETING',
  DELETE_COMPLETE: 'DELETE_COMPLETE',
};

function ConfirmDeleteDialog(props) {
  const {
    open,
    handleClose,
    confirmTitle,
    confirmMessage,
    confirmTitleAgain,
    confirmMessageAgain,
    deleteCompleteTitle,
    deleteCompleteBtnLabel,
    deleteCompleteAction,
    deleteAction,
    handleDeleteSuccess,
    handleDeleteError,
  } = props;

  const [showStep, setShowStep] = useState(deleteSteps.CONFIRM);

  const executeAction = async () => {
    setShowStep(deleteSteps.DELETING);

    try {
      const res = await deleteAction();
      if (handleDeleteSuccess) handleDeleteSuccess(res);

      setShowStep(deleteSteps.DELETE_COMPLETE);
    } catch (error) {
      if (handleDeleteError) handleDeleteError(error);

      setShowStep(deleteSteps.CONFIRM_AGAIN);
    }
  };

  const handleConfirm = () => {
    if (showStep === deleteSteps.CONFIRM) {
      setShowStep(deleteSteps.CONFIRM_AGAIN);
    } else if (showStep === deleteSteps.CONFIRM_AGAIN) {
      executeAction();
    }
  };

  const handleCloseDialog = () => {
    handleClose();

    setShowStep(deleteSteps.CONFIRM);
  };

  const handleBack = () => {
    if (showStep === deleteSteps.CONFIRM_AGAIN) {
      setShowStep(deleteSteps.CONFIRM);
    } else {
      handleCloseDialog();
    }
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={() => {
          if (!deleteSteps.DELETE_COMPLETE) {
            handleClose();
          }
        }}
        aria-labelledby="responsive-dialog-title"
        classes={{ root: 'bg-transparent' }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            paddingTop: '40px',
          },
        }}
        TransitionComponent={Fade}
      >
        <div className="w-full flex flex-col items-center pt-16 pb-8 px-8 bg-white rounded-3xl relative lg:min-w-[360px]">
          {showStep === deleteSteps.DELETE_COMPLETE ? null : (
            <div className="absolute w-20 h-20 -top-10 left-1/2 -translate-x-1/2 rounded-full overflow-hidden bg-red-500 flex items-center justify-center">
              <FontAwesomeIcon icon={faTriangleExclamation} size="3x" color="white" />
            </div>
          )}

          {showStep === deleteSteps.DELETE_COMPLETE ? (
            <>
              <h3 className="text-xl font-semibold mb-14 text-center">{deleteCompleteTitle}</h3>

              <div className="w-full flex items-center justify-center text-white text-base font-semibold gap-4">
                <button onClick={deleteCompleteAction} type="button" className="button-size bg-gray-btn-secondary">
                  {deleteCompleteBtnLabel}
                </button>
              </div>
            </>
          ) : (
            <>
              <h3 className="text-xl font-semibold mb-3 text-center">
                {showStep === deleteSteps.CONFIRM ? confirmTitle : confirmTitleAgain}
              </h3>

              <p className="text-lg font-base mb-8">
                {showStep === deleteSteps.CONFIRM ? confirmMessage : confirmMessageAgain}
              </p>

              <div className="w-full flex items-center justify-center text-white text-base font-semibold gap-4">
                <button onClick={handleBack} type="button" className="button-size bg-white text-black">
                  {showStep === deleteSteps.CONFIRM
                    ? props.confirmCancleLabel || 'キャンセル'
                    : props.confirmAgainCancleLabel || 'キャンセル'}
                </button>
                <button onClick={handleConfirm} type="button" className="button-size bg-blue-btn-primary">
                  {showStep === deleteSteps.CONFIRM
                    ? props.confirmLabel || '削除する'
                    : props.confirmAgainLabel || '削除する'}
                </button>
              </div>
            </>
          )}
        </div>
      </Dialog>

      <LoadingBackdrop isOpen={showStep === deleteSteps.DELETING} />
    </>
  );
}

export default ConfirmDeleteDialog;
