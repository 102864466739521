import React from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import { useNavigate } from 'react-router-dom';
import Path from '../../../route/Path';
import { MENUS } from './const';
import ManagementMenu from '../../../components/ManagementMenu';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';

function ScalpLabManagementTopPage() {
  const navigate = useNavigate();

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
        onClick={() => navigate(Path.home)}
      >
        <div className="w-full h-full pb-8 lg:pb-12 relative">
          <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              <NavigationBackBtn url={Path.home} />
            </h2>
          </div>

          <div className="px-4 md:px-0">
            <div>
              <div className="text-sm lg:text-base lg:text-center py-4 lg:py-6">
                登録済みのお客様に頭皮解析サービスのご提案を行うには同意書への同意が必要になります。
              </div>
              <button
                type="button"
                class="w-full lg:w-[160px] button-size bg-blue-btn-primary mx-auto rounded-[12px] py-6 mb-12"
                onClick={() => navigate(Path.selectCustomer)}
              >
                同意書確認を行う
              </button>
            </div>

            <ManagementMenu menus={MENUS} />
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ScalpLabManagementTopPage;
