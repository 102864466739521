import React from "react";
import {formatPrice} from "../../../../../utils";

const DEFAULT_IMAGE =
  "https://data-service.pharmacity.io/pmc-upload-media/production/pmc-ecm-core/products/P26349_1.jpg";

export const Item = ({ data }) => {
  const [show, setShow] = React.useState(false);
  const onExpand = () => setShow((arg) => !arg);
  return (
    <div className="w-full bg-white rounded-xl shadow-[0_1px_10px_0_#006B8833] mb-3 p-3">
      <button
        onClick={onExpand}
        className="w-full bg-white items-center flex rounded-xl"
      >
        <div className="w-20 h-[104px] px-6 py-3">
          <img
            src={data?.imageUrl ?? DEFAULT_IMAGE}
            className="w-full h-full object-cover"
            alt=""
          />
        </div>
        <div className="flex-1">
          <p className="text-xs text-left">{data?.series}</p>
          <p className="text-xs text-left">{data?.name}({data?.size})</p>
          <p className="text-xs text-left mt-3">税込価格 {formatPrice(data?.priceTaxInc)}</p>
        </div>
      </button>
      {show && (
        <div className="p-2 pt-4 border-t text-xs">
          <div className="flex-col">
            <div className="flex">
              <p className="w-14">メーカー</p>
              <p className="flex-1">
                <p className="inline">:</p> {data?.maker}
              </p>
            </div>
            <div className="flex">
              <p className="w-14">製品名</p>
              <p className="flex-1">
                <p className="inline font-bold">:</p> {data?.name}({data?.size})
              </p>
            </div>
          </div>
          <div className="flex-col mt-4">
            <p className="mb-2">お店からのコメント</p>
            <p>{data?.description}</p>
          </div>
        </div>
      )}
    </div>
  );
};
