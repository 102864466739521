import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ErrorIcon from '@mui/icons-material/Error';
import InputField from 'app/components/commonUI/InputField';
import SelectField from 'app/components/commonUI/SelectField';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Link } from 'react-router-dom';
import Path from 'app/route/Path';
import { durationOptions } from '../EditMenuForm';

const validationErrMessages = {
  nameRequired: 'メニュー名は必須です。',
  menuTypeRequired: 'メニューカテゴリは必須です。',
  durationNumberRequired: '所要時間は必須です。',
  priceTaxExcRequired: '料金（税抜）は必須です。',
  publicityStatusRequired: '公開設定は必須です。',
};

const publicityStatus = {
  PUBLIC: 1,
  PRIVATE: 0,
};

const menuCategories = [
  { value: 'cut', label: 'カット' },
  { value: 'color', label: 'カラー' },
  { value: 'perm', label: 'パーマ' },
  { value: 'straight', label: 'ストレート' },
  { value: 'spa', label: 'スパ' },
  { value: 'treatment', label: 'トリートメント' },
  { value: 'other', label: 'その他' },
];

function CreateMenuForm({ onSubmit }) {
  const [isConfirmMode, setIsConfirmMode] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(validationErrMessages.nameRequired),
    menuType: Yup.array().min(1, validationErrMessages.menuTypeRequired),
    durationNumber: Yup.string().required(validationErrMessages.durationNumberRequired),
    priceTaxExc: Yup.string().required(validationErrMessages.priceTaxExcRequired),
    publicityStatus: Yup.number().required(validationErrMessages.publicityStatusRequired),
  });

  const handleFormSubmit = async (values) => {
    if (isConfirmMode) {
      onSubmit(values);
    } else {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length === 0) {
        setIsConfirmMode(true);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      menuType: [],
      durationNumber: '',
      priceTaxExc: '',
      publicityStatus: publicityStatus.PUBLIC,
    },
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  const { handleSubmit, errors, values, setFieldValue, touched, handleBlur } = formik;

  const handleMenuTypeChange = (value) => {
    const currentTypes = values.menuType;
    const newTypes = currentTypes.includes(value)
      ? currentTypes.filter((type) => type !== value)
      : [...currentTypes, value];
    setFieldValue('menuType', newTypes);
  };

  const formatPrice = (value) => {
    // Remove non-numeric characters and convert to number
    const numericValue = Number(value.replace(/[^0-9]/g, ''));
    // Format with commas
    return numericValue.toLocaleString();
  };

  const handleBack = () => {
    setIsConfirmMode(false);
  };

  return (
    <div className="lg:px-0 px-4">
      <div className="space-y-6">
        <div className="sm:w-[352px]">
          <label className="block text-sm font-medium text-gray-700 mb-1">メニュー名</label>
          <InputField
            name="name"
            value={values.name}
            placeholder=""
            onChange={(newValue) => setFieldValue('name', newValue)}
            handleBlur={handleBlur}
            touched={touched.name}
            errors={errors.name}
            disabled={isConfirmMode}
          />
        </div>

        <div className="sm:w-[352px]">
          <label className="block text-sm font-medium text-gray-700 mb-1">メニューカテゴリ</label>
          <div className="flex flex-col">
            {menuCategories.map((category) => (
              <FormControlLabel
                key={category.value}
                control={
                  <Checkbox
                    checked={values.menuType.includes(category.value)}
                    onChange={() => handleMenuTypeChange(category.value)}
                    disabled={isConfirmMode}
                  />
                }
                label={category.label}
              />
            ))}
            {touched.menuType && errors.menuType && (
              <p className="text-red-600 text-xs flex items-center">
                <ErrorIcon className="w-4 h-4 mr-1" />
                {errors.menuType}
              </p>
            )}
          </div>
        </div>

        <div className="sm:w-[352px]">
          <label className="block text-sm font-medium text-gray-700 mb-1">所要時間</label>
          <SelectField
            name="durationNumber"
            value={values.durationNumber}
            options={durationOptions}
            setValue={(newValue) => setFieldValue('durationNumber', newValue)}
            handleBlur={handleBlur}
            touched={touched.durationNumber}
            errors={errors.durationNumber}
            disabled={isConfirmMode}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">料金(税抜き)</label>
          <div className="flex items-center w-full">
            <InputField
              name="priceTaxExc"
              value={values.priceTaxExc ? formatPrice(values.priceTaxExc.toString()) : ''}
              type="text"
              placeholder=""
              onChange={(newValue) => setFieldValue('priceTaxExc', Number(newValue.replace(/[^0-9]/g, '')))}
              handleBlur={handleBlur}
              touched={touched.priceTaxExc}
              errors={errors.priceTaxExc}
              inputWrapClasses="sm:w-[352px] w-full"
              disabled={isConfirmMode}
            />
            <span className="ml-2">円</span>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">公開設定</label>
          <RadioGroup
            value={values.publicityStatus}
            onChange={(e) => setFieldValue('publicityStatus', Number(e.target.value))}
          >
            <div className="flex gap-4">
              <FormControlLabel
                value={publicityStatus.PUBLIC}
                control={<Radio disabled={isConfirmMode} />}
                label="公開"
              />
              <FormControlLabel
                value={publicityStatus.PRIVATE}
                control={<Radio disabled={isConfirmMode} />}
                label="非公開"
              />
            </div>
          </RadioGroup>
          {touched.publicityStatus && errors.publicityStatus && (
            <p className="text-red-600 text-xs flex items-center mt-1">
              <ErrorIcon className="w-4 h-4 mr-1" />
              {errors.publicityStatus}
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-center gap-4 mt-8">
        {isConfirmMode ? (
          <>
            <button
              onClick={handleBack}
              type="button"
              className="px-8 py-2 border border-gray-300 rounded-md hover:bg-gray-50 text-sm flex-1"
            >
              戻る
            </button>
            <button
              onClick={handleSubmit}
              type="button"
              className="px-8 py-2 bg-[#00A0E9] text-white rounded-md hover:bg-[#0093d5] text-sm flex-1"
            >
              内容登録
            </button>
          </>
        ) : (
          <>
            <Link
              to={Path.systemManagementAllMenu}
              className="px-8 py-2 border border-gray-300 rounded-md hover:bg-gray-50 text-sm flex-1 text-center"
            >
              キャンセル
            </Link>
            <button
              onClick={handleSubmit}
              type="button"
              className="px-8 py-2 bg-[#00A0E9] text-white rounded-md hover:bg-[#0093d5] text-sm flex-1"
            >
              追加する
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default CreateMenuForm;
