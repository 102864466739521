import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '../Icons';
import React from 'react';

export default function NavigationBackBtn({ url, text, onClick, actionButton }) {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between items-center px-4 py-1 md:px-0 line-clamp-2">
      <Box className="flex items-center gap-1 line-clamp-2">
        <Box
          className="cursor-pointer"
          onClick={() => {
            onClick ? onClick() : navigate(url || -1);
          }}
        >
          <ChevronLeftIcon />
        </Box>
        <h2 className="text-xl font-medium line-clamp-2 break-all">{text || "頭皮labo"}</h2>
      </Box>
      {actionButton}
    </div>
  );
}
