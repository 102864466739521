import { useNavigate } from 'react-router-dom';
import GA from '../../../../services/googleAnalytics';

const ManagementMenu = ({ menus = [], onNavigate }) => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    onNavigate ? onNavigate(path) : navigate(path);
  };

  const handleClick = (menu) => {
    if (menu.eventName) {
      GA.tracking(menu.eventName);
    }
    handleNavigate(menu.path);
  };

  return (
    <div className="w-full h-full px-4 lg:px-0">
      <div className="w-full h-full">
        <div className="w-full h-full flex flex-col gap-4 overflow-y-auto overflow-x-hidden text-lg">
          {menus.map((menu, index) => (
            <button
              key={index}
              className="w-full min-h-[48px] bg-white flex items-center border border-co shadow-item2 justify-center border-[#006B8833] rounded-xl text-sm md:text-base lg:text-lg px-4"
              onClick={() => handleClick(menu)}
            >
              <p className="text-base font-medium">{menu.title}</p>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ManagementMenu;
