import React from 'react';

export default function ItemBasicInfo(props) {
  const { label, value } = props;

  return (
    <div>
      <p className="text-sm text-gray-600">{label}</p>
      <p className="text-base mt-1 break-words">{value}</p>
    </div>
  );
}
