import React, { useEffect, useMemo, useState } from 'react';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import { useNavigate } from 'react-router-dom';
import LoadingProgress from 'app/components/commonUI/LoadingProgress';
import { getSalonDetail } from 'app/services/api/18';
import NoData from 'app/components/commonUI/NoData';
import usePagination from 'app/hooks/usePagination';
import Pagination from 'app/components/common/Pagination';
import GA, { EVENT_NAMES } from 'app/services/googleAnalytics';
import NavigationBackBtn from 'app/components/common/NavigationBackButton';
import BasicTabs from 'app/components/common/BasicTabs';
import TabPanel from 'app/components/common/BasicTabs/TabPanel';
import { toast } from 'react-toastify';
import ConfirmDeleteDialog from 'app/components/CommonDialog/ConfirmDeleteDialog';
import ItemMenu from './components/ItemMenu';
import { deleteMenu } from 'app/services/api/1D';

const TAB_VALUES = {
  PRIVATE: 0,
  PUBLIC: 1,
};

function SystemManagementAllMenuPage() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(TAB_VALUES.PUBLIC);
  const [fetchAllMenu, setFetchAllMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedMenuId, setSelectedMenuId] = useState(null);

  const filteredMenus = useMemo(() => {
    return (
      fetchAllMenu?.salonMenus?.filter((menu) =>
        activeTab === TAB_VALUES.PUBLIC ? menu.publicityStatus : !menu.publicityStatus,
      ) || []
    );
  }, [fetchAllMenu, activeTab]);

  const loadAllMenu = async () => {
    setIsLoading(true);
    try {
      const res = await getSalonDetail();
      setFetchAllMenu(res.data);
    } catch (error) {
      toast.error('メニュー取得に失敗しました');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAllMenu();
  }, []);

  const handleAddMenu = () => {
    GA.tracking(EVENT_NAMES.MENU_LIST.CLICK_CREATE);
    navigate(Path.systemManagementRegisterMenu);
  };

  const handleDeleteMenu = (id) => {
    setSelectedMenuId(id);
    setShowDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteMenu(selectedMenuId);
      toast.success('削除成功');
      GA.tracking(EVENT_NAMES.MENU_LIST.DELETE, {
        menuId: selectedMenuId,
      });
      loadAllMenu();
    } catch (error) {
      toast.error('削除が成功しない');
    } finally {
      setSelectedMenuId(null);
      setShowDeleteDialog(false);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingProgress />;
    }

    return filteredMenus?.length > 0 ? (
      <div className="space-y-4">
        {filteredMenus?.map((menu, index) => (
          <ItemMenu
            key={index}
            id={menu._id}
            title={menu.name}
            category={menu.menuType}
            durationNumber={menu.durationNumber}
            priceTaxExc={menu.priceTaxExc}
            onDelete={handleDeleteMenu}
          />
        ))}
      </div>
    ) : (
      <div className="mt-8">
        <NoData />
      </div>
    );
  };

  return (
    <DefaultLayout>
      <MainContentLayout contentClass="sm:min-h-full">
        <div className="w-full h-full flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-base lg:text-xl font-semibold">
              <NavigationBackBtn url={Path.systemManagement} text="店舗メニュー" />
            </h2>
            <button
              onClick={handleAddMenu}
              type="button"
              className="px-4 py-2 bg-[#00A0E9] text-white rounded-lg hover:bg-[#0090D9] flex items-center gap-1 mr-4 lg:mr-0"
            >
              メニュー追加
            </button>
          </div>

          <BasicTabs activeTab={activeTab} onChange={setActiveTab}>
            <TabPanel label="公開" value={TAB_VALUES.PUBLIC} className="px-4 md:px-0">
              {renderContent()}
            </TabPanel>
            <TabPanel label="非公開" value={TAB_VALUES.PRIVATE} className="px-4 md:px-0">
              {renderContent()}
            </TabPanel>
          </BasicTabs>

          {/* {totalPage > 1 && (
            <div className="mt-4">
              <Pagination totalPage={totalPage} onPageChange={changeCurrentPage} />
            </div>
          )} */}
        </div>
      </MainContentLayout>
      {showDeleteDialog && (
        <ConfirmDeleteDialog
          open={showDeleteDialog}
          handleClose={() => setShowDeleteDialog(false)}
          confirmTitle="以下のメニューを削除しますか？"
          confirmMessage={`メニュー名 ${fetchAllMenu?.salonMenus?.find((menu) => menu._id === selectedMenuId)?.name}`}
          confirmTitleAgain="本当に削除してもよろしいでしょうか？"
          deleteCompleteTitle="メニューを削除しました"
          deleteCompleteBtnLabel="システム設定へ戻る"
          deleteCompleteAction={() => setShowDeleteDialog(false)}
          deleteAction={confirmDelete}
          confirmCancleLabel="キャンセル"
          confirmCancleAgainLabel="キャンセル"
          confirmLabel="削除"
          confirmAgainLabel="削除"
        />
      )}
    </DefaultLayout>
  );
}

export default SystemManagementAllMenuPage;
