import React, { useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import Path from '../../../route/Path';
import { getInventoryAll } from '../../../services/api/18';
import usePagination from '../../../hooks/usePagination';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import NoData from '../../../components/commonUI/NoData';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../components/commonUI/LoadInitError';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import SelectField from '../../../components/commonUI/SelectField';
import ListInventory from './ListInventory';
import { PRODUCT_CATEGORY_OPTIONS } from '../../../constants';
import InputField from '../../../components/commonUI/InputField';

const ListIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 4H14" stroke="#00B3ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 4H3" stroke="#00B3ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 12H12" stroke="#00B3ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 12H3" stroke="#00B3ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M21 20H16" stroke="#00B3ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 20H3" stroke="#00B3ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 2V6" stroke="#00B3ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8 10V14" stroke="#00B3ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 18V22" stroke="#00B3ED" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

function InventoryManagementTopPage() {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [name, setName] = useState('');
  const [categorySearch, setCategorySearch] = useState('');
  const [category, setCategory] = useState('');
  const [makerSearch, setMakerSearch] = useState('');
  const [maker, setMaker] = useState('');
  const [fetchInventory, setFetchInventory] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const { data, totalPage, changeCurrentPage } = usePagination({
    totalData: fetchInventory.data,
  });

  const loadInventory = () => {
    setFetchInventory({
      isLoading: true,
      data: null,
      error: null,
    });

    getInventoryAll(name, maker, '', '', '', '', category)
      .then((res) => {
        setFetchInventory({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchInventory({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, maker, category]);

  const categoryOptions = useMemo(() => {
    return [{ value: '', label: 'カテゴリで絞り込む' }, ...PRODUCT_CATEGORY_OPTIONS];
  }, []);

  const onFilter = () => {
    setMaker(makerSearch);
    setCategory(categorySearch);
  };

  const goToCheckStock = () => {
    let queryString = {};
    if (maker) {
      queryString.maker = maker;
    }
    if (category) {
      queryString.category = category;
    }
    navigate({
      pathname: Path.inventoryManagementCheckStock,
      search: createSearchParams(queryString).toString(),
    });
  };

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-full pb-6 md:pb-8  relative">
          <div className="w-full flex items-center justify-start inset-x-0 top-0 pr-4 md:pr-0">
            <NavigationBackBtn url={Path.home} text={'商品在庫一覧'} />
            <div className="flex flex-row gap-2 ml-auto">
              <button onClick={goToCheckStock} className="button-size-small bg-blue-btn-primary">
                在庫管理
              </button>
            </div>
          </div>

          <div className="flex items-center gap-2 mt-6 p-4 md:p-0">
            <div className="flex flex-row gap-2 mr-auto">
              <InputField
                label=""
                name="maker"
                value={makerSearch}
                type="text"
                onChange={(value) => setMakerSearch(value)}
                placeholder="メーカーで絞り込む"
                inputWrapClasses="max-w-full !w-[150px]  md:!w-[200px] select-field"
              />

              <SelectField
                name="category"
                inputWrapClasses="max-w-full !w-[150px] md:!w-[200px] select-field"
                inputClasses="w-full "
                value={categorySearch}
                setValue={(value) => setCategorySearch(value)}
                options={categoryOptions}
                displayEmpty
              />
            </div>
            <div onClick={() => onFilter()} className="cursor-pointer">
              <ListIcon />
            </div>
          </div>

          <div className="mt-6 w-full p-4 md:p-0">
            {fetchInventory.isLoading && <LoadingProgress />}

            {!fetchInventory.isLoading && fetchInventory.error && <LoadInitError error={fetchInventory.error} />}

            {!fetchInventory.isLoading && !fetchInventory.error && (
              <>
                {Array.isArray(fetchInventory.data) && fetchInventory.data.length > 0 ? (
                  <ListInventory items={fetchInventory.data} />
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default InventoryManagementTopPage;
