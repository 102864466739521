import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import React, { useMemo } from 'react';

const RadioComponent = (props) => {
  const { value, handleSelect, errors, touched, options, label, labelClasses, row = true, display = false } = props;

  const optionChecked = useMemo(() => {
    return options.find((option) => option.value == value);
  }, [value, options]);

  return (
    <div className="! w-full h-auto">
      {label && <label className={`text-sm text-subtitle mb-4 ${labelClasses || ''}`}>{label}</label>}
      {display ? (
        <div className="break-all">{optionChecked?.label || ''}</div>
      ) : (
        <>
          <RadioGroup
            value={`${value}`}
            onChange={(event) => {
              handleSelect(event.target.value);
            }}
            disabled={value !== null ? true : false}
            className="flex flex-row flex-wrap gap-4"
            row={row}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
                className="!mr-0"
              />
            ))}
          </RadioGroup>
          {touched && errors ? (
            <p className="w-full !text-red-600 !text-[12px]">
              <span className="w-4 h-4 mr-1 text-base leading-4 shrink-0">
                <ErrorIcon fontSize="inherit" className="text-xs text-red-600 font-thin" />
              </span>
              <span>{errors}</span>
            </p>
          ) : null}
        </>
      )}
    </div>
  );
};

export default RadioComponent;
