import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import { toast } from 'react-toastify';
import { createNewSalon, getSalon, updateSalon } from '../../../services/api/1D';
import CreateCompleteDialog from '../../../components/CommonDialog/CreateCompleteDialog';
import LoadingBackdrop from '../../../components/commonUI/LoadingBackdrop';
import LoadInitError from '../../../components/commonUI/LoadInitError';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import CreateSalonForm from '../../../components/salonForm/CreateSalonForm';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthUser } from '../../../redux/authReducer';
import { accountTypes } from '../../../constants';

const INITIAL_STATE = {
  isLoading: false,
  data: null,
  error: null,
};

const INITIAL_CREATING_STATE = {
  confirmedData: null,
  isCreating: false,
  error: null,
  res: null,
};

function RegisterSalonFormPage() {
  const { salonId: id = null } = useParams();
  const navigate = useNavigate();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [creatingSalonState, setCreatingSalonState] = useState(INITIAL_CREATING_STATE);
  const [fetchSalonDetail, setFetchSalonDetail] = useState(INITIAL_STATE);

  const user = useSelector((state) => state.auth?.user);
  const dispatch = useDispatch();

  const loadSalonDetail = useCallback(() => {
    setFetchSalonDetail({
      ...INITIAL_STATE,
      isLoading: true,
    });

    getSalon(id)
      .then((res) => {
        setFetchSalonDetail({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchSalonDetail({
          isLoading: false,
          data: null,
          error,
        });
      });
  }, [id]);

  const handleError = (error) => {
    toast.error('店舗情報の登録に失敗しました。もう一度お試しください。');
    setCreatingSalonState((prev) => ({
      ...prev,
      isCreating: false,
      error: null,
      res: error,
    }));
  };

  const handleSuccess = (res) => {
    setCreatingSalonState((prev) => ({
      ...prev,
      isCreating: false,
      error: null,
      res,
    }));
    setIsOpenDialog(true);
  };

  const handleSubmit = async (values) => {
    setCreatingSalonState((prev) => ({
      ...prev,
      isCreating: true,
      error: null,
      res: null,
    }));

    try {
      const res = id ? await updateSalon(id, values) : await createNewSalon(values);

      if (res) {
        if (id) {
          GA.tracking(EVENT_NAMES.SALON_EDIT.EDIT_SALON, {
            salonId: id,
          });
        }

        if (res.data?._id && res.data._id === user?.salonId) {
          dispatch(
            setAuthUser({
              ...user,
              email: res.data.email,
              salonImageUrl: res.data.imageUrl,
              salonName: res.data.name,
            }),
          );
        }

        handleSuccess(res);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const confirmSalonData = (confirmedData) => {
    setCreatingSalonState({
      ...INITIAL_CREATING_STATE,
      confirmedData,
    });
  };

  useEffect(() => {
    if (id) loadSalonDetail();
  }, [id, loadSalonDetail]);

  const renderContent = () => {
    if (fetchSalonDetail.isLoading) {
      return <LoadingProgress />;
    }

    if (!fetchSalonDetail.isLoading && fetchSalonDetail.error) {
      return <LoadInitError error={fetchSalonDetail.error} />;
    }

    if (!fetchSalonDetail.isLoading && !fetchSalonDetail.error && id !== '' && fetchSalonDetail.data) {
      return (
        <CreateSalonForm
          salonId={id}
          fetchSalonDetail={fetchSalonDetail.data}
          handleCreate={handleSubmit}
          confirmSalonData={confirmSalonData}
        />
      );
    }

    return null;
  };

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-16 lg:h-12 flex items-center justify-start inset-x-0 top-0">
          <h2 className="text-base lg:text-xl font-semibold">
            <NavigationBackBtn text="店舗情報を変更" />
          </h2>
        </div>

        <div className="w-full h-full pb-8 lg:pb-12 relative">
          <div className="w-full h-full py-3">
            <div className="w-full h-full pr-3">
              <div className="w-full h-full overflow-auto">{renderContent()}</div>
            </div>
          </div>
        </div>

        <CreateCompleteDialog
          open={isOpenDialog}
          createCompleteTitle={id ? '店舗情報を変更しました。' : '登録を完了しました。'}
          createCompleteBtnLabel1="システム設定へ戻る"
          createCompleteAction1={() => navigate(Path.systemManagement)}
          createCompleteBtnLabel2="終了"
          createCompleteAction2={() => navigate(Path.systemManagementDetail(id))}
        />

        <LoadingBackdrop isOpen={creatingSalonState.isCreating} />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default RegisterSalonFormPage;
