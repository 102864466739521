import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import CommonConfirmDialog from 'app/components/CommonDialog';
import ConfirmLoginDialog from '../../components/CommonDialog/ConfirmLoginDialog';
import { ProfileIcon, StackIcon, SettingIcon, TaskIcon } from 'app/components/common/Icons';
import Path from 'app/route/Path';
import { setPolicy } from '../../redux/authReducer';
import GA, { EVENT_NAMES } from '../../services/googleAnalytics';
import MenuItem from './MenuItem';
import Image from '../../components/Image';

const MENU_ITEMS = [
  {
    title: 'お客様情報',
    icon: ProfileIcon,
    onClick: (navigate) => {
      GA.tracking(EVENT_NAMES.HOME.CLICK_CUSTOMER);
      navigate(Path.customerManagement);
    },
  },
  {
    title: '頭皮labo',
    icon: TaskIcon,
    onClick: (navigate) => {
      GA.tracking(EVENT_NAMES.HOME.CLICK_SCALP_LAB);
      navigate(Path.scalpLabManagement);
    },
  },
  {
    title: '商品在庫確認',
    icon: StackIcon,
    onClick: (navigate) => {
      GA.tracking(EVENT_NAMES.HOME.CLICK_INVENTORY);
      navigate(Path.inventoryManagement);
    },
  },
  {
    title: 'システム設定',
    icon: SettingIcon,
    onClick: (navigate) => {
      GA.tracking(EVENT_NAMES.HOME.CLICK_SYSTEM);
      navigate(Path.systemManagement);
    },
  },
];

const FOOTER_LINKS = [
  {
    title: '特定商取引法',
    path: '/act-of-commercial-transactions',
    onClick: () => GA.tracking(EVENT_NAMES.HOME.CLICK_TERMS),
  },
  {
    title: 'プライバシーポリシー',
    path: '/privacy-policy',
    onClick: () => GA.tracking(EVENT_NAMES.HOME.CLICK_PRIVACY_POLICY),
  },
];

function Home() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const showPolicy = useSelector((state) => state.auth?.showPolicy);

  const renderFooterLink = ({ title, path, onClick }) => (
    <Link key={title} onClick={onClick} to={path} className="text-sm">
      {title}
    </Link>
  );

  const user = useSelector((state) => state.auth?.user);

  return (
    <DefaultLayout>
      <MainContentLayout contentClass="h-full">
        <div className="w-full h-full flex flex-col overflow-auto justify-between">
          <div>
            {user && (
              <div className="flex items-center gap-2 mb-6 px-4">
                {user.salonImageUrl ? (
                  <Image
                    className="w-6 h-6 rounded-full bg-gray-200"
                    imageClassName="w-full h-full object-cover"
                    src={user.salonImageUrl}
                    alt="product-image"
                  />
                ) : (
                  <div className="w-6 h-6 rounded-full bg-gray-200"></div>
                )}
                <h2 className="text-base lg:text-xl font-semibold">{user.salonName}</h2>
              </div>
            )}

            <div className="grid grid-cols-2 gap-4 lg:gap-6 px-4 pt-6">
              {MENU_ITEMS.map((item, index) => (
                <MenuItem key={index} {...item} />
              ))}
            </div>
          </div>

          <div className="flex justify-center gap-8 underline text-[#666666] mt-8 mb-4">
            {FOOTER_LINKS.map(renderFooterLink)}
          </div>
        </div>

        <CommonConfirmDialog open={open} handleClose={() => setOpen(false)} title="Q. お客様は頭皮ラボの会員ですか？" />
        <ConfirmLoginDialog open={showPolicy} handleClose={() => dispatch(setPolicy(false))} />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default Home;
