import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import LoadingProgress from 'app/components/commonUI/LoadingProgress';
import LoadInitError from 'app/components/commonUI/LoadInitError';
import ConfirmUpdateDialog from 'app/components/CommonDialog/ConfirmUpdateDialog';
import { getDetailSalonStaff, updateSalonStaff } from 'app/services/api';
import Path from 'app/route/Path';
import GA, { EVENT_NAMES } from 'app/services/googleAnalytics';
import EditStaffForm from '../components/EditStaffForm';
import NavigationBackBtn from 'app/components/common/NavigationBackButton';
import { toast } from 'react-toastify';

const StaffManagementUpdatePageContent = ({ staffInfo, staffId }) => {
  const navigate = useNavigate();
  const [confirmedData, setConfirmedData] = useState(null);
  const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);

  const handleUpdateStaffInfo = async () => {
    if (!confirmedData) return;

    const data = {
      lastNameKata: confirmedData.lastNameKata,
      firstNameKata: confirmedData.firstNameKata,
      lastNameKanji: confirmedData.lastNameKanji,
      firstNameKanji: confirmedData.firstNameKanji,
      dob: confirmedData.dob?.toISOString() || null,
      phone: confirmedData.phone,
      postalCode: confirmedData.postalCode,
      address: confirmedData.address,
      imageUrl: confirmedData.imageUrl,
      ...(confirmedData.email && { email: confirmedData.email }),
    };

    GA.tracking(EVENT_NAMES.STAFF_DETAIL.UPDATE, { staffId });
    return await updateSalonStaff(staffId, data);
  };

  const handleUpdateComplete = () => {
    navigate(Path.staffManagementDetail(staffId));
  };

  const onSubmitForm = (data) => {
    setConfirmedData(data);
    setShowConfirmUpdate(true);
  };

  const handleDeleteError = (error) => {
    const errors = error?.response?.data?.message[0]?.constraints;
    if (errors?.isEmail) {
      toast.error('メールアドレスが無効です。');
    } else {
      toast.error('エラーが発生しました。もう一度お試しください。');
    }
    setShowConfirmUpdate(false);
  };

  return (
    <>
      <EditStaffForm staffInfo={staffInfo} onSubmitForm={onSubmitForm} />
      <ConfirmUpdateDialog
        open={confirmedData && showConfirmUpdate}
        handleClose={() => setShowConfirmUpdate(false)}
        confirmTitle="スタッフ情報を変更したいのですが?"
        confirmMessage=""
        updateCompleteTitle="社員情報を更新しました"
        updateCompleteBtnLabel="終了"
        updateCompleteAction={handleUpdateComplete}
        updateAction={handleUpdateStaffInfo}
        handleDeleteError={handleDeleteError}
        confirmCancleLabel="キャンセル"
        confirmLabel="変化"
      />
    </>
  );
};

const StaffManagementUpdatePage = () => {
  const [fetchInitState, setFetchInitState] = useState({
    data: null,
    error: null,
  });
  const { staffId } = useParams();
  const [loading, setLoading] = useState(false);

  const loadStaffData = async (staffId) => {
    setLoading(true);
    try {
      const response = await getDetailSalonStaff(staffId);
      setFetchInitState({
        data: response.data,
        error: null,
      });
    } catch (error) {
      setFetchInitState({
        data: null,
        error,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (staffId) {
      loadStaffData(staffId);
    }
  }, [staffId]);

  const renderContent = () => {
    if (loading) return <LoadingProgress />;
    if (fetchInitState.error) return <LoadInitError error={fetchInitState.error} />;
    if (fetchInitState.data)
      return <StaffManagementUpdatePageContent staffInfo={fetchInitState.data} staffId={staffId} />;
    return <></>;
  };

  return (
    <DefaultLayout>
      <MainContentLayout>
        <div className="w-full h-16 lg:h-12 flex items-center justify-start inset-x-0 top-0">
          <h2 className="text-base lg:text-xl font-semibold">
            <NavigationBackBtn url={Path.staffManagementDetail(staffId)} text={'スタッフ情報を変更'} />
          </h2>
        </div>

        <div className="w-full h-full py-3">
          <div className="w-full h-full">{renderContent()}</div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
};

export default StaffManagementUpdatePage;
