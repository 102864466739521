import React, { useEffect, useState } from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import Path from '../../../route/Path';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { getSalon } from '../../../services/api/1D';
import LoadingBackdrop from 'app/components/commonUI/LoadingBackdrop';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import defaultAvatar from 'app/assets/svg/default_avatar.svg';

function SystemManagementDetailPage() {
  const navigate = useNavigate();
  const { salonId: id = null } = useParams();
  const [salonDetail, setSalonDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadSalonDetail = async (id) => {
    setLoading(true);
    try {
      const response = await getSalon(id);
      setSalonDetail(response?.data);
    } catch (error) {
      toast.error(error?.message || '店舗情報の取得に失敗しました。');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      loadSalonDetail(id);
    }
  }, [id]);

  const renderField = (label, value) => (
    <div>
      <p className="text-sm text-gray-600">{label}</p>
      <p className="text-base mt-1 break-words">{value}</p>
    </div>
  );

  return (
    <DefaultLayout>
      <LoadingBackdrop isOpen={loading} />
      <MainContentLayout>
        <div className="w-full h-16 lg:h-12 flex items-center justify-between inset-x-0 top-0">
          <h2 className="text-base lg:text-xl font-semibold">
            <NavigationBackBtn url={Path.systemManagement} text={'店舗情報'} />
          </h2>
          <button
            onClick={() => navigate(Path.systemManagementEdit(id))}
            className="mr-3 lg:mr-0 px-3 py-2 text-white bg-blue-btn-primary rounded hover:bg-blue-600"
          >
            変更する
          </button>
        </div>

        <div className="w-full h-full pt-8 pb-8 lg:py-12 relative">
          <div className="w-full max-w-[960px] mx-auto">
            <div className="flex justify-center mb-8">
              <Avatar
                alt="Avatar"
                src={salonDetail?.imageUrl || defaultAvatar}
                sx={{
                  width: 112,
                  height: 112,
                  border: '2px solid #D9D9D9',
                }}
              />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 lg:gap-x-32 px-4 lg:px-0">
              {renderField('店舗名', salonDetail?.name)}
              {renderField('メールアドレス', salonDetail?.email)}
              {renderField('電話番号', salonDetail?.phone)}
              {renderField('郵便番号', salonDetail?.postalCode)}
              {renderField('都道府県', salonDetail?.prefecture)}
              {renderField('市区町村', salonDetail?.city)}
              {renderField('町名', salonDetail?.street)}
              {renderField('番地・ビル名', salonDetail?.building)}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SystemManagementDetailPage;
