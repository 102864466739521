import { useEffect, useState } from 'react';

export default function CheckboxField(props) {
  const {
    label,
    name,
    value = false,
    onChange,
    isToggle = false,
  } = props;
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleClick = () => {
    if (isToggle) {
      setIsChecked(!isChecked);
      onChange({ target: { name, value: !isChecked } });
    } else {
      setIsChecked(true);
      onChange({ target: { name, value: true } });
    }
  };

  return (
    <label className="flex justify-center mt-6 cursor-pointer" onClick={handleClick}>
      <span className={`w-6 h-6 border border-[#cacaca] rounded-sm mr-2 justify-center items-center flex ${isChecked ? "bg-blue-btn-primary border-blue-btn-primary" : ""}`} >
        <i className="fa fa-check text-white"></i>
      </span>
      <span className="text-normal text-black">{label}</span>
    </label>
  );
}
