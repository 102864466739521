import { permissionCodes } from "../constants";

const customerManagementPrefix = "/customer-management";
const saleManagementPrefix = "/sale-management";
const reservationManagementPrefix = "/reservation-management";
const staffManagementPrefix = "/staff-management";

const routePaths = {
  home: "/",
  login: "/login",

  // 11**
  createCustomerInfo: "/create-customer",
  confirmCustomerInfo: (customerId) =>
    customerId
      ? `/confirm-customer-info/${customerId}`
      : "/confirm-customer-info/:customerId",

  customterCounselingSheet: (customerId) =>
    customerId
      ? `/customer-counseling-sheet/${customerId}`
      : "/customer-counseling-sheet/:customerId",

  createNewReservationAndVisit: (customerId) =>
    customerId
      ? `/create-reservation-and-visit/${customerId}`
      : "/create-reservation-and-visit/:customerId",

  confirmReservationInfo: (reservationId) =>
    reservationId
      ? `/confirm-reservation-info/${reservationId}`
      : "/confirm-reservation-info/:reservationId",

  // 12**
  customersUndergoingTreatment: "/customers-undergoing-treatment",
  duringService: (id) =>
    id ? `/during-service/${id}` : "/during-service/:id",
  updateVisitMenu: (id) =>
    id ? `/update-visit-menu/${id}` : "/update-visit-menu/:id",
  duringServiceAddCounselingPage: (visitId) =>
    visitId
      ? `/add-counseling/${visitId}`
      : "/add-counseling/:visitId",
  customersUndergoingTreatmentIntroduction: (id) =>
    id
      ? `/customer-undergoing-treatment/${id}/introduction`
      : "/customer-undergoing-treatment/:id/introduction",
  customersUndergoingTreatmentIntroductionScalpLab: (id) =>
    id
      ? `/customer-undergoing-treatment/${id}/introduction-scalp-lab`
      : "/customer-undergoing-treatment/:id/introduction-scalp-lab",
  customersUndergoingTreatmentIntroductionScalpLabForm: (id) =>
    id
      ? `/customer-undergoing-treatment/${id}/introduction-scalp-lab/form`
      : "/customer-undergoing-treatment/:id/introduction-scalp-lab/form",
  // 13**
  toDayVisitList: "/to-day-visit-list",
  paymentNonCustomer: "/payment-non-customer",
  customerPaymentByCash: (id) =>
    id
      ? `/customer-payment-by-cash/${id}`
      : `/customer-payment-by-cash/:id`,
  customerPaymentByOtherMethod: (id) =>
    id
      ? `/customer-payment-by-other-method/${id}`
      : `/customer-payment-by-other-method/:id`,

  // 14** Customer Management
  customerManagement: customerManagementPrefix,
  customerManagementRegisterNew: `${customerManagementPrefix}/register-new-customer`,
  customerManagementDetail: (customerId) =>
    customerId
      ? `${customerManagementPrefix}/${customerId}/detail`
      : `${customerManagementPrefix}/:customerId/detail`,
  customerManagementBasicInfo: (customerId) =>
    customerId
      ? `${customerManagementPrefix}/${customerId}/basic-info`
      : `${customerManagementPrefix}/:customerId/basic-info`,
  customerManagementUpdateBasicInfo: (customerId) =>
    customerId
      ? `${customerManagementPrefix}/${customerId}/update-basic-info`
      : `${customerManagementPrefix}/:customerId/update-basic-info`,
  customerManagementFactCheck: (customerId, stepId) =>
    customerId && stepId
      ? `${customerManagementPrefix}/${customerId}/1st-impression-and-visit-history/${stepId}`
      : `${customerManagementPrefix}/:customerId/1st-impression-and-visit-history/:stepId`,
  customerManagementCounselingSheet: (customerId) =>
    customerId
      ? `${customerManagementPrefix}/${customerId}/counseling-sheet`
      : `${customerManagementPrefix}/:customerId/counseling-sheet`,
  customerManagementReservation: (customerId) =>
    customerId
      ? `${customerManagementPrefix}/${customerId}/reservation`
      : `${customerManagementPrefix}/:customerId/reservation`,
  customerManagementVisitHistory: (customerId) =>
    customerId
      ? `${customerManagementPrefix}/${customerId}/visit-history`
      : `${customerManagementPrefix}/:customerId/visit-history`,

  // 15** Reservation Management
  reservationManagement: reservationManagementPrefix,
  reservationManagementRegister: `${reservationManagementPrefix}/register-new`,
  reservationManagementDetail: (reservationId) =>
    reservationId
      ? `${reservationManagementPrefix}/${reservationId}/detail`
      : `${reservationManagementPrefix}/:reservationId/detail`,
  reservationManagementEdit: (reservationId) =>
    reservationId
      ? `${reservationManagementPrefix}/${reservationId}/edit`
      : `${reservationManagementPrefix}/:reservationId/edit`,
  contactToCustomer: (customerId) =>
    customerId
      ? `${reservationManagementPrefix}/contact/${customerId}`
      : `${reservationManagementPrefix}/contact/:customerId`,

  // 16** Salon Stafff Management
  staffManagement: staffManagementPrefix,

  staffManagementCalendar: `${staffManagementPrefix}/calendar`,
  staffManagementRegister: `${staffManagementPrefix}/register`,
  staffManagementList: `${staffManagementPrefix}/all-staffs`,
  staffManagementDetail: (staffId) =>
    staffId
      ? `${staffManagementPrefix}/${staffId}/detail`
      : `${staffManagementPrefix}/:staffId/detail`,
  staffManagementUpdate: (staffId) =>
    staffId
      ? `${staffManagementPrefix}/${staffId}/update`
      : `${staffManagementPrefix}/:staffId/update`,
  staffManagementSelectStaffRegisterShiftPlan: `${staffManagementPrefix}/register-shift-plan`,
  staffManagementRegisterShiftPlan: (staffId) =>
    staffId
      ? `${staffManagementPrefix}/${staffId}/register-shift-plan`
      : `${staffManagementPrefix}/:staffId/register-shift-plan`,
  staffManagementListShiftPlan: (staffId) =>
    staffId
      ? `${staffManagementPrefix}/${staffId}/list-shift-plan`
      : `${staffManagementPrefix}/:staffId/list-shift-plan`,
  staffManagementIndividualSales: (staffId) =>
    staffId
      ? `${staffManagementPrefix}/${staffId}/individual-sales`
      : `${staffManagementPrefix}/:staffId/individual-sales`,
  staffManagementIndividualAttendances: (staffId) =>
    staffId
      ? `${staffManagementPrefix}/${staffId}/individual-attendances`
      : `${staffManagementPrefix}/:staffId/individual-attendances`,
  staffManagementUpdateStaffAttendance: (staffId, attendanceId) =>
    staffId && attendanceId
      ? `${staffManagementPrefix}/${staffId}/attendance/${attendanceId}/update-attendance`
      : `${staffManagementPrefix}/:staffId/attendance/:attendanceId/update-attendance`,
  staffManagementAttendanceWithoutShiftPlan: (staffId) =>
    staffId
      ? `${staffManagementPrefix}/${staffId}/attendance-without-shift-plan`
      : `${staffManagementPrefix}/:staffId/attendance-without-shift-plan`,

  // 17** Sale Management
  saleManagement: saleManagementPrefix,
  detailSaleOfSalonPage: `${saleManagementPrefix}/detail`,
  listStaffMember: `${saleManagementPrefix}/list-staff`,
  inputSaleStaff: (staffId) =>
    staffId
      ? `${saleManagementPrefix}/${staffId}/input-sale`
      : `${saleManagementPrefix}/:staffId/input-sale`,
  saleOfStaffMemberDetail: (staffId) =>
    staffId
      ? `${saleManagementPrefix}/${staffId}/sale-detail`
      : `${saleManagementPrefix}/:staffId/sale-detail`,

  // 18** Inventory Management
  inventoryManagement: "/inventory-management",
  inventoryManagementRegister: "/inventory-management/register",
  inventoryManagementSearch: "/inventory-management/search",
  inventoryManagementCheckStock: "/inventory-management/check-stock",
  inventoryManagementItemDetail: (itemId = ':productId') => `/inventory-management/${itemId}/detail`,

  // 1A** DMs Management
  dmsManagement: "/dms-management",
  dmsManagementHistory: "/dms-management/history",
  dmsManagementHistoryDetail: (dmsId) =>
    dmsId
      ? `/dms-management/history/${dmsId}/detail`
      : `/dms-management/history/:dmsId/detail`,

  // 1b** Analyze Management
  analyzeManagement: "/analyze-management",
  customerVisitAnalyzeManagement:
    "/analyze-management/customer-visit",
  customerRepeatRateAnalyzeManagement:
    "/analyze-management/customer-repeat-rate",
  turnoverAnalyzeManagement: "/analyze-management/turnover",
  averageSpendPerCustomerAnalyzeManagement:
    "/analyze-management/average-spend-per-customer",
  costRateAnalyzeManagement: "/analyze-management/cost-rate",
  operatingProfitRatioAnalyzeManagement:
    "/analyze-management/operating-profit-ratio",
  productivityAnalyzeManagement: "/analyze-management/productivity",
  inputConsultationAnalyzeManagement:
    "/analyze-management/input-consultation",

  // 1C** Scalp Lab Management
  scalpLabManagement: "/scalp-lab-management",
  scalpLabManagementIntroduction:
    "/scalp-lab-management/introduction",
  scalpLabManagementApplicationForm:
    "/scalp-lab-management/application-form",
  scalpLabManagementConfirmApplicationForm:
    "/scalp-lab-management/application-form/confirm",
  orderScalpLab: "/scalp-lab-management/oder-form",
  batchDeliverScalpLab: "/scalp-lab-management/batch-deliver",
  batchDeliverScalpLabDetail: (group_id) =>
    group_id
      ? `/scalp-lab-management/batch-deliver/${group_id}/detail`
      : `/scalp-lab-management/batch-deliver/:group_id/detail`,
  historyOfOrdering: "/scalp-lab-management/history-of-ordering",
  historyOfOrderingDetail: (customerNo) =>
    customerNo
      ? `/scalp-lab-management/history-of-ordering/${customerNo}/detail`
      : `/scalp-lab-management/history-of-ordering/:customerNo/detail`,
  historyOfOrderingDetailByKitId: (customerNo, inspectionKitId) =>
    customerNo
      ? `/scalp-lab-management/history-of-ordering/${customerNo}/detail/${inspectionKitId}`
      : `/scalp-lab-management/history-of-ordering/:customerNo/detail/:inspectionKitId`,
  historyOfOrderingDetailByKitIdCollectLocation: (customerNo, inspectionKitId) =>
    customerNo
      ? `/scalp-lab-management/history-of-ordering/${customerNo}/detail/${inspectionKitId}/collect-location`
      : `/scalp-lab-management/history-of-ordering/:customerNo/detail/:inspectionKitId/collect-location`,
  orderKits: "/scalp-lab-management/order-kit",
  contactOperator: "/scalp-lab-management/contact-operator",
  selectCustomer: "/scalp-lab-management/select-customer",

  // 1D** System Management
  systemManagement: "/system-management",
  systemManagementRegisterSalon: "/system-management/register-salon",
  systemManagementDetail: (salonId) =>
    salonId
      ? `/system-management/detail/${salonId}`  
      : `/system-management/detail/:salonId`,
  systemManagementEdit: (salonId) =>
    salonId
      ? `/system-management/${salonId}/edit`
      : `/system-management/:salonId/edit`,
  systemManagementRegisterMenu: "/system-management/register-menu",
  systemManagementAllMenu: "/system-management/all-menu",
  systemManagementMenuEdit: (menuId) =>
    menuId
      ? `/system-management/menu/${menuId}/edit`
      : `/system-management/menu/:menuId/edit`,
  systemManagementSettingAccessRight:
    "/system-management/setting-access-right",
  systemManagementConnectDevice: "/system-management/connect-device",
  systemManagementConnectDeviceSuccess:
    "/system-management/connect-device/connect-success",

  // 1E** Cashier Management
  cashierManagement: "/cashier-management",
  cashierManagementAll: "/cashier-management/all-cashier",
  cashierManagementTotalSalesToday:
    "/cashier-management/total-sales-today",
  cashierManagementCashierVisitDetail: (cashierId) =>
    cashierId
      ? `/cashier-management/${cashierId}/visit-detail`
      : `/cashier-management/:cashierId/visit-detail`,
  cashierManagementCashierNonVisitDetail: (cashierId) =>
    cashierId
      ? `/cashier-management/${cashierId}/non-visit-detail`
      : `/cashier-management/:cashierId/non-visit-detail`,
  // Product order
  labProductOrder: (segmentId) => {
    if (segmentId === undefined || segmentId === null) {
      return `/scalp-lab-management/labProductOrder/:segmentName`;
    }

    if (segmentId === "normal" || segmentId === "orderInfo") {
      return `/scalp-lab-management/labProductOrder/${segmentId}`;
    }

    return `/scalp-lab-management/labProductOrder/orderInfo`;
  },
  labProductOrderProductDetail: (productId) =>
    productId
      ? `/scalp-lab-management/labProductOrder/product/${productId}`
      : `/scalp-lab-management/labProductOrder/product/:productId`,

  // terms
  actOfCommercialTransactions: "/act-of-commercial-transactions",
  privacyPolicy: "/privacy-policy",
};

export const routePathPermission = {
  [routePaths.home]: [],
  [routePaths.login]: [],

  // ********* 11
  [routePaths.createCustomerInfo]: [
    permissionCodes.customer,
    permissionCodes.reservation,
  ],
  [routePaths.confirmCustomerInfo()]: [
    permissionCodes.customer,
    permissionCodes.reservation,
  ],
  [routePaths.customterCounselingSheet()]: [
    permissionCodes.customer,
    permissionCodes.reservation,
  ],
  [routePaths.createNewReservationAndVisit()]: [
    permissionCodes.customer,
    permissionCodes.reservation,
  ],
  [routePaths.confirmReservationInfo()]: [
    permissionCodes.reservation,
  ],

  // ********* 12
  [routePaths.customersUndergoingTreatment]: [
    permissionCodes.reservation,
  ],
  [routePaths.duringService()]: [permissionCodes.reservation],
  [routePaths.updateVisitMenu()]: [permissionCodes.reservation],
  [routePaths.duringServiceAddCounselingPage()]: [
    permissionCodes.reservation,
  ],
  [routePaths.customersUndergoingTreatmentIntroduction()]: [
    permissionCodes.reservation,
  ],
  [routePaths.customersUndergoingTreatmentIntroductionScalpLab()]: [
    permissionCodes.reservation,
  ],
  [routePaths.customersUndergoingTreatmentIntroductionScalpLabForm()]:
    [permissionCodes.reservation],

  // ********* 13
  [routePaths.toDayVisitList]: [],
  [routePaths.paymentNonCustomer]: [],
  [routePaths.customerPaymentByCash()]: [],
  [routePaths.customerPaymentByOtherMethod()]: [],

  // ********* 14
  [routePaths.customerManagement]: [permissionCodes.customer],
  [routePaths.customerManagementRegisterNew]: [
    permissionCodes.customer,
  ],
  [routePaths.customerManagementDetail()]: [permissionCodes.customer],
  [routePaths.customerManagementBasicInfo()]: [
    permissionCodes.customer,
  ],
  [routePaths.customerManagementUpdateBasicInfo()]: [
    permissionCodes.customer,
  ],
  [routePaths.customerManagementFactCheck()]: [
    permissionCodes.customer,
  ],
  [routePaths.customerManagementCounselingSheet()]: [
    permissionCodes.customer,
  ],
  [routePaths.customerManagementReservation()]: [
    permissionCodes.customer,
  ],
  [routePaths.customerManagementVisitHistory()]: [
    permissionCodes.customer,
  ],

  // ********* 15
  [routePaths.reservationManagement]: [permissionCodes.reservation],
  [routePaths.reservationManagementRegister]: [
    permissionCodes.reservation,
  ],
  [routePaths.reservationManagementDetail()]: [
    permissionCodes.reservation,
  ],
  [routePaths.reservationManagementEdit()]: [
    permissionCodes.reservation,
  ],
  [routePaths.contactToCustomer()]: [permissionCodes.reservation],

  // ********* 16
  [routePaths.staffManagement]: [],
  [routePaths.staffManagementCalendar]: [],
  [routePaths.staffManagementRegister]: [],
  [routePaths.staffManagementList]: [],
  [routePaths.staffManagementDetail()]: [],
  [routePaths.staffManagementUpdate()]: [],
  [routePaths.staffManagementSelectStaffRegisterShiftPlan]: [],
  [routePaths.staffManagementRegisterShiftPlan()]: [],
  [routePaths.staffManagementListShiftPlan()]: [],
  [routePaths.staffManagementIndividualSales()]: [],
  [routePaths.staffManagementIndividualAttendances()]: [],
  [routePaths.staffManagementUpdateStaffAttendance()]: [
    permissionCodes.attendance_input,
  ],
  [routePaths.staffManagementAttendanceWithoutShiftPlan()]: [
    permissionCodes.attendance_input,
  ],

  // ********* 17
  [routePaths.saleManagement]: [permissionCodes.sales_confirm],
  [routePaths.detailSaleOfSalonPage]: [],
  [routePaths.listStaffMember]: [],
  [routePaths.inputSaleStaff()]: [permissionCodes.sales_input],
  [routePaths.saleOfStaffMemberDetail()]: [],

  // ********* 18
  [routePaths.inventoryManagement]: [permissionCodes.inventory],
  [routePaths.inventoryManagementRegister]: [
    permissionCodes.inventory,
  ],
  [routePaths.inventoryManagementSearch]: [permissionCodes.inventory],
  [routePaths.inventoryManagementCheckStock]: [
    permissionCodes.inventory,
  ],
  [routePaths.inventoryManagementItemDetail]: [
    permissionCodes.inventory,
  ],

  [routePaths.dmsManagement]: [
    permissionCodes.dm_delivery_distribution,
  ],
  [routePaths.dmsManagementHistory]: [
    permissionCodes.dm_delivery_distribution,
  ],
  [routePaths.dmsManagementHistoryDetail()]: [
    permissionCodes.dm_delivery_distribution,
  ],

  [routePaths.analyzeManagement]: [],
  [routePaths.customerVisitAnalyzeManagement]: [],
  [routePaths.customerRepeatRateAnalyzeManagement]: [],
  [routePaths.turnoverAnalyzeManagement]: [],
  [routePaths.averageSpendPerCustomerAnalyzeManagement]: [],
  [routePaths.costRateAnalyzeManagement]: [],
  [routePaths.operatingProfitRatioAnalyzeManagement]: [],
  [routePaths.productivityAnalyzeManagement]: [],
  [routePaths.inputConsultationAnalyzeManagement]: [],

  [routePaths.scalpLabManagement]: [permissionCodes.scalp_lab],
  [routePaths.scalpLabManagementIntroduction]: [
    permissionCodes.scalp_lab,
  ],
  [routePaths.scalpLabManagementApplicationForm]: [
    permissionCodes.scalp_lab,
  ],
  [routePaths.scalpLabManagementConfirmApplicationForm]: [
    permissionCodes.scalp_lab,
  ],
  [routePaths.orderScalpLab]: [permissionCodes.scalp_lab],
  [routePaths.historyOfOrdering]: [permissionCodes.scalp_lab],
  [routePaths.historyOfOrderingDetail()]: [permissionCodes.scalp_lab],
  [routePaths.orderKits]: [permissionCodes.scalp_lab],
  [routePaths.contactOperator]: [permissionCodes.scalp_lab],

  [routePaths.systemManagement]: [
    /* permissionCodes.admin */
  ],
  [routePaths.systemManagementRegisterSalon]: [
    /* permissionCodes.admin */
  ],
  [routePaths.systemManagementDetail()]: [
    /* permissionCodes.admin */
  ],
  [routePaths.systemManagementEdit()]: [
    /* permissionCodes.admin */
  ],
  [routePaths.systemManagementRegisterMenu]: [
    /* permissionCodes.admin */
  ],
  [routePaths.systemManagementAllMenu]: [
    /* permissionCodes.admin */
  ],
  [routePaths.systemManagementMenuEdit()]: [
    /* permissionCodes.admin */
  ],
  [routePaths.systemManagementSettingAccessRight]: [
    /* permissionCodes.admin */
  ],
  [routePaths.systemManagementConnectDevice]: [
    /* permissionCodes.admin */
  ],
  [routePaths.systemManagementConnectDeviceSuccess]: [
    /* permissionCodes.admin */
  ],

  [routePaths.cashierManagement]: [
    /* permissionCodes.admin */
  ],
  [routePaths.cashierManagementAll]: [
    /* permissionCodes.admin */
  ],
  [routePaths.cashierManagementTotalSalesToday]: [
    /* permissionCodes.admin */
  ],
  [routePaths.cashierManagementCashierVisitDetail()]: [
    /* permissionCodes.admin */
  ],
  [routePaths.cashierManagementCashierNonVisitDetail()]: [
    /* permissionCodes.admin */
  ],
  [routePaths.actOfCommercialTransactions]: [],
  [routePaths.privacyPolicy]: [],
  [routePaths.selectCustomer]: [],
};

export default routePaths;
