import DefaultLayout from '../../../layouts/DefaultLayout';
import BackBtn from '../../../components/common/BackButton';
import { height } from '@mui/system';

const PrivacyPolicy = () => {
  return (
    <DefaultLayout containerMargin="mb-12">
      <div className="flex justify-center text-justify">
        <div className="w-full mx-3 sm:mx-16 sm:px-6 px-4 h-[calc(100vh-100px)] sm:h-[calc(100vh-120px)] overflow-auto">
          <div className="sm:w-[1000px] w-full mx-auto">
            <h1 className="my-4 text-center font-bold">プライバシーポリシー</h1>
            <p>
              株式会社頭皮Labo（以下「当社」といいます。）は、当社の提供する頭皮解析サービス
              （以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用
              者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」とい
              います。）を定めます。なお、当社は、ユーザーの個人情報を匿名加工情報又は仮名加工
              情報に加工することは行っておりません。
            </p>
            <br />
            <div>
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>１</div>
                <div>
                  <div>収集する利用者情報及び収集方法</div> <br />
                  <div>
                    本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サー
                    ビス上の行動履歴、その他ユーザーのスマートフォン、PC 等の端末においてユー
                    ザー又はユーザーの端末に関連して生成又は蓄積された情報であって、本ポリシー
                    に基づき当社が収集するものを意味するものとします。本サービスにおいて当社が
                    収集する利用者情報は、その収集方法に応じて、以下のようなものとなります
                  </div>
                  <br />
                  <div className="grid grid-cols-[30px_1fr] gap-y-2">
                    <div>(1)</div>
                    <div>
                      <div>ユーザーからご提供いただく情報</div>{' '}
                      <div>ユーザーが本サービスを利用するために、ご提供いただく情報は以下のとおりです。</div>
                      <div className="grid grid-cols-[20px_1fr]">
                        <div>・</div>
                        <div>氏名</div>
                        <div>・</div>
                        <div>メールアドレス</div>
                        <div>・</div>
                        <div>生年月日</div>
                        <div>・</div>
                        <div>性別</div>
                        <div>・</div>
                        <div>頭皮、頭髪についての自己評価</div>
                        <div>・</div>
                        <div>生活習慣</div>
                        <div>・</div>
                        <div>クレジットカード情報</div>
                        <div>・</div>
                        <div>写真</div>
                        <div>・</div>
                        <div>その他当社が定める入力フォーム又は書式にユーザーが入力又は記入する情報</div>
                      </div>
                    </div>
                    <div>(2)</div>
                    <div>
                      <div>
                        ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報
                      </div>
                      <div>
                        ユーザーが本サービスを利用するにあたり、ソーシャルネットワークサービス等の外部サービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。
                      </div>
                      <div className="grid grid-cols-[20px_1fr]">
                        <div>・</div>
                        <div>当該外部サービスでユーザーが利用するID</div>
                        <div>・</div>
                        <div>その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示 を認めた情報</div>
                      </div>
                    </div>
                    <div>(3)</div>
                    <div>
                      <div>ユーザーが本サービスを利用するにあたって、当社が収集する情報</div>
                      <div>
                        当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
                      </div>
                      <div className="grid grid-cols-[20px_1fr]">
                        <div>・</div>
                        <div>端末情報</div>
                        <div>・</div>
                        <div>ログ情報</div>
                        <div>・</div>
                        <div>Cookie及び匿名ID</div>
                        <div>・</div>
                        <div>位置情報</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>２</div>
                <div>利用目的</div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>２-１</div>
                <div>
                  利用者情報は、2-2 に定めるとおり本サービスの提供のために利用されるほか、2-3
                  に定めるとおり、その他の目的にも利用される場合があります。
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>２-２</div>
                <div>
                  <div>本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとお りです。</div>
                  <div className="grid grid-cols-[30px_1fr] gap-y-2">
                    <div>(1)</div>
                    <div>
                      本サービスに関する登録の受付、本人確認、頭皮の解析の実施、利用料金の計
                      算等本サービスの提供、維持、保護及び改善のため
                    </div>
                    <div>(2)</div>
                    <div>本サービスに関するご案内、お問い合わせ等への対応のため</div>
                    <div>(3)</div>
                    <div>
                      本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
                    </div>
                    <div>(4)</div>
                    <div>本サービスに関する規約等の変更などを通知するため</div>
                    <div>(5)</div>
                    <div>上記の利用目的に付随する利用目的のため</div>
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>２-３</div>
                <div>
                  <div>上記2-2以外の利用目的は以下のとおりです。</div>
                  <div className="grid grid-cols-[30px_1fr] gap-y-2">
                    <div>(1)</div>
                    <div>
                      <div>
                        利用目的：当社のサービスに関連して、個人を識別できない形式に加工した統計データを作成するため
                      </div>
                      <div>対応する利用者情報の項目</div>
                      <div className="grid grid-cols-[20px_1fr]">
                        <div>・</div>
                        <div>端末情報</div>
                        <div>・</div>
                        <div>ログ情報</div>
                        <div>・</div>
                        <div>Cookie及び匿名ID</div>
                        <div>・</div>
                        <div>位置情報</div>
                      </div>
                    </div>
                    <div>(2)</div>
                    <div>
                      <div>当社又は第三者の広告の配信または表示のため</div>
                      <div>対応する利用者情報の項目</div>
                      <div className="grid grid-cols-[20px_1fr]">
                        <div>・</div>
                        <div>端末情報</div>
                        <div>・</div>
                        <div>ログ情報</div>
                        <div>・</div>
                        <div>Cookie及び匿名ID</div>
                        <div>・</div>
                        <div>位置情報</div>
                      </div>
                    </div>

                    <div>(3)</div>
                    <div>
                      <div>利用目的：その他マーケティングに利用するため対応する利用者情報の項目</div>
                      <div>対応する利用者情報の項目</div>
                      <div className="grid grid-cols-[20px_1fr]">
                        <div>・</div>
                        <div>氏名</div>
                        <div>・</div>
                        <div>メールアドレス</div>
                        <div>・</div>
                        <div>生年月日</div>
                        <div>・</div>
                        <div>その他当社が定める入力フォーム又は書式にユーザーが入力又は記入する情報</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>３</div>
                <div>通知・公表又は同意取得の方法、利用中止要請の方法</div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>３-１</div>
                <div>
                  <div>以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。</div>
                  <div className="grid grid-cols-[30px_1fr]">
                    <div>(1)</div>
                    <div>端末情報</div>
                    <div>(2)</div>
                    <div>位置情報</div>
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>３-２</div>
                <div>
                  ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部又は一部についてその利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集又は利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集を停止します。
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>４</div>
                <div>
                  <div>外部送信、第三者提供、情報収集モジュールの有無</div>
                  <br />
                  <div>
                    利用者情報を利用したターゲティング広告の配信のため、本サービスには以下の情報収集モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者への利用者情報の提供を行います。
                  </div>
                  <br />
                  <div className="grid grid-cols-[30px_1fr]">
                    <div>(1)</div>
                    <div>情報収集モジュールの名称：Touhilab System</div>
                    <div>(2)</div>
                    <div>情報収集モジュールの提供者：株式会社頭皮ラボ</div>
                    <div>(3)</div>
                    <div>
                      提供される利用者情報の項目：ユーザーID、アクセス日時、利用デバイス情報（デバイスの種類、OS、ブラウザの種類とバージョン）、IPアドレス、アクセスしたページや利用履歴、クッキーを通じて収集した行動履歴
                    </div>
                    <div>(4)</div>
                    <div>
                      提供の手段・方法：利用者のブラウザから直接、情報収集モジュール提供者のサーバーへデータが送信されます。
                    </div>
                    <div>(5)</div>
                    <div>
                      上記提供者における利用目的：利用者の行動分析と利用傾向の把握、サービス改善および新たなサービスの開発、利用者に最適化された広告の配信、セキュリティ強化と不正アクセスの防止
                    </div>
                    <div>(6)</div>
                    <div>
                      上記提供者における第三者提供の有無：本情報は、法律に基づく要請がある場合、または利用者の同意がある場合を除き、第三者に提供されることはありません。提供が必要となる具体的な第三者のカテゴリー：広告配信事業者、データ分析サービス提供者
                    </div>
                    <div>(7)</div>
                    <div className="text-left">
                      上記提供者のプライバシーポリシーのURL：
                      <a href="https://salon.touhilab-hub.com/privacy_policy">
                        https://salon.touhilab-hub.com/privacy_policy
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>５</div>
                <div>
                  <div>第三者提供</div>
                  <br />
                  <div>
                    当社は、利用者情報のうち、個人情報については、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、あらかじめユーザーの同意を得ないで、第三者に提供しません。但し、次に掲げる場合はこの限りではありません。
                  </div>
                  <br />
                  <div className="grid grid-cols-[30px_1fr]">
                    <div>(1)</div>
                    <div>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合</div>
                    <div>(2)</div>
                    <div>合併その他の事由による事業の承継に伴って個人情報が提供される場合</div>
                    <div>(3)</div>
                    <div>第4項の定めに従って、情報収集モジュール提供者へ個人情報が提供される場合</div>
                    <div>(4)</div>
                    <div>
                      国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
                    </div>
                    <div>(5)</div>
                    <div>その他、個人情報保護法その他の法令で認められる場合</div>
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>６</div>
                <div>
                  <div>個人情報の開示</div>
                  <br />
                  <div>
                    当社は、ユーザーから、個人情報保護法の定めに基づき個人情報及び個人情報の第三者提供にかかる記録の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーから指定のあった方法により、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件当たり1,000円）を頂戴しておりますので、あらかじめ御了承ください。また、ユーザーから指定のあった方法による開示に多額の費用を要する場合その他の当該方法による開示が困難である場合又はユーザーから開示の方法の指定が無かった場合、当社は、書面の交付により、個人情報の開示をいたします。
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>７</div>
                <div>
                  <div> 匿名加工情報の取扱い</div>
                  <br />
                  <div>
                    当社は、次に掲げるとおり匿名加工情報を作成し、第三者に提供いたします。当社は、匿名加工情報を作成し、第三者に提供するにあたっては、個人情報保護法その他の法令を遵守いたします。また、当社は、今後も継続的に同様の匿名加工情報を作成し、第三者に提供することを予定しております。
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>７-１</div>
                <div>
                  <div>
                    当社が作成する匿名加工情報に含まれる個人に関する情報の項目及び第三者に提供する匿名加工情報に含まれる個人に関する情報の項目
                  </div>
                  <br />
                  <div className="grid grid-cols-[30px_1fr]">
                    <div>(1)</div>
                    <div>生年月日</div>
                    <div>(2)</div>
                    <div>性別</div>
                    <div>(3)</div>
                    <div>頭皮、頭髪についての自己評価</div>
                    <div>(4)</div>
                    <div>生活習慣</div>
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>７-２</div>
                <div>
                  <div>匿名加工情報の第三者への提供の方法</div>
                  <br />
                  <div>
                    書面による提供、電子メールによる送信、CD-ROM,USB等の外部記憶媒体の送付、サーバへのアップロード
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>７-３</div>
                <div>
                  <div>匿名加工情報の安全管理措置等の適正な取り扱いを確保するために必要な措置</div>
                  <br />
                  <div>
                    当社は、匿名加工情報の作成に用いた個人情報から削除した記述等の情報について、安全管理のための措置を講じています。また、当社は、匿名加工情報取り扱いマニュアルを作成し、当該マニュアルに従って匿名加工情報を取り扱うことで、匿名加工情報の安全管理措置等の適正な取り扱いを確保するために必要な措置を講じています。匿名加工情報に関するお問い合わせは、「9お問い合わせ窓口」までご連絡ください。8
                    個人情報の訂正及び利用停止等
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>８-１</div>
                <div>
                  <div>
                    当社は、ユーザーから、個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び以下のいずれかに該当するとして個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、合理的な理由に基づいて訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
                  </div>
                  <br />
                  <div className="grid grid-cols-[30px_1fr]">
                    <div>(1)</div>
                    <div>
                      個人情報があらかじめ公表された利用目的の範囲を超えて取り扱われている場合又は偽りその他不正の手段により収集されたものである場合
                    </div>
                    <div>(2)</div>
                    <div>当社が個人情報を利用する必要がなくなった場合</div>
                    <div>(3)</div>
                    <div>
                      個人情報の漏えい、滅失、毀損その他の安全の確保にかかる事態であって個人の権利利益を害するおそれが大きい事態が生じた場合
                    </div>
                    <div>(4)</div>
                    <div>その他個人情報の取扱いにより当該本人の権利又は正当な利益が害されるおそれがある場合</div>
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>８-２</div>
                <div>
                  当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>８-３</div>
                <div>
                  個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、前2項の規定は適用されません。
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>９</div>
                <div>
                  <div>お問い合わせ窓口</div>
                  <br />
                  <div>
                    ご意見、ご質問、苦情のお申出、当社における個人データに該当する利用者情報の安全管理のための措置の内容、その他利用情報に関するお問い合わせは、下記の窓口までお願い致します。
                  </div>
                  <br />
                  <div>住所：〒530-0044 大阪府大阪市北区東天満2-10-31第九田渕ビル305</div>
                  <br />
                  <div>株式会社頭皮Labo 担当部署：高野憲一</div>
                  <br />
                  <div>
                    E-mail：
                    <a href="mailto:inquiry@touhilab.co.jp">inquiry@touhilab.co.jp</a>
                  </div>
                </div>
              </div>
              <br />
              <div className="grid grid-cols-[45px_1fr] gap-y-2">
                <div>１０</div>
                <div>
                  {' '}
                  <div>プライバシーポリシーの変更手続</div>
                  <br />
                  <div>
                    当社は、利用者情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、本ポリシーを変更することがあります。変更した場合には、メール配信の方法でユーザーに通知いたします。但し、法令上ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法でユーザーの同意を得るものとします。
                  </div>
                </div>
              </div>
            </div>
            <div className="h-12"></div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-4 right-4">
        <BackBtn classButton="h-7 sm:h-8" />
      </div>
    </DefaultLayout>
  );
};

export default PrivacyPolicy;
