const UserPointItem = ({ point = 0, ratio = 0, bar = '', textLeft = '', textRight = '' }) => {
  const progressingPoint = Math[point % 1 > 0.9 ? 'floor' : 'ceil'](point * 10) / 10;
  return (
    <div className="w-full h-auto">
      <div className="col-span-11 text-[13px] sm:text-sm text-[#252525] flex justify-between pb-4">
        <span className="text-[13px ]">{textLeft}</span>
        <span className="bg-gray-base px-1 text-[10px]">{textRight}</span>
      </div>
      <div className="flex gap-4 items-center row-span-2 col-span-11 h-9">
        <div className="flex flex-col w-40">
          <div className="text-center text-2xl">{point}</div>
          {bar === 'B' && <hr className="w-2/3 mx-auto border-t-4 border-blue-500 my-1" />}
          {bar === 'R' && <hr className="w-2/3 mx-auto  border-t-4 border-red-500 my-1" />}
          <div className="text-center text-sm">(上位{ratio}%)</div>
        </div>
        <div className="w-full h-full flex gap-1">
          {[...Array(10)].map((_, index) => (
            <div key={index} className={`w-full h-full bg-[#CACACA] rounded`}>
              <div
                className={`h-full bg-[#00B3ED] ${
                  progressingPoint - index >= 1
                    ? 'w-full rounded'
                    : progressingPoint - index > 0
                      ? `w-[${(progressingPoint - index).toFixed(1) * 100}%] rounded-s`
                      : 'w-0'
                }`}
              ></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserPointItem;
