import axios from 'axios';
import store from 'app/redux/store';
// import jwt from "jwt-decode";
import { setAuthToken, setAuthUser } from 'app/redux/authReducer';

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 120 * 1000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const CancelTokens = [];

export const cancelPreviousPageRequest = () => {
  let i = CancelTokens.length;
  while (i--) {
    try {
      CancelTokens[i]?.cancel();
    } catch (e) {
      console.log(e);
    }

    CancelTokens.splice(i, 1);
  }
};

client.interceptors.request.use(
  (config) => {
    const token = store.getState()?.auth.token?.accessToken || '';
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json;charset=UTF-8',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      };
    }

    if (!config.cancelToken) {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      config.cancelToken = source.token;
      CancelTokens.push(source);
    }

    return config;
  },
  (error) => Promise.reject(error),
);

client.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    if (err.response?.status === 400) {
    }
    if (originalConfig?.url !== '/api/login' && err.response) {
      if (err.response.status === 403) {
        window.location = '/Forbidden';
      }
      if (err.response.status === 401) {
        const token = {
          accessToken: null,
          refreshToken: null,
        };
        store.dispatch(setAuthUser(null));
        store.dispatch(setAuthToken(token));
      }
    }
    return Promise.reject(err);
  },
);

export default client;
