import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputField from 'app/components/commonUI/InputField';
import validationSchema from './validator';
import InputImage from '../../commonUI/input-image';
import SelectField from '../../commonUI/SelectField';
import { PREFECTURES } from './const';

function CreateSalonForm({ salonInfo = {}, handleCreate, confirmSalonData, fetchSalonDetail }) {
  const navigate = useNavigate();
  const [geoCode, setGeoCode] = useState({
    lat: typeof fetchSalonDetail?.latitude !== 'undefined' ? Number(fetchSalonDetail.latitude) : 35.6801482,
    lng: typeof fetchSalonDetail?.longitude !== 'undefined' ? Number(fetchSalonDetail.longitude) : 139.7692938,
  });

  const onSubmit = async (values) => {
    const data = {
      ...values,
      latitude: geoCode.lat.toString(),
      longitude: geoCode.lng.toString(),
    };
    confirmSalonData(data);
    handleCreate(data);
  };

  const { handleSubmit, errors, values, setFieldValue, touched, handleBlur } = useFormik({
    enableReinitialize: true,
    initialValues: {
      imageUrl: !!fetchSalonDetail ? fetchSalonDetail.imageUrl : salonInfo.imageUrl || '',
      name: !!fetchSalonDetail ? fetchSalonDetail.name : salonInfo.name || '',
      email: !!fetchSalonDetail ? fetchSalonDetail.email : salonInfo.email || '',
      phone: !!fetchSalonDetail ? fetchSalonDetail.phone : salonInfo.phone || '',
      postalCode: !!fetchSalonDetail ? fetchSalonDetail.postalCode : salonInfo.postalCode || '',
      prefecture: !!fetchSalonDetail ? fetchSalonDetail.prefecture : salonInfo.prefecture || '',
      city: !!fetchSalonDetail ? fetchSalonDetail.city : salonInfo.city || '',
      street: !!fetchSalonDetail ? fetchSalonDetail.street : salonInfo.street || '',
      building: !!fetchSalonDetail ? fetchSalonDetail.building : salonInfo.building || '',
    },
    onSubmit,
    validationSchema,
  });

  const handleSearchByPostalCode = async (postalCode) => {
    try {
      // Use Japan Post API to get address details
      const response = await fetch(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`);
      const data = await response.json();

      if (data.status === 200 && data.results) {
        const addressData = data.results[0];

        // Set address fields
        setFieldValue('prefecture', addressData.address1);
        setFieldValue('city', addressData.address2);
        setFieldValue('street', addressData.address3);

        // Get coordinates using address
        if (window.google?.maps) {
          const geocoder = new window.google.maps.Geocoder();
          const fullAddress = `${addressData.address1}${addressData.address2}${addressData.address3}`;

          const geoResponse = await geocoder.geocode({
            address: fullAddress,
            region: 'jp',
          });

          if (geoResponse.results.length > 0) {
            const location = {
              lat: geoResponse.results[0].geometry.location.lat(),
              lng: geoResponse.results[0].geometry.location.lng(),
            };
            setGeoCode(location);
          }
        }
      } else {
        toast.error('郵便番号情報が見つかりません');
      }
    } catch (error) {
      console.error(error);
      toast.error('郵便番号情報が見つかりません');
    }
  };

  const handlePostalCodeChange = (newValue) => {
    // Remove any non-digit characters
    const cleanedValue = newValue.replace(/[^\d]/g, '');
    setFieldValue('postalCode', cleanedValue);

    // Auto-search when 7 digits are entered
    if (cleanedValue.length === 7) {
      handleSearchByPostalCode(cleanedValue);
    }
  };

  return (
    <form className="w-full h-full">
      <div className="w-full h-full flex flex-col space-y-6 p-4">
        <InputImage
          label="アイコン"
          imageUrl={values.imageUrl}
          setImageUrl={(newValue) => setFieldValue('imageUrl', newValue)}
        />
        <InputField
          label="店舗名"
          name="name"
          value={values.name}
          type="text"
          onChange={(newValue) => setFieldValue('name', newValue)}
          onBlur={handleBlur}
          touched={touched.name}
          errors={errors.name}
          inputWrapClasses="sm:w-[35%]"
        />

        <InputField
          label="メールアドレス"
          name="email"
          value={values.email}
          type="email"
          onChange={(newValue) => setFieldValue('email', newValue)}
          onBlur={handleBlur}
          touched={touched.email}
          errors={errors.email}
          inputWrapClasses="sm:w-[35%]"
        />

        <InputField
          label="電話番号"
          name="phone"
          value={values.phone}
          type="tel"
          onChange={(newValue) => setFieldValue('phone', newValue)}
          onBlur={handleBlur}
          touched={touched.phone}
          errors={errors.phone}
          inputWrapClasses="sm:w-[35%]"
        />

        <div className="flex items-center sm:sm:w-[35%] relative">
          <InputField
            label="郵便番号"
            name="postalCode"
            inputProps={{ maxLength: 7 }}
            value={values.postalCode}
            type="tel"
            onChange={(newValue) => handlePostalCodeChange(newValue)}
            onBlur={handleBlur}
            touched={touched.postalCode}
            errors={errors.postalCode}
            inputClasses="w-[120px]"
          />
          <div className="absolute left-[140px] text-sm text-[#666666] mt-[26px]">住所を自動入力</div>
        </div>

        <SelectField
          label="都道府県"
          inputWrapClasses="sm:w-[35%]"
          inputClasses="w-full"
          value={values.prefecture}
          setValue={(newValue) => setFieldValue('prefecture', newValue)}
          onBlur={handleBlur}
          touched={touched.prefecture}
          errors={errors.prefecture}
          options={PREFECTURES}
        />

        <InputField
          label="市区町村"
          name="city"
          value={values.city}
          type="text"
          onChange={(newValue) => setFieldValue('city', newValue)}
          onBlur={handleBlur}
          touched={touched.city}
          errors={errors.city}
          inputWrapClasses="sm:w-[35%]"
        />

        <InputField
          label="町名"
          name="street"
          value={values.street}
          type="text"
          onChange={(newValue) => setFieldValue('street', newValue)}
          onBlur={handleBlur}
          touched={touched.street}
          errors={errors.street}
          inputWrapClasses="sm:w-[35%]"
        />

        <InputField
          label="番地・ビル名"
          name="building"
          value={values.building}
          type="text"
          onChange={(newValue) => setFieldValue('building', newValue)}
          onBlur={handleBlur}
          touched={touched.building}
          errors={errors.building}
          inputWrapClasses="sm:w-[35%]"
        />
      </div>

      <div className="w-full h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0">
        <button
          onClick={() => navigate(-1)}
          type="button"
          className="px-6 py-2 text-black bg-white border border-gray-300 rounded-xl hover:bg-gray-50 w-6/12 shadow sm:mr-4 ml-[16px] mr-2"
        >
          キャンセル
        </button>
        <button
          onClick={handleSubmit}
          type="button"
          className="px-6 py-2 text-white bg-blue-btn-primary rounded-xl hover:bg-blue-600 w-6/12 shadow mr-[16px]"
        >
          変更する
        </button>
      </div>
    </form>
  );
}

export default CreateSalonForm;
