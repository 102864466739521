import React, { useMemo, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
// import Path from "../../../route/Path";
import MainContentLayout from "../../../layouts/MainContentLayout";
import { toast } from "react-toastify";
import { sendCustomerContact } from "../../../services/api";
import CreateCompleteDialog from "../../../components/CommonDialog/CreateCompleteDialog";
import GA, { EVENT_NAMES } from "../../../services/googleAnalytics";

function ContactingCustomer() {
  const [contact, setContact] = useState({
    title: "",
    content: "",
  });

  const [contactConfirmed, setContactConfirmed] = useState(null);
  const [sendContactStatus, setSendContactStatus] = useState("idle");
  const { customerId } = useParams();

  const navigate = useNavigate();

  const isValidContact = useMemo(
    () =>
      contact.title &&
      contact.content &&
      contact.title.trim() &&
      contact.content.trim(),
    [contact]
  );

  const handleSendContact = () => {
    if (!contact || sendContactStatus === "loading") return;
    setSendContactStatus("loading");

    sendCustomerContact(customerId, contactConfirmed)
      .then((res) => {
        setSendContactStatus("success");
      })
      .catch((error) => {
        toast.error("Send consultation error!");
        console.log("error: ", error);
        setSendContactStatus("idle");
      });
  };

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>予約</p>
          </>
        }
      >
        <div className="w-full h-full pt-16 pb-8 lg:py-12 relative px-4">
          <div className="h-16 lg:h-12 pl-20 lg:pl-0 flex items-center justify-start absolute inset-x-0 left-4 right-4 top-0 bg-[#F0F1EF] bg-opacity-50">
            <h2 className="text-base lg:text-xl font-semibold">
              お客様への連絡
            </h2>
          </div>

          <div className="w-full h-full py-3">
            <div className="w-full h-full border border-gray-400 p-6 rounded-xl">
              <div className="w-full h-auto flex items-center mb-6">
                <label
                  className="w-20 h-10 flex items-center font-semibold"
                  htmlFor="title"
                >
                  タイトル
                </label>
                <div className="w-[calc(100%-80px)] h-10">
                  <input
                    id="title"
                    type="text"
                    value={contact.title}
                    onChange={(e) =>
                      setContact((oldState) => ({
                        ...oldState,
                        title: e.target.value,
                      }))
                    }
                    readOnly={contactConfirmed}
                    className={`w-full h-full px-3 py-2 text-base border border-gray-400 rounded-md ${
                      contactConfirmed ? "bg-gray-100" : ""
                    }`}
                  />
                </div>
              </div>

              <div className="w-full h-[calc(100%-64px)] flex items-start">
                <label
                  className="w-20 h-10 flex items-center font-semibold"
                  htmlFor="content"
                >
                  本文
                </label>
                <div className="w-[calc(100%-80px)] h-full">
                  <textarea
                    id="content"
                    type="text"
                    value={contact.content}
                    onChange={(e) =>
                      setContact((oldState) => ({
                        ...oldState,
                        content: e.target.value,
                      }))
                    }
                    readOnly={contactConfirmed}
                    className={`w-full h-full px-3 py-2 text-base border border-gray-400 rounded-md ${
                      contactConfirmed ? "bg-gray-100" : ""
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="left-4 right-4 h-8 flex items-center justify-end text-white text-base font-semibold absolute inset-x-0 bottom-0 pt-4">
            {!contactConfirmed ? (
              <>
                <Link
                  to={-1}
                  className="button-size bg-gray-btn-secondary mr-4"
                >
                  戻る
                </Link>

                <button
                  onClick={() => {
                    if (isValidContact) setContactConfirmed(contact);
                  }}
                  type="button"
                  className={`button-size ${
                    isValidContact
                      ? "bg-blue-btn-primary"
                      : "bg-gray-btn-secondary"
                  }`}
                >
                  内容確認
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => setContactConfirmed(null)}
                  type="button"
                  className="button-size bg-gray-btn-secondary mr-4 lg:mr-6"
                >
                  戻る
                </button>

                <button
                  onClick={() => {
                    GA.tracking(EVENT_NAMES.CUSTOMER_DETAIL.CONTACT, {
                      customerId: customerId,
                    });
                    handleSendContact();
                  }}
                  type="button"
                  className="button-size bg-blue-btn-primary"
                >
                  登録
                </button>
              </>
            )}
          </div>
        </div>

        <CreateCompleteDialog
          open={sendContactStatus === "success"}
          createCompleteTitle="顧客連絡先が送信されました"
          createCompleteBtnLabel2="終了"
          createCompleteAction2={() => {
            navigate(-1);
          }}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default ContactingCustomer;
