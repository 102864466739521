import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { Radar } from 'react-chartjs-2';

import { getHistoryOfOrderingDetail, getScalpProducts } from '../../../../services/api/1C';
import DefaultLayout from '../../../../layouts/DefaultLayout';
import MainContentLayout from '../../../../layouts/MainContentLayout';
import LoadingProgress from '../../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../../components/commonUI/LoadInitError';
import NoData from '../../../../components/commonUI/NoData';
import { SelectItem } from './SelectItem';
import useDimension from '../../../../hooks/useDimension';
import UserPoint from './UserPoint';

import TypesReferenceInfo from './TypesReferenceInfo';
import { ANALYSE_STATUS } from '../../../../constants';
import { convertClusterToClass } from './helpers';
import {
  AVERAGE_SCORE_BY_CLUSTER,
  BACKGROUND_COLOR_BY_CLUSTER,
  BORDER_COLOR_BY_CLUSTER,
  DEFAULT_RADAR_DATA,
  EXPECTED_HEIGHT,
  EXPECTED_RATIO,
  IMAGE_URL_TOUHI_CLASS,
} from './constants';
import { getTScore } from '../../../../utils';
import NavigationBackBtn from '../../../../components/common/NavigationBackButton';
import moment from 'moment';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

function HistoryOfOrderDetailByKitIdPage() {
  const customerNo = useParams();
  const scroll = React.useRef(null);
  const radarRef = React.useRef();

  const [fetchHistoryOrderDetail, setFetchHistoryOrderDetail] = useState({
    isLoading: false,
    data: null,
    error: null,
  });
  const [radarData, setRadarData] = useState(DEFAULT_RADAR_DATA);
  const [scalpData, setScalpData] = useState(false);
  const [products, setProducts] = useState([]);

  const touhiImageUrl = IMAGE_URL_TOUHI_CLASS[scalpData.factorCluster];

  const isFactorClusterInvalid = scalpData.factorCluster === 0;

  const { width } = useDimension();
  const isTablet = width > 768;

  const labelSize = 24;
  const chartHeight = (width > EXPECTED_HEIGHT ? EXPECTED_HEIGHT : width) / EXPECTED_RATIO;
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
      },
    },
    scales: {
      r: {
        angleLines: {
          display: false,
        },
        min: 0,
        max: 10,
        beginAtZero: true,
        padding: 0,
        backgroundColor: '#F3F3F3',
        grid: {
          lineWidth: 0.5,
        },
        ticks: {
          display: false,
          showLabelBackdrop: false,
          stepSize: 2,
        },
        pointLabels: {
          display: true,
          padding: 6,
          color: 'black',
          font: {
            size: labelSize,
          },
        },
      },
    },
  };

  const loadHistoryOrderDetail = () => {
    setFetchHistoryOrderDetail({
      isLoading: true,
      data: null,
      error: null,
    });
    getHistoryOfOrderingDetail(customerNo.customerNo)
      .then((res) => {
        const found = res.data.find(function (item) {
          return item.scalpData.inspectionKitId === customerNo.inspectionKitId;
        });
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: found,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryOrderDetail({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  const loadScalpProducts = async () => {
    const res = await getScalpProducts(scalpData?.factorCluster);
    const _products = res.result ?? [];
    setProducts(_products);
  };

  const scrollToBottom = () => {
    scroll.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const displayName = useMemo(() => {
    const {
      lastNameKanji = '',
      firstNameKanji = '',
      lastNameKata = '',
      firstNameKata = '',
    } = fetchHistoryOrderDetail?.data?.customer || {};
    const lastName = lastNameKanji || lastNameKata || '';
    const firstName = firstNameKanji || firstNameKata || '';
    return `${lastName} ${firstName}さん`;
  }, [fetchHistoryOrderDetail]);

  useEffect(() => {
    if (radarRef.current) {
      radarRef.current.scales.r.options.pointLabels._proxy.font.size = labelSize;
      radarRef.current.canvas.style.height = chartHeight + 'px';
    }
  }, [chartHeight, labelSize]);

  useEffect(() => {
    loadHistoryOrderDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scalpData?.factorCluster) {
      loadScalpProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scalpData]);

  useEffect(() => {
    // 在这里执行你想要的操作，可以访问 fetchHistoryOrderDetail 的最新值
    // console.log(
    //   "fetchHistoryOrderDetail changed:",
    //   fetchHistoryOrderDetail
    // );

    // 例如，检查 isLoading 是否为 true，然后执行相应的操作
    if (fetchHistoryOrderDetail.isLoading) {
      // 执行你的操作...
    } else {
      if (fetchHistoryOrderDetail.data?.scalpData?.factorCellArea == null) {
      } else if (fetchHistoryOrderDetail.data?.scalpData?.factorPeeling == null) {
      } else if (fetchHistoryOrderDetail.data?.scalpData?.factorCP == null) {
      } else {
        const cluster = fetchHistoryOrderDetail.data?.scalpData?.factorCluster;

        const backgroundColor = BACKGROUND_COLOR_BY_CLUSTER[cluster] ?? 'rgba(157, 193, 226, 0.7)';
        const borderColor = BORDER_COLOR_BY_CLUSTER[cluster] ?? '';
        const averageValues = AVERAGE_SCORE_BY_CLUSTER[cluster] ?? [];
        const convertedTScores = [averageValues[0] ?? 0, averageValues[1] ?? 0, averageValues[2] ?? 0];

        const factorCellArea = (Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorCellArea * 1000) / 1000).toFixed(3);

        const factorPeeling = (Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorPeeling * 1000) / 1000).toFixed(3);

        const factorCP = (Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorCP * 1000) / 1000).toFixed(3);

        const barCA = fetchHistoryOrderDetail.data?.scalpData?.barCA ?? 0;

        const barCP = fetchHistoryOrderDetail.data?.scalpData?.barCP ?? 0;

        const barMP = fetchHistoryOrderDetail.data?.scalpData?.barMP ?? 0;

        const ratioCA = fetchHistoryOrderDetail.data?.scalpData?.ratioCA ?? 0;

        const ratioCP = fetchHistoryOrderDetail.data?.scalpData?.ratioCP ?? 0;

        const ratioMP = fetchHistoryOrderDetail.data?.scalpData?.ratioMP ?? 0;

        setRadarData({
          labels: [
            (Math.round(factorCellArea * 10) / 10).toFixed(1),
            (Math.round(factorPeeling * 10) / 10).toFixed(1),
            (Math.round(factorCP * 10) / 10).toFixed(1),
          ],
          datasets: [
            {
              label: 'average value',
              data: convertedTScores,

              order: 2,
              borderColor,
              backgroundColor: 'transparent',
              borderWidth: 1,
              pointStyle: false,
            },
            {
              label: '# of Votes',
              data: [
                (Math.round(factorCellArea * 10) / 10).toFixed(1),
                (Math.round(factorPeeling * 10) / 10).toFixed(1),
                (Math.round(factorCP * 10) / 10).toFixed(1),
              ],
              order: 1,
              backgroundColor,
              borderColor: backgroundColor,
              borderWidth: 1,
              pointStyle: false,
            },
          ],
        });

        setScalpData({
          factorCellArea: factorCellArea,
          factorPeeling: factorPeeling,
          factorCP: factorCP,
          factorScore: Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorScore),
          factorCluster: Math.round(fetchHistoryOrderDetail.data?.scalpData?.factorCluster),
          barCA,
          barMP,
          barCP,
          ratioCA,
          ratioMP,
          ratioCP,
        });
      }

      if (
        fetchHistoryOrderDetail.data?.scalpData?.analyseStatus === ANALYSE_STATUS.ANALYSE_NOT_STARTED ||
        fetchHistoryOrderDetail.data?.scalpData?.analyseStatus === ANALYSE_STATUS.ANALYSE_IN_PROGRESS
      ) {
        setScalpData({});
      }
    }
  }, [fetchHistoryOrderDetail]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>ラボ</p>
          </>
        }
      >
        <div className="w-full h-16 lg:h-12">
          <h2 className="w-full text-base lg:text-xl font-medium">
            <NavigationBackBtn
              text={'検体No. ' + customerNo.inspectionKitId}
              actionButton={
                <div className="mr-2">
                  <Link
                    to={-1}
                    className="button-size h-9 bg-white rounded-lg"
                    style={{ boxShadow: '0px 1px 10px rgba(0, 107, 136, 0.2)' }}
                  >
                    <span className="text-sm text-black font-medium">他の検体を見る</span>
                  </Link>
                </div>
              }
            />
          </h2>
        </div>

        {isTablet ? (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-8 pb-8">
            <div className="w-full h-full py-3 px-2">
              {fetchHistoryOrderDetail.isLoading && <LoadingProgress />}
              {!fetchHistoryOrderDetail.isLoading &&
              fetchHistoryOrderDetail.error &&
              fetchHistoryOrderDetail.data?.inspectionKitId ? (
                <LoadInitError error={fetchHistoryOrderDetail.error} />
              ) : null}
              {!fetchHistoryOrderDetail.isLoading && !fetchHistoryOrderDetail.error ? (
                <div className="md:flex gap-6 h-full">
                  <div className="w-full flex flex-col h-1/2 md:h-full gap-6 items-center">
                    {!scalpData ? (
                      <LoadingProgress />
                    ) : (
                      <>
                        {isFactorClusterInvalid ? (
                          <>
                            <div className="mt-4 mb-10 w-full lg:w-[500px]">
                              <div className="bg-[#C8C5C2] p-8 rounded-md">
                                <div className="mb-8 py-2 bg-[#808080]">
                                  <h2 className="text-white text-center text-lg font-bold">判定不能</h2>
                                </div>
                                <div className="text-center font-bold">
                                  <p>頭皮判定ができませんでした。</p>
                                  <p>下記の理由が考えられます。</p>
                                  <p>再度、採取をお願いいたします。</p>
                                </div>
                              </div>
                              <div className="flex justify-center">
                                <div className="mt-16 grid grid-cols-[18px_1fr]">
                                  <div>・</div>
                                  <div>採取できている角層が全体的に少ない。</div>
                                  <div></div>
                                  <div>頭皮が油っぽい人は特にご注意ください。</div>
                                  <div>・</div>
                                  <div>テープの上半分にしか角層が採取できていない。</div>
                                  <div>・</div>
                                  <div>テープの真ん中に角層が採取できていない。</div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="w-full flex items-center justify-between px-2 md:px-4 text-xs text-[#666666]">
                              <div>お客様No. {customerNo.customerNo}</div>
                              <div>
                                発送日時：{' '}
                                {moment(fetchHistoryOrderDetail.data?.scalpData?.deliverDate).format(
                                  'YYYY-MM-DD HH:mm',
                                )}
                              </div>
                            </div>
                            <div className="w-full flex gap-5 border-b">
                              <div className="flex-1">
                                {scalpData?.factorCluster && (
                                  <>
                                    <TypesReferenceInfo
                                      type={convertClusterToClass(scalpData?.factorCluster)}
                                      factorCluster={scalpData?.factorCluster}
                                      displayName={displayName}
                                      isTablet
                                    />
                                    <div className={'w-full mt-4'}>
                                      {/* <Link
                                        to={'#'}
                                        className="button-size h-9 bg-white rounded-lg"
                                        style={{ boxShadow: '0px 1px 10px rgba(0, 107, 136, 0.2)' }}
                                      >
                                        <span className="text-sm text-black">タイプごとの特徴を確認する</span>
                                      </Link> */}
                                    </div>
                                    <div className="w-full flex flex-col items-center mt-8">
                                      <div>判定結果の詳細</div>
                                      <div className="border border-[#CACACA] mt-4">
                                        <img className="object-fit" src={touhiImageUrl} alt="touhi" />
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="w-[45%] lg:flex-1">
                                <UserPoint scalpData={scalpData} isTablet />
                                <div className="w-full h-auto flex flex-col items-center justify-center py-8">
                                  <div className="text-center">
                                    <div className="text-xs">細胞面積(階層細胞の大きさ)</div>
                                    <div className="inline-block bg-gray-base px-1 rounded text-[10px]">
                                      ターンオーバーの指標
                                    </div>
                                  </div>
                                  <div className="w-full h-auto mb-[-50px]">
                                    <Radar
                                      ref={radarRef}
                                      onClick={scrollToBottom}
                                      data={radarData ? radarData : {}}
                                      options={chartOptions}
                                      height={chartHeight}
                                    />
                                  </div>
                                  <div className="w-full flex justify-between">
                                    <div className="text-center">
                                      <div className="text-xs">カルボニルタンパク</div>
                                      <div className="inline-block bg-gray-base px-1 rounded text-[10px]">
                                        頭皮酸化ストレスの指標
                                      </div>
                                    </div>
                                    <div className="text-center">
                                      <div className="text-xs">多重剥離度(角層の重なり)</div>
                                      <div className="inline-block bg-gray-base px-1 rounded text-[10px]">
                                        乾燥や肌荒れの指標
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {!isFactorClusterInvalid && <SelectItem products={products} isTablet />}
                  </div>
                </div>
              ) : (
                <NoData />
              )}
            </div>
          </div>
        ) : (
          <div className="w-full h-full mx-auto relative max-h-full flex flex-wrap pt-2 pb-8 lg:py-12">
            <div className="w-full h-full py-3 px-4">
              {fetchHistoryOrderDetail.isLoading && <LoadingProgress />}
              {!fetchHistoryOrderDetail.isLoading &&
              fetchHistoryOrderDetail.error &&
              fetchHistoryOrderDetail.data?.inspectionKitId ? (
                <LoadInitError error={fetchHistoryOrderDetail.error} />
              ) : null}

              {!fetchHistoryOrderDetail.isLoading && !fetchHistoryOrderDetail.error ? (
                <div className="md:flex gap-6 h-full">
                  <div className="w-full flex flex-col h-1/2 md:h-full gap-6 items-center">
                    {/* <InputField
                        label="お客様No."
                        name="customerNo"
                        value={fetchHistoryOrderDetail.data?.customerNo}
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="お客様名"
                        name="customerName"
                        value={
                          fetchHistoryOrderDetail.data?.scalpData.customerName
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="キットID"
                        name="inspectionKitId"
                        value={
                          fetchHistoryOrderDetail.data?.scalpData
                            .inspectionKitId
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="発送時間"
                        name="shippingDateTime"
                        value={moment(
                          fetchHistoryOrderDetail.data?.scalpData?.deliverDate
                        ).format("YYYY-MM-DD HH:mm")}
                        disabled={true}
                        labelClasses="!w-24"
                      />
                      <InputField
                        label="状況"
                        name="situation"
                        value={
                          // fetchHistoryOrderDetail.data?.scalpData
                          //   ?.analyseStatus
                          //   ? fetchHistoryOrderDetail.data?.scalpData
                          //       ?.analyseStatus
                          //   : "pending"
                          fetchHistoryOrderDetail.data?.scalpData
                            .analyseStatus == "analyse_not_started"
                            ? "未分析"
                            : fetchHistoryOrderDetail.data?.scalpData
                                .analyseStatus == "analyse_in_progress"
                            ? "分析中"
                            : fetchHistoryOrderDetail.data?.scalpData
                                .analyseStatus == "analyse_completed"
                            ? "分析完了"
                            : ""
                        }
                        disabled={true}
                        labelClasses="!w-24"
                      /> */}

                    {!scalpData ? (
                      <LoadingProgress />
                    ) : (
                      <>
                        {isFactorClusterInvalid ? (
                          <>
                            <div className="mt-4 mb-10 w-full lg:w-[500px]">
                              <div className="bg-[#C8C5C2] p-8 rounded-md">
                                <div className="mb-8 py-2 bg-[#808080]">
                                  <h2 className="text-white text-center text-lg font-bold">判定不能</h2>
                                </div>
                                <div className="text-center font-bold">
                                  <p>頭皮判定ができませんでした。</p>
                                  <p>下記の理由が考えられます。</p>
                                  <p>再度、採取をお願いいたします。</p>
                                </div>
                              </div>
                              <div className="flex justify-center">
                                <div className="mt-16 grid grid-cols-[18px_1fr]">
                                  <div>・</div>
                                  <div>採取できている角層が全体的に少ない。</div>
                                  <div></div>
                                  <div>頭皮が油っぽい人は特にご注意ください。</div>
                                  <div>・</div>
                                  <div>テープの上半分にしか角層が採取できていない。</div>
                                  <div>・</div>
                                  <div>テープの真ん中に角層が採取できていない。</div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <div className="w-full flex items-center justify-between mb-2 text-xs text-[#666666]">
                                <div>お客様No. {customerNo.customerNo}</div>
                                <div>
                                  発送日時：{' '}
                                  {moment(fetchHistoryOrderDetail.data?.scalpData?.deliverDate).format(
                                    'YYYY-MM-DD HH:mm',
                                  )}
                                </div>
                              </div>
                              {scalpData?.factorCluster && (
                                <TypesReferenceInfo
                                  type={convertClusterToClass(scalpData?.factorCluster)}
                                  factorCluster={scalpData?.factorCluster}
                                  displayName={displayName}
                                  isTablet
                                />
                              )}
                            </div>
                            {scalpData?.factorCluster && (
                              <div className="w-full">
                                {/* <Link
                                  to={'#'}
                                  className="button-size h-9 bg-white rounded-lg"
                                  style={{ boxShadow: '0px 1px 10px rgba(0, 107, 136, 0.2)' }}
                                >
                                  <span className="text-sm text-black">タイプごとの特徴を確認する</span>
                                </Link> */}
                              </div>
                            )}
                            <div className="w-full md:w-1/2 h-auto flex flex-col gap-6 items-start justify-center">
                              {scalpData?.factorCluster && (
                                <div className="w-full flex flex-col items-center pt-4 pb-8 border-b">
                                  <div className="mb-4">判定結果の詳細</div>
                                  <div className="border border-[#CACACA] mt-4">
                                    <img className="object-fit" src={touhiImageUrl} alt="touhi" />
                                  </div>
                                </div>
                              )}
                              <UserPoint scalpData={scalpData} />
                              <div className="w-full h-auto flex flex-col items-center justify-center pb-8 border-b">
                                <div className="text-center">
                                  <div className="text-xs">細胞面積(階層細胞の大きさ)</div>
                                  <div className="inline-block bg-gray-base px-1 rounded text-[10px]">
                                    ターンオーバーの指標
                                  </div>
                                </div>
                                <div className="w-full h-auto sm:mt-12 mb-[-50px]">
                                  <Radar
                                    ref={radarRef}
                                    onClick={scrollToBottom}
                                    data={radarData ? radarData : {}}
                                    options={chartOptions}
                                    height={chartHeight}
                                  />
                                </div>
                                <div className="w-full flex justify-between mt-4">
                                  <div className="text-center">
                                    <div className="text-xs">カルボニルタンパク</div>
                                    <div className="inline-block bg-gray-base px-1 rounded text-[10px]">
                                      頭皮酸化ストレスの指標
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    <div className="text-xs">多重剥離度(角層の重なり)</div>
                                    <div className="inline-block bg-gray-base px-1 rounded text-[10px]">
                                      乾燥や肌荒れの指標
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* <div className="w-full h-1/2 lg:h-full p-4">
                        {fetchHistoryOrderDetail.data?.scalpData
                          ?.analyseStatus === "analyse_completed" ? (
                          <>
                            <p className="whitespace-nowrap px-6 py-4">
                              クラスター　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorCluster.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              細胞面積　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorCellArea.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              多重剥離　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorPeeling.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              CP　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorCP.toFixed(
                                1
                              )}
                            </p>
                            <p className="whitespace-nowrap px-6 py-4">
                              総合　：　
                              {fetchHistoryOrderDetail.data?.scalpData.factorScore.toFixed(
                                1
                              )}
                            </p>
                          </>
                        ) : (
                          "まだ分析終わってない"
                        )}
                      </div> */}

                    {!isFactorClusterInvalid && <SelectItem products={products} />}
                  </div>
                </div>
              ) : (
                <NoData />
              )}
            </div>
          </div>
        )}
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default HistoryOfOrderDetailByKitIdPage;
