import React from 'react';
import { FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import BottomButtons from '../BottomButtons';
import SurveyButton from '../SurveyButton';
import { questions, sections } from '../../../pages/14/Customer1StImpressionAndFactCheckPage/constants';

const SelectingBlock = ({
  stepId,
  selectedValue,
  selectedButtons,
  isDisableFlag = false,
  inputData,
  handleChange,
  handleInputChange,
  handleButtonClick,
  handleNextSection,
  handleSubmit,
  customerName,
}) => {
  return (
    <div className="w-full h-full bg-white">
      {/* Customer Info & Section Select */}
      <div className="px-4 py-4">
        <div className="mb-2">
          <span className="text-base text-gray-600">お客様名：</span>
          <span className="text-base">{customerName}</span>
        </div>
        <FormControl fullWidth size="small">
          <Select
            value={selectedValue}
            onChange={handleChange}
            className="bg-white sm:w-[352px] w-full h-12"
            sx={{
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#E5E7EB',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#9CA3AF',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#2563EB',
              },
            }}
          >
            {sections.map((section) => (
              <MenuItem key={section.id} value={section.id}>
                {section.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {/* Questions */}
      <div className="px-4 py-2">
        {questions[stepId - 1].map((question) => (
          <div key={question.id} className="mb-6">
            <div className="flex items-center mb-4">
              <div className="w-1 h-6 bg-[#00B3ED] mr-4" />
              <div className="text-sm font-medium text-gray-900">
                <span className="text-[#00B3ED]">{question.index}.</span>
                <span className="ml-2">{question.content}</span>
              </div>
            </div>
            {question.type === 'options' ? (
              <div className="grid grid-cols-3 gap-2 sm:gap-3">
                {question.options.map((option) => (
                  <SurveyButton
                    key={option}
                    text={option}
                    name={question.id}
                    active={
                      question.isMultiSelect
                        ? selectedButtons[question.id]?.answers?.includes(option)
                        : selectedButtons[question.id]?.answer === option
                    }
                    disabled={isDisableFlag}
                    handleClick={() => handleButtonClick(question.id, option)}
                  />
                ))}
              </div>
            ) : (
              <div className="w-full">
                <input
                  type="text"
                  name={question.id}
                  className="w-full sm:w-[352px] h-[48px] px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="入力してください"
                  value={inputData[question.id]?.answer || ''}
                  disabled={isDisableFlag}
                  onChange={(e) => handleInputChange(e, question.id)}
                />
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Bottom Buttons */}
      <div className="bg-white px-4 py-4">
        <BottomButtons step={stepId} handleNextSection={handleNextSection} handleSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default SelectingBlock;
