import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from 'app/layouts/DefaultLayout';
import MainContentLayout from 'app/layouts/MainContentLayout';
import Path from 'app/route/Path';
import NavigationBackBtn from 'app/components/common/NavigationBackButton';
import { getDetailSalonStaff } from 'app/services/api';
import LoadingBackdrop from 'app/components/commonUI/LoadingBackdrop';
import BasicTabs from 'app/components/common/BasicTabs';
import TabPanel from 'app/components/common/BasicTabs/TabPanel';
import defaultAvatar from 'app/assets/svg/default_avatar.svg';
import { Avatar } from '@mui/material';
import ItemBasicInfo from '../components/ItemBasicInfo';
import TabRightStaffDetail from '../components/TabRightStaffDetail';
import { formatToJapaneseDate } from 'app/utils';
import { CSVLink } from 'react-csv';
import GA, { EVENT_NAMES } from '../../../services/googleAnalytics';

const StaffManagementDetailPage = () => {
  const navigate = useNavigate();
  const { staffId } = useParams();
  const [activeTab, setActiveTab] = useState('0');
  const [staffDetail, setStaffDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadStaffDetail = async (staffId) => {
    setLoading(true);
    try {
      const { data } = await getDetailSalonStaff(staffId);
      setStaffDetail(data);
    } catch (error) {
      console.error('Failed to load staff detail:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (staffId) {
      loadStaffDetail(staffId);
    }
  }, [staffId]);

  const handleTabChange = (newValue) => setActiveTab(newValue);

  const handleUpdateClick = () => navigate(Path.staffManagementUpdate(staffId));

  const renderBasicInfo = () => (
    <div className="mt-4">
      <div className="flex justify-center mb-8">
        <Avatar
          alt="Avatar"
          src={staffDetail?.imageUrl || defaultAvatar}
          sx={{
            width: 112,
            height: 112,
            border: '2px solid #D9D9D9',
          }}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-6 lg:gap-x-32">
        <ItemBasicInfo label="スタッフ名" value={`${staffDetail?.lastNameKanji} ${staffDetail?.firstNameKanji}`} />
        <ItemBasicInfo
          label="スタッフ名フリガナ"
          value={`${staffDetail?.lastNameKata} ${staffDetail?.firstNameKata}`}
        />
        <ItemBasicInfo label="生年月日" value={formatToJapaneseDate(staffDetail?.dob)} />
        <ItemBasicInfo label="電話番号" value={staffDetail?.phone} />
        <ItemBasicInfo label="メールアドレス" value={staffDetail?.email} />
        <ItemBasicInfo label="郵便番号" value={staffDetail?.postalCode} />
        <ItemBasicInfo label="住所" value={staffDetail?.address} />
        <ItemBasicInfo label="ログイン名" value={staffDetail?.loginName} />
      </div>
      <div className="sm:mt-[120px] mt-8">
        <button
          onClick={handleUpdateClick}
          className="px-6 py-2 text-white bg-blue-btn-primary rounded hover:bg-blue-600"
        >
          変更する
        </button>
      </div>
    </div>
  );

  const renderAccessRights = () => (
    <div className="space-y-4">
      <TabRightStaffDetail staffId={staffId} />
    </div>
  );

  const csvData = useMemo(() => {
    const data = [['', '']];
    if (staffDetail) {
      data.push(['セイ', staffDetail.lastNameKata]);
      data.push(['メイ', staffDetail.firstNameKata]);
      data.push(['姓', staffDetail.lastNameKanji]);
      data.push(['名', staffDetail.firstNameKanji]);
      data.push(['生年月日', staffDetail.dob]);
      data.push(['電話番号', staffDetail.phone]);
      data.push(['メールアドレス', staffDetail.email]);
      data.push(['住所', staffDetail.postalCode]);
      data.push(['', staffDetail.address]);
    }
    return data;
  }, [staffDetail]);

  const renderCSVButton = () => {
    return (
      <div className="flex justify-end">
        <CSVLink
          onClick={() => {
            GA.tracking(EVENT_NAMES.STAFF_DETAIL.DOWNLOAD_CSV, {
              staffId,
            });
          }}
          data={csvData}
          className="bg-blue-btn-primary px-4 py-2 rounded-[8px] text-white text-sm"
        >
          <span>エクスポート</span>
        </CSVLink>
      </div>
    );
  };

  console.log('activeTab', activeTab);

  return (
    <DefaultLayout>
      <LoadingBackdrop isOpen={loading} />
      <MainContentLayout contentClass="lg:min-h-full">
        <div className="w-full h-16 lg:h-12 flex items-center justify-between inset-x-0 top-0">
          <h2 className="text-base lg:text-xl font-semibold">
            <NavigationBackBtn
              url={Path.staffManagementList}
              text={staffDetail ? `${staffDetail?.lastNameKanji} ${staffDetail?.firstNameKanji}` : 'スタッフ情報'}
            />
          </h2>
          {activeTab != 0 && renderCSVButton()}
        </div>

        <div className="w-full h-full py-0 lg:py-2 relative">
          <div className="w-full max-w-[960px] mx-auto">
            <BasicTabs value={activeTab} onChange={handleTabChange}>
              <TabPanel label="基本情報" value={'0'} className="px-4 md:px-0">
                {renderBasicInfo()}
              </TabPanel>
              <TabPanel label="アクセス権" value={'1'} className="px-4 md:px-0">
                {renderAccessRights()}
              </TabPanel>
            </BasicTabs>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
};

export default StaffManagementDetailPage;
