import { useNavigate } from 'react-router-dom';
import { ResponsiveMenuItem } from 'app/components/ResponsiveMenuItem';
import GA from '../../services/googleAnalytics';
const ManagementMenuMobile = ({ menus = [], onNavigate }) => {
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    if (onNavigate) {
      onNavigate(path);
    } else {
      navigate(path);
    }
  };
  return (
    <div className="w-full h-[calc(100%-4rem)]">
      <div className="w-full h-full">
        <div className="w-full h-full flex flex-col gap-4 overflow-visible text-lg">
          {menus.map((menu, index) => (
            <div key={index} className="flex flex-col gap-2 justify-center items-center">
              <div className="w-full flex justify-end">
                <button disabled className="w-full h-9 flex items-center lg:justify-center lg:px-4 py-1">
                  <span>{menu.title}</span>
                </button>
              </div>
              <div className="w-full flex flex-wrap lg:flex-nowrap space-y-4 lg:space-y-0 lg:space-x-4 justify-center py-6">
                {menu.children.map((childMenu, childIndex) => (
                  <ResponsiveMenuItem
                    key={childIndex}
                    isMobile
                    itemTitle={<p>{childMenu.title.replace('\n', '')}</p>}
                    onClick={() => {
                      if (childMenu.eventName) {
                        GA.tracking(childMenu.eventName);
                      }
                      handleNavigate(childMenu.path);
                    }}
                    size="100%"
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ManagementMenuMobile;
