import React from "react";
import { Link } from "react-router-dom";
import Path from "app/route/Path";
import { useDispatch } from "react-redux";
import { setAuthUser, setAuthToken } from "app/redux/authReducer";

function NoPermission() {
  const dispatch = useDispatch();

  const handleLogout = () => {
    const token = {
      accessToken: null,
      refreshToken: null,
    };
    dispatch(setAuthUser(null));
    dispatch(setAuthToken(token));
    window.location = "/login";
  };

  return (
    <div className="w-full h-full min-h-screen flex flex-col items-center justify-center md:max-w-5xl m-auto">
      <p className="text-xl font-semibold mb-8 text-center">
        このページにはアクセスできません。
      </p>

      <Link to={Path.home} className="button-size bg-blue-btn-primary mb-6 w-fit">
        メイン画面に戻る
      </Link>

      <button
        type="button"
        onClick={handleLogout}
        className="button-size bg-gray-btn-secondary w-fit"
      >
        ログアウト
      </button>
    </div>
  );
}

export default NoPermission;
