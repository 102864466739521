import React, { useRef } from 'react';
import clientFormData from 'app/services/api/clientFormData';

const InputImage = ({ imageUrl, setImageUrl, label, type = 'avatars', disabled }) => {
  const inputFileRef = useRef();

  const onFileUpload = (file) => {
    const formData = new FormData();

    formData.append('file', file, file.name);
    formData.append('type', type);

    clientFormData.post('/upload-image', formData).then((res) => {
      if (res.data?.success && res.data?.result) {
        setImageUrl(res.data?.result.imageUrl);
      }
    });
  };

  const onFileChange = (event) => {
    const selectFile = event.target.files[0];
    if (selectFile) {
      onFileUpload(event.target.files[0]);
    }
  };

  const handleDelete = () => {
    // Clear the file input value
    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
    setImageUrl(null);
  };

  return (
    <>
      <span className="text-sm text-gray-700">{label}</span>
      <div className="flex items-center space-x-4">
        <div>
          {/* Icon or Uploaded Image */}
          <div className="w-20 h-20 rounded-full bg-gray-300 flex justify-center items-center overflow-hidden mt-2">
            {imageUrl && <img src={imageUrl} alt="Uploaded" className="w-full h-full object-cover" />}
          </div>
        </div>

        {/* Register Image Button */}
        <div className="flex space-x-2">
          <label
            onClick={() => {
              if (!disabled) {
                inputFileRef.current && inputFileRef.current.click();
              }
            }}
            className={`px-4 py-2 border rounded-md text-gray-700 bg-white hover:bg-gray-100 cursor-pointer ${
              disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            画像を登録する
          </label>
          <input ref={inputFileRef} type="file" className="hidden" accept=".jpg,.jpeg,.png" onChange={onFileChange} />

          {/* Delete Button */}
          <button
            onClick={handleDelete}
            className={`px-4 py-2 border rounded-md text-gray-700 bg-white hover:bg-gray-100 ${
              disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={!imageUrl || disabled}
          >
            削除
          </button>
        </div>
      </div>
    </>
  );
};

export default InputImage;
