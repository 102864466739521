import React, { useEffect, useRef, useState } from 'react';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import Path from 'app/route/Path';
import LoadingProgress from '../../../components/commonUI/LoadingProgress';
import LoadInitError from '../../../components/commonUI/LoadInitError';
import { getAllHistoryOfOrdering } from '../../../services/api/1C';
import NoData from '../../../components/commonUI/NoData';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import HistoryOrderItem from '../../../components/HistoryOrder/HistoryOrderItem';
import SelectField from '../../../components/commonUI/SelectField';
import InputField from '../../../components/commonUI/InputField';

function HistoryOfOrderingPage() {
  const [fetchHistoryOrdering, setFetchHistoryOrdering] = useState({
    isLoading: false,
    data: null,
    error: null,
  });

  const firstRender = useRef(true);

  const sort = {
    desc: -1,
    asc: 1,
  };

  const [filter, setFilter] = useState({
    deliverDateSort: sort.desc,
    customerName: '',
  });

  const loadHistoryOrdering = () => {
    setFetchHistoryOrdering({
      isLoading: true,
      data: null,
      error: null,
    });

    getAllHistoryOfOrdering(filter)
      .then((res) => {
        setFetchHistoryOrdering({
          isLoading: false,
          data: res.data,
          error: null,
        });
      })
      .catch((error) => {
        setFetchHistoryOrdering({
          isLoading: false,
          data: null,
          error: error,
        });
      });
  };

  useEffect(() => {
    loadHistoryOrdering();
  }, [filter.deliverDateSort]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const timeoutId = setTimeout(() => {
      loadHistoryOrdering();
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, [filter.customerName]);

  return (
    <DefaultLayout>
      <MainContentLayout
        pageTitle={
          <>
            <p>頭皮ラボ</p>
          </>
        }
      >
        <div className="w-full h-full relative">
          <div className="w-full h-8 lg:h-12 flex items-center justify-start inset-x-0 top-0">
            <h2 className="text-base lg:text-xl font-semibold">
              <NavigationBackBtn url={Path.scalpLabManagement} text="頭皮データ発送履歴" />
            </h2>
          </div>

          <div className="w-full h-full px-4 md:px-0">
            <div className="flex justify-between items-center mt-4 md:my-6">
              <InputField
                label=""
                name="customerName"
                inputClasses="h-9 !text-xs rounded-[54px] w-[164px] md:w-[300px] !bg-gray-base"
                value={filter.customerName}
                type="text"
                onChange={(newValue) => setFilter({ ...filter, customerName: newValue })}
                placeholder="お客様名で検索"
              />
              <SelectField
                label=""
                inputWrapClasses="!w-auto"
                inputClasses="w-[164px] !text-xs h-9 !px-0"
                value={filter.deliverDateSort}
                setValue={(newValue) => {
                  setFilter({ ...filter, deliverDateSort: newValue });
                }}
                options={[
                  { value: sort.desc, label: '発送日時が新しい順' },
                  { value: sort.asc, label: '発送日時が古い順' },
                ]}
              />
            </div>
            <div className="w-full h-full">
              {fetchHistoryOrdering.isLoading ? <LoadingProgress /> : null}

              {!fetchHistoryOrdering.isLoading && fetchHistoryOrdering.error ? (
                <LoadInitError error={fetchHistoryOrdering.error} />
              ) : null}
              {!fetchHistoryOrdering.isLoading && !fetchHistoryOrdering.error ? (
                Array.isArray(fetchHistoryOrdering.data) && fetchHistoryOrdering.data.length > 0 ? (
                  <div>
                    {fetchHistoryOrdering.data.map((item, index) => (
                      <HistoryOrderItem key={index} data={item?.scalpData} />
                    ))}
                  </div>
                ) : (
                  <NoData />
                )
              ) : null}
            </div>
          </div>
        </div>
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default HistoryOfOrderingPage;
