import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultLayout from '../../../layouts/DefaultLayout';
import MainContentLayout from '../../../layouts/MainContentLayout';
import Path from '../../../route/Path';
import { getSalonDetail } from '../../../services/api/18';
import CommonDialog from '../../../components/CommonDialog';
import NavigationBackBtn from '../../../components/common/NavigationBackButton';
import { MENUS } from './const';
import ManagementMenu from './components/ManagementMenu';

function SystemManagementTopPage() {
  const navigate = useNavigate();
  const [checkSalonRegister, setCheckSalonRegister] = useState(false);

  const handleCheckSalonRegister = async () => {
    try {
      const response = await getSalonDetail();
      const { _id } = response.data;

      if (_id) {
        setCheckSalonRegister(false);
        navigate(Path.systemManagementDetail(_id));
      } else {
        setCheckSalonRegister(true);
      }
    } catch (error) {
      console.error('Error checking salon registration:', error);
    }
  };

  const handleNavigate = (path) => {
    if (path === Path.systemManagementDetail()) {
      handleCheckSalonRegister();
      return;
    }
    navigate(path);
  };

  const handleCloseDialog = () => {
    setCheckSalonRegister(false);
  };

  const handleRegisterSalon = () => {
    navigate(Path.systemManagementRegisterSalon);
  };

  const handleBackToSystem = () => {
    navigate(Path.systemManagement);
  };

  const renderDialogActions = () => (
    <div className="w-full flex items-center justify-end text-white text-base font-semibold">
      <button onClick={handleBackToSystem} type="button" className="button-size bg-gray-btn-secondary mr-4 lg:mr-6">
        戻る
      </button>
      <button onClick={handleRegisterSalon} type="button" className="button-size bg-blue-btn-primary">
        登録
      </button>
    </div>
  );

  return (
    <DefaultLayout>
      <MainContentLayout contentClass="sm:h-full">
        <div className="w-full h-16 lg:h-12 flex items-center justify-start inset-x-0 top-0">
          <h2 className="text-base lg:text-xl font-semibold">
            <NavigationBackBtn url={Path.home} text={'システム設定'} />
          </h2>
        </div>
        <div className="w-full h-full py-[24px] relative">
          <ManagementMenu menus={MENUS} onNavigate={handleNavigate} />
        </div>
        <CommonDialog
          open={checkSalonRegister}
          handleClose={handleCloseDialog}
          title="店舗情報が登録されていません。"
          actions={renderDialogActions()}
        />
      </MainContentLayout>
    </DefaultLayout>
  );
}

export default SystemManagementTopPage;
