import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function MenuItem({ title, icon: Icon, onClick }) {
  const navigate = useNavigate();

  return (
    <button
      key={title}
      onClick={() => onClick(navigate)}
      className="w-full h-[124px] lg:h-[160px hover:shadow-md transition-shadow flex flex-col items-center justify-center border shadow-item2 rounded-xl"
    >
      <div className="w-12 h-12 flex items-center justify-center">
        <Icon />
      </div>
      <span className="text-sm mt-[12px]">{title}</span>
    </button>
  );
}
